import {
  AppIdentifiers,
  IMessageBroker,
  ITopicHeaders,
} from "@sparkware/uc-sdk-core";
import { takeEvery } from "redux-saga/effects";
import {
  GameClosed,
  authenticated,
  authenticationError,
  authenticationRequired,
  gameClosed,
  openCashier,
  openPokerTournament,
  pendingActionRequired,
  resetReconnectionError,
  userKickedFromServer,
} from "../../../actions";
import {
  dispatchAuthenticate,
  dispatchBankrollUpdate,
  dispatchCloseApp,
  dispatchSegmentationUpdate,
  publishAuthenticationFailed,
  publishAuthenticationSuccess,
  publishLoginIntent,
  publishOpenCashier,
} from "./authentication";
import createExternalMessageCommunication from "./createExternalMessageCommunication";
import dispatchBonusShower from "./dispatchBonusShower";
import publishLogout from "./publishLogout";
import { publishPendingAction } from "./publishPendingAction";
import { publishPokerTournament } from "./publishPokerTournament";

export const MB_HEADERS: ITopicHeaders = { publisher: "pokerWebClient" };
export const APP_ID = "PokerClient" as AppIdentifiers;

export default function* externalCommunication(messageBroker: IMessageBroker) {
  const authenticate = createExternalMessageCommunication(
    messageBroker.identity.topics.authenticate,
  );

  const segmentationUpdate = createExternalMessageCommunication(
    messageBroker.player.topics.playerSegmentationUpdate,
  );

  const logout = createExternalMessageCommunication(
    messageBroker.identity.topics.logout, // or logoutSuccess?
  );

  const bankrollUpdate = createExternalMessageCommunication(
    messageBroker.wallet.topics.BankrollUpdate,
  );

  const bonusShowerUpdate = createExternalMessageCommunication(
    messageBroker.communications.topics.HostedClientMessage,
  );

  yield takeEvery(
    [authenticationRequired.type, resetReconnectionError.type],
    publishLoginIntent,
    messageBroker,
  );
  yield takeEvery(
    openPokerTournament.type,
    publishPokerTournament,
    messageBroker,
  );
  yield takeEvery(authenticate, dispatchAuthenticate);
  yield takeEvery(
    authenticated.type,
    publishAuthenticationSuccess,
    messageBroker,
  );
  yield takeEvery(
    authenticationError.type,
    publishAuthenticationFailed,
    messageBroker,
  );
  yield takeEvery(logout, dispatchCloseApp);
  yield takeEvery(segmentationUpdate, dispatchSegmentationUpdate);
  yield takeEvery(bankrollUpdate, dispatchBankrollUpdate);
  yield takeEvery(bonusShowerUpdate, dispatchBonusShower);
  yield takeEvery(openCashier.type, publishOpenCashier, messageBroker);
  yield takeEvery(userKickedFromServer.type, publishLogout, messageBroker);
  yield takeEvery(pendingActionRequired.type, () =>
    publishPendingAction(messageBroker),
  );

  yield takeEvery(gameClosed.type, (action: GameClosed) => {
    if (action.payload.reason === "pendingAction") {
      return publishPendingAction(messageBroker);
    }
  });
}
