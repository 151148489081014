import styled from "@emotion/styled/macro";

export const Plate = styled.div`
  width: 90%;
  background: #1a1a1a;
  border: 0.0625em solid #707070;
  border-radius: 3.75em;
  box-sizing: border-box;
  position: absolute;
  font-size: 0.4em;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.9em;
  user-select: none;
`;

export const SitOutWrapper = styled.div`
  position: relative;

  width: fit-content;
  height: fit-content;

  font-family: "Roboto";
`;
