import { createReducer } from "@reduxjs/toolkit";
import { joined } from "../actions";
import Application from "../types/Application";

const initialValue: Application = {
  cashTableSessionLimit: -1,
  ratHolingPolicyWaitTime: 0,
  features: { internalHud: false },
  reEntryDelay: 0,
};

export const application = createReducer<Application>(initialValue, (builder) =>
  builder.addCase(joined, (state, { payload }) => {
    state.cashTableSessionLimit = payload.CashTableSessionLimit;
    state.ratHolingPolicyWaitTime = payload.RatHolingPolicyWaitTime;
    state.reEntryDelay = payload.ReEntryRegistrationDelay;
    state.features.internalHud = payload.FeaturesOnOffAb.InternalHud === "on";
  }),
);
