import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getHeroSelector, getTurnSelector } from "../store/game/selectors";
import useModalState from "./useModalState";
import usePrevious from "./usePrevious";

export default function useGameModalState<D = undefined>(
  initial: boolean,
  autoclose = false,
) {
  const popupState = useModalState<D>(initial, autoclose);
  const turn = useSelector(getTurnSelector);
  const hero = useSelector(getHeroSelector);

  const previousHeroTurn = usePrevious(
    turn && hero && turn.seatId === hero.seatId,
  );

  useEffect(() => {
    const currentHeroTurn = turn && hero && turn.seatId === hero.seatId;

    if (currentHeroTurn && currentHeroTurn !== previousHeroTurn) {
      popupState.setOpened(false);
    }
  }, [hero, popupState, previousHeroTurn, turn]);

  return popupState;
}
