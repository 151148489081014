import { put, race, select, take } from "redux-saga/effects";
import {
  MAIN_CLIENT_JOIN_APP_ERR,
  MAIN_CLIENT_JOIN_APP_OK,
  joinApp,
} from "../../../../network/protocol/mainServer";
import type {
  JoinAppFault,
  JoinAppSuccess,
  MainServerMessage,
} from "../../../../network/protocol/mainServer/types";
import { joinError, joined } from "../../actions";
import { getConfigurationSelector } from "../../selectors";
import { FullConfiguration } from "../../types/Configuration";
import MessagesCommunication from "./messagesCommunication";

export default function* joinAppToMainServer(
  connection: MessagesCommunication<MainServerMessage>,
) {
  const {
    currentCulture,
    productPackage,
    brand: { id, subBrand },
  }: FullConfiguration = yield select(getConfigurationSelector);

  connection.send(
    joinApp({
      clientVersion: `${process.env.REACT_APP_VERSION || "8.0.0"}`,
      languageId: currentCulture.languageId,
      brandId: id,
      subBrandTo: subBrand,
      productPackageTo: productPackage,
      subBrandFrom: 0,
      productPackageFrom: -1,
    }),
  );

  const [response, error]: [JoinAppSuccess, JoinAppFault] = yield race([
    take(connection.messages, MAIN_CLIENT_JOIN_APP_OK),
    take(connection.messages, MAIN_CLIENT_JOIN_APP_ERR),
  ]);

  if (response) {
    yield put(joined(response.configuration));
  } else if (error) {
    yield put(joinError({ errorCode: error.errorCode, reason: 0 }));
  }
}
