const namespace = "ftdNegotiation";

export const TITLE = `${namespace}.title`;
export const CAPTION = `${namespace}.caption`;
export const POS_COLUMN = `${namespace}.posColumn`;
export const NAME_COLUMN = `${namespace}.nameColumn`;
export const CHIPS_AMOUNT_COLUMN = `${namespace}.chipsAmountColumn`;
export const DEAL_AMOUNT_COLUMN = `${namespace}.dealAmountColumn`;
export const ORIGINAL_PRIZE_AMOUNT_COLUMN = `${namespace}.originalPrizeAmountColumn`;
export const WINNER_PRIZE = `${namespace}.winnerPrize`;
export const REJECT_BUTTON = `${namespace}.rejectButton`;
export const ACCEPT_BUTTON = `${namespace}.acceptButton`;
export const SELECTION_ERROR = `${namespace}.ftdSelectionError`;
