import { call } from "redux-saga/effects";
import { ShowBlastDeposit } from "../../../actions";
import sendEvent from "./sendEvent";

export default function* sendDepositDisplayedEvent(action: ShowBlastDeposit) {
  yield call(
    sendEvent,
    "poker-web",
    "depositPrompted",
    ["Log"],
    action.payload,
  );
}
