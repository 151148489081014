import delimiter from "../delimiter";
import getCardData from "./getCardData";
import parseUncalledBetSection from "./parseUncalledBetSection";
import Card from "./types/Card";

export default function parseStartRoundMessage(source: string) {
  const [roundSection, uncalledBetSection] = source.split(delimiter.pipe);
  const [roundBet, potSize, rakeSize, ...cardsData] = roundSection.split(
    delimiter.section,
  );
  const cards: Card[] = [];

  cardsData.forEach((cardIdString) => {
    const card = getCardData(Number.parseInt(cardIdString));
    if (card) {
      cards.push(card);
    }
  });

  return {
    roundBet: Number.parseInt(roundBet),
    potSize: Number.parseInt(potSize),
    rakeSize: Number.parseInt(rakeSize),
    cards,
    uncalledBet: parseUncalledBetSection(uncalledBetSection),
  } as const;
}
