import { PropsWithChildren, useEffect, useState } from "react";
import { OrientationContext } from "./OrientationContext";

export const SMALL_SCREEN_BREAKPOINT = 1025;

export const OrientationProvider = ({ children }: PropsWithChildren) => {
  const [isLandscape, setIsLandscape] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const landscape = "screen and (orientation: landscape)";
    const smallLandscape = `(max-width: ${SMALL_SCREEN_BREAKPOINT}px)`;

    const landscapeMediaQuery = window.matchMedia(landscape);
    const smallLandscapeMediaQuery = window.matchMedia(smallLandscape);

    setIsLandscape(landscapeMediaQuery.matches);
    setIsSmallScreen(smallLandscapeMediaQuery.matches);

    const handleLandscapeMatch = (event: MediaQueryListEvent) =>
      setIsLandscape(event.matches);

    const handleSmallLandscapeMatch = (event: MediaQueryListEvent) =>
      setIsSmallScreen(event.matches);

    try {
      landscapeMediaQuery.addEventListener("change", handleLandscapeMatch);
      smallLandscapeMediaQuery.addEventListener(
        "change",
        handleSmallLandscapeMatch,
      );

      return () => {
        landscapeMediaQuery.removeEventListener("change", handleLandscapeMatch);
        smallLandscapeMediaQuery.removeEventListener(
          "change",
          handleSmallLandscapeMatch,
        );
      };
    } catch {
      /* 
        - deprecated for modern browsers, some of them may not support these methods
        - currently in use only for iOS 13
        - remove this block when iOS 13 will not be supported
      */
      landscapeMediaQuery.addListener(handleLandscapeMatch);
      smallLandscapeMediaQuery.addListener(handleSmallLandscapeMatch);

      return () => {
        landscapeMediaQuery.removeListener(handleLandscapeMatch);
        smallLandscapeMediaQuery.removeListener(handleSmallLandscapeMatch);
      };
    }
  }, []);

  return (
    <OrientationContext.Provider value={{ isLandscape, isSmallScreen }}>
      {children}
    </OrientationContext.Provider>
  );
};
