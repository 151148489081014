export interface OutgoingMessage {
  type: string;
  body?: string;
}

export interface IncomingMessage {
  type: string;
  body: string;
}

type Message<T extends string = string> = { type: T };
export type ParsersMap<T extends string, P extends Message<T>> = {
  [K in T]: (message: IncomingMessage) => Extract<P, { type: K }>;
};

export enum LogoutReason {
  ClientLeave = 0, //clientLeave
  ClientKicked = 1,
  SocketClose = 2,
  LostPing = 3,
  SendLogoutMsg = 4, //client sent logout message
  LoginError = 5,
  GameTimeout = 6,
  ClientLeaveAck = 7,
  ClientReconnected = 8, // client reconnected before disconnection was noticed         MainCrashed =
  MainCrashed = 9, // Main server crashed and recovered, logging out users that are marked as logged in through this main
  MainIsGoingDown = 10, // Main server is going down, logging out users that are logged in through this main
  ClientDisconnected = 11,
  RemoveEntryFromAMDB = 12,
  KickUserAlreadyLoggedIn = 20,
  KickedToLobbyDueToMobileLocation = 21,
  WebLogout = 22,
  ClientInactivity = 23,
  LoginExpired = 37,
}

export enum TourUnregErrorCode {
  UNREG_PLAYER_FAILED_TOUR_NOT_EXISTS = 1,
  UNREG_PLAYER_FAILED_DUE_TO_TOUR_STATUS = 2,
  UNREG_PLAYER_FAILED_CLIENT_NOT_EXISTS = 3,
  UNREG_PLAYER_FAILED_NON_REFUNDABLE_TOUR = 4,
  UNREG_PLAYER_FAILED_SOME_OTHER_ERROR = 5,
  UNREG_PLAYER_FAILED_REGISTRATION_INPROCESS = 6,
  UNREG_PLAYER_FAILED_UNREG_INPROCESS = 7,
  UNREG_PLAYER_FAILED_TICKET_NORESPONSE_ERROR = 8,
  UNREG_PLAYER_FAILED_TICKET_NON_REFUNDABLE_TOUR = 9,
}
