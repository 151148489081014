import { performRaise } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { BetRaise } from "../../actions";

export default function raise(
  connection: MessagesCommunication<GameServerMessage>,
  action: BetRaise,
) {
  connection.send(performRaise(action.payload.amount));
}
