import AvatarSelectionPopup from "../../components/AvatarSelectionPopup";
import useAvatarSelection from "../../hooks/useAvatarSelection";
import useSettings from "../../hooks/useSettings";
import { selectAvatar } from "../../utils/settingsReducer/actions";

export default function AvatarSelectionPopupContainer() {
  const { showAvatarSelectionPopup, setShowAvatarSelectionPopup } =
    useAvatarSelection();

  const {
    state: { avatarId },
    dispatch,
  } = useSettings();

  return (
    <AvatarSelectionPopup
      open={showAvatarSelectionPopup}
      onClose={() => setShowAvatarSelectionPopup(false)}
      onConfirm={(avatarId) => {
        dispatch(selectAvatar(avatarId));
        setShowAvatarSelectionPopup(false);
      }}
      selectedAvatar={avatarId}
      testId="poker-game-avatar-selection-popup"
    />
  );
}
