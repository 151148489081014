import { ftdDecision } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { ftdDecision as ftdDecisionAction } from "../../actions";

export default function sendFtdDecision(
  connection: MessagesCommunication<GameServerMessage>,
  action: ReturnType<typeof ftdDecisionAction>,
) {
  connection.send(ftdDecision(action.payload.accepted));
}
