// reference https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx#L380

import { PropsWithChildren, useLayoutEffect, useState } from "react";
import { Router as ReactRouter } from "react-router-dom";
import { RouterProps } from "./types";

const Router = (props: PropsWithChildren<RouterProps>) => {
  const { navigator } = props;

  const [state, setState] = useState({
    action: navigator.action,
    location: navigator.location,
  });

  useLayoutEffect(() => navigator.listen(setState), [navigator]);

  return <ReactRouter location={state.location} {...props} />;
};

export default Router;
