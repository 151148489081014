import { NotUndefined } from "@redux-saga/types";
import { multicastChannel, PredicateTakeableChannel } from "redux-saga";
import Communication from "../../../../network/Communication";
import MessageEvent from "../../../../network/events/MessageEvent";
import { OutgoingMessage } from "../../../../network/protocol/types";

interface MessagesChannel<T> extends PredicateTakeableChannel<T> {
  close(): void;
}

export function createMessagesChannel<
  M extends { message: MessageEvent<E> },
  E extends NotUndefined,
>(communication: Communication<M>): MessagesChannel<E> {
  const channel = multicastChannel<E>();
  const { close } = channel;

  function messageHandler(event: MessageEvent<E>) {
    channel.put(event.message);
  }

  const closeMessageChannel = () => {
    communication.removeEventListener("message", messageHandler);
    close();
  };

  communication.addEventListener("message", messageHandler);
  channel.close = closeMessageChannel;

  return channel;
}

export default interface MessagesCommunication<M> {
  send: (message: OutgoingMessage) => void;
  messages: MessagesChannel<M>;
}
