import BlindLevel from "../types/BlindLevel";

const initialState: BlindLevel = {
  currentLevel: NaN,
  currentSmallBlind: NaN,
  currentLowLimit: NaN,
  currentHighLimit: NaN,
  nextSmallBlind: NaN,
  nextLowLimit: NaN,
  nextHighLimit: NaN,
  ante: NaN,
  changedStakesInterval: NaN,
  isNextUplevel: false,
  isMaxUplevel: false,
  nextAnte: NaN,
  blastDuration: NaN,
  levelEndTime: NaN,
  blastEnds: NaN,
  totalChipsOnTable: NaN,
  syncDate: NaN,
};

export default initialState;
