import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useAppConfig from "./useAppConfig";
import useCurrencyFormat from "./useCurrencyFormat";
import useDateFormat from "./useDateFormat";
import useNumberFormat from "./useNumberFormat";
import useTimeFormat from "./useTimeFormat";

const STRING_ID_TAG = "StringID";
const PARAMETER_TAG = "Parameter";
const ORDER_TAG = "Order";
const TYPE_TAG = "Type";
const VALUE_TAG = "Value";
const YEAR_TAG = "Year";
const MONTH_TAG = "Month";
const DAY_TAG = "Day";
const HOUR_TAG = "Hour";
const MINUTE_TAG = "Minute";
const SECOND_TAG = "Second";
const TRANSLATION_TAG = "Translation";
const TRANSLATIONID_TAG = "TranslationId";
const DATA_TAG = "Data";

type ParameterType =
  | "Date"
  | "Time"
  | "Timespan"
  | "String"
  | "Int"
  | "Long"
  | "Ordinal"
  | "Double"
  | "Double2p"
  | "Currency"
  | "UserCurrency"
  | "StringID";

const parser = new DOMParser();

export default function useBFXFormat() {
  const { t, i18n } = useTranslation();
  const formatDate = useDateFormat();
  const formatTime = useTimeFormat();
  const formatNumber = useNumberFormat();
  const formatCurrency = useCurrencyFormat();
  const {
    userAccount,
    culture: { languageId },
  } = useAppConfig();

  return useCallback(
    (xmlString: string) => {
      const xml = parser.parseFromString(xmlString, "application/xml");

      const errorNode = xml.querySelector("parsererror");
      if (errorNode) {
        return undefined;
      }
      const stringId = xml.querySelector(STRING_ID_TAG)?.textContent;

      if (!stringId || !i18n.exists(`serverText.${stringId}`)) {
        return undefined;
      }

      const parametersNodes = xml.getElementsByTagName(PARAMETER_TAG);

      const params = Array.from(parametersNodes).reduce<Record<string, string>>(
        (acc, param) => {
          const order = param.querySelector(ORDER_TAG)?.textContent;
          const type = param.querySelector(TYPE_TAG)?.textContent;

          if (order && type) {
            const parameterType: ParameterType = type as ParameterType;
            let result: string | undefined;
            switch (parameterType) {
              case "Ordinal":
              case "Int": {
                const value = param.querySelector(VALUE_TAG)?.textContent;
                result = value ? Number.parseInt(value).toString() : undefined;
                break;
              }
              case "Double":
              case "Double2p":
              case "Long": {
                const value = param.querySelector(VALUE_TAG)?.textContent;
                result = value
                  ? formatNumber(Number.parseFloat(value))
                  : undefined;
                break;
              }
              case "Date": {
                const yearString = param.querySelector(YEAR_TAG)?.textContent;
                const monthString = param.querySelector(MONTH_TAG)?.textContent;
                const dayString = param.querySelector(DAY_TAG)?.textContent;

                if (yearString && monthString && dayString) {
                  const date = new Date(
                    Number.parseInt(yearString),
                    Number.parseInt(monthString),
                    Number.parseInt(dayString),
                  );
                  result = formatDate(date.getTime());
                }
                break;
              }
              case "Time":
              case "Timespan": {
                const hourString = param.querySelector(HOUR_TAG)?.textContent;
                const minutesString =
                  param.querySelector(MINUTE_TAG)?.textContent;
                const secondsString =
                  param.querySelector(SECOND_TAG)?.textContent;

                if (hourString && minutesString && secondsString) {
                  const date = new Date(0);
                  date.setHours(Number.parseInt(hourString));
                  date.setMinutes(Number.parseInt(minutesString));
                  date.setSeconds(Number.parseInt(secondsString));
                  result = formatTime(date.getTime());
                }
                break;
              }
              case "Currency": {
                const valueString = param.querySelector(VALUE_TAG)?.textContent;
                if (valueString) {
                  result = formatCurrency(Number.parseInt(valueString));
                }
                break;
              }
              case "UserCurrency": {
                const valueString = param.querySelector(VALUE_TAG)?.textContent;
                if (valueString && userAccount) {
                  result = formatCurrency(
                    Number.parseInt(valueString),
                    userAccount.currency,
                  );
                }
                break;
              }
              case "StringID": {
                const value = param.querySelector(VALUE_TAG)?.textContent;
                result = value ? t(`serverText.${value}`) : undefined;
                break;
              }
              default: {
                const value = param.querySelector(VALUE_TAG)?.textContent;
                let translatedString: string | undefined;

                //it could be a translated tour name
                const translations =
                  param.getElementsByTagName(TRANSLATION_TAG);
                if (translations.length > 0) {
                  for (const translation of translations) {
                    const translationIdString =
                      translation.querySelector(TRANSLATIONID_TAG)?.textContent;
                    const data =
                      translation.querySelector(DATA_TAG)?.textContent;

                    if (!translationIdString || !data) continue;

                    const translationId = Number.parseInt(translationIdString);

                    if (translationId === languageId) {
                      translatedString = data;
                    }
                  }
                }
                result = translatedString || value || undefined;
              }
            }

            if (result) {
              acc[order] = result;
            }
          }

          return acc;
        },
        {},
      );

      return t(`serverText.${stringId}`, params);
    },
    [
      formatCurrency,
      formatDate,
      formatNumber,
      formatTime,
      i18n,
      languageId,
      t,
      userAccount,
    ],
  );
}
