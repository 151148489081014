import { PropsWithChildren } from "react";
import Styleable from "../../utils/types/Styleable";
import Screen from "../Screen";
import { FullScreenGenericError } from "./styles";
import { GenericErrorScreenProps } from "./types";

export default function GenericErrorScreen({
  text,
  testId,
  iconSource,
  onBackToLobby,
  children,
  className,
}: PropsWithChildren<Styleable<GenericErrorScreenProps>>) {
  return (
    <Screen onBackToLobby={onBackToLobby}>
      <FullScreenGenericError
        text={text}
        testId={testId}
        iconSource={iconSource}
        className={className}
      >
        {children}
      </FullScreenGenericError>
    </Screen>
  );
}
