const namespace = "tournamentsArena";

const TOUR_STATUS = `${namespace}.tourInfo.tourStatus`;
const TIMER_DISPLAY = `${namespace}.tourInfo.timerDisplay`;
const LABELS = `${namespace}.tourInfo.labels`;
const EXTENSIVE_LABELS = `${namespace}.tourInfo.extensiveLabels`;
const TABS = `${namespace}.tourInfo.tabs`;
const SEARCH_BAR = `${namespace}.searchBar`;
const SORTING_OPTIONS = `${namespace}.sortingOptions`;
const PLAYERS_TAB_CONTENT = `${namespace}.tourInfo.tabs.players`;
const STRUCTURE_TAB_CONTENT = `${namespace}.tourInfo.tabs.structure`;
const SATELLITES_TAB_CONTENT = `${namespace}.tourInfo.tabs.satellites`;
const SUMMARY_TAB_CONTENT = `${namespace}.tourInfo.tabs.summary`;
const PAYOUTS_TAB_CONTENT = `${namespace}.tourInfo.tabs.payouts`;
const BLIND_INTERVAL_TOOLTIP = `${namespace}.tourInfo.blindIntervalTooltip`;
const EMPTY_DATA_CONTENT = `${namespace}.emptyDataContent`;

export const ANNOUNCED_STATUS = `${TOUR_STATUS}.announced`;
export const REGISTERING_STATUS = `${TOUR_STATUS}.registering`;
export const SEATING_STATUS = `${TOUR_STATUS}.seating`;
export const LATE_REGISTRATION_STATUS = `${TOUR_STATUS}.lateRegistration`;
export const RUNNING_STATUS = `${TOUR_STATUS}.running`;
export const COMPLETED_STATUS = `${TOUR_STATUS}.completed`;
export const CANCELLED_STATUS = `${TOUR_STATUS}.cancelled`;
export const ON_BREAK_STATUS = `${TOUR_STATUS}.break`;

export const STARTS_IN = `${TIMER_DISPLAY}.startsIn`;
export const ENDS_IN = `${TIMER_DISPLAY}.endsIn`;
export const RUNNING_FOR = `${TIMER_DISPLAY}.runningFor`;
export const RAN_FOR = `${TIMER_DISPLAY}.ranFor`;

export const BUY_IN = `${LABELS}.buyIn`;
export const FREEROLL = `${LABELS}.freeroll`;
export const REGISTRATION = `${LABELS}.registration`;
export const PLAYERS = `${LABELS}.players`;
export const PRIZEPOOL = `${LABELS}.prizePool`;
export const TYPE = `${LABELS}.type`;

export const REGISTRATION_TIME = `${EXTENSIVE_LABELS}.registrationTime`;
export const START_TIME = `${EXTENSIVE_LABELS}.startTime`;
export const BLIND_INTERVAL = `${EXTENSIVE_LABELS}.blindInterval`;
export const STARTING_CHIPS = `${EXTENSIVE_LABELS}.startingChips`;
export const CURRENT_BLIND = `${EXTENSIVE_LABELS}.currentBlind`;
export const MY_POSITION = `${EXTENSIVE_LABELS}.myPosition`;
export const REGISTER = `${EXTENSIVE_LABELS}.register`;
export const UNREGISTER = `${EXTENSIVE_LABELS}.unregister`;
export const RE_ENTRY_LABEL = `${EXTENSIVE_LABELS}.reentry`;

export const SUMMARY = `${TABS}.summary.label`;
export const PAYOUTS = `${TABS}.payouts.label`;
export const STRUCTURE = `${TABS}.structure.label`;
export const SATELLITES = `${TABS}.satellites.label`;
export const PLAYERS_TAB = `${TABS}.players.label`;

export const NOT_READY = `${PLAYERS_TAB_CONTENT}.notReady`;
export const NO_PLAYERS = `${PLAYERS_TAB_CONTENT}.noPlayers`;
export const PLAYER_POSITION = `${PLAYERS_TAB_CONTENT}.position`;
export const PLAYER_NAME = `${PLAYERS_TAB_CONTENT}.playerName`;
export const PLAYER_CHIPS = `${PLAYERS_TAB_CONTENT}.chips`;
export const PLAYER_BUSTED = `${PLAYERS_TAB_CONTENT}.busted`;

export const LEVEL = `${STRUCTURE_TAB_CONTENT}.level`;
export const SMALL_BLIND = `${STRUCTURE_TAB_CONTENT}.smallBlind`;
export const BIG_BLIND = `${STRUCTURE_TAB_CONTENT}.bigBlind`;
export const ANTE = `${STRUCTURE_TAB_CONTENT}.ante`;
export const TIME_BANK = `${STRUCTURE_TAB_CONTENT}.timeBank`;

export const SATELLITE_NOT_READY = `${SATELLITES_TAB_CONTENT}.notReady`;
export const SATELLITE_NO_PLAYERS = `${SATELLITES_TAB_CONTENT}.noSatellites`;
export const SATELLITE_RUNNING = `${SATELLITES_TAB_CONTENT}.running`;
export const SATELLITE_STAY_TUNED = `${SATELLITES_TAB_CONTENT}.stayTuned`;

export const PLACES_PAID = `${SUMMARY_TAB_CONTENT}.placesPaid`;
export const BASED_ON = `${SUMMARY_TAB_CONTENT}.basedOn`;
export const SEATS = `${SUMMARY_TAB_CONTENT}.seats`;
export const TICKET = `${SUMMARY_TAB_CONTENT}.ticket`;
export const TARGET_TOURNAMENT = `${SUMMARY_TAB_CONTENT}.targetTournament`;
export const GAME_TYPE = `${SUMMARY_TAB_CONTENT}.gameType`;
export const LATE_REGISTRATION = `${SUMMARY_TAB_CONTENT}.lateRegistration`;
export const LATE_REG_AVAILABLE = `${SUMMARY_TAB_CONTENT}.lateRegAvailable`;
export const ENTRIES = `${SUMMARY_TAB_CONTENT}.entries`;
export const RE_ENTRY = `${SUMMARY_TAB_CONTENT}.reEntry`;
export const RE_ENTRY_COST = `${SUMMARY_TAB_CONTENT}.reEntryCost`;
export const RE_ENTRY_DENIED = `${SUMMARY_TAB_CONTENT}.reEntryDenied`;
export const RE_ENTRY_UNLIMITED = `${SUMMARY_TAB_CONTENT}.reEntryUnlimited`;
export const RE_ENTRY_LIMITED = `${SUMMARY_TAB_CONTENT}.reEntryLimited`;
export const RUNNING_START_TIME = `${SUMMARY_TAB_CONTENT}.startTime`;
export const MIN_ENTRANCE = `${SUMMARY_TAB_CONTENT}.minEntrance`;
export const MAX_ENTRANTS = `${SUMMARY_TAB_CONTENT}.maxEntrants`;
export const PLAYERS_LABEL = `${SUMMARY_TAB_CONTENT}.players`;
export const PLAYERS_PER_TABLE = `${SUMMARY_TAB_CONTENT}.playersPerTable`;
export const MAX_PLAYERS_PER_TABLE = `${SUMMARY_TAB_CONTENT}.maxPlayersPerTable`;
export const BREAK = `${SUMMARY_TAB_CONTENT}.break`;
export const BREAK_DESCRIPTION = `${SUMMARY_TAB_CONTENT}.breakDescription`;
export const DEAL_MAKING = `${SUMMARY_TAB_CONTENT}.dealMaking`;
export const DEAL_POSSIBLE = `${SUMMARY_TAB_CONTENT}.dealPossible`;
export const DEAL_NOT_ALLOWED = `${SUMMARY_TAB_CONTENT}.dealNotAllowed`;
export const TOUR_ID = `${SUMMARY_TAB_CONTENT}.tourId`;

export const ENTRANTS = `${PAYOUTS_TAB_CONTENT}.entrants`;
export const PLACES = `${PAYOUTS_TAB_CONTENT}.placesPaid`;
export const POSITION = `${PAYOUTS_TAB_CONTENT}.position`;
export const PAYOUT = `${PAYOUTS_TAB_CONTENT}.payout`;

export const PLACEHOLDER = `${SEARCH_BAR}.placeholder`;

export const SORTING_TITLE = `${namespace}.sortingTitle`;
export const SORT_BY_START_TIME = `${SORTING_OPTIONS}.startTime`;
export const SORT_BY_BUY_IN = `${SORTING_OPTIONS}.buyIn`;
export const SORT_BY_PLAYERS = `${SORTING_OPTIONS}.players`;

export const MY_TOURS_TITLE = `${namespace}.myToursTitle`;

export const NO_TOURNEYS = `${EMPTY_DATA_CONTENT}.noTourneys`;
export const NO_AVAILABLE_TOURS = `${EMPTY_DATA_CONTENT}.noAvailableTours`;
export const TRY_AGAIN = `${EMPTY_DATA_CONTENT}.tryAgain`;

export const INTERVAL_TO_LATE_REG = `${BLIND_INTERVAL_TOOLTIP}.intervalToEndLateReg`;
export const INTERVAL_TO_THE_MONEY = `${BLIND_INTERVAL_TOOLTIP}.intervalToTheMoney`;
export const INTERVAL_TO_FINAL_TABLE = `${BLIND_INTERVAL_TOOLTIP}.intervalToFinalTable`;
export const INTERVAL_TO_LAST_HAND = `${BLIND_INTERVAL_TOOLTIP}.intervalToLastHand`;
