import { call, select } from "redux-saga/effects";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { getHeroWillSitOutSelector } from "../../selectors";
import sitOutOfTour from "./sitOutOfTour";

export default function* sitOutOfTourDelayed(
  connection: MessagesCommunication<GameServerMessage>
) {
  const shouldSitOut: boolean | undefined = yield select(
    getHeroWillSitOutSelector
  );

  if (shouldSitOut) {
    yield call(sitOutOfTour, connection);
  }
}
