import { useContext } from "react";
import { SoundContext } from "../context";

export default function useSound() {
  const soundCtx = useContext(SoundContext);

  if (!soundCtx) {
    throw new Error("useSound must be inside a SoundContext provider");
  }

  return soundCtx;
}
