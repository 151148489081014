import { createReducer, Draft } from "@reduxjs/toolkit";
import { TourUnregErrorCode } from "../../../network/protocol/types";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import findTourRegistration from "../../../utils/findTourRegistration";
import isGameSession from "../../../utils/isGameSession";
import {
  blastRegistrationRestored,
  blastRegistrationSuccess,
  disconnected,
  gameClosed,
  gameCreated,
  gameServerConnected,
  gameServerConnectionError,
  gameStateChanged,
  logout,
  reconnectError,
  resetCurrentSession,
  resetTourRegistration,
  resetUnregistrationError,
  setCurrentSession,
  tourEndOrCancelled,
  tourUnregistrationError,
  tourUnregistrationSuccess,
} from "../actions";
import { ConnectionStatus } from "../types/Connection";
import type Sessions from "../types/Sessions";
import { ActiveSessions } from "../types/Sessions";

const initialState: Sessions = {
  activeSessions: {},
};

function getNextCurrentSession(
  state: Draft<Sessions>,
  gameIdWillDelete: number,
): number | undefined {
  if (state.currentSession === gameIdWillDelete) {
    const activeSessionsList = Object.values(state.activeSessions);

    if (activeSessionsList.length < 2) {
      return undefined;
    }

    const currentIndex = activeSessionsList.findIndex(
      (value) => value.gameId === gameIdWillDelete,
    );

    return (
      activeSessionsList[currentIndex - 1] ||
      activeSessionsList[currentIndex + 1]
    ).gameId;
  }

  return state.currentSession;
}

export const sessions = createReducer<Sessions>(initialState, (builder) => {
  builder
    .addCase(gameCreated, (state, { payload: { context } }) => {
      state.currentSession =
        context.gameType === "cash" || context.gameType === "snap"
          ? context.gameId
          : state.currentSession || context.gameId;

      state.activeSessions = {
        ...state.activeSessions,
        [context.gameId]: context,
      };
    })
    .addCase(gameClosed, (state, { payload: { gameId } }) => {
      const nextCurrentSessionId = getNextCurrentSession(state, gameId);

      const newActiveSessions = { ...state.activeSessions };

      delete newActiveSessions[gameId];

      state.currentSession = nextCurrentSessionId;
      state.activeSessions = newActiveSessions;
    })
    .addCase(gameServerConnected, (state, { payload: { gameId } }) => {
      const session = state.activeSessions[gameId];

      if (session && isGameSession(session)) {
        session.connectionState = ConnectionStatus.SUCCESS;

        state.activeSessions = {
          ...state.activeSessions,
          [session.gameId]: session,
        };
      }
    })
    .addCase(gameServerConnectionError, (state, { payload: { gameId } }) => {
      const session = state.activeSessions[gameId];

      if (session && isGameSession(session)) {
        session.connectionState = ConnectionStatus.ERROR;

        state.activeSessions = {
          ...state.activeSessions,
          [session.gameId]: session,
        };
      }
    })
    .addCase(disconnected, (state) => {
      const newActiveSessions: Draft<ActiveSessions> = {};

      for (const gameId in state.activeSessions) {
        const session = state.activeSessions[gameId];

        if (session && isGameSession(session)) {
          session.connectionState = ConnectionStatus.IDLE;

          newActiveSessions[gameId] = session;
        }
      }

      state.activeSessions = newActiveSessions;
    })
    .addCase(gameStateChanged, (state, { payload }) => {
      const session = state.activeSessions[payload.gameId];

      if (session && isGameSession(session)) {
        session.gameState = payload.state;

        state.activeSessions = {
          ...state.activeSessions,
          [session.gameId]: session,
        };
      }
    })
    .addCase(
      tourUnregistrationSuccess,
      (state, { payload: { regCode, tourId } }) => {
        const registration = findTourRegistration(state.activeSessions, tourId);

        if (registration) {
          state.activeSessions[registration.gameId] = {
            state: "Unregistered",
            gameId: registration.gameId,
            tourId,
            regCode,
          };
        }
      },
    )
    .addCase(resetTourRegistration, (state, { payload: { tourId } }) => {
      const registration = findTourRegistration(state.activeSessions, tourId);

      if (registration) {
        const nextCurrentSessionId = getNextCurrentSession(
          state,
          registration.gameId,
        );

        const newActiveSessions = { ...state.activeSessions };

        delete newActiveSessions[registration.gameId];

        state.currentSession = nextCurrentSessionId;
        state.activeSessions = newActiveSessions;
      }
    })
    .addCase(tourEndOrCancelled, (state, { payload: { tourId } }) => {
      const registration = findTourRegistration(state.activeSessions, tourId);

      if (registration) {
        state.activeSessions[registration.gameId] = {
          state: "Cancelled",
          gameId: registration.gameId,
          tourId,
        };
      }
    })
    .addCase(tourUnregistrationError, (state, { payload: { error } }) => {
      const { tourId, resultCode } = error;

      const registration = findTourRegistration(state.activeSessions, tourId);

      if (registration && registration.state === "Registered") {
        switch (resultCode) {
          case TourUnregErrorCode.UNREG_PLAYER_FAILED_DUE_TO_TOUR_STATUS:
          case TourUnregErrorCode.UNREG_PLAYER_FAILED_TOUR_NOT_EXISTS:
          case TourUnregErrorCode.UNREG_PLAYER_FAILED_UNREG_INPROCESS:
            state.activeSessions[registration.gameId] = {
              state: "Unregistered",
              gameId: registration.gameId,
              tourId,
              regCode: 0,
            };
            break;
          default:
            state.activeSessions[registration.gameId] = {
              state: "UnregistrationError",
              gameId: registration.gameId,
              tourId,
              resultCode,
              error,
            };
        }
      }
    })
    .addCase(resetUnregistrationError, (state, { payload: { tourId } }) => {
      const registration = findTourRegistration(state.activeSessions, tourId);

      if (registration) {
        state.activeSessions[registration.gameId] = {
          state: "Registered",
          gameId: registration.gameId,
          tourId,
        };
      }
    })
    .addCase(resetCurrentSession, (state) => {
      state.currentSession = undefined;
    })
    .addCase(setCurrentSession, (state, { payload: { gameId } }) => {
      state.currentSession = gameId;
    })
    .addMatcher(
      createActionMatcher([
        blastRegistrationRestored,
        blastRegistrationSuccess,
      ]),
      (state, { payload: { gameId, tourId } }) => {
        state.currentSession = gameId;

        state.activeSessions[gameId] = {
          state: "Registered",
          gameId,
          tourId,
        };
      },
    )
    .addMatcher(
      createActionMatcher([reconnectError, logout]),
      () => initialState,
    );
});
