import Players from "../../../store/game/types/Players";
import leagacyMessagesFilter from "../../../utils/leagacyMessagesFilter";
import delimiter from "../delimiter";
import { JoinRingGameError } from "../mainServer/types/JoinRingGameError";
import type { IncomingMessage } from "../types";
import { parseNamesList } from "../utils";
import {
  parseFtdAvailabilityBody,
  parseFtdNegotiationStatusBody,
  parseFtdSelectionStatusBody,
  parseFtdStatusBody,
} from "./ftdParsers";
import getCardData from "./getCardData";
import {
  GH_CS_SET_POKER_SESSIONS_DETAILS,
  GH_CS_SET_POKER_TOURNAMENTS_DETAILS,
  GH_SC_SET_SCSHL_GAME_STR,
  GH_SC_SET_THP_GAME_STR,
  LOBBY_SC_CHAT_EX,
  LOBBY_SC_JOIN_POKER_RING_GAME_ERR_BFX,
  LOBBY_SC_JOIN_POKER_RING_GAME_OK,
  LOBBY_SC_JOIN_POKER_TOURNAMENT_ERR_BFX,
  LOBBY_SC_JOIN_POKER_TOURNAMENT_OK,
  POKER_SC_ADD_KICK_CANDIDATE,
  POKER_SC_ADD_PLAYER,
  POKER_SC_ALL_INS,
  POKER_SC_ALL_IN_EQUITY,
  POKER_SC_BIG_BLIND,
  POKER_SC_BIG_BLIND_POSTED,
  POKER_SC_BREAK_END,
  POKER_SC_BREAK_START,
  POKER_SC_BUY_IN_ERR_BFX,
  POKER_SC_CALL,
  POKER_SC_CHANGE_RSRV_STATUS,
  POKER_SC_CHANGE_RSRV_STATUS_EX_ERR,
  POKER_SC_CHANGE_RSRV_STATUS_EX_OK,
  POKER_SC_CHECK,
  POKER_SC_CHOOSE_TOUR_DEALER,
  POKER_SC_CLEAR_TABLE,
  POKER_SC_CLOSE_TABLE,
  POKER_SC_DEAD_PENALTY_BET_POSTED,
  POKER_SC_DONT_SHOW_CARDS,
  POKER_SC_FINAL_PLAYERS,
  POKER_SC_FINAL_TABLE,
  POKER_SC_FINISHED_TOUR,
  POKER_SC_FOLD,
  POKER_SC_FTD_ALLOWED,
  POKER_SC_FTD_NEGOTIATION_STATUS,
  POKER_SC_FTD_RECONNECTION_DETAILS,
  POKER_SC_FTD_SELECTION_STATUS,
  POKER_SC_FT_DEAL_STATUS,
  POKER_SC_GET_QUICK_POS_EX_ERR,
  POKER_SC_GET_QUICK_POS_EX_OK,
  POKER_SC_H4H_ACTIVATE,
  POKER_SC_H4H_DEACTIVATE,
  POKER_SC_H4H_IN_PROCESS,
  POKER_SC_JOIN_OK,
  POKER_SC_JOIN_POS_FAILED,
  POKER_SC_JOIN_POS_OK,
  POKER_SC_JOIN_TOUR_TABLE,
  POKER_SC_LIST_PLAYERS,
  POKER_SC_NEW_GAME_RESULT,
  POKER_SC_PLAYER_AM_SESSION_DETAILS,
  POKER_SC_PLAYER_FTD_SELECTION_ERR,
  POKER_SC_PLAYER_FTD_SELECTION_OK,
  POKER_SC_PLAYER_REGULATION_DATA,
  POKER_SC_PLAYER_SIT_IN_TOUR,
  POKER_SC_PLAYER_SIT_OUT_TOUR,
  POKER_SC_PUSH_NOTIFICATION,
  POKER_SC_RABBIT_HUNT,
  POKER_SC_RAISE_VAR_POSTED,
  POKER_SC_REMOVE,
  POKER_SC_REMOVE_KICK_CANDIDATE,
  POKER_SC_RETURN_TO_TOUR_LOBBY,
  POKER_SC_RETURN_TO_VIEWER,
  POKER_SC_RING_SNAP_STATE,
  POKER_SC_SET_ANTE_POSTED,
  POKER_SC_SET_CARDS,
  POKER_SC_SET_CREDIT,
  POKER_SC_SET_CREDIT_PENDING,
  POKER_SC_SET_TURN,
  POKER_SC_SET_TURN_TB,
  POKER_SC_SHOW_CARDS,
  POKER_SC_SIT_IN,
  POKER_SC_SIT_IN_LIVE_AND_DEAD_PENALTY,
  POKER_SC_SIT_IN_LIVE_PENALTY,
  POKER_SC_SIT_IN_LIVE_PENALTY_POSTED,
  POKER_SC_SIT_OUT,
  POKER_SC_SMALL_BLIND,
  POKER_SC_SMALL_BLIND_POSTED,
  POKER_SC_START_GAME,
  POKER_SC_START_ROUND_1,
  POKER_SC_START_ROUND_2,
  POKER_SC_START_ROUND_3,
  POKER_SC_START_ROUND_4,
  POKER_SC_START_SHOW_CARD_ROUND,
  POKER_SC_SUCCESSFULL_RE_BUY,
  POKER_SC_TABLE_HIST,
  POKER_SC_THROW,
  POKER_SC_TOUR_ANTE,
  POKER_SC_TOUR_BREAK_ACTIVE,
  POKER_SC_TOUR_BREAK_INACTIVE,
  POKER_SC_TOUR_ENDED,
  POKER_SC_UPDATE_SNAP_POOL_TABLE_ID,
  POKER_SC_UP_LEVEL,
  POKER_SC_WAIT_FOR_ENTER_POSITION_STATE,
} from "./messageTypes";
import parseHandHistoryDate from "./parseHandHistoryDate";
import { parseLimitType } from "./parseLimitType";
import parsePlayer from "./parsePlayer";
import parsePlayerAction from "./parsePlayerAction";
import { parsePokerType } from "./parsePokerType";
import parseQuickPosMessage from "./parseQuickPosMessage";
import parseSeatReservationError from "./parseSeatReservationError";
import parseSeatReservationStatus from "./parseSeatReservationStatus";
import parseStartRoundMessage from "./parseStartRoundMessage";
import parseUncalledBetSection from "./parseUncalledBetSection";
import { ActiveBreakData } from "./types/ActiveBreakData";
import AllInEquityData from "./types/AllInEquityData";
import Card, { KnownCards } from "./types/Card";
import { HandHistory } from "./types/HandHistory";
import JoinPositionError from "./types/JoinPositionError";
import {
  NotificationID,
  PositionInTournament,
  PrizeInfo,
} from "./types/NotificationType";
import ServerSitOutData from "./types/ServerSitOutData";
import Winner from "./types/Winner";

const availablePositions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

export function joinTournamentOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const blockedPositions = new Map<number, boolean>();
  const rawBlockedPositions = params[1];

  rawBlockedPositions.split(delimiter.caret).forEach((element) => {
    const trimmedElement = element.trim();

    if (trimmedElement) {
      blockedPositions.set(Number.parseInt(trimmedElement), true);
    }
  });

  const positions = availablePositions.filter(
    (value) => !blockedPositions.get(value),
  );

  return {
    type: LOBBY_SC_JOIN_POKER_TOURNAMENT_OK,
    tournamentId: params[0],
    positions,
  } as const;
}

export function joinTournamentErrorBfx(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: LOBBY_SC_JOIN_POKER_TOURNAMENT_ERR_BFX,
    tournamentId: params[0],
    error: {
      errorCode: Number.parseInt(params[1]),
      message: params[2],
    },
  } as const;
}

export function joinTourTable(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  const timeSection = params[40].split(delimiter.stringList);
  const statusSection = params[41].split(delimiter.stringList);

  const tourMasksSection = params[28];
  const [subTypeMaskString, attributesMaskString] = tourMasksSection.split(
    delimiter.innerField,
  );

  return {
    type: POKER_SC_JOIN_TOUR_TABLE,
    tournamentId: params[0],
    tableId: params[1],
    pokerType: Number.parseInt(params[2]),
    buyIn: Number.parseInt(params[3]),
    prizePool: Number.parseInt(params[4]),
    maxPlayers: Number.parseInt(params[7]),
    limitType: parseLimitType(params[8]),
    currentLevel: Number.parseInt(params[9]),
    currentSmallBlind: Number.parseInt(params[10]),
    currentLowLimit: Number.parseInt(params[11]),
    currentHighLimit: Number.parseInt(params[12]),
    nextSmallBlind: Number.parseInt(params[13]),
    nextLowLimit: Number.parseInt(params[14]),
    nextHighLimit: Number.parseInt(params[15]),
    ante: Number.parseInt(params[16]),
    changedStakesInterval: Number.parseInt(params[17]),
    isNextUplevel: params[18] === "1",
    isMaxUplevel: params[19] === "1",
    tourName: params[22],
    nextAnte: Number.parseInt(params[23]),
    tournamentType: Number.parseInt(params[24]),
    isObserverChatEnable: params[25] === "1",
    isFinalTable: params[26] === "1",
    isFinalPlayers: params[27] === "1",
    attributesMask: Number.parseInt(attributesMaskString),
    subTypeMask: Number.parseInt(subTypeMaskString),
    tournamentStatus: params[32],
    blastDuration: Number.parseInt(params[33]),
    blastBuyInId: Number.parseInt(params[34]),
    blastMultiplayerId: Number.parseInt(params[35]),
    tourNames: parseNamesList(params[36]),
    tourStartTime: Number(timeSection[0]),
    lateRegDuration: Number(timeSection[1]),
    h4hActive: statusSection[0] === "1",
    inTheMoney: statusSection[1] === "1",
    syncDate: Date.now(),
  } as const;
}

export function joinPositionOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const effectiveStack = parseInt(params[8]);
  const credit = Number.parseInt(params[2]);
  const withdrawnAmounts = params[9] && params[9].split(delimiter.innerField);

  return {
    type: POKER_SC_JOIN_POS_OK,
    seatId: Number.parseInt(params[0]),
    credit,
    isJoinFromDisconnection: params[3] === "1",
    sessionId: Number.parseInt(params[4]),
    sitOutTime: Number.parseInt(params[5]),
    playerInstanceId: Number.parseInt(params[6]),
    snapKickSitOutPlayersInterval: Number.parseInt(params[7]),
    effectiveStack: Number.isNaN(effectiveStack) ? credit : effectiveStack,
    withdrawnRealAmount: Number.parseInt(withdrawnAmounts[0], 10),
    withdrawnRestrictedAmount: Number.parseInt(withdrawnAmounts[1], 10),
  } as const;
}

export function joinPositionErr(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const error: JoinPositionError = {
    reason: Number.parseInt(params[1]),
  };

  return {
    type: POKER_SC_JOIN_POS_FAILED,
    seatId: Number.parseInt(params[0]),
    error,
  } as const;
}

export function increaseBlindLevel(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_UP_LEVEL,
    currentLevel: Number.parseInt(params[0]),
    currentSmallBlind: Number.parseInt(params[1]),
    currentLowLimit: Number.parseInt(params[2]),
    currentHighLimit: Number.parseInt(params[3]),
    nextSmallBlind: Number.parseInt(params[4]),
    nextLowLimit: Number.parseInt(params[5]),
    nextHighLimit: Number.parseInt(params[6]),
    ante: Number.parseInt(params[7]),
    changedStakesInterval: Number.parseInt(params[8]),
    isNextUplevel: params[9] === "1",
    isMaxUplevel: params[10] === "1",
    nextAnte: Number.parseInt(params[11]),
    blastDuration: Number.parseInt(params[12]),
    syncDate: Date.now(),
  } as const;
}

export function listPlayers(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const players: Players = {};

  params.forEach((playerInfo) => {
    if (playerInfo === "") return;
    const player = parsePlayer(playerInfo);
    players[player.seatId] = player;
  });

  return {
    type: POKER_SC_LIST_PLAYERS,
    data: players,
  } as const;
}

export function breakStart(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_BREAK_START,
    totalTime: Number.parseInt(params[0]),
    duration: Number.parseInt(params[1]),
    breakType: Number.parseInt(params[2]),
    syncDate: Date.now(),
  } as const;
}

export function breakEnd(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_BREAK_END,
    breakType: Number.parseInt(params[0]),
    syncDate: Date.now(),
  } as const;
}

export function breakActive(message: IncomingMessage) {
  const params: ActiveBreakData = JSON.parse(message.body);

  return {
    type: POKER_SC_TOUR_BREAK_ACTIVE,
    totalTime: params.OriginalBreakDuration,
    duration: params.SecondsTillEndOfBreak,
    breakType: params.BreakType,
    syncDate: Date.now(),
  } as const;
}

export function breakInactive() {
  return {
    type: POKER_SC_TOUR_BREAK_INACTIVE,
    syncDate: Date.now(),
  } as const;
}

export function startGame(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_START_GAME,
    dealerSeatId: Number.parseInt(params[0]),
    handId: Number.parseInt(params[1]),
    firstToActionPosition: Number.parseInt(params[2]),
    uncalledBetEnabled: params[3] === "1",
  } as const;
}

export function startRound1(message: IncomingMessage) {
  const [roundData, uncalledBetSection] = message.body.split(delimiter.pipe);
  const [roundSection, ...playersBetData] = roundData.split(delimiter.hash);
  const [roundBet, ...cardsData] = roundSection.split(delimiter.section);

  const cards: Card[] = [];
  cardsData.forEach((cardIdString) => {
    const card = getCardData(Number.parseInt(cardIdString));
    if (card) {
      cards.push(card);
    }
  });

  const historyCards: Record<number, Card[]> = {};
  const playersBets: { seatId: number; bet: number }[] = [];

  playersBetData.forEach((playerBetData) => {
    if (playerBetData) {
      /**
       * In the hand history string
       * we can receive players cards
       * in bets section
       */
      const [seatIdString, bet, ...playerCardsData] = playerBetData.split(
        delimiter.section,
      );

      const seatId = Number.parseInt(seatIdString);
      playersBets.push({ seatId, bet: Number.parseInt(bet) });

      // Workaround for hand history
      if (playerCardsData) {
        const playerCards: Card[] = [];
        playerCardsData.forEach((cardIdString) => {
          const card = getCardData(Number.parseInt(cardIdString));
          if (card) {
            playerCards.push(card);
          }
        });

        if (playerCards.length) {
          historyCards[seatId] = playerCards;
        }
      }
    }
  });

  return {
    type: POKER_SC_START_ROUND_1,
    roundBet: Number.parseInt(roundBet),
    cards: cards.length > 0 ? cards : undefined,
    playersBets,
    uncalledBet: parseUncalledBetSection(uncalledBetSection),
    historyCards,
  } as const;
}

export function startRound2(message: IncomingMessage) {
  const roundData = parseStartRoundMessage(message.body);

  return {
    type: POKER_SC_START_ROUND_2,
    ...roundData,
  } as const;
}

export function startRound3(message: IncomingMessage) {
  const roundData = parseStartRoundMessage(message.body);

  return {
    type: POKER_SC_START_ROUND_3,
    ...roundData,
  } as const;
}

export function startRound4(message: IncomingMessage) {
  const roundData = parseStartRoundMessage(message.body);

  return {
    type: POKER_SC_START_ROUND_4,
    ...roundData,
  } as const;
}

export function startShowCardsRound(message: IncomingMessage) {
  const [roundSection, uncalledBetSection] = message.body.split(delimiter.pipe);
  const [roundBet, potSize, rakeSize] = roundSection.split(delimiter.section);

  return {
    type: POKER_SC_START_SHOW_CARD_ROUND,
    roundBet: Number.parseInt(roundBet),
    potSize: Number.parseInt(potSize),
    rakeSize: Number.parseInt(rakeSize),
    uncalledBet: parseUncalledBetSection(uncalledBetSection),
  } as const;
}

export function gameResult(message: IncomingMessage) {
  const [roundSection, ...winnersSection] = message.body.split(delimiter.caret);
  // the bankroll parameter will be ignored because it is always 0
  const [, credit, delay, rakeSize] = roundSection.split(delimiter.hash);
  const winners: Winner[] = [];

  winnersSection.forEach((potWinnersSection) => {
    if (potWinnersSection) {
      // the oneWinner parameter will be ignored
      const [, ...winnersData] = potWinnersSection.split(delimiter.hash);

      winnersData.forEach((winnerData) => {
        if (winnerData) {
          const [playerSection, handSection] = winnerData.split(
            delimiter.tilda,
          );
          const [
            seatIdString,
            payOffString,
            card1IdString,
            card2IdString,
            uncalledBetString,
            rakeString,
          ] = playerSection.split(delimiter.section);

          const cards: Card[] = [];
          const card1 = getCardData(Number.parseInt(card1IdString));
          const card2 = getCardData(Number.parseInt(card2IdString));

          if (card1) {
            cards.push(card1);
          }

          if (card2) {
            cards.push(card2);
          }

          const handData = handSection.split(delimiter.section);
          const hand: Card[] = [];

          handData.forEach((cardIdString) => {
            const card = getCardData(Number.parseInt(cardIdString));
            if (card) {
              hand.push(card);
            }
          });

          winners.push({
            seatId: Number.parseInt(seatIdString),
            payOff: Number.parseInt(payOffString),
            rake: Number.parseInt(rakeString),
            uncalledBet: Number.parseInt(uncalledBetString),
            cards,
            hand,
          });
        }
      });
    }
  });

  return {
    type: POKER_SC_NEW_GAME_RESULT,
    credit: Number.parseInt(credit),
    delay: Number.parseInt(delay),
    rakeSize: Number.parseInt(rakeSize),
    winners,
  } as const;
}

export function clearTable() {
  return {
    type: POKER_SC_CLEAR_TABLE,
  } as const;
}

export function smallBlind(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_SMALL_BLIND,
    seatId: Number.parseInt(params[0]),
    bet: Number.parseInt(params[1]),
    timeOut: Number.parseInt(params[2]),
    syncDate: Date.now(),
  } as const;
}

export function smallBlindPosted(message: IncomingMessage) {
  const playerActionData = parsePlayerAction(message.body);

  return {
    type: POKER_SC_SMALL_BLIND_POSTED,
    ...playerActionData,
  } as const;
}

export function bigBlind(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_BIG_BLIND,
    seatId: Number.parseInt(params[0]),
    bet: Number.parseInt(params[1]),
    timeOut: Number.parseInt(params[2]),
    syncDate: Date.now(),
  } as const;
}

export function bigBlindPosted(message: IncomingMessage) {
  const playerActionData = parsePlayerAction(message.body);

  return {
    type: POKER_SC_BIG_BLIND_POSTED,
    ...playerActionData,
  } as const;
}

export function antePosted(message: IncomingMessage) {
  const playerActionData = parsePlayerAction(message.body);

  return {
    type: POKER_SC_SET_ANTE_POSTED,
    ...playerActionData,
  } as const;
}

export function livePenaltyPosted(message: IncomingMessage) {
  const playerActionData = parsePlayerAction(message.body);

  return {
    type: POKER_SC_SIT_IN_LIVE_PENALTY_POSTED,
    ...playerActionData,
  } as const;
}

export function betCalled(message: IncomingMessage) {
  const playerActionData = parsePlayerAction(message.body);

  return {
    type: POKER_SC_CALL,
    ...playerActionData,
  } as const;
}

export function betRaised(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const credit = Number.parseInt(params[2]);
  const effectiveStack = Number.parseInt(params[4]);
  const roundBet = Number.parseInt(params[1]);
  const lastRaiseAmount = Number.parseInt(params[3]);

  return {
    type: POKER_SC_RAISE_VAR_POSTED,
    seatId: Number.parseInt(params[0]),
    roundBet,
    credit,
    lastRaiseAmount,
    effectiveStack: Number.isNaN(effectiveStack) ? credit : effectiveStack,
  } as const;
}

export function allInsPosted(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  return {
    type: POKER_SC_ALL_INS,
    seatId: Number.parseInt(params[0]),
    bet: Number.parseInt(params[1]),
    potSize: Number.parseInt(params[2]),
    rakeSize: Number.parseInt(params[3]),
    jackPotRake: Number.parseInt(params[4]),
  } as const;
}

export function deadPenaltyPosted(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  return {
    type: POKER_SC_DEAD_PENALTY_BET_POSTED,
    seatId: Number.parseInt(params[0]),
    penalty: Number.parseInt(params[1]),
  } as const;
}

export function tourAnte(message: IncomingMessage) {
  // the first element will be ignored because it is always an empty string
  const [, uncalledBetSection] = message.body.split(delimiter.pipe);

  return {
    type: POKER_SC_TOUR_ANTE,
    uncalledBet: parseUncalledBetSection(uncalledBetSection),
  };
}

export function fold(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_FOLD,
    seatId: Number.parseInt(params[0]),
    reson: Number.parseInt(params[1]),
    showFoldMessage: Number.parseInt(params[2]),
    foldType: Number.parseInt(params[3]),
  } as const;
}

export function check(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_CHECK,
    seatId: Number.parseInt(params[0]),
  } as const;
}

export function showCards(message: IncomingMessage) {
  const [seatIdString, ...cardsData] = message.body.split(delimiter.section);
  const cards: KnownCards = [];

  cardsData.forEach((cardIdString) => {
    if (cardIdString) {
      cards.push(getCardData(Number.parseInt(cardIdString)));
    }
  });

  return {
    type: POKER_SC_SHOW_CARDS,
    seatId: Number.parseInt(seatIdString),
    cards,
  } as const;
}

export function playerDontShowCards(message: IncomingMessage) {
  const [seatIdString, ...cardsData] = message.body.split(delimiter.section);

  const cards: KnownCards = [];

  cardsData.forEach((cardIdString) => {
    if (cardIdString) {
      cards.push(null);
    }
  });

  return {
    type: POKER_SC_DONT_SHOW_CARDS,
    seatId: Number.parseInt(seatIdString),
    cards,
  } as const;
}

export function setTurn(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const actionAmounts = params[7].split(delimiter.caret);

  return {
    type: POKER_SC_SET_TURN,
    seatId: Number.parseInt(params[0]),
    mask: Number.parseInt(params[1]),
    timeOut: Number.parseInt(params[2]),
    bringIn: Number.parseInt(params[3]),
    timeBank: Number.parseInt(params[4]),
    upperLimit: Number.parseInt(params[5]),
    timeBankNotification: Number.parseInt(params[6]),
    callAmount: Number.parseInt(actionAmounts[0]),
    minRaiseAmount: Number.parseInt(actionAmounts[1]),
    maxRaiseAmount: Number.parseInt(actionAmounts[2]),
    syncDate: Date.now(),
  } as const;
}

export function setTurnTimebank(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const actionAmounts = params[5].split(delimiter.caret);

  return {
    type: POKER_SC_SET_TURN_TB,
    seatId: Number.parseInt(params[0]),
    mask: Number.parseInt(params[1]),
    timeOut: Number.parseInt(params[2]),
    bringIn: Number.parseInt(params[3]),
    upperLimit: Number.parseInt(params[4]),
    callAmount: Number.parseInt(actionAmounts[0]),
    minRaiseAmount: Number.parseInt(actionAmounts[1]),
    maxRaiseAmount: Number.parseInt(actionAmounts[2]),
    syncDate: Date.now(),
  } as const;
}

export function playerSitIn(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_SIT_IN,
    seatId: Number.parseInt(params[0]),
  } as const;
}

export function playerSitOut(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_SIT_OUT,
    seatId: Number.parseInt(params[0]),
    syncDate: Date.now(),
  } as const;
}

export function playerSitInTour(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_PLAYER_SIT_IN_TOUR,
    seatId: Number.parseInt(params[0]),
  } as const;
}

export function playerSitOutTour(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_PLAYER_SIT_OUT_TOUR,
    seatId: Number.parseInt(params[0]),
  } as const;
}

export function tableHistory(message: IncomingMessage) {
  const params = message.body.split(delimiter.field);
  const hisoryMessageIdOffset = 53601;
  const messages: IncomingMessage[] = [];

  for (let i = 0; i < params.length; i += 2) {
    messages.push({
      type: (hisoryMessageIdOffset + Number.parseInt(params[i])).toString(10),
      body: params[i + 1],
    });
  }

  return {
    type: POKER_SC_TABLE_HIST,
    messages,
  } as const;
}

export function setCards(message: IncomingMessage) {
  const [seatIdString, ...cardsData] = message.body.split(delimiter.section);
  const cards: Card[] = [];

  cardsData.forEach((cardIdString) => {
    const card = getCardData(Number.parseInt(cardIdString));
    if (card) {
      cards.push(card);
    }
  });

  return {
    type: POKER_SC_SET_CARDS,
    seatId: Number.parseInt(seatIdString),
    cards,
  } as const;
}

export function tourEnded() {
  return {
    type: POKER_SC_TOUR_ENDED,
  } as const;
}

export function finishedTour(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_FINISHED_TOUR,
    position: Number.parseInt(params[0]),
    players: Number.parseInt(params[1]),
    addons: Number.parseInt(params[2]),
    rebuys: Number.parseInt(params[3]),
    addonCost: Number.parseInt(params[4]),
    rebuyCost: Number.parseInt(params[5]),
    prize: Number.parseInt(params[6]),
    rematch: Number.parseInt(params[7]) === 1,
    availableReEntries: Number.parseInt(params[8]),
    lateRegSecondsLeft: Number.parseInt(params[9]),
  } as const;
}

export function addPlayer(message: IncomingMessage) {
  return {
    type: POKER_SC_ADD_PLAYER,
    player: parsePlayer(message.body),
  } as const;
}

export function removePlayer(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_REMOVE,
    seatId: Number.parseInt(params[0]),
  } as const;
}

export function receiveChatMessage(message: IncomingMessage) {
  const messageData = message.body.split(delimiter.field)[0];
  const payload = messageData.split(" >> ");

  return {
    type: LOBBY_SC_CHAT_EX,
    nickname: payload[0],
    message: payload[1],
    syncDate: Date.now(),
  } as const;
}

export function returnToTourLobby() {
  return {
    type: POKER_SC_RETURN_TO_TOUR_LOBBY,
  } as const;
}

export function allInEquityData(message: IncomingMessage) {
  const messageData = message.body
    .split(delimiter.group)[0]
    .split(delimiter.record);

  const equityData: AllInEquityData = {};

  messageData.forEach((message) => {
    const payload = message.split(delimiter.unit);
    payload.pop();

    if (payload.length === 2) {
      const playerPos = Number.parseInt(payload[0]);

      equityData[playerPos] = {
        playerPos,
        winPercentage: Number.parseFloat(payload[1]),
      };
    }
  });

  return {
    type: POKER_SC_ALL_IN_EQUITY,
    equityData,
  } as const;
}
export function rabbitHuntData(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  params.shift();
  params.pop();

  const cards: Card[] = [];
  params.forEach((cardIdString) => {
    const card = getCardData(Number.parseInt(cardIdString));
    if (card) {
      cards.push(card);
    }
  });

  return {
    type: POKER_SC_RABBIT_HUNT,
    cards,
  } as const;
}

export function thrownObject(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_THROW,
    sender: parseInt(params[0]),
    receiver: parseInt(params[1]),
    kind: parseInt(params[2]),
    willCooldownAt: Date.now() + parseInt(params[3]),
  } as const;
}

export function joinRingGameOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  const blockedPositions = new Map<number, boolean>();
  const rawBlockedPositions = params[1];

  rawBlockedPositions.split(delimiter.caret).forEach((element) => {
    const trimmedElement = element.trim();

    if (trimmedElement) {
      blockedPositions.set(Number.parseInt(trimmedElement), true);
    }
  });

  const positions = availablePositions.filter(
    (value) => !blockedPositions.get(value),
  );

  return {
    type: LOBBY_SC_JOIN_POKER_RING_GAME_OK,
    tableId: params[0],
    playerInstanceId: Number.parseInt(params[1]),
    handId: Number.parseInt(params[3]),
    positions,
  } as const;
}

export function joinRingGameErrorBfx(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: LOBBY_SC_JOIN_POKER_RING_GAME_ERR_BFX,
    tableId: params[0],
    error: {
      errorCode: Number.parseInt(params[1]) as JoinRingGameError,
      message: params[2],
    },
  } as const;
}

export function joinRingTable(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_JOIN_OK,
    seatId: Number.parseInt(params[0]),
    credit: Number.parseInt(params[1]),
    tableMinBet: Number.parseInt(params[2]),
    maxRaiseCount: Number.parseInt(params[3]),
    playerName: params[4],
    tableName: params[5],
    maxPlayers: Number.parseInt(params[6]),
    pokerType: parsePokerType(params[7]),
    limitType: parseLimitType(params[8]),
    minBuyIn: Number.parseInt(params[9]),
    maxBuyIn: Number.parseInt(params[10]),
    highLimit: Number.parseInt(params[11]),
    minPlayers: Number.parseInt(params[12]),
    jackPotsString: params[13],
    isObserverChatEnable: params[14] === "1",
    bigBlind: Number.parseInt(params[15]),
    smallBlind: Number.parseInt(params[16]),
    ante: Number.parseInt(params[17]),
    attributes: Number.parseInt(params[18]),
    isSitoutTimeLimitKickFeatureEnabled: params[19] === "1",
    snapRingPoolTableId: Number.parseInt(params[20]),
  } as const;
}

export function resevationStatusChangedOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const data = parseSeatReservationStatus(params[0]);

  return {
    type: POKER_SC_CHANGE_RSRV_STATUS_EX_OK,
    ...data,
  } as const;
}

export function resevationStatusChangedErr(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const data = parseSeatReservationError(params[0]);

  return {
    type: POKER_SC_CHANGE_RSRV_STATUS_EX_ERR,
    syncDate: Date.now(),
    ...data,
  } as const;
}

export function resevationStatusChanged(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_CHANGE_RSRV_STATUS,
    seatId: Number.parseInt(params[0]),
    reserved: params[1] === "1",
  } as const;
}

export function getQuickPosOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const data = parseQuickPosMessage(params[0]);

  return {
    type: POKER_SC_GET_QUICK_POS_EX_OK,
    syncDate: Date.now(),
    ...data,
  } as const;
}

export function getQuickPosErr(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const data = parseQuickPosMessage(params[0]);

  return {
    type: POKER_SC_GET_QUICK_POS_EX_ERR,
    syncDate: Date.now(),
    ...data,
  } as const;
}

export function returnToViewer(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_RETURN_TO_VIEWER,
    seatId: Number.parseInt(params[0]),
  } as const;
}

export function sitInLivePenalty(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_SIT_IN_LIVE_PENALTY,
    seatId: Number.parseInt(params[0]),
    bet: Number.parseInt(params[1]),
    timeOut: Number.parseInt(params[2]),
    syncDate: Date.now(),
  } as const;
}

export function sitInLiveAndDeadPenalty(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_SIT_IN_LIVE_AND_DEAD_PENALTY,
    seatId: Number.parseInt(params[0]),
    bet: Number.parseInt(params[1]),
    penalty: Number.parseInt(params[2]),
    timeOut: Number.parseInt(params[3]),
    syncDate: Date.now(),
  } as const;
}

export function ringSnapState(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_RING_SNAP_STATE,
    tableId: Number.parseInt(params[0]),
    isSnapMode: params[1] === "1",
  } as const;
}

export function buyInErrorBfx(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_BUY_IN_ERR_BFX,
    requestedCredit: Number.parseInt(params[0]),
    error: {
      errorCode: Number.parseInt(params[1]),
      message: params[2],
    },
  } as const;
}

export function setCreditPending() {
  return {
    type: POKER_SC_SET_CREDIT_PENDING,
    pending: true,
  } as const;
}

export function setCredit(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_SET_CREDIT,
    seatId: Number.parseInt(params[0]),
    credit: Number.parseInt(params[1]),
    bankroll: Number.parseInt(params[2]),
    effectiveChipStack: Number.parseInt(params[3]),
  } as const;
}

export function rebuySuccess(message: IncomingMessage) {
  const params = message.body.split(delimiter.innerField);

  return {
    type: POKER_SC_SUCCESSFULL_RE_BUY,
    withdrawnRealAmount: Number.parseInt(params[0]),
    withdrawnRestrictedAmount: Number.parseInt(params[1]),
  } as const;
}

export function sessionDetails(message: IncomingMessage) {
  const jsonMessage = message.body.split(delimiter.section)[0];
  const parsedMessage: { PlayerLimit: string; TicketID: string } =
    JSON.parse(jsonMessage);

  return {
    type: POKER_SC_PLAYER_AM_SESSION_DETAILS,
    playerLimit: Number.parseInt(parsedMessage.PlayerLimit),
  } as const;
}

export function addKickCandidate(message: IncomingMessage) {
  const jsonMessage = message.body.split(delimiter.section)[0];
  const parsedMessage: ServerSitOutData = JSON.parse(jsonMessage);

  return {
    type: POKER_SC_ADD_KICK_CANDIDATE,
    timeUntilKick: Number.parseInt(parsedMessage.ConfiguredTimeTillKick),
    timeUntilBlock: Number.parseInt(parsedMessage.ConfiguredTimeToBlock),
    timeSinceAddToList: Number.parseInt(parsedMessage.TimeSinceAddToList),
    syncDate: Date.now(),
  } as const;
}

export function removeKickCandidate() {
  return {
    type: POKER_SC_REMOVE_KICK_CANDIDATE,
  } as const;
}

export function tableClosed(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_CLOSE_TABLE,
    tableId: Number.parseInt(params[0]),
    instanceId: Number.parseInt(params[1]),
    closeReason: Number.parseInt(params[2]),
  } as const;
}

export function waitForEnterPositionState(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_WAIT_FOR_ENTER_POSITION_STATE,
    waiting: params[0] === "1",
  } as const;
}

export function setRingGameHandsList(message: IncomingMessage) {
  const params = message.body.split(delimiter.star);
  const handsData = params[3].split(delimiter.at);

  //we will ignore the last member
  handsData.pop();

  const hands = handsData.map((entry): HandHistory => {
    const handParams = entry.split(delimiter.pipe);
    const cardsData = handParams[3].split(delimiter.section);
    const cards: Card[] = [];

    cardsData.forEach((cardIdString) => {
      const card = getCardData(Number.parseInt(cardIdString));
      if (card) {
        cards.push(card);
      }
    });

    return {
      date: parseHandHistoryDate(handParams[0]),
      id: Number.parseInt(handParams[11]),
      heroSeatId: Number.parseInt(handParams[2]),
      serverId: handParams[12],
      cards,
      profit: Number.parseInt(handParams[8]),
      pot: Number.parseInt(handParams[19]),
    };
  });

  return {
    type: GH_CS_SET_POKER_SESSIONS_DETAILS,
    hands,
  } as const;
}

export function setTournamentHandsList(message: IncomingMessage) {
  const params = message.body.split(delimiter.star);
  const handsData = params[3].split(delimiter.at);

  //we will ignore the last member
  handsData.pop();

  const hands: HandHistory[] = [];
  handsData.forEach((entry) => {
    const handParams = entry.split(delimiter.pipe);
    const type = Number.parseInt(handParams[1]);
    const isNotHandEntry = type > 0;

    if (isNotHandEntry) {
      return;
    }

    const cardsData = handParams[3].split(delimiter.section);
    const cards: Card[] = [];

    cardsData.forEach((cardIdString) => {
      const card = getCardData(Number.parseInt(cardIdString));
      if (card) {
        cards.push(card);
      }
    });

    hands.push({
      date: parseHandHistoryDate(handParams[0]),
      id: Number.parseInt(handParams[10]),
      heroSeatId: Number.parseInt(handParams[13]),
      serverId: handParams[12],
      cards,
      profit: Number.parseInt(handParams[7]),
      pot: Number.parseInt(handParams[14]),
    });
  });

  return {
    type: GH_CS_SET_POKER_TOURNAMENTS_DETAILS,
    hands,
  } as const;
}

export function setRingHandHistory(message: IncomingMessage) {
  const params = message.body.split(delimiter.hist);
  const gameString = params[2];

  const historyMessages = gameString.split(delimiter.field);
  const hisoryMessageIdOffset = 53601;
  const messages: IncomingMessage[] = [];

  for (let i = 0; i < historyMessages.length; i += 2) {
    const typeString = historyMessages[i];
    const body = historyMessages[i + 1];

    if (typeString) {
      messages.push({
        type: (hisoryMessageIdOffset + Number.parseInt(typeString)).toString(
          10,
        ),
        body,
      });
    }
  }

  messages.reverse();

  return {
    type: GH_SC_SET_THP_GAME_STR,
    messages: messages.filter(leagacyMessagesFilter),
  } as const;
}

export function setTourHandHistory(message: IncomingMessage) {
  const params = message.body.split(delimiter.hist);
  const gameString = params[2];

  const historyMessages = gameString.split(delimiter.field);
  const hisoryMessageIdOffset = 53601;
  const messages: IncomingMessage[] = [];

  for (let i = 0; i < historyMessages.length; i += 2) {
    const typeString = historyMessages[i];
    const body = historyMessages[i + 1];

    if (typeString) {
      messages.push({
        type: (hisoryMessageIdOffset + Number.parseInt(typeString)).toString(
          10,
        ),
        body,
      });
    }
  }

  messages.reverse();

  return {
    type: GH_SC_SET_SCSHL_GAME_STR,
    messages: messages.filter(leagacyMessagesFilter),
  } as const;
}

export function chooseTourDealer(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const cards: { seatId: number; card: Card }[] = [];

  for (let i = 0; i < params.length; i++) {
    const element = params[i];
    const card = getCardData(Number.parseInt(element));

    if (card) {
      cards.push({ seatId: i + 1, card });
    }
  }

  return {
    type: POKER_SC_CHOOSE_TOUR_DEALER,
    cards,
  } as const;
}

export function tournamentFinalTable(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_FINAL_TABLE,
    isFinalTable: params[0] === "1",
  } as const;
}

export function tournamentFinalPlayers(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_FINAL_PLAYERS,
    playersNumber: Number.parseInt(params[0]),
  } as const;
}

export function playerRegulationData(message: IncomingMessage) {
  const params = message.body.split(delimiter.section)[0];
  const parsedMessage: { PlayerRegulationId: string } = JSON.parse(params);

  return {
    type: POKER_SC_PLAYER_REGULATION_DATA,
    participationId: parsedMessage.PlayerRegulationId,
  } as const;
}

export function ftdAvailability(message: IncomingMessage) {
  return {
    type: POKER_SC_FTD_ALLOWED,
    allowed: parseFtdAvailabilityBody(message.body),
  } as const;
}

export function ftdSelectionOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_PLAYER_FTD_SELECTION_OK,
    selected: params[0] === "1",
  } as const;
}

export function ftdSelectionErr(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: POKER_SC_PLAYER_FTD_SELECTION_ERR,
    error: {
      // 0 –  general error;
      // 1 – player reached out of the amount of times allowed to change selection
      errorCode: Number.parseInt(params[0]),
    },
  } as const;
}

export function ftdSelectionStatus(message: IncomingMessage) {
  return {
    type: POKER_SC_FTD_SELECTION_STATUS,
    selections: parseFtdSelectionStatusBody(message.body),
  } as const;
}

export function ftdNegotiationStatus(message: IncomingMessage) {
  return {
    type: POKER_SC_FTD_NEGOTIATION_STATUS,
    ...parseFtdNegotiationStatusBody(message.body),
  } as const;
}

export function ftdStatus(message: IncomingMessage) {
  return {
    type: POKER_SC_FT_DEAL_STATUS,
    decisions: parseFtdStatusBody(message.body),
  } as const;
}

export function ftdReconnectionDetails(message: IncomingMessage) {
  const params = message.body.split(delimiter.pipe);

  return {
    type: POKER_SC_FTD_RECONNECTION_DETAILS,
    allowed: parseFtdAvailabilityBody(params[0]),
    selections: parseFtdSelectionStatusBody(params[2]),
    decisions: parseFtdStatusBody(params[3]),
    negotiationStatus: parseFtdNegotiationStatusBody(params[1]),
  } as const;
}

export function updateSnapPoolTableId(message: IncomingMessage) {
  const params = message.body.split(delimiter.pipe);

  return {
    type: POKER_SC_UPDATE_SNAP_POOL_TABLE_ID,
    tableId: Number.parseInt(params[0]),
  } as const;
}

export function pushNotification(message: IncomingMessage) {
  const [id, data] = message.body.split(delimiter.section);
  const params = data.split(delimiter.field);

  let positionInTournament: PositionInTournament | undefined = undefined;
  let prizeInfo: PrizeInfo | undefined = undefined;

  switch (Number(id)) {
    case NotificationID.PositionInTournament:
      positionInTournament = {
        heroPosition: Number.parseInt(params[0]),
        playersLeft: Number.parseInt(params[1]),
      };
      break;
    case NotificationID.PrizeInfo:
      prizeInfo = {
        paidPlaces: Number.parseInt(params[0]),
        totalRegisteredPlayers: Number.parseInt(params[1]),
        firstPrizeValue: Number.parseInt(params[2]),
        firstPrizeType: Number.parseInt(params[3]),
        lastPrizeValue: Number.parseInt(params[4]),
        lastPrizeType: Number.parseInt(params[5]),
        totalPrizePool: Number.parseInt(params[6]),
        totalKOPrizePool: Number.parseInt(params[7]),
        firstPrizeDescription: params[8],
        lastPrizeDescription: params[9],
      };
      break;
  }

  return {
    type: POKER_SC_PUSH_NOTIFICATION,
    positionInTournament,
    prizeInfo,
  };
}

export function h4hActivated() {
  return {
    type: POKER_SC_H4H_ACTIVATE,
  } as const;
}

export function h4hInProcess() {
  return {
    type: POKER_SC_H4H_IN_PROCESS,
  } as const;
}

export function h4hDeactivated() {
  return {
    type: POKER_SC_H4H_DEACTIVATE,
  } as const;
}
