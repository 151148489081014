import {
  BASE_URL,
  BRAND_ID,
  getDictionaryById,
  INFO_VERSION,
  LobbyDictionaryType,
  TIME_STAMP,
} from "../lobbyServices";
import { LobbyServiceResponse } from "../types";
import { fetchData } from "../utils";
import getSortedBlastPool from "./getSortedBlastPool";

const BLAST_POOL_DATA_API_URL = "api/Dictionaries/GetDictionaries/";

export async function getBlastInfo(blastPoolId: number, abort?: AbortSignal) {
  return getDictionaryById(
    LobbyDictionaryType.BLAST_TEMPLATE,
    blastPoolId,
    abort,
  );
}

export async function getBlastPoolData(abort?: AbortSignal) {
  const lobbyServiceUrl = new URL(BLAST_POOL_DATA_API_URL, BASE_URL);

  lobbyServiceUrl.searchParams.append(
    "Type",
    LobbyDictionaryType.BLAST_TEMPLATE,
  );
  lobbyServiceUrl.searchParams.append("BrandID", BRAND_ID);
  lobbyServiceUrl.searchParams.append("InfoVer", INFO_VERSION);
  lobbyServiceUrl.searchParams.append("LastTimeStamp", TIME_STAMP);

  const responseJson = await fetchData<LobbyServiceResponse>(
    lobbyServiceUrl.href,
    abort,
  ).then((resolve) => {
    return getSortedBlastPool(
      resolve.DictionariesTypes[LobbyDictionaryType.BLAST_TEMPLATE].Data,
    );
  });

  return responseJson;
}
