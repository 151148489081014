import { Draft } from "@reduxjs/toolkit";
import { ActiveSessions, TourRegistration } from "../store/app/types/Sessions";

export default function findTourRegistration(
  sessions: Draft<ActiveSessions> | ActiveSessions,
  tourId: number,
) {
  const entries = Object.values(sessions);

  return entries.find(
    (session) => "tourId" in session && session.tourId === tourId,
  ) as TourRegistration | undefined;
}
