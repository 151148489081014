import { ActionCreator, UnknownAction } from "@reduxjs/toolkit";
import { put, take } from "redux-saga/effects";
import {
  MainServerMessage,
  MainServerMessageType,
} from "../../../../network/protocol/mainServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";

export default function* pushMSMessageHandler(
  messageType: MainServerMessageType,
  actionCreator: ActionCreator<UnknownAction>,
  connection: MessagesCommunication<MainServerMessage>,
) {
  while (true) {
    const { type, ...message }: MainServerMessage = yield take(
      connection.messages,
      messageType,
    );
    yield put(actionCreator(message));
  }
}
