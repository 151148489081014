import { ToggleControl } from "./styles";
import { ToggleProps } from "./types";

export default function Toggle({
  checked,
  onClick,
  dataTestId = "poker-toggle",
}: ToggleProps) {
  return (
    <ToggleControl
      type="checkbox"
      data-testid={dataTestId}
      checked={checked}
      onClick={onClick}
      readOnly
    />
  );
}
