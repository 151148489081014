import { put, select, take } from "redux-saga/effects";
import {
  POKER_SC_SIT_IN_LIVE_AND_DEAD_PENALTY,
  postSitInPenalty,
  waitForFirstToAct,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  LiveAndDeadPenalty,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { liveAndDeadPenalty } from "../../actions";
import {
  getHeroSelector,
  getIsAutoPenaltyPaymentSelector,
} from "../../selectors";
import Player from "../../types/Player";
import { NonRoundAction } from "../../types/Turn";

export default function* liveAndDeadPenaltyHandler(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const penalty: LiveAndDeadPenalty = yield take(
      connection.messages,
      POKER_SC_SIT_IN_LIVE_AND_DEAD_PENALTY,
    );

    const hero: Player | undefined = yield select(getHeroSelector);
    const isAutoPayment: boolean | undefined = yield select(
      getIsAutoPenaltyPaymentSelector,
    );

    if (penalty.seatId === hero?.seatId && isAutoPayment !== undefined) {
      connection.send(isAutoPayment ? postSitInPenalty() : waitForFirstToAct());
    } else {
      yield put(
        liveAndDeadPenalty({
          seatId: penalty.seatId,
          actions: [
            NonRoundAction.WAIT_FOR_FIRST_TO_ACT,
            NonRoundAction.DEAD_PENALTY,
          ],
          timeOut: penalty.timeOut,
          syncDate: penalty.syncDate,
          bet: penalty.bet,
          penalty: penalty.penalty,
        }),
      );
    }
  }
}
