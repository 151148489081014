import { fork } from "redux-saga/effects";
import MainServer from "../../../../network/MainServer";
import authenticationFlow from "./authenticationFlow";
import closeStandaloneAppFlow from "./closeStandaloneAppFlow";
import connectionLoop from "./connectionLoop";

export default function* rootSaga(server: MainServer) {
  yield fork(
    connectionLoop,
    server,
    authenticationFlow,
    closeStandaloneAppFlow,
  );
}
