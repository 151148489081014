import { devToolsEnhancerLogOnlyInProduction } from "@redux-devtools/extension";
import { Store, UnknownAction } from "@reduxjs/toolkit";
import { ISharedTriple8Messaging } from "@sparkware/uc-sdk-core";
import createSagaMiddleware from "redux-saga";
import Communication from "../../../../../network/Communication";
import GameServer from "../../../../../network/GameServer";
import { GameServerEventMap } from "../../../../../network/events";
import { OutgoingMessage } from "../../../../../network/protocol/types";
import initialHeroState from "../../../../game/initialStates/hero";
import cashGame from "../../../../game/sagas/ringGames/cashGame";
import snapGame from "../../../../game/sagas/ringGames/snapGame";
import internalHudFlow from "../../../../game/sagas/shared/internalHudFlow";
import blastTournament from "../../../../game/sagas/tournaments/blastTournament";
import multiTableTournament from "../../../../game/sagas/tournaments/multiTableTournament";
import createGameStore, { AnyGameState } from "../../../../game/store";
import Game, { GameType } from "../../../../game/types/Game";
import { RingGameData, TournamentStartedData } from "../../../actions/types";
import { ConnectionStatus } from "../../../types/Connection";
import GameState from "../../../types/GameState";

export interface GameContext {
  gameId: number;
  serverId: string;
  gameInstanceId: string;
  gameServer: GameServer;
  gameType: GameType;
  store: Store<AnyGameState, UnknownAction>;
  connectionState: ConnectionStatus;
  gameState: GameState;
  close: () => void;
}

const getGameSaga = (
  type: GameType,
): ((server: Communication<GameServerEventMap>) => Generator) => {
  switch (type) {
    case "blast":
      return blastTournament;
    case "mtt":
    case "snapTour":
      return multiTableTournament;
    case "cash":
      return cashGame;
    case "snap":
      return snapGame;
  }
};

export default function gameFactory(
  gameId: number,
  gameData: TournamentStartedData | RingGameData,
  sender: (message: OutgoingMessage) => void,
  heroName: string,
  sessionLimit: number,
  triple8DataPromise: Promise<ISharedTriple8Messaging["subscribe"]> | null,
): GameContext {
  const {
    gameType,
    serverId,
    gameInstanceId,
    isOpenSession,
    playerInstanceId = 0,
  } = gameData;

  const gameInfo: Game = {
    gameInstanceId,
    type: gameType,
    serverId,
    inializedByOpenSession: isOpenSession,
  };

  const devToolsEnhancers = devToolsEnhancerLogOnlyInProduction({
    name: gameInfo.gameInstanceId,
  });

  const gameServer = new GameServer(sender);
  const sagaMiddleware = createSagaMiddleware();
  const store = createGameStore(
    {
      game: gameInfo,
      hero: {
        ...initialHeroState,
        name: heroName,
        playerInstanceId,
        initialSessionLimit: sessionLimit,
        sessionLimit,
      },
    },
    [sagaMiddleware],
    [devToolsEnhancers],
  );

  const task = sagaMiddleware.run(function* () {
    sagaMiddleware.run(getGameSaga(gameType), gameServer);

    if (!triple8DataPromise) return;

    sagaMiddleware.run(internalHudFlow, gameServer, triple8DataPromise);
  });

  return {
    gameId,
    serverId,
    gameInstanceId,
    gameServer,
    gameType,
    store,
    connectionState: ConnectionStatus.IDLE,
    gameState: "play",
    close: () => {
      task.cancel();
    },
  };
}
