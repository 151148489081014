import { put, select, take } from "redux-saga/effects";
import {
  GH_SC_SET_THP_GAME_STR,
  getRingHandHistory,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  SetRingHandHistory,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { HandHistorySelected, handHistoryReceived } from "../../actions";
import { getServerIdSelector } from "../../selectors";

export default function* requestRingHandHistory(
  connection: MessagesCommunication<GameServerMessage>,
  { payload: { id } }: HandHistorySelected,
) {
  const serverId: string = yield select(getServerIdSelector);

  connection.send(getRingHandHistory(id, serverId));

  const result: SetRingHandHistory = yield take(
    connection.messages,
    GH_SC_SET_THP_GAME_STR,
  );
  yield put(handHistoryReceived({ id, messages: result.messages }));
}
