/**
 * use https://www.htmlcsscolor.com/ for naming.
 * if there is a color with no naming but closed to an existing one
 * (the website will suggest) put the name + index
 */

const colors = {
  white: "#FFFFFF",
  black: "#000000",
  black2: "#0F0F0F",
  nero: "#1A1A1A",
  red: "#E20000",
  tangerineYellow: "#FFC710",
  mangoTango: "#E17309",
  dodgerBlue: "#007CF7",
  dodgerBlue2: "#15A7FF",
  dodgerBlue3: "#1088FF",
  navyBlue: "#0265CB",
  navyBlue2: "#084CD2",
  scienceBlue: "#0370DD",
  tropicalBlue: "#BEDAF5",
  blueGreen: "#0F9BBF",
  blackRussian: "#00000020",
  paynesGrey: "#424246",
  suvaGrey: "#8D8D8D",
  silver: "#C2C2C2",
  charcoal: "#474747",
  charcoal2: "#4A4A4A",
  manatee: "#8C8D8F",
  lightGrey: "#D2D2D2",
  darkGrey: "#A4A4A4",
  snow: "#FFFFFF20",
  sanFelix: "#176D23",
  endeavour: "#17589A",
  eclipse: "#3D3D3D",
  eclipse2: "#3C3C3C",
  grey: "#7B7B7B",
  matterhorn: "#525252",
};

export default colors;
