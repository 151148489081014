import { call, put, race, select, take } from "redux-saga/effects";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { creditPending, gameResult, tableWillClear } from "../../actions";
import { getHeroSelector } from "../../selectors";
import Player from "../../types/Player";
import cashBuyIn from "./cashBuyIn";

export default function* manualRebuy(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const hero: Player | undefined = yield select(getHeroSelector);

  if (hero?.cards) {
    yield put(creditPending({ pending: true }));
    yield race([take(gameResult.type), take(tableWillClear.type)]);
    yield put(creditPending({ pending: false }));
  }

  yield call(cashBuyIn, connection);
}
