enum PlayerLastActionKeys {
  ANTE = "playerLastAction.ante",
  SMALL_BLIND = "playerLastAction.smallBlind",
  BIG_BLIND = "playerLastAction.bigBlind",
  FOLD = "playerLastAction.fold",
  CHECK = "playerLastAction.check",
  CALL = "playerLastAction.call",
  RAISE = "playerLastAction.raise",
  BET = "playerLastAction.bet",
  ALL_IN = "playerLastAction.allIn",
  CAP = "playerLastAction.cap",
  PENALTY = "playerLastAction.penalty",
}

export { PlayerLastActionKeys };
