import { put, select, take } from "redux-saga/effects";
import {
  GH_CS_SET_POKER_SESSIONS_DETAILS,
  getRingGameHands,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  RingGameHandsList,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { handsList } from "../../actions";
import { getSessionIdSelector, getTableSelector } from "../../selectors";
import Table from "../../types/Table";

export default function* getHandsList(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const sessionId: number = yield select(getSessionIdSelector);
  const table: Table = yield select(getTableSelector);

  if (sessionId) {
    connection.send(getRingGameHands(sessionId, table.pokerType));

    const handsListMessage: RingGameHandsList = yield take(
      connection.messages,
      GH_CS_SET_POKER_SESSIONS_DETAILS,
    );

    if (handsListMessage.hands.length > 0) {
      yield put(handsList(handsListMessage.hands));
    }
  }
}
