import {
  TourAttribute,
  TourSubType,
} from "../network/protocol/mainServer/types";

export const supportedTourAttributes =
  TourAttribute.UP_LEVEL_BY_GAMES |
  TourAttribute.RE_BUY_IN_BY_GAMES |
  TourAttribute.ADD_ON_BY_GAMES |
  TourAttribute.PARTIAL_RE_BUY_IN |
  TourAttribute.PARTIAL_ADD_ON |
  TourAttribute.PRIZES_BY_RATIO |
  TourAttribute.ENABLE_RE_BUY_IN |
  TourAttribute.ENABLE_ADD_ON |
  TourAttribute.SATELITE_TYPE |
  TourAttribute.ENABLE_STOP_TOURNAMENT |
  TourAttribute.NO_PRIZE_POOL_DISTRIBUTION |
  TourAttribute.ALLOW_LATE_REGISTRATION |
  TourAttribute.CHANGE_LAST_TABLE_BACKGROUND |
  TourAttribute.CHANGE_LAST_2_PLAYERS_DISPLAY |
  TourAttribute.ENABLE_OBSERVERS_CHAT |
  TourAttribute.SYNC_BREAK |
  TourAttribute.BRANDED_TOURNAMENT |
  TourAttribute.PRIVATE_TOUR |
  TourAttribute.DEEP_STACK |
  TourAttribute.CASH_BUYIN |
  TourAttribute.TICKET_BUYIN |
  TourAttribute.SHOW_ME_TOUR |
  TourAttribute.REGULAR_SPEED |
  TourAttribute.TURBO_SPEED |
  TourAttribute.SUPER_TURBO_SPEED |
  TourAttribute.RESTRICT_SATELLITE_REGISTRATION;

export const supportedSubType =
  TourSubType.FINAL_TABLE_DEAL |
  TourSubType.BLAST |
  TourSubType.IMMEDIATE_BONUS |
  TourSubType.SECOND_CHANCE |
  TourSubType.BROADCAST |
  TourSubType.RE_ENTRY;

const isExperimentalTournamentsEnabled =
  process.env.REACT_APP_TOURNAMENTS_SUPPORT === "enabled";

export function isTourSupported(attributes: number, subType: number) {
  if (!isExperimentalTournamentsEnabled) {
    return (subType & TourSubType.BLAST) > 0;
  }

  return (
    (attributes & ~supportedTourAttributes) === 0 &&
    (subType & ~supportedSubType) === 0
  );
}
