import { ReactNode } from "react";
import useModalState from "../hooks/useModalState";
import { AvatarSelectionContext } from "./AvatarSelectionContext";

export function AvatarSelectionProvider({ children }: { children: ReactNode }) {
  const { opened, setOpened } = useModalState(false, true);

  return (
    <AvatarSelectionContext.Provider
      value={{
        showAvatarSelectionPopup: opened,
        setShowAvatarSelectionPopup: setOpened,
      }}
    >
      {children}
    </AvatarSelectionContext.Provider>
  );
}
