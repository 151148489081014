import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  betCalled,
  betRaised,
  bigBlind,
  bigBlindPosted,
  checked,
  deadPenaltyPosted,
  folded,
  heroDontShowCards,
  heroShowCards,
  liveAndDeadPenalty,
  livePenalty,
  livePenaltyPosted,
  playerCardsShowed,
  playerDontShowCards,
  setTurn,
  setTurnTimebank,
  smallBlind,
  smallBlindPosted,
  stateRestored,
} from "../actions";
import Turn from "../types/Turn";

const initialState = null;

export const turn = createReducer<Turn | null>(initialState, (builder) => {
  builder
    .addCase(stateRestored, (_, { payload }) => payload.turn)
    .addMatcher(
      createActionMatcher([
        setTurn,
        setTurnTimebank,
        smallBlind,
        bigBlind,
        livePenalty,
        liveAndDeadPenalty,
      ]),
      (_, { payload }) => payload,
    )
    .addMatcher(
      createActionMatcher([
        setTurn,
        setTurnTimebank,
        smallBlind,
        bigBlind,
        livePenalty,
        liveAndDeadPenalty,
      ]),
      (_, { payload }) => payload,
    )
    .addMatcher(
      createActionMatcher([
        smallBlindPosted,
        bigBlindPosted,
        livePenaltyPosted,
        deadPenaltyPosted,
        betCalled,
        betRaised,
        folded,
        checked,
        playerCardsShowed,
        heroShowCards,
        heroDontShowCards,
        playerDontShowCards,
      ]),
      () => initialState,
    );
});
