import { fork, takeEvery } from "redux-saga/effects";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  changeSeatReservation,
  joinPosition as joinPositionAction,
  ringGameJoined,
  ringTableJoined,
} from "../../actions";
import joinCashTable from "./joinCashTable";
import joinPosition from "./joinPosition";
import requestAvailableSeat from "./requestAvailableSeat";
import ringGameFlow from "./ringGameFlow";
import updateSeatReservation from "./updateSeatReservation";

export default function* cashGameFlow(
  connection: MessagesCommunication<GameServerMessage>,
) {
  yield takeEvery(ringTableJoined.type, requestAvailableSeat, connection);
  yield takeEvery(
    changeSeatReservation.type,
    updateSeatReservation,
    connection,
  );
  yield takeEvery(joinPositionAction.type, joinPosition, connection);

  yield takeEvery(ringGameJoined.type, joinCashTable, connection);

  yield fork(ringGameFlow, connection);
}
