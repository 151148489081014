import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  blindLevelIncreased,
  breakEnd,
  playersListed,
  stateRestored,
  tourTableJoined,
} from "../actions";
import initialState from "../initialStates/blindLevels";
import BlindLevel from "../types/BlindLevel";
import { BreakType } from "../types/Break";

export const blindLevel = createReducer<BlindLevel>(initialState, (builder) => {
  builder
    .addCase(breakEnd, (state, { payload: { breakType, syncDate } }) => {
      if (breakType === BreakType.SEATING) {
        state.levelEndTime = syncDate + state.changedStakesInterval * 1000;
        state.blastEnds = syncDate + state.blastDuration * 1000;
        state.syncDate = syncDate;
      }
    })
    .addCase(playersListed, (state, { payload: players }) => {
      let totalTableCredit = 0;
      for (const seat in players) {
        const player = players[seat];
        totalTableCredit += player.credit;
      }

      state.totalChipsOnTable = totalTableCredit;
    })
    .addCase(stateRestored, (_, { payload: { blindLevel } }) => {
      if ("blastEnds" in blindLevel) {
        return blindLevel;
      }
    })
    .addMatcher(
      createActionMatcher([tourTableJoined, blindLevelIncreased]),
      (state, { payload }) => {
        return {
          currentLevel: payload.currentLevel,
          currentSmallBlind: payload.currentSmallBlind,
          currentLowLimit: payload.currentLowLimit,
          currentHighLimit: payload.currentHighLimit,
          nextSmallBlind: payload.nextSmallBlind,
          nextLowLimit: payload.nextLowLimit,
          nextHighLimit: payload.nextHighLimit,
          ante: payload.ante,
          changedStakesInterval: payload.changedStakesInterval,
          isNextUplevel: payload.isNextUplevel,
          isMaxUplevel: payload.isMaxUplevel,
          nextAnte: payload.nextAnte,
          blastDuration: payload.blastDuration,
          levelEndTime: payload.syncDate + payload.changedStakesInterval * 1000,
          blastEnds: payload.syncDate + payload.blastDuration * 1000,
          totalChipsOnTable: state.totalChipsOnTable,
          syncDate: payload.syncDate,
        };
      },
    );
});
