import { IBankrollMessageData } from "@sparkware/uc-sdk-core";
import { put } from "redux-saga/effects";
import { bankrollUpdated, bankrollUpdateError } from "../../../../actions";

export function* dispatchBankrollUpdate({
  realBR,
  pokerRM,
}: IBankrollMessageData) {
  try {
    if (!Number.isFinite(realBR))
      throw new Error(`realBR: ${realBR} should be finite number`);
    if (!Number.isFinite(pokerRM))
      throw new Error(`pokerRM: ${pokerRM} should be finite number`);

    yield put(bankrollUpdated({ real: realBR, bonus: pokerRM }));
  } catch (error) {
    yield put(bankrollUpdateError({ message: String(error) }));
  }
}
