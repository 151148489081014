import { call } from "redux-saga/effects";
import Communication from "../../../../network/Communication";
import { GameServerEventMap } from "../../../../network/events";
import connectionLoop from "../shared/connectionLoop";
import multiTableTournamentFlow from "./multiTableTournamentFlow";

export default function* multiTableTournament(
  server: Communication<GameServerEventMap>,
) {
  yield call(connectionLoop, server, multiTableTournamentFlow);
}
