import {
  Middleware,
  StoreEnhancer,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { blastReducers, ringGameReducers } from "./reducers";
import BlindData from "./types/BlindData";
import BlindLevel from "./types/BlindLevel";
import Break from "./types/Break";
import Game from "./types/Game";
import GameHistory from "./types/GameHistory";
import Hero from "./types/Hero";
import Players from "./types/Players";
import { PotState } from "./types/Pots";
import PreActions from "./types/PreActions";
import Reservation from "./types/Reservation";
import Seats from "./types/Seats";
import Statistics from "./types/Statistics";
import Table from "./types/Table";
import Tournament from "./types/Tournament";
import Turn from "./types/Turn";

export const tournamentRootReducer = combineReducers(blastReducers);
export const ringGameRootReducer = combineReducers(ringGameReducers);
export interface GameState {
  game: Game;
  table: Table;
  players: Players;
  seats: Seats;
  pots: PotState;
  hero: Hero;
  turn: Turn | null;
  preActions: PreActions;
  history: GameHistory;
  statistics: Statistics;
}

export interface RingGameState extends GameState {
  blindLevel: BlindData;
  reservation: Reservation;
}

export interface TournamentGameState extends GameState {
  tournament: Tournament;
  blindLevel: BlindLevel;
  gameBreak: Break | null;
}

export type AnyGameState = RingGameState | TournamentGameState;

export default function createGameStore(
  initialState: Pick<GameState, "game" | "hero"> & Partial<GameState>,
  extraMiddleware?: Middleware[],
  extraEnhancers?: StoreEnhancer[],
) {
  if (initialState.game.type === "blast" || initialState.game.type === "mtt") {
    return configureStore({
      reducer: tournamentRootReducer,
      preloadedState: initialState,
      devTools: false,
      middleware: extraMiddleware
        ? (getDefaultMiddleware) =>
            getDefaultMiddleware({
              serializableCheck: false,
              thunk: false,
            }).concat(...extraMiddleware)
        : undefined,
      enhancers: extraEnhancers
        ? (getDefaultEnhancers) =>
            getDefaultEnhancers().concat(...extraEnhancers)
        : undefined,
    });
  }

  return configureStore({
    reducer: ringGameRootReducer,
    preloadedState: initialState,
    devTools: false,
    middleware: extraMiddleware
      ? (getDefaultMiddleware) =>
          getDefaultMiddleware({
            serializableCheck: false,
            thunk: false,
          }).concat(...extraMiddleware)
      : undefined,
    enhancers: extraEnhancers
      ? (getDefaultEnhancers) => getDefaultEnhancers().concat(...extraEnhancers)
      : undefined,
  });
}
