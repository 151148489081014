import GameState from "../../store/app/types/GameState";

export default class GameStateEvent extends Event {
  static readonly GAME_STATE_CHANGED = "gameStateChanged" as const;

  state: GameState;

  constructor(state: GameState) {
    super(GameStateEvent.GAME_STATE_CHANGED);

    this.state = state;
  }
}
