export const PORTRAIT_TABLE_WIDTH = 340;
export const PORTRAIT_TABLE_HEIGHT = 443;
export const LANDSCAPE_TABLE_WIDTH = 524;
export const LANDSCAPE_TABLE_HEIGHT = 239;

export const PORTRAIT_GAME_WIDTH = 340;
export const PORTRAIT_GAME_HEIGHT = 566;

export const LANDSCAPE_GAME_WIDTH = 640;
export const LANDSCAPE_GAME_HEIGHT = 300;
