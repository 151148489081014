import { select, take } from "redux-saga/effects";
import { PROXY_CLIENT_GENERAL_GAME_MSG } from "../../../../../network/protocol/mainServer";
import {
  GeneralGameMessage,
  MainServerMessage,
} from "../../../../../network/protocol/mainServer/types";
import isGameSession from "../../../../../utils/isGameSession";
import { getSessionsSelector } from "../../../selectors";
import MessagesCommunication from "../messagesCommunication";

export default function* routeGameMessages(
  connection: MessagesCommunication<MainServerMessage>,
) {
  while (true) {
    const message: GeneralGameMessage = yield take(
      connection.messages,
      PROXY_CLIENT_GENERAL_GAME_MSG,
    );
    const games: ReturnType<typeof getSessionsSelector> =
      yield select(getSessionsSelector);
    const gameContext = games[message.gameId];

    if (gameContext && isGameSession(gameContext)) {
      gameContext.gameServer.dispatchMessage(message.gameMessage);
    }
  }
}
