import isRoundTurn from "../../../../utils/isRoundTurn";
import { GameState } from "../../store";
import { TurnAction } from "../../types/Turn";
import getHeroTurn from "./getHeroTurn";

export default function getHeroFocusableTurn(state: GameState) {
  const turn = getHeroTurn(state);

  const showCardsRound =
    turn && isRoundTurn(turn) && turn.actions.includes(TurnAction.SHOW_CARDS);

  return !showCardsRound ? turn : undefined;
}
