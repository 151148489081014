import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import useModalState from "../hooks/useModalState";
import { getTurnSelector } from "../store/game/selectors";
import { KeyboardContext } from "./KeyboardContext";

export function KeyboardProvider({ children }: { children: ReactNode }) {
  const { opened, setOpened } = useModalState(false, true);
  const turn = useSelector(getTurnSelector);

  useEffect(() => {
    if (turn === null) {
      setOpened(false);
    }
  }, [setOpened, turn]);

  return (
    <KeyboardContext.Provider
      value={{ showKeyboard: opened, setShowKeyboard: setOpened }}
    >
      {children}
    </KeyboardContext.Provider>
  );
}
