import { fork, takeEvery } from "redux-saga/effects";
import {
  POKER_SC_ADD_KICK_CANDIDATE,
  POKER_SC_ADD_PLAYER,
  POKER_SC_CHANGE_RSRV_STATUS,
  POKER_SC_PLAYER_AM_SESSION_DETAILS,
  POKER_SC_REMOVE_KICK_CANDIDATE,
  POKER_SC_RETURN_TO_VIEWER,
  POKER_SC_RING_SNAP_STATE,
  POKER_SC_SET_CREDIT,
  POKER_SC_SET_CREDIT_PENDING,
  POKER_SC_SIT_IN,
  POKER_SC_SIT_OUT,
  POKER_SC_WAIT_FOR_ENTER_POSITION_STATE,
} from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  addKickCandidate,
  creditPending,
  gameResult,
  handHistorySelected,
  joinNow as joinNowAction,
  manualRebuy as manualRebuyAction,
  playerAdded,
  playerSatIn,
  playerSatOut,
  postLiveAndDeadPenalty,
  postLivePenalty,
  removeKickCandidate,
  returnToViewer,
  ringSnapState,
  seatReservationChanged,
  sessionDetails,
  setCredit,
  sitIn,
  sitOut,
  tableWillClear,
  waitForFirstToAct,
  waitingForEnterPositionChanged,
} from "../../actions";
import genericGameFlow from "../shared/genericGameFlow";
import pushMessageHandler from "../shared/pushMessageHandler";
import requestRingHandHistory from "../shared/requestRingHandHistory";
import autoRebuy from "./autoRebuy";
import bigBlindHandler from "./bigBlindHandler";
import getHandsList from "./getHandsList";
import joinNow from "./joinNow";
import joinToRingGame from "./joinToRingGame";
import liveAndDeadPenaltyHandler from "./liveAndDeadPenaltyHandler";
import deadPenaltyHandler from "./livePenaltyHandler";
import manualRebuy from "./manualRebuy";
import sitInPenalty from "./sitInPenalty";
import sitInToCashTable from "./sitInToCashTable";
import sitOutOfCashTable from "./sitOutOfCashTable";
import sitOutOfCashTableDelayed from "./sitOutOfCashTableDelayed";
import smallBlindHandler from "./smallBlindHandler";
import callWaitForFirstToAct from "./waitForFirstToAct";

export default function* ringGameFlow(
  connection: MessagesCommunication<GameServerMessage>,
) {
  yield fork(joinToRingGame, connection);

  yield fork(smallBlindHandler, connection);
  yield fork(bigBlindHandler, connection);
  yield fork(deadPenaltyHandler, connection);
  yield fork(liveAndDeadPenaltyHandler, connection);

  yield takeEvery(waitForFirstToAct.type, callWaitForFirstToAct, connection);
  yield takeEvery(joinNowAction.type, joinNow, connection);
  yield takeEvery(
    [postLivePenalty.type, postLiveAndDeadPenalty.type],
    sitInPenalty,
    connection,
  );

  yield takeEvery(sitIn.type, sitInToCashTable, connection);
  yield takeEvery(sitOut.type, sitOutOfCashTable, connection);
  yield takeEvery(gameResult.type, sitOutOfCashTableDelayed, connection);

  yield fork(pushMessageHandler, POKER_SC_SIT_IN, playerSatIn, connection);
  yield fork(pushMessageHandler, POKER_SC_SIT_OUT, playerSatOut, connection);

  yield fork(pushMessageHandler, POKER_SC_ADD_PLAYER, playerAdded, connection);
  yield fork(
    pushMessageHandler,
    POKER_SC_RETURN_TO_VIEWER,
    returnToViewer,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_CHANGE_RSRV_STATUS,
    seatReservationChanged,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_RING_SNAP_STATE,
    ringSnapState,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_ADD_KICK_CANDIDATE,
    addKickCandidate,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_REMOVE_KICK_CANDIDATE,
    removeKickCandidate,
    connection,
  );

  yield fork(genericGameFlow, connection);

  yield takeEvery(
    [gameResult.type, tableWillClear.type],
    autoRebuy,
    connection,
  );

  yield takeEvery(manualRebuyAction.type, manualRebuy, connection);

  yield fork(
    pushMessageHandler,
    POKER_SC_SET_CREDIT_PENDING,
    creditPending,
    connection,
  );
  yield fork(pushMessageHandler, POKER_SC_SET_CREDIT, setCredit, connection);

  yield fork(
    pushMessageHandler,
    POKER_SC_PLAYER_AM_SESSION_DETAILS,
    sessionDetails,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_WAIT_FOR_ENTER_POSITION_STATE,
    waitingForEnterPositionChanged,
    connection,
  );

  yield takeEvery(gameResult.type, getHandsList, connection);
  yield takeEvery(handHistorySelected.type, requestRingHandHistory, connection);
}
