import { put, select, take } from "redux-saga/effects";
import {
  GH_CS_SET_POKER_TOURNAMENTS_DETAILS,
  getTournamentHands,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  TournamentHandsList,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { handsList } from "../../actions";
import { getTournamentSelector } from "../../selectors";
import Tournament from "../../types/Tournament";

export default function* getHandsList(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const tournament: Tournament = yield select(getTournamentSelector);

  connection.send(getTournamentHands(tournament.tournamentId));

  const handsListMessage: TournamentHandsList = yield take(
    connection.messages,
    GH_CS_SET_POKER_TOURNAMENTS_DETAILS,
  );

  if (handsListMessage.hands.length > 0) {
    yield put(handsList(handsListMessage.hands));
  }
}
