import { select } from "redux-saga/effects";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { getHeroWillSitOutSelector } from "../../selectors";
import sitOutOfCashTable from "./sitOutOfCashTable";

export default function* sitOutOfCashTableDelayed(
  connection: MessagesCommunication<GameServerMessage>
) {
  const shouldSitOut: boolean | undefined = yield select(
    getHeroWillSitOutSelector
  );

  if (shouldSitOut) sitOutOfCashTable(connection);
}
