const delimiter = {
  field: String.fromCharCode(1),
  list: String.fromCharCode(2),
  innerField: String.fromCharCode(3),
  data: String.fromCharCode(4),
  stringList: String.fromCharCode(5),
  hist: String.fromCharCode(7),
  group: String.fromCharCode(29),
  record: String.fromCharCode(30),
  unit: String.fromCharCode(31),
  section: ";",
  caret: "^",
  hash: "#",
  tilda: "~",
  star: "*",
  pipe: "|",
  at: "@",
  endOfMessage: "$@$",
} as const;

export default delimiter;
