import { ReactNode } from "react";
import useModalState from "../hooks/useModalState";
import { FoldConfirmationContext } from "./FoldConfirmationContext";

export function FoldConfirmationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { opened, setOpened } = useModalState(false, true);

  return (
    <FoldConfirmationContext.Provider
      value={{
        openFoldConfirmationPopup: opened,
        setOpenFoldConfirmationPopup: setOpened,
      }}
    >
      {children}
    </FoldConfirmationContext.Provider>
  );
}
