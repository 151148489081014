const namespace = "tourDialog";

export const BANKROLL = `${namespace}.bankroll`;
export const BUY_IN = `${namespace}.buyIn`;
export const FREEROLL = `${namespace}.freeroll`;
export const TICKET_BUY_IN = `${namespace}.ticketBuyIn`;
export const PLAY = `${namespace}.play`;
export const REGISTER = `${namespace}.register`;
export const RE_ENTER = `${namespace}.reEnter`;
export const RE_ENTRY_MESSAGE = `${namespace}.reEntryMessage`;
export const TOURNAMENT_REGISTRATION = `${namespace}.tournamentRegistration`;
export const UNLIMITED_RE_ENTRIES = `${namespace}.unlimitedReEntries`;
export const LIMITED_RE_ENTRIES = `${namespace}.limitedReEntries`;
export const LAST_RE_ENTRY = `${namespace}.lastReEntry`;
export const NO_TICKETS_AVAILABLE = `${namespace}.noTicketsAvailable`;
export const TICKETS_AVAILABLE = `${namespace}.ticketsAvailable`;
export const OK = `${namespace}.ok`;

export const PAYMENT_TYPE = `${namespace}.paymentType`;

export const CASH = `${PAYMENT_TYPE}.cash`;
export const TICKET = `${PAYMENT_TYPE}.ticket`;
