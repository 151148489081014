import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import {
  ProgressBarWrapper,
  ProgressShape,
} from "../../TurnProgressBar/styles";
import { TableWrapper } from "../TableIndicator/styles";
import { IndicatorWrapper } from "../styles";
import { ReactComponent as DealIconSvg } from "./assets/deal-icon.svg";

export const DealIcon = styled(DealIconSvg)`
  display: block;
  height: 1em;
  fill: currentColor;
`;

export const FtdIndicatorWrapper = styled(IndicatorWrapper)<{
  $active?: boolean;
  $notified?: boolean;
}>`
  ${({ $active, $notified, theme }) => {
    return css`
      color: ${$active ? "#fff" : theme.sessionIndicator.table.inactiveContent};

      ${TableWrapper} {
        ${$notified
          ? css`
              border: 0.0625em solid ${theme.sessionIndicator.notified.border};
              box-shadow: ${$active
                ? theme.sessionIndicator.notified.glowing
                : "none"};
            `
          : ""}
      }

      :hover ${TableWrapper} {
        ${$notified &&
        css`
          border: 0.0625em solid ${theme.sessionIndicator.notified.border};
          box-shadow: ${theme.sessionIndicator.notified.glowing};
        `}
      }

      ${ProgressShape} {
        height: 0.25em;
        border-radius: 0.1875em;
      }

      ${ProgressBarWrapper} {
        background-color: ${theme.progressBar.headerBackgroundColor};

        position: absolute;
        width: 90%;
        bottom: 0.3em;
        left: 50%;
        transform: translateX(-50%);
      }

      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }

      ::after {
        content: ${$notified ? '"!"' : "none"};

        position: absolute;

        top: 1em;
        right: 1em;

        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;

        background: ${theme.sessionIndicator.notified.border};

        font-size: 0.45em;
        color: #fff;
        font-family: "Roboto-Bold";
        transform: translate(40%, -30%);
        padding-top: 0.1em;
        box-sizing: border-box;
      }
    `;
  }}
`;
