import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { ReactComponent as AddChipsSvg } from "./assets/add-chips.svg";
import { ReactComponent as BackArrowSvg } from "./assets/back-arrow-icon.svg";
import { ReactComponent as CashierSvg } from "./assets/cashier.svg";
import { ReactComponent as ExitSvg } from "./assets/exit-icon.svg";
import { ReactComponent as ForwardArrowSvg } from "./assets/forward-arrow.svg";
import { ReactComponent as LeaveGameSvg } from "./assets/leave-game-icon.svg";
import { ReactComponent as SettingsSvg } from "./assets/settings-icon.svg";
import { ReactComponent as SitOutSvg } from "./assets/sit-out-icon.svg";

const defaultSvgCss = ({ theme }: { theme: Theme }) => css`
  fill: ${theme.hamburgerMenu.iconColor};
  height: 5em;
  padding: 0.5em;

  box-sizing: border-box;
`;

export const SitOutIcon = styled(SitOutSvg)<{ $active?: boolean }>`
  ${defaultSvgCss};
  fill: ${({ theme, $active }) =>
    $active ? theme.hamburgerMenu.activeColor : theme.hamburgerMenu.iconColor};
`;

export const SitInIcon = styled(SitOutIcon)`
  transform: rotateY(180deg);
`;

export const SettingsIcon = styled(SettingsSvg)`
  ${defaultSvgCss};
`;

export const CashierIcon = styled(CashierSvg)`
  ${defaultSvgCss};
`;

export const LeaveGameIcon = styled(LeaveGameSvg)`
  ${defaultSvgCss};
`;

export const ExitIcon = styled(ExitSvg)`
  ${defaultSvgCss};
  width: 3.48em;
  padding-left: 0.9em;
  padding-right: 1.1em;
`;

export const BackArrowIcon = styled(BackArrowSvg)`
  ${defaultSvgCss};
`;

export const ForwardArrowIcon = styled(ForwardArrowSvg)`
  ${defaultSvgCss};
  margin: 0;
  height: 2em;
`;

export const AddChipsIcon = styled(AddChipsSvg)`
  ${defaultSvgCss};
  margin: 0;
`;
