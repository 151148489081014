import { useContext, useEffect, useMemo, useRef } from "react";
import MainServerContext from "../context/CommunicationContext";
import Communication from "../network/Communication";
import {
  CommunicationLifecycleEvent,
  MainServerEventMap,
  MessageEvent,
} from "../network/events";
import {
  INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_ERR,
  INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_OK,
  INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_ERR,
  INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_OK,
  INFO_SRV_CLIENT_REG_BLAST_POOL_ERR,
  INFO_SRV_CLIENT_REG_TOUR_ERR,
  INFO_SRV_CLIENT_REG_TOUR_OK,
  INFO_SRV_CLIENT_UNREG_TOUR_ERR,
  INFO_SRV_CLIENT_UNREG_TOUR_OK,
  MAIN_CLIENT_GET_RELEVANT_BONUSES_RESPONSE,
  getIdenticalRingTable,
  getQuickTableFromCreatorId,
  getRelevantBonuses,
  registerToBlastPool,
  registerToMTT,
  unregisterFromTournament as tournamentUnregistration,
} from "../network/protocol/mainServer";
import {
  IdenticalRingTableError,
  IdenticalRingTableOk,
  MainServerMessage,
  QuickTable,
  QuickTableError,
  RegisterBlastError,
  RegisterTourError,
  RegisterTourOk,
  UnregisterTourError,
  UnregisterTourOk,
} from "../network/protocol/mainServer/types";
import BlastPoolRegistrationData from "../network/protocol/mainServer/types/BlastPoolRegistrationData";
import { BonusInfoItemsMessage } from "../network/protocol/mainServer/types/BonusInfoItemsMessage";
import { OutgoingMessage } from "../network/protocol/types";

type ResolveOrReject<A extends Array<any>, V, E> = (
  message: MessageEvent<MainServerMessage>,
  resolve: (value: V) => void,
  reject: (reason?: E) => void,
  ...args: A
) => boolean;

type Unsubscribe = () => void;

function getterFactory<C extends (...args: any[]) => OutgoingMessage, V, E>(
  server: Communication<MainServerEventMap>,
  abortSignal: AbortSignal,
  resolveOrReject: ResolveOrReject<Parameters<C>, V, E>,
  messageCreator: C,
) {
  return (...args: Parameters<C>) => {
    return new Promise(
      (resolve: (value: V) => void, reject: (reason?: E | Error) => void) => {
        function removeListeners() {
          server.removeEventListener(MessageEvent.MESSAGE, messageHandler);
          server.removeEventListener(
            CommunicationLifecycleEvent.DISCONNECTED,
            disconnectionHandler,
          );
          abortSignal.removeEventListener("abort", abortHandler);
        }

        function messageHandler(event: MessageEvent<MainServerMessage>) {
          if (resolveOrReject(event, resolve, reject, ...args)) {
            removeListeners();
          }
        }

        function disconnectionHandler() {
          removeListeners();
          reject(new Error("Rejected by disconnection"));
        }

        function abortHandler() {
          removeListeners();
          reject(new Error("Aborted"));
        }

        server.addEventListener(
          CommunicationLifecycleEvent.DISCONNECTED,
          disconnectionHandler,
        );
        server.addEventListener(MessageEvent.MESSAGE, messageHandler);
        abortSignal.addEventListener("abort", abortHandler);

        server.send(messageCreator(...args));
      },
    );
  };
}

export type BonusInfo = {
  ticket: number;
  bonusInfoItems: BonusInfoItemsMessage[];
};

export default function useServerApi() {
  const server = useContext(MainServerContext);

  if (!server) {
    throw new Error(
      "useServerApi must be inside an MainServerContext with a value",
    );
  }

  const { current: controller } = useRef(new AbortController());

  useEffect(() => () => controller.abort(), [controller]);

  const api = useMemo(
    () =>
      ({
        getBonuses: getterFactory(
          server,
          controller.signal,
          (event, resolve: (value: BonusInfo | null) => void) => {
            const message = event.message;
            if (message.type === MAIN_CLIENT_GET_RELEVANT_BONUSES_RESPONSE) {
              if (message.errorCode === 0) {
                const { ticket, bonusInfoItems } = message;
                resolve({ ticket, bonusInfoItems });
              } else {
                resolve(null);
              }

              return true;
            }

            return false;
          },
          getRelevantBonuses,
        ),
        getRingTable: getterFactory(
          server,
          controller.signal,
          (
            event,
            resolve: (value: QuickTable) => void,
            reject: (reason: QuickTableError) => void,
          ) => {
            const message = event.message;

            if (
              message.type ===
              INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_OK
            ) {
              resolve(message);
              return true;
            } else if (
              message.type ===
              INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_ERR
            ) {
              reject(message);
              return true;
            }

            return false;
          },
          getQuickTableFromCreatorId,
        ),
        getIdenticalRingTable: getterFactory(
          server,
          controller.signal,
          (
            event,
            resolve: (value: IdenticalRingTableOk) => void,
            reject: (reason: IdenticalRingTableError) => void,
            { reservedId },
          ) => {
            const message = event.message;

            if (
              message.type === INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_OK &&
              message.reservedId === reservedId
            ) {
              resolve(message);
              return true;
            } else if (
              message.type === INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_ERR &&
              message.reservedId === reservedId
            ) {
              reject(message);
              return true;
            }

            return false;
          },
          getIdenticalRingTable,
        ),
        registerToBlastTour: getterFactory(
          server,
          controller.signal,
          (
            event,
            resolve: (
              value: RegisterTourOk &
                Pick<BlastPoolRegistrationData, "blastPoolId" | "buyInValue">,
            ) => void,
            reject: (
              reason: RegisterBlastError["error"] | RegisterTourError["error"],
            ) => void,
            { blastPoolId, buyInValue },
          ) => {
            const message = event.message;

            if (message.type === INFO_SRV_CLIENT_REG_TOUR_OK) {
              resolve({ ...message, blastPoolId, buyInValue });
              return true;
            } else if (
              message.type === INFO_SRV_CLIENT_REG_BLAST_POOL_ERR ||
              message.type === INFO_SRV_CLIENT_REG_TOUR_ERR
            ) {
              reject(message.error);
              return true;
            }

            return false;
          },
          registerToBlastPool,
        ),
        registerToTournament: getterFactory(
          server,
          controller.signal,
          (
            event,
            resolve: (value: RegisterTourOk) => void,
            reject: (reason: RegisterTourError["error"]) => void,
            { tourId },
          ) => {
            const message = event.message;

            if (
              message.type === INFO_SRV_CLIENT_REG_TOUR_OK &&
              message.tourId === tourId
            ) {
              resolve(message);
              return true;
            } else if (
              message.type === INFO_SRV_CLIENT_REG_TOUR_ERR &&
              message.error.tourId === tourId
            ) {
              reject(message.error);
              return true;
            }

            return false;
          },
          registerToMTT,
        ),
        unregisterFromTournament: getterFactory(
          server,
          controller.signal,
          (
            event,
            resolve: (value: UnregisterTourOk) => void,
            reject: (reason: UnregisterTourError["error"]) => void,
            tourId,
          ) => {
            const message = event.message;

            if (
              message.type === INFO_SRV_CLIENT_UNREG_TOUR_OK &&
              message.tourId === tourId
            ) {
              resolve(message);
              return true;
            } else if (
              message.type === INFO_SRV_CLIENT_UNREG_TOUR_ERR &&
              message.error.tourId === tourId
            ) {
              reject(message.error);
              return true;
            }

            return false;
          },
          tournamentUnregistration,
        ),
        subcribe: <T extends MainServerMessage["type"]>(
          type: T,
          listener: (message: Extract<MainServerMessage, { type: T }>) => void,
        ): Unsubscribe => {
          function messageHandler(event: MessageEvent<MainServerMessage>) {
            const message = event.message;
            if (message.type === type) {
              listener(
                event.message as Extract<MainServerMessage, { type: T }>,
              );
            }
          }

          server.addEventListener("message", messageHandler);

          return () => server.removeEventListener("message", messageHandler);
        },
      }) as const,
    [controller.signal, server],
  );
  return api;
}
