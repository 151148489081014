import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const ScrollbarContainer = styled.div`
  *::-webkit-scrollbar {
    width: 3px;
  }

  ${({ theme }) => css`
    *::-webkit-scrollbar-track {
      border-radius: 1px;

      background: ${theme.scrollbar.trackColor};
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 1px;

      background: ${theme.scrollbar.thumbColor};
    }
  `}
`;
