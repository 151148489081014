import { ReactNode } from "react";
import useSoundControls from "../hooks/useSoundControls";
import { SoundContext } from "./SoundContext";

export function SoundProvider({ children }: { children: ReactNode }) {
  const soundControls = useSoundControls();

  return (
    <SoundContext.Provider value={soundControls}>
      {children}
    </SoundContext.Provider>
  );
}
