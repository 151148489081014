import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import {
  ProgressBarWrapper,
  ProgressShape,
} from "../../TurnProgressBar/styles";
import { TableWrapper } from "../TableIndicator/styles";
import { IndicatorWrapper } from "../styles";

export const UserTurnWrapper = styled(IndicatorWrapper)<{ $active?: boolean }>`
  ${({ $active, theme }) => {
    const borderWithGlowingStyles = css`
      border: 0.0625em solid ${theme.sessionIndicator.notified.border};
      box-shadow: ${theme.sessionIndicator.notified.glowing};
    `;

    const userTurnStyles = css`
      ${borderWithGlowingStyles}
      ${!$active && borderWithGlowingStyles}
    `;

    return css`
      /* override base blue border and glowing for active/hover state */
      ${TableWrapper}, &:hover ${TableWrapper} {
        ${userTurnStyles}
      }

      ${TableWrapper} {
        /* only for inactive leave red border but remove glowing */
        ${!$active &&
        css`
          box-shadow: none;
        `}
      }

      ${ProgressShape} {
        height: 0.25em;
        border-radius: 0.1875em;
      }

      ${ProgressBarWrapper} {
        background-color: ${theme.progressBar.headerBackgroundColor};

        position: absolute;
        width: 90%;
        bottom: 0.3em;
        left: 50%;
        transform: translateX(-50%);
      }
    `;
  }}
`;
