import delimiter from "./delimiter";

export function parseNamesList(source: string) {
  const names = new Map<number, string>();

  if (source) {
    const items = source.split(delimiter.data);

    items.forEach((item) => {
      if (item) {
        const [languageId, name] = item.split(delimiter.stringList);
        names.set(Number.parseInt(languageId), name);
      }
    });
  }

  return names;
}
