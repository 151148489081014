import { call, put, race, select, take } from "redux-saga/effects";
import {
  POKER_SC_JOIN_POS_FAILED,
  POKER_SC_JOIN_POS_OK,
  joinToPosition,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  GetQuickPosErr,
  GetQuickPosOk,
  JoinPositionErr,
  JoinPositionOk,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { BuyIn, joinPositionError, seatUnavailable } from "../../actions";
import { getReservedSeatSelector } from "../../selectors";
import getAvailableSeat from "./getAvailableSeat";

export default function* joinPosition(
  connection: MessagesCommunication<GameServerMessage>,
  { payload: { avatarId, credit } }: BuyIn,
) {
  const resevedSeat: number | undefined = yield select(getReservedSeatSelector);
  let targetSeat = resevedSeat;

  if (!targetSeat) {
    const [response, error]: [GetQuickPosOk, GetQuickPosErr] = yield call(
      getAvailableSeat,
      connection,
    );

    if (error) {
      yield put(seatUnavailable(error));
      return;
    }

    targetSeat = response.seatId;
  }

  connection.send(joinToPosition(targetSeat, credit, avatarId));

  const [, error]: [JoinPositionOk, JoinPositionErr] = yield race([
    take(connection.messages, POKER_SC_JOIN_POS_OK),
    take(connection.messages, POKER_SC_JOIN_POS_FAILED),
  ]);

  if (error) {
    yield put(joinPositionError({ seatId: error.seatId, error: error.error }));
  }
}
