import { keyframes } from "@emotion/react";
import styled from "@emotion/styled/macro";

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CircleWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  width: 132px;
  height: 75.5px;
`;

const Pulse1KeyFrame = keyframes`
 0% {
   transform: scale(.5);
   opacity: 0;
  }

  10%, 100% {
    transform: scale(1);
    opacity: 0.88;
  }
`;

const Pulse4KeyFrame = keyframes`
  0%, 10% {
    transform: scale(.5);
    opacity: 0;
  }

  20%, 100% {
    transform: scale(1);
    opacity: 0.88;
  }
`;

const Pulse5KeyFrame = keyframes`
 0%, 20% {
    transform: scale(.5);
    opacity: 0;
  }

  30%, 100% {
    transform: scale(1);
    opacity: 0.88;
  }
`;

const Pulse2KeyFrame = keyframes`
 0%, 30% {
    transform: scale(.5);
    opacity: 0;
  }

  40%, 100% {
    transform: scale(1);
    opacity: 0.88;
  }
`;

const Pulse3KeyFrame = keyframes`
 0%, 40% {
    transform: scale(.5);
    opacity: 0;
  }

  50%, 100% {
    transform: scale(1);
    opacity: 0.88;
  }
`;

const Pulse6KeyFrame = keyframes`
 0%, 50% {
    transform: scale(.5);
    opacity: 0;
  }

  60%, 100% {
    transform: scale(1);
    opacity: 0.88;
  }
`;

const Circle = styled.div`
  width: 44px;
  height: 44px;

  border: 12.5px solid ${({ theme }) => theme.spinner.circle.borderColor};
  border-radius: 50%;

  box-sizing: border-box;

  opacity: 0.88;

  animation-duration: 4s;
  animation-timing-function: ease-in;
  animation-delay: 1s;
  animation-iteration-count: infinite;

  &:nth-child(1) {
    animation-name: ${Pulse1KeyFrame};
  }

  &:nth-child(4) {
    margin-top: -11.5px;

    animation-name: ${Pulse4KeyFrame};
  }

  &:nth-child(2) {
    animation-name: ${Pulse2KeyFrame};
  }

  &:nth-child(5) {
    margin-top: -11.5px;

    animation-name: ${Pulse5KeyFrame};
  }

  &:nth-child(3) {
    animation-name: ${Pulse3KeyFrame};
  }

  &:nth-child(6) {
    margin-top: -11.5px;

    animation-name: ${Pulse6KeyFrame};
  }
`;

const Pulse7KeyFrame = keyframes`
 0%, 60% {
    transform: scale(.5);
    opacity: 0;
  }

  70%, 100% {
    transform: scale(1);
    opacity: 0.88;
  }
`;

const PokerText = styled.span`
  align-self: flex-end;
  width: 80px;

  color: ${({ theme }) => theme.spinner.text.color};
  font-size: 28px;
  line-height: 28px;

  opacity: 0.88;

  animation: ${Pulse7KeyFrame} 4s infinite;
  animation-timing-function: ease-in;
  animation-delay: 1s;
`;

export { Circle, CircleWrapper, Container, PokerText, SpinnerWrapper };
