import { fork, takeEvery } from "redux-saga/effects";
import {
  INFO_SRV_CLIENT_END_OR_CANCEL_TOUR,
  INFO_SRV_CLIENT_PLAYER_FINISHED_TOUR,
  INFO_SRV_CLIENT_UNREG_TOUR_ERR,
  INFO_SRV_CLIENT_UNREG_TOUR_OK,
} from "../../../../network/protocol/mainServer";
import type { MainServerMessage } from "../../../../network/protocol/mainServer/types";
import {
  authenticated,
  joined,
  playerFinishedTour,
  tourEndOrCancelled,
  tourUnregistrationError,
  tourUnregistrationSuccess,
} from "../../actions";
import startTournament from "../tournaments/startTournament";
import tourRegistrationHandler from "../tournaments/tourRegistrationHandler";
import autoReplyToMTC from "./autoReplyToMTC";
import getAccountAttributesSaga from "./getAccountAttributesSaga";
import getOpenSessionsSaga from "./getOpenSessions";
import kickToLobbySaga from "./kickToLobbySaga";
import kickUserHandler from "./kickUserHandler";
import MessagesCommunication from "./messagesCommunication";
import prepareForLogin from "./prepareForLogin";
import pushMSMessageHandler from "./pushMSMessageHandler";
import sendReadyForMTC from "./sendReadyForMTC";

export default function* applicationFlow(
  connection: MessagesCommunication<MainServerMessage>,
) {
  yield takeEvery(joined.type, prepareForLogin);
  yield takeEvery(authenticated.type, getAccountAttributesSaga, connection);
  yield takeEvery(authenticated.type, getOpenSessionsSaga, connection);
  yield takeEvery(authenticated.type, sendReadyForMTC, connection);
  yield fork(startTournament, connection);
  yield fork(kickToLobbySaga, connection);
  yield fork(kickUserHandler, connection);
  yield fork(
    pushMSMessageHandler,
    INFO_SRV_CLIENT_END_OR_CANCEL_TOUR,
    tourEndOrCancelled,
    connection,
  );
  yield fork(
    pushMSMessageHandler,
    INFO_SRV_CLIENT_PLAYER_FINISHED_TOUR,
    playerFinishedTour,
    connection,
  );
  yield fork(
    pushMSMessageHandler,
    INFO_SRV_CLIENT_UNREG_TOUR_OK,
    tourUnregistrationSuccess,
    connection,
  );
  yield fork(
    pushMSMessageHandler,
    INFO_SRV_CLIENT_UNREG_TOUR_ERR,
    tourUnregistrationError,
    connection,
  );
  yield fork(tourRegistrationHandler, connection);
  yield fork(autoReplyToMTC, connection);
}
