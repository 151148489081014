import { put, select, take } from "redux-saga/effects";
import { POKER_SC_START_ROUND_1 } from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  RoundOne,
} from "../../../../network/protocol/gameServer/types";
import Card, {
  Cards,
} from "../../../../network/protocol/gameServer/types/Card";
import { PokerType } from "../../../../network/protocol/gameServer/types/PokerType";
import getPlayerCardsLength from "../../../../utils/getPlayerCardsLength";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { round1Started } from "../../actions";
import {
  getHeroSelector,
  getPokerTypeSelector,
  getRestoredCardsOfHeroSelector,
} from "../../selectors";
import Player from "../../types/Player";

export default function* roundOneStarted(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const message: RoundOne = yield take(
      connection.messages,
      POKER_SC_START_ROUND_1,
    );

    const pokerType: PokerType = yield select(getPokerTypeSelector);
    const hero: Player | undefined = yield select(getHeroSelector);
    const restoredCards: Card[] | undefined = yield select(
      getRestoredCardsOfHeroSelector,
    );

    const playerCardsLength = getPlayerCardsLength(pokerType);
    const playersBets = message.playersBets;
    const historyCards = message.historyCards;
    const playersCards: { seatId: number; cards: Cards | undefined }[] =
      playersBets.map((playerBet) => {
        const seatId = playerBet.seatId;
        if (playerBet.seatId === hero?.seatId) {
          return {
            seatId,
            cards:
              restoredCards ||
              (hero ? historyCards[hero.seatId] : undefined) ||
              message.cards,
          };
        } else {
          return {
            seatId,
            cards: new Array(playerCardsLength).fill(undefined),
          };
        }
      });

    yield put(round1Started({ data: message, playersCards }));
  }
}
