import { put, take } from "redux-saga/effects";
import { INFO_SRV_CLIENT_REG_TOUR_OK } from "../../../../network/protocol/mainServer";
import {
  MainServerMessage,
  RegisterTourOk,
  TourSubType,
} from "../../../../network/protocol/mainServer/types";
import makeGameId from "../../../../utils/makeGameId";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  blastRegistrationSuccess,
  tourRegistrationSuccess,
} from "../../actions";

export default function* tourRegistrationHandler(
  connection: MessagesCommunication<MainServerMessage>,
) {
  while (true) {
    const registration: RegisterTourOk = yield take(
      connection.messages,
      INFO_SRV_CLIENT_REG_TOUR_OK,
    );

    if (registration.subTypeMask & TourSubType.BLAST) {
      yield put(
        blastRegistrationSuccess({
          gameId: makeGameId(),
          tourId: registration.tourId,
          withdrawnRealAmount: registration.withdrawnRealAmount,
          withdrawnRestrictedAmount: registration.withdrawnRestrictedAmount,
        }),
      );
    } else {
      yield put(
        tourRegistrationSuccess({
          tourId: registration.tourId,
          withdrawnRealAmount: registration.withdrawnRealAmount,
          withdrawnRestrictedAmount: registration.withdrawnRestrictedAmount,
          reEntryLeft: registration.reEntryLeft,
        }),
      );
    }
  }
}
