import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import GenericErrorScreen from "../../components/GenericErrorScreen";
import Screen from "../../components/Screen";
import Spinner from "../../components/Spinner";
import { ConnectionErrorKeys, CriticalErrorKeys } from "../../i18n/keys";
import { APP_WILL_CLOSE } from "../../network/CommunicationError";
import { LOBBY_ROUTE } from "../../router";
import { connect } from "../../store/app/actions";
import {
  getConnectionSelector,
  isConnectedSelector,
} from "../../store/app/selectors";
import { ConnectionStatus } from "../../store/app/types/Connection";

export default function ConnectionGuard({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isConnected = useSelector(isConnectedSelector);
  const { connectionError, joinState } = useSelector(getConnectionSelector);

  if (isConnected) return <>{children}</>;

  if (connectionError && connectionError.message !== APP_WILL_CLOSE) {
    return (
      <GenericErrorScreen
        testId="poker-connection-generic-error"
        text={t(CriticalErrorKeys.DESCRIPTION)}
      >
        <Button onClick={() => dispatch(connect())} testId="poker-retry-button">
          {t(ConnectionErrorKeys.RETRY)}
        </Button>
      </GenericErrorScreen>
    );
  }

  if (joinState === ConnectionStatus.ERROR) {
    return (
      <GenericErrorScreen
        testId="poker-connection-generic-error"
        text={t(CriticalErrorKeys.DESCRIPTION)}
      >
        <Button
          onClick={() => navigate(LOBBY_ROUTE)}
          testId="poker-back-to-lobby"
        >
          {t(ConnectionErrorKeys.CLOSE)}
        </Button>
      </GenericErrorScreen>
    );
  }

  return (
    <Screen>
      <Spinner />
    </Screen>
  );
}
