import { select } from "redux-saga/effects";
import isGameSession from "../../../../../utils/isGameSession";
import { TopUpChanged } from "../../../actions";
import { getSessionsSelector } from "../../../selectors";

export default function* dispatchTopUpToGame(action: TopUpChanged) {
  const sessions: ReturnType<typeof getSessionsSelector> =
    yield select(getSessionsSelector);

  for (const sessionId in sessions) {
    const session = sessions[sessionId];

    if (isGameSession(session)) {
      session.store.dispatch(action);
    }
  }
}
