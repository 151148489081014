import { useContext } from "react";
import { CloseConfirmationContext } from "../context";

export default function useCloseConfirmation() {
  const closeAppContext = useContext(CloseConfirmationContext);

  if (!closeAppContext) {
    throw new Error(
      "useCloseConfirmation must be inside an CloseConfirmationContext with a value",
    );
  }

  return closeAppContext;
}
