import { put, race, take } from "redux-saga/effects";
import {
  POKER_SC_CHANGE_RSRV_STATUS_EX_ERR,
  POKER_SC_CHANGE_RSRV_STATUS_EX_OK,
  changeSeatReservation,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  ResevationStatusChangedErr,
  ResevationStatusChangedOk,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  ChangeSeatResevation,
  heroSeatReservationChanged,
  heroSeatReservationError,
} from "../../actions";

export default function* updateSeatReservation(
  connection: MessagesCommunication<GameServerMessage>,
  { payload: { reserved, seatId } }: ChangeSeatResevation,
) {
  connection.send(changeSeatReservation(seatId, reserved));

  const [response, error]: [
    ResevationStatusChangedOk,
    ResevationStatusChangedErr,
  ] = yield race([
    take(connection.messages, POKER_SC_CHANGE_RSRV_STATUS_EX_OK),
    take(connection.messages, POKER_SC_CHANGE_RSRV_STATUS_EX_ERR),
  ]);

  if (response) {
    yield put(heroSeatReservationChanged(response));
  } else if (error) {
    yield put(heroSeatReservationError(error));
  }
}
