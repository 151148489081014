import { LimitType } from "../../network/protocol/gameServer/types/LimitType";
import { PokerType } from "../../network/protocol/gameServer/types/PokerType";
import { BASE_URL, BRAND_ID, INFO_VERSION, TIME_STAMP } from "../lobbyServices";
import { fetchData } from "../utils";
import getSortedRingGames from "./getSortedRingGames";
import { MaxPlayersNumber, RingGameType, RingServerResponse } from "./types";

const LANG_ID = "0";
const MOBILE_ARENA_TYPE = "0";
const RING_GAMES_API_URL = "api/desktop/v2/cash/groups/";

export async function getRingGamesData(
  ringGameType: RingGameType,
  abort?: AbortSignal,
) {
  const lobbyServiceUrl = new URL(RING_GAMES_API_URL, BASE_URL);

  lobbyServiceUrl.searchParams.append("BrandID", BRAND_ID);
  lobbyServiceUrl.searchParams.append("InfoVer", INFO_VERSION);
  lobbyServiceUrl.searchParams.append("LangID", LANG_ID);
  lobbyServiceUrl.searchParams.append("MobileArenaType", MOBILE_ARENA_TYPE);
  lobbyServiceUrl.searchParams.append("LastTimeStamp", TIME_STAMP);
  lobbyServiceUrl.searchParams.append(
    "GameType",
    String(PokerType.TEXASHOLDEM),
  );
  lobbyServiceUrl.searchParams.append("Type", String(ringGameType));
  lobbyServiceUrl.searchParams.append("LimitType", String(LimitType.NOLIMIT));
  lobbyServiceUrl.searchParams.append(
    "TableMax",
    String(MaxPlayersNumber.Holdem),
  );

  const responseJson = await fetchData<RingServerResponse>(
    lobbyServiceUrl.href,
    abort,
  ).then((resolve) => {
    return getSortedRingGames(resolve.CashGroupData);
  });

  return responseJson;
}
