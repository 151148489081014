const namespace = "heroPanel";

export const I_AM_BACK = `${namespace}.imBack`;
export const JOIN_NOW = `${namespace}.joinNow`;
export const CALL = `${namespace}.call`;
export const CHECK = `${namespace}.check`;
export const ALL_IN = `${namespace}.allIn`;
export const BET = `${namespace}.bet`;
export const RAISE_TO = `${namespace}.raiseTo`;
export const RAISE = `${namespace}.raise`;
export const FOLD = `${namespace}.fold`;
export const AUTO_CHECK_OR_FOLD = `${namespace}.checkOrFold`;
export const FOLD_CONFIRMATION_MESSAGE = `${namespace}.foldConfirmationMessage`;
export const WAIT_FOR_FIRST_TO_ACT = `${namespace}.waitForFirstToAct`;
export const POST_DEAD = `${namespace}.postDead`;
