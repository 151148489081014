import { put, select } from "redux-saga/effects";
import isGameSession from "../../../../../utils/isGameSession";
import { reconnectGame } from "../../../actions";
import { getSessionsSelector } from "../../../selectors";

export default function* reconnectGames() {
  const games: ReturnType<typeof getSessionsSelector> =
    yield select(getSessionsSelector);
  const gameContexts = Object.values(games);
  for (const gameHolder of gameContexts) {
    if (isGameSession(gameHolder) && gameHolder.gameState === "play") {
      yield put(
        reconnectGame({
          gameId: gameHolder.gameId,
          serverId: gameHolder.serverId,
          context: {
            gameType: gameHolder.gameType,
          },
        }),
      );
    }
  }
}
