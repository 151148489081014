import { Dispatch, RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useScreenOrientation from "../../hooks/useScreenOrientation";
import { AvatarSelectionKeys } from "../../i18n/keys";
import { SELECTABLE_AVATAR_IDS } from "../../utils/avatar";
import Button from "../Button";
import SelectableAvatar from "./SelectableAvatar";
import playerIcon from "./assets/player-icon.svg";
import {
  AvatarGrid,
  ContentWrapper,
  HeaderWrapper,
  PlayerIcon,
} from "./styles";
import { AvatarSelectionDialogProps } from "./types";

const renderAvatars = (
  onAvatarSelected: Dispatch<number>,
  selectedAvatar: number,
  buttonRef: RefObject<HTMLButtonElement>,
) => {
  return SELECTABLE_AVATAR_IDS.map((avatarId) => {
    const avatarIsSelected = Number(selectedAvatar) === Number(avatarId);

    return (
      <SelectableAvatar
        ref={avatarIsSelected ? buttonRef : null}
        onClick={() => onAvatarSelected(Number(avatarId))}
        id={avatarId}
        selected={avatarIsSelected}
        key={avatarId}
      />
    );
  });
};

export default function AvatarSelectionDialog({
  onConfirm,
  selectedAvatar,
}: AvatarSelectionDialogProps) {
  const selectedAvatarRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const [selectedAvatarId, setSelectedAvatarId] = useState(selectedAvatar);
  const { isLandscape } = useScreenOrientation();

  useEffect(() => {
    selectedAvatarRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [isLandscape]);

  return (
    <ContentWrapper $isLandscape={isLandscape}>
      <HeaderWrapper $isLandscape={isLandscape}>
        <PlayerIcon
          src={playerIcon}
          alt="Player icon"
          $isLandscape={isLandscape}
        />
        {t(AvatarSelectionKeys.SELECT_IMAGE)}
      </HeaderWrapper>

      <AvatarGrid $isLandscape={isLandscape}>
        {renderAvatars(
          setSelectedAvatarId,
          selectedAvatarId,
          selectedAvatarRef,
        )}
      </AvatarGrid>
      <Button
        onClick={() => onConfirm(selectedAvatarId)}
        testId="poker-avatar-confirm"
      >
        {t(AvatarSelectionKeys.CONFIRM)}
      </Button>
    </ContentWrapper>
  );
}
