import { AreaType, IMessageBroker, TriggerType } from "@sparkware/uc-sdk-core";
import { v4 as uuid } from "uuid";
import { APP_ID, MB_HEADERS } from "./externalCommunication";

export function publishPendingAction(messageBroker: IMessageBroker) {
  messageBroker.navigation.topics.performAction.publish(MB_HEADERS, {
    actionID: "openAppPending",
    launchInfo: {
      channel: {
        area: AreaType.PendingAction,
        source: APP_ID,
      },
      businessCorrelationID: uuid(),
      trigger: TriggerType.automaticFlow,
      sourceAppID: APP_ID,
      sourceAppVersion: process.env.REACT_APP_VERSION || "",
    },
  });
}
