import { select } from "redux-saga/effects";
import isGameSession from "../../../../../utils/isGameSession";
import { SetBonus } from "../../../actions";
import { getSessionsSelector } from "../../../selectors";

export default function* dispatchBonusShowerToGame(action: SetBonus) {
  const sessions: ReturnType<typeof getSessionsSelector> =
    yield select(getSessionsSelector);

  for (const sessionId in sessions) {
    const session = sessions[sessionId];

    if (
      isGameSession(session) &&
      (action.payload.tableId === session.gameInstanceId ||
        action.payload.tournamentId === session.gameInstanceId)
    ) {
      session.store.dispatch(action);
    }
  }
}
