import { forwardRef, useMemo } from "react";
import { getAvatar } from "../../../utils/avatar";
import selectionBg from "./assets/selection-background.svg";
import selectionHighlight from "./assets/selection-highlight.svg";
import {
  SelectableAvatarButton,
  SelectableAvatarImage,
  SelectionBackground,
  SelectionHighlight,
} from "./styles";
import SelectableAvatarProps from "./types";

export const SelectableAvatar = forwardRef<
  HTMLButtonElement,
  SelectableAvatarProps
>(({ onClick, selected = false, id }, ref) => {
  const source = useMemo(() => getAvatar(id), [id]);

  return (
    <SelectableAvatarButton
      ref={ref}
      onClick={onClick}
      data-testid={`poker-selectable-avatar-${id}`}
    >
      <SelectableAvatarImage src={source} alt={`Avatar-${id}`} />
      {selected && (
        <>
          <SelectionBackground src={selectionBg} alt="Selection background" />
          <SelectionHighlight
            src={selectionHighlight}
            alt="Selection highlight"
          />
        </>
      )}
    </SelectableAvatarButton>
  );
});
