import styled from "@emotion/styled/macro";

export const TRANSITION_DURATION = 500;

export const HamburgerMenuPlaceholder = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;

  .menuBackdrop-enter {
    opacity: 0;
  }
  .menuBackdrop-enter-active {
    opacity: 0.6;

    transition: opacity ${TRANSITION_DURATION}ms;
  }
  .menuBackdrop-enter-done {
    opacity: 0.6;
  }

  .menuBackdrop-exit {
    opacity: 0.6;
  }
  .menuBackdrop-exit-active {
    opacity: 0;

    transition: opacity ${TRANSITION_DURATION}ms;
  }

  .menuContent-enter {
    transform: translateX(-100%);

    pointer-events: none;
  }
  .menuContent-enter-active {
    transform: translateX(0%);

    transition: transform ${TRANSITION_DURATION}ms;

    pointer-events: none;
  }
  .menuContent-enter-done {
    transform: translateX(0%);

    pointer-events: all;
  }

  .menuContent-exit {
    transform: translateX(0%);

    pointer-events: none;
  }
  .menuContent-exit-active {
    transform: translateX(-100%);

    transition: transform ${TRANSITION_DURATION}ms;

    pointer-events: none;
  }
`;

export const Backdrop = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.hamburgerMenu.backdropColor};

  pointer-events: auto;
`;

export const MenuItemButton = styled.button<{ $active: boolean }>`
  position: relative;

  display: flex;
  align-items: center;

  padding: 0 1em;

  border: none;
  border-bottom: 0.2em solid ${({ theme }) => theme.hamburgerMenu.borderColor};

  color: ${({ theme, $active }) =>
    $active ? theme.hamburgerMenu.activeColor : theme.hamburgerMenu.textColor};
  font-size: inherit;
  font-family: inherit;

  background: transparent;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.2;
    cursor: initial;

    &::after {
      display: none;
    }
  }

  &::after {
    position: absolute;
    left: 0;

    width: 100%;
    height: 100%;

    background: ${({ theme }) => theme.hamburgerMenu.buttonOverlayColor};
    opacity: 0;

    content: "";
  }

  @media (hover: hover) {
    &:hover::after {
      opacity: 0.25;
    }
  }

  &:active::after {
    opacity: 0.5;
  }
`;

export const MenuItemLabel = styled.div`
  flex-grow: 1;

  margin: 0 0.5em;

  text-align: left;
`;
