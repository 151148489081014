import { PropsWithChildren, useReducer, useRef } from "react";
import ModalsContext from "./ModalsContext";
import { ModalConfig } from "./ModalsContext/types";

type Modals = ModalConfig[];

function addModal(modal: ModalConfig) {
  return {
    type: "ADD",
    modal,
  } as const;
}

function removeModal(id: string) {
  return {
    type: "REMOVE",
    id,
  } as const;
}

function reducer(
  state: Modals,
  action: ReturnType<typeof addModal> | ReturnType<typeof removeModal>,
) {
  switch (action.type) {
    case "ADD": {
      const exist =
        state.findIndex((modal) => modal.id === action.modal.id) >= 0;
      if (exist) {
        return state;
      }

      const notClosed = state.filter((modal) => !modal.autoclose);
      notClosed.unshift(action.modal);

      return notClosed;
    }
    case "REMOVE": {
      const newState = state.filter((modal) => modal.id !== action.id);
      return newState.length === state.length ? state : newState;
    }
    default:
      return state;
  }
}

export default function ModalsProvider({ children }: PropsWithChildren) {
  const [popups, dispatch] = useReducer(reducer, []);
  const { current: add } = useRef((modal: ModalConfig) =>
    dispatch(addModal(modal)),
  );
  const { current: remove } = useRef((id: string) => dispatch(removeModal(id)));

  return (
    <ModalsContext.Provider
      value={{
        modals: popups,
        add,
        remove,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
}
