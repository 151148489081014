import { GameState } from "../../store";
import type Player from "../../types/Player";

export default function getHero({
  players,
  hero,
}: GameState): Player | undefined {
  if (hero.viewer) return undefined;

  for (const seatId in players) {
    const player: Player | undefined = players[seatId];
    if (player?.name === hero.name) {
      return player;
    }
  }
  return undefined;
}
