import { ThemeProvider } from "@emotion/react";
import { Suspense, lazy, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  RouterProvider,
  Routes,
  createMemoryRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Portal from "./components/Portal/Portal";
import { StyledProtectedFullScreen } from "./components/ProtectedFullScreenRoute/styles";
import BrowserRouter from "./components/Router";
import { RouterProps } from "./components/Router/types";
import Spinner from "./components/Spinner/Spinner";
import ActionIndicator from "./containers/ActionIndicator/ActionIndicator";
import NotificationToasterContainer from "./containers/NotificationToaster";
import {
  ApplicationConfigContext,
  ApplicationConfigContextProps,
  SettingsContext,
  SettingsContextProps,
  SoundProvider,
} from "./context";
import ModalsProvider from "./context/ModalsProvider";
import { OrientationProvider } from "./context/OrientationProvider";
import PersonalAvatarOwnerProvider from "./context/PersonalAvatarOwnerProvider";
import useImagesPreloaded from "./hooks/useImagesPreloaded";
import useLocalStorageReducer from "./hooks/useLocalStorageReducer";
import useSoundControls from "./hooks/useSoundControls";
import { useWakeLock } from "./hooks/useWakeLock";
import { GAME_ROUTE, LOBBY_ROUTE, TOURNAMENTS_ARENA_ROUTE } from "./router";
import {
  getBrandConfigSelector,
  getConfigurationSelector,
  getCurrentSessionSelector,
  getRegulationTypeSelector,
  getUserSelector,
} from "./store/app/selectors";
import theme, { ApplicationWrapper, fourColorDeckTheme } from "./styles";
import { getPersonalAvatarId, getRandomAvatarId } from "./utils/avatar";
import { UK_REGULATION_TYPE } from "./utils/regulationTypes";
import settingsReducer from "./utils/settingsReducer/reducer";
import Settings from "./utils/types/Settings";

const Game = lazy(
  () => import(/* webpackChunkName: "Game" */ "./screens/Game"),
);

const Lobby = lazy(
  () => import(/* webpackChunkName: "Lobby" */ "./screens/Lobby"),
);

const TournamentsArena = lazy(
  () =>
    import(
      /* webpackChunkName: "TournamentsArena" */ "./screens/TournamentsArena"
    ),
);

const defaultSettings: Settings = {
  autoFocus: true,
  sounds: true,
  emojis: true,
  emojiQuickPanel: true,
  fourColorDeck: false,
  avatarId: getRandomAvatarId(),
  bigBlindFormat: false,
  topUp: false,
};

const router = createMemoryRouter(
  createRoutesFromElements(
    <Route path={LOBBY_ROUTE}>
      <Route
        path={GAME_ROUTE}
        element={
          <StyledProtectedFullScreen>
            <Suspense fallback={<Spinner />}>
              <Game />
            </Suspense>
          </StyledProtectedFullScreen>
        }
      />
    </Route>,
  ),
);

export default function App({ routerProps }: { routerProps: RouterProps }) {
  const configuration = useSelector(getConfigurationSelector);
  const { cid, userStatus } = useSelector(getUserSelector);
  const regulationType = useSelector(getRegulationTypeSelector);
  const brand = useSelector(getBrandConfigSelector);
  const hasCurrentSession = !!useSelector(getCurrentSessionSelector);

  const { mute } = useSoundControls();
  const [settings, settingsDispatch] = useLocalStorageReducer(
    settingsReducer,
    defaultSettings,
    cid ? `${cid}` : undefined,
  );

  const { sounds } = settings;

  useImagesPreloaded();

  useEffect(() => {
    const handleVisibilityChange = () => {
      mute(document.visibilityState === "hidden" ? true : !sounds);
    };

    mute(!sounds);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return document.removeEventListener(
      "visibilityChange",
      handleVisibilityChange,
    );
  }, [mute, sounds]);

  const { requestWakeLock, releaseWakeLock } = useWakeLock();

  useEffect(() => {
    requestWakeLock();

    return releaseWakeLock;
  }, [releaseWakeLock, requestWakeLock]);

  useEffect(() => {
    if (hasCurrentSession) {
      router.navigate(GAME_ROUTE);
    }
  }, [hasCurrentSession]);

  const currency = brand.currency;
  const locale = configuration.currentLocale;
  const culture = configuration.currentCulture;
  const { userInfo } = useSelector(getUserSelector);
  const accountCurrency = userInfo?.bankrollCurrencyCode;
  const exchangeRate = userInfo?.exchangeRateDollarToBankrollCurrency;

  const appConfig: ApplicationConfigContextProps = useMemo(
    () => ({
      currency,
      locale,
      culture,
      userAccount:
        accountCurrency && exchangeRate
          ? { currency: accountCurrency, exchangeRate }
          : undefined,
    }),
    [accountCurrency, culture, currency, exchangeRate, locale],
  );

  const settingsContextValue = useMemo((): SettingsContextProps => {
    let overriddenSettings = settings;

    if (regulationType === UK_REGULATION_TYPE) {
      overriddenSettings = { ...overriddenSettings, topUp: false };
    }

    // For any truthy userStatus, we can have an personal avatar
    if (cid && userStatus) {
      const personalAvatarId = getPersonalAvatarId(cid);

      if (personalAvatarId) {
        overriddenSettings = {
          ...overriddenSettings,
          avatarId: personalAvatarId,
        };
      }
    }

    return {
      state: overriddenSettings,
      dispatch: settingsDispatch,
    };
  }, [cid, regulationType, settings, settingsDispatch, userStatus]);

  return (
    <ApplicationWrapper>
      <ApplicationConfigContext.Provider value={appConfig}>
        <ThemeProvider
          theme={settings.fourColorDeck ? fourColorDeckTheme : theme}
        >
          <SoundProvider>
            <PersonalAvatarOwnerProvider>
              <SettingsContext.Provider value={settingsContextValue}>
                <Portal
                  placeholder={document.getElementById(
                    configuration.containers.actionIndicator,
                  )}
                >
                  <ActionIndicator />
                </Portal>
                <Portal
                  placeholder={document.getElementById(
                    configuration.containers.modalContainer,
                  )}
                >
                  <NotificationToasterContainer />
                </Portal>
                <ModalsProvider>
                  <OrientationProvider>
                    <BrowserRouter {...routerProps}>
                      <Routes>
                        <Route
                          path={LOBBY_ROUTE}
                          element={
                            <Suspense fallback={<Spinner />}>
                              <Lobby />
                            </Suspense>
                          }
                        />
                        <Route
                          path={TOURNAMENTS_ARENA_ROUTE}
                          element={
                            <Suspense fallback={<Spinner />}>
                              <TournamentsArena />
                            </Suspense>
                          }
                        />
                      </Routes>
                    </BrowserRouter>
                  </OrientationProvider>
                </ModalsProvider>
                <ModalsProvider>
                  <RouterProvider router={router} />
                </ModalsProvider>
              </SettingsContext.Provider>
            </PersonalAvatarOwnerProvider>
          </SoundProvider>
        </ThemeProvider>
      </ApplicationConfigContext.Provider>
    </ApplicationWrapper>
  );
}
