import type { Saga, Task } from "redux-saga";
import { cancel, fork, take, takeEvery } from "redux-saga/effects";
import Communication from "../../../../network/Communication";
import {
  GameServerEventMap,
  WithdrawFundsEvent,
} from "../../../../network/events";
import CommunicationLifecycleEvent from "../../../../network/events/CommunicationLifecycleEvent";
import GameCloseEvent from "../../../../network/events/GameCloseEvent";
import GameStateEvent from "../../../../network/events/GameStateEvent";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import { JoinPositionErrorReason } from "../../../../network/protocol/gameServer/types/JoinPositionError";
import { JoinRingGameError } from "../../../../network/protocol/mainServer/types/JoinRingGameError";
import createEventChannel from "../../../app/sagas/shared/createEventChannel";
import MessagesCommunication, {
  createMessagesChannel,
} from "../../../app/sagas/shared/messagesCommunication";
import {
  HeroSatDown,
  JoinPositionError,
  RebuySuccess,
  TableClosed,
  gameWillClose,
  heroSatDown,
  joinPositionError,
  joinRingGameError,
  joinTournamentError,
  reBuySuccess,
  tableClosed,
  tournamentCancelled,
} from "../../actions";
import { CloseTableReason } from "../../types/CloseTableReason";
import getTableHistory from "./getTableHistory";

export default function* connectionLoop(
  server: Communication<GameServerEventMap>,
  gameFlow: Saga,
) {
  const connectionChannel = createEventChannel(
    server,
    CommunicationLifecycleEvent.CONNECTED,
  );
  const disconnectionChannel = createEventChannel(
    server,
    CommunicationLifecycleEvent.DISCONNECTED,
  );
  const connection: MessagesCommunication<GameServerMessage> = {
    send: server.send,
    messages: createMessagesChannel(server),
  };

  yield takeEvery(gameWillClose.type, () => {
    server.dispatchEvent(new GameCloseEvent());
  });

  yield takeEvery(
    joinRingGameError.type,
    (action: ReturnType<typeof joinRingGameError>) => {
      if (
        action.payload.error.errorCode ===
        JoinRingGameError.JOIN_RING_GAME_ERROR_PENDING_ACTION
      ) {
        server.dispatchEvent(new GameCloseEvent("pendingAction"));
      }
    },
  );

  yield takeEvery(joinPositionError.type, (action: JoinPositionError) => {
    if (
      action.payload.error.reason === JoinPositionErrorReason.PENDING_ACTION
    ) {
      server.dispatchEvent(new GameCloseEvent("pendingAction"));
    }
  });

  yield takeEvery([joinTournamentError.type, joinRingGameError.type], () => {
    server.dispatchEvent(new GameStateEvent("error"));
  });

  yield takeEvery(tournamentCancelled.type, () => {
    server.dispatchEvent(new GameStateEvent("cancelled"));
  });

  yield takeEvery(
    tableClosed.type,
    ({ payload: { closeReason } }: TableClosed) => {
      switch (closeReason) {
        case CloseTableReason.UNKNOWN:
          server.dispatchEvent(new GameStateEvent("error"));
          break;
        case CloseTableReason.NIGHT_LIMIT_KICK:
          server.dispatchEvent(new GameCloseEvent("nightLimit"));
          break;
        default:
          server.dispatchEvent(new GameStateEvent("ended"));
      }
    },
  );

  yield takeEvery(
    [reBuySuccess.type, heroSatDown.type],
    ({
      payload: { withdrawnRealAmount, withdrawnRestrictedAmount },
    }: RebuySuccess | HeroSatDown) => {
      server.dispatchEvent(
        new WithdrawFundsEvent(withdrawnRealAmount, withdrawnRestrictedAmount),
      );
    },
  );

  while (true) {
    yield take(connectionChannel);

    const gameFlowTask: Task = yield fork(gameFlow, connection);
    const historyTask: Task = yield fork(getTableHistory, connection);

    yield take(disconnectionChannel);

    yield cancel(historyTask);
    yield cancel(gameFlowTask);
  }
}
