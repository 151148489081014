import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

const ButtonStyle = styled.button<{ kind: "primary" | "secondary" }>`
  position: relative;

  min-width: 120px;

  padding: 4.5px 23.5px;

  border-radius: 30px;

  ${({ kind, theme }) => css`
    border: 3px solid ${theme.button.border[kind]};

    color: ${theme.button.color[kind]};
    font-size: ${theme.fontSize.small};

    background-color: ${theme.button.backgroundColor[kind]};
  `}

  font-family: inherit;

  text-transform: uppercase;

  outline: none;

  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }

  @media (hover: hover) {
    &:hover:enabled {
      ${({ kind, theme }) => css`
        color: ${theme.button.colorHover[kind]};

        background-color: ${theme.button.backgroundHoverColor};
      `}
    }
  }
`;

export { ButtonStyle };
