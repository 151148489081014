import { fork, takeEvery, takeLeading } from "redux-saga/effects";
import {
  POKER_SC_CHOOSE_TOUR_DEALER,
  POKER_SC_FT_DEAL_STATUS,
  POKER_SC_FTD_ALLOWED,
  POKER_SC_FTD_NEGOTIATION_STATUS,
  POKER_SC_FTD_RECONNECTION_DETAILS,
  POKER_SC_H4H_ACTIVATE,
  POKER_SC_H4H_DEACTIVATE,
  POKER_SC_H4H_IN_PROCESS,
} from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  chooseTourDealer,
  ftdAvailabilityChanged,
  ftdDecision,
  ftdNegotiationStatus,
  ftdReconnectionDetails,
  ftdSatus,
  ftdSelection,
  h4hActivated,
  h4hDeactivated,
  h4hInProcess,
  tournamentJoined,
} from "../../actions";
import pushMessageHandler from "../shared/pushMessageHandler";
import ftdSelectionStatusesHandler from "./ftdSelectionStatusesHandler";
import pushNotificationHandler from "./pushNotificationHandler";
import selectFTD from "./selectFTD";
import sendFtdDecision from "./sendFtdDecision";
import subscribePushNotification from "./subscribePushNotification";
import tournamentFlow from "./tournamentFlow";

export default function* multiTableTournamentFlow(
  connection: MessagesCommunication<GameServerMessage>,
) {
  yield fork(
    pushMessageHandler,
    POKER_SC_CHOOSE_TOUR_DEALER,
    chooseTourDealer,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_FTD_ALLOWED,
    ftdAvailabilityChanged,
    connection,
  );

  yield fork(ftdSelectionStatusesHandler, connection);

  yield fork(pushMessageHandler, POKER_SC_FT_DEAL_STATUS, ftdSatus, connection);

  yield fork(
    pushMessageHandler,
    POKER_SC_FTD_NEGOTIATION_STATUS,
    ftdNegotiationStatus,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_FTD_RECONNECTION_DETAILS,
    ftdReconnectionDetails,
    connection,
  );

  yield takeLeading(ftdSelection.type, selectFTD, connection);
  yield takeEvery(ftdDecision.type, sendFtdDecision, connection);
  yield takeEvery(tournamentJoined.type, subscribePushNotification, connection);

  yield fork(pushNotificationHandler, connection);

  yield fork(
    pushMessageHandler,
    POKER_SC_H4H_ACTIVATE,
    h4hActivated,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_H4H_IN_PROCESS,
    h4hInProcess,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_H4H_DEACTIVATE,
    h4hDeactivated,
    connection,
  );

  yield fork(tournamentFlow, connection);
}
