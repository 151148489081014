// regular avatars
import avatar1001 from "../assets/avatars/1001.png";
import avatar1002 from "../assets/avatars/1002.png";
import avatar1004 from "../assets/avatars/1004.png";
import avatar1006 from "../assets/avatars/1006.png";
import avatar1007 from "../assets/avatars/1007.png";
import avatar1009 from "../assets/avatars/1009.png";
import avatar1010 from "../assets/avatars/1010.png";
import avatar1011 from "../assets/avatars/1011.png";
import avatar1024 from "../assets/avatars/1024.png";
import avatar1025 from "../assets/avatars/1025.png";
import avatar1026 from "../assets/avatars/1026.png";
import avatar1027 from "../assets/avatars/1027.png";
import avatar1049 from "../assets/avatars/1049.png";
import avatar1050 from "../assets/avatars/1050.png";
import avatar1051 from "../assets/avatars/1051.png";
import avatar1052 from "../assets/avatars/1052.png";
import avatar1053 from "../assets/avatars/1053.png";
import avatar1054 from "../assets/avatars/1054.png";
import avatar1055 from "../assets/avatars/1055.png";
import avatar1056 from "../assets/avatars/1056.png";
import avatar1057 from "../assets/avatars/1057.png";
import avatar1058 from "../assets/avatars/1058.png";
import avatar1059 from "../assets/avatars/1059.png";
import avatar1060 from "../assets/avatars/1060.png";
import avatar1063 from "../assets/avatars/1063.png";
import avatar1064 from "../assets/avatars/1064.png";
import avatar1065 from "../assets/avatars/1065.png";
import avatar1066 from "../assets/avatars/1066.png";
import avatar1067 from "../assets/avatars/1067.png";
import avatar1068 from "../assets/avatars/1068.png";
import avatar1069 from "../assets/avatars/1069.png";
import avatar1070 from "../assets/avatars/1070.png";
import avatar1103 from "../assets/avatars/1103.png";
import avatar1105 from "../assets/avatars/1105.png";
import avatar1112 from "../assets/avatars/1112.png";
import avatar1113 from "../assets/avatars/1113.png";
import avatar1114 from "../assets/avatars/1114.png";
import avatar1115 from "../assets/avatars/1115.png";
import avatar1116 from "../assets/avatars/1116.png";
import avatar1117 from "../assets/avatars/1117.png";
import avatar1118 from "../assets/avatars/1118.png";
import avatar1119 from "../assets/avatars/1119.png";
import avatar1120 from "../assets/avatars/1120.png";
import avatar1121 from "../assets/avatars/1121.png";
import avatar1122 from "../assets/avatars/1122.png";
import avatar1123 from "../assets/avatars/1123.png";
import avatar1131 from "../assets/avatars/1131.png";
import avatar1132 from "../assets/avatars/1132.png";
import avatar1133 from "../assets/avatars/1133.png";
import avatar1134 from "../assets/avatars/1134.png";
import avatar1136 from "../assets/avatars/1136.png";
import avatar1137 from "../assets/avatars/1137.png";
import avatar1138 from "../assets/avatars/1138.png";
import avatar1139 from "../assets/avatars/1139.png";
import avatar1140 from "../assets/avatars/1140.png";
import avatar1141 from "../assets/avatars/1141.png";
import avatar1142 from "../assets/avatars/1142.png";
import avatar1143 from "../assets/avatars/1143.png";
import avatar1144 from "../assets/avatars/1144.png";
import avatar1145 from "../assets/avatars/1145.png";
import avatar1146 from "../assets/avatars/1146.png";
import avatar1147 from "../assets/avatars/1147.png";
import avatar1148 from "../assets/avatars/1148.png";
import avatar3000 from "../assets/avatars/3000.png";
import avatar3001 from "../assets/avatars/3001.png";
import avatar3002 from "../assets/avatars/3002.png";
import avatar3003 from "../assets/avatars/3003.png";
import avatar3004 from "../assets/avatars/3004.png";
import avatar3005 from "../assets/avatars/3005.png";
import avatar3006 from "../assets/avatars/3006.png";
import avatar3007 from "../assets/avatars/3007.png";
import avatar3008 from "../assets/avatars/3008.png";
import avatar3009 from "../assets/avatars/3009.png";
import avatar3010 from "../assets/avatars/3010.png";
import avatar3011 from "../assets/avatars/3011.png";
import avatar3012 from "../assets/avatars/3012.png";
import avatar3013 from "../assets/avatars/3013.png";
import avatar3014 from "../assets/avatars/3014.png";
import avatar3015 from "../assets/avatars/3015.png";
import avatar3016 from "../assets/avatars/3016.png";
import avatar3017 from "../assets/avatars/3017.png";
import avatar3018 from "../assets/avatars/3018.png";
import avatar3019 from "../assets/avatars/3019.png";
import avatar3100 from "../assets/avatars/3100.png";
import avatar3101 from "../assets/avatars/3101.png";
import avatar3102 from "../assets/avatars/3102.png";
import avatar3103 from "../assets/avatars/3103.png";
import avatar3104 from "../assets/avatars/3104.png";
import avatar3105 from "../assets/avatars/3105.png";
import avatar3106 from "../assets/avatars/3106.png";
import avatar3107 from "../assets/avatars/3107.png";
import avatar3108 from "../assets/avatars/3108.png";
import avatar3109 from "../assets/avatars/3109.png";
import avatar3110 from "../assets/avatars/3110.png";
import avatar3111 from "../assets/avatars/3111.png";
import avatar3112 from "../assets/avatars/3112.png";
import avatar3113 from "../assets/avatars/3113.png";
import avatar3114 from "../assets/avatars/3114.png";
import avatar3115 from "../assets/avatars/3115.png";
import avatar3116 from "../assets/avatars/3116.png";
import avatar3117 from "../assets/avatars/3117.png";
// flags avatars
import avatar1250 from "../assets/avatars/1250.png";
import avatar1251 from "../assets/avatars/1251.png";
import avatar1252 from "../assets/avatars/1252.png";
import avatar1253 from "../assets/avatars/1253.png";
import avatar1254 from "../assets/avatars/1254.png";
import avatar1255 from "../assets/avatars/1255.png";
import avatar1256 from "../assets/avatars/1256.png";
import avatar1257 from "../assets/avatars/1257.png";
import avatar1258 from "../assets/avatars/1258.png";
import avatar1259 from "../assets/avatars/1259.png";
import avatar1260 from "../assets/avatars/1260.png";
import avatar1261 from "../assets/avatars/1261.png";
import avatar1262 from "../assets/avatars/1262.png";
import avatar1263 from "../assets/avatars/1263.png";
import avatar1264 from "../assets/avatars/1264.png";
import avatar1265 from "../assets/avatars/1265.png";
import avatar1266 from "../assets/avatars/1266.png";
import avatar1267 from "../assets/avatars/1267.png";
import avatar1268 from "../assets/avatars/1268.png";
import avatar1269 from "../assets/avatars/1269.png";
import avatar1270 from "../assets/avatars/1270.png";
import avatar1271 from "../assets/avatars/1271.png";
import avatar1272 from "../assets/avatars/1272.png";
import avatar1273 from "../assets/avatars/1273.png";
import avatar1274 from "../assets/avatars/1274.png";
import avatar1275 from "../assets/avatars/1275.png";
import avatar1276 from "../assets/avatars/1276.png";
import avatar1277 from "../assets/avatars/1277.png";
import avatar1278 from "../assets/avatars/1278.png";
import avatar1279 from "../assets/avatars/1279.png";
import avatar1280 from "../assets/avatars/1280.png";
import avatar1281 from "../assets/avatars/1281.png";
// ambassadors avatars
import avatar1928 from "../assets/avatars/1928.png";
import avatar1982 from "../assets/avatars/1982.png";
import avatar1983 from "../assets/avatars/1983.png";
import avatar1986 from "../assets/avatars/1986.png";
import avatar1988 from "../assets/avatars/1988.png";
import avatar1989 from "../assets/avatars/1989.png";
import avatar1990 from "../assets/avatars/1990.png";
import avatar1992 from "../assets/avatars/1992.png";
import avatar1993 from "../assets/avatars/1993.png";
import avatar1994 from "../assets/avatars/1994.png";
import avatar1995 from "../assets/avatars/1995.png";
import avatar1996 from "../assets/avatars/1996.png";
import avatar1997 from "../assets/avatars/1997.png";
import avatar1998 from "../assets/avatars/1998.png";
import avatar1999 from "../assets/avatars/1999.png";
import avatar2000 from "../assets/avatars/2000.png";

function getObjectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as unknown as (keyof T)[];
}

const PEOPLE_AVATARS = {
  1001: avatar1001,
  1002: avatar1002,
  1004: avatar1004,
  1006: avatar1006,
  1007: avatar1007,
  1009: avatar1009,
  1010: avatar1010,
  1011: avatar1011,
  1024: avatar1024,
  1025: avatar1025,
  1026: avatar1026,
  1027: avatar1027,
  1049: avatar1049,
  1050: avatar1050,
  1051: avatar1051,
  1052: avatar1052,
  1053: avatar1053,
  1054: avatar1054,
  1055: avatar1055,
  1056: avatar1056,
  1057: avatar1057,
  1058: avatar1058,
  1059: avatar1059,
  1060: avatar1060,
  1063: avatar1063,
  1064: avatar1064,
  1065: avatar1065,
  1066: avatar1066,
  1067: avatar1067,
  1068: avatar1068,
  1069: avatar1069,
  1070: avatar1070,
  3000: avatar3000,
  3001: avatar3001,
  3002: avatar3002,
  3003: avatar3003,
  3004: avatar3004,
  3005: avatar3005,
  3006: avatar3006,
  3007: avatar3007,
  3008: avatar3008,
  3009: avatar3009,
  3010: avatar3010,
  3011: avatar3011,
  3012: avatar3012,
  3013: avatar3013,
  3014: avatar3014,
  3015: avatar3015,
  3016: avatar3016,
  3017: avatar3017,
  3018: avatar3018,
  3019: avatar3019,
} as const;

const ANIMALS_AVATARS = {
  1103: avatar1103,
  1105: avatar1105,
  1112: avatar1112,
  1113: avatar1113,
  1114: avatar1114,
  1115: avatar1115,
  1116: avatar1116,
  1117: avatar1117,
  1118: avatar1118,
  1119: avatar1119,
  1120: avatar1120,
  1121: avatar1121,
  1122: avatar1122,
  1123: avatar1123,
  1131: avatar1131,
  1132: avatar1132,
  1133: avatar1133,
  1134: avatar1134,
  1136: avatar1136,
  1137: avatar1137,
  1138: avatar1138,
  1139: avatar1139,
  1140: avatar1140,
  1141: avatar1141,
  1142: avatar1142,
  1143: avatar1143,
  1144: avatar1144,
  1145: avatar1145,
  1146: avatar1146,
  1147: avatar1147,
  1148: avatar1148,
  3100: avatar3100,
  3101: avatar3101,
  3102: avatar3102,
  3103: avatar3103,
  3104: avatar3104,
  3105: avatar3105,
  3106: avatar3106,
  3107: avatar3107,
  3108: avatar3108,
  3109: avatar3109,
  3110: avatar3110,
  3111: avatar3111,
  3112: avatar3112,
  3113: avatar3113,
  3114: avatar3114,
  3115: avatar3115,
  3116: avatar3116,
  3117: avatar3117,
} as const;

const FLAGS_AVATARS = {
  1250: avatar1250,
  1251: avatar1251,
  1252: avatar1252,
  1253: avatar1253,
  1254: avatar1254,
  1255: avatar1255,
  1256: avatar1256,
  1257: avatar1257,
  1258: avatar1258,
  1259: avatar1259,
  1260: avatar1260,
  1261: avatar1261,
  1262: avatar1262,
  1263: avatar1263,
  1264: avatar1264,
  1265: avatar1265,
  1266: avatar1266,
  1267: avatar1267,
  1268: avatar1268,
  1269: avatar1269,
  1270: avatar1270,
  1271: avatar1271,
  1272: avatar1272,
  1273: avatar1273,
  1274: avatar1274,
  1275: avatar1275,
  1276: avatar1276,
  1277: avatar1277,
  1278: avatar1278,
  1279: avatar1279,
  1280: avatar1280,
  1281: avatar1281,
} as const;

export const AMBASSADORS_AVATARS: Record<number, string> = {
  1928: avatar1928,
  1982: avatar1982,
  1983: avatar1983,
  1986: avatar1986,
  1988: avatar1988,
  1989: avatar1989,
  1990: avatar1990,
  1992: avatar1992,
  1993: avatar1993,
  1994: avatar1994,
  1995: avatar1995,
  1996: avatar1996,
  1997: avatar1997,
  1998: avatar1998,
  1999: avatar1999,
  2000: avatar2000,
} as const;

const cidToAvatarIdMap: Record<number, keyof typeof AMBASSADORS_AVATARS> = {
  812000840: 1928,
  813842367: 1982,
  779099017: 1983,
  833305898: 1986,
  830464645: 1988,
  804878400: 1989,
  763060109: 1990,
  758540871: 1992,
  837569765: 1993,
  834245873: 1994,
  796018942: 1995,
  806642101: 1996,
  832960066: 1997,
  796478994: 1998,
  830378453: 1999,
  825754710: 2000,
};

const AVATARS = {
  ...PEOPLE_AVATARS,
  ...ANIMALS_AVATARS,
  ...FLAGS_AVATARS,
  ...AMBASSADORS_AVATARS,
} as const;

export const REGULAR_AVATAR_IDS = [
  ...getObjectKeys(PEOPLE_AVATARS),
  ...getObjectKeys(ANIMALS_AVATARS),
];
export const SELECTABLE_AVATAR_IDS = [
  ...getObjectKeys(PEOPLE_AVATARS),
  ...getObjectKeys(ANIMALS_AVATARS),
  ...getObjectKeys(FLAGS_AVATARS),
];

export function getRandomAvatarId() {
  return REGULAR_AVATAR_IDS[
    Math.floor(Math.random() * REGULAR_AVATAR_IDS.length)
  ];
}

function isAvatarId(id: number): id is keyof typeof AVATARS {
  return id in AVATARS;
}

export function getAvatar(id: number) {
  return AVATARS[isAvatarId(id) ? id : getRandomAvatarId()];
}

export function getPersonalAvatarId(cid: number): number | undefined {
  return cidToAvatarIdMap[cid];
}
