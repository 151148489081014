import { put, race, select, take } from "redux-saga/effects";
import {
  MAIN_CLIENT_RECONNECT_APP_ERR,
  MAIN_CLIENT_RECONNECT_APP_OK,
  reconnectApp,
} from "../../../../network/protocol/mainServer";
import { MainServerMessage } from "../../../../network/protocol/mainServer/types";
import ErrorMessage from "../../../../network/protocol/mainServer/types/ErrorMessage";
import ReconnectionData from "../../../../network/protocol/mainServer/types/ReconnectionData";
import { appReconnected, reconnectError } from "../../actions";
import { getUserSelector } from "../../selectors";
import User from "../../types/User";
import MessagesCommunication from "./messagesCommunication";

export default function* appReconnection(
  connection: MessagesCommunication<MainServerMessage>,
) {
  const user: User = yield select(getUserSelector);

  connection.send(reconnectApp(user));

  const [response, error]: [ReconnectionData, ErrorMessage] = yield race([
    take(connection.messages, MAIN_CLIENT_RECONNECT_APP_OK),
    take(connection.messages, MAIN_CLIENT_RECONNECT_APP_ERR),
  ]);

  if (response) {
    yield put(appReconnected(response));
  } else if (error) {
    yield put(reconnectError({ errorCode: error.ErrorCode, reason: -1 }));
  }
}
