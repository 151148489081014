import { RefObject, useEffect, useState } from "react";
import {
  LANDSCAPE_GAME_HEIGHT,
  LANDSCAPE_GAME_WIDTH,
  PORTRAIT_GAME_HEIGHT,
  PORTRAIT_GAME_WIDTH,
} from "../styles/gameLayout";

const SMALL_CONTAINER_SIZE = 1025;

export type ScaleObjectType = ReturnType<typeof useScaleToFit>;

export default function useScaleToFit(
  containerRef: RefObject<HTMLDivElement>,
  highLimit = Number.POSITIVE_INFINITY,
) {
  const [scale, setScale] = useState({
    contentScale: 0,
    landscape: false,
    smallContainer: false,
    sourceWidth: PORTRAIT_GAME_WIDTH,
    sourceHeight: PORTRAIT_GAME_HEIGHT,
    offsetX: 0,
    offsetY: 0,
  });

  useEffect(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const observer = new ResizeObserver((entries) => {
      const { contentRect } = entries[0];
      const landscape = contentRect.width > contentRect.height;
      const smallContainer = contentRect.width < SMALL_CONTAINER_SIZE;
      const sourceWidth = landscape
        ? LANDSCAPE_GAME_WIDTH
        : PORTRAIT_GAME_WIDTH;
      const sourceHeight = landscape
        ? LANDSCAPE_GAME_HEIGHT
        : PORTRAIT_GAME_HEIGHT;
      const scale =
        Math.floor(
          Math.min(
            contentRect.width / sourceWidth,
            contentRect.height / sourceHeight,
            highLimit,
          ) * 100,
        ) / 100;
      const offsetX = Math.floor((contentRect.width - sourceWidth * scale) / 2);
      const offsetY = Math.floor(
        (contentRect.height - sourceHeight * scale) / 2,
      );

      setScale({
        contentScale: scale,
        landscape,
        smallContainer,
        sourceWidth,
        sourceHeight,
        offsetX,
        offsetY,
      });
    });

    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, [containerRef, highLimit]);

  return scale;
}
