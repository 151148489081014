import { put, select, take } from "redux-saga/effects";
import {
  POKER_SC_SMALL_BLIND,
  postSmallBlind,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  SmallBlind,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { smallBlind } from "../../actions";
import { getHeroSelector } from "../../selectors";
import Player from "../../types/Player";
import { NonRoundAction } from "../../types/Turn";

export default function* smallBlindHandler(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const blind: SmallBlind = yield take(
      connection.messages,
      POKER_SC_SMALL_BLIND,
    );
    const hero: Player | undefined = yield select(getHeroSelector);

    if (blind.seatId === hero?.seatId) {
      connection.send(postSmallBlind());
    } else {
      yield put(
        smallBlind({
          seatId: blind.seatId,
          actions: [
            NonRoundAction.SMALL_BLIND,
            NonRoundAction.DECLINE_SMALL_BLIND,
          ],
          timeOut: blind.timeOut,
          syncDate: blind.syncDate,
        }),
      );
    }
  }
}
