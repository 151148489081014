import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { getConfigurationSelector } from "../../store/app/selectors";
import Styleable from "../../utils/types/Styleable";
import { FullScreenContent } from "./styles";

const FullScreenPortal = ({
  children,
  ...styles
}: Styleable<PropsWithChildren>) => {
  const { containers } = useSelector(getConfigurationSelector);

  const container = document.getElementById(containers.fullScreenPopup);

  if (!container) throw new Error("Full screen container not found");

  return createPortal(
    <FullScreenContent {...styles}>{children}</FullScreenContent>,
    container,
  );
};

export default FullScreenPortal;
