import { Dictionaries, LobbyServiceResponse } from "./types";
import { fetchData } from "./utils";

export const TIME_STAMP = "0";
export const INFO_VERSION = "25";

const DICTIONARY_BY_ID_URL = "api/Dictionaries/GetDictionaryByID/";

export let BRAND_ID: string;
export let BASE_URL: string;

export enum LobbyDictionaryType {
  TOUR_PRIZE_POOLS = "6",
  TOUR_UP_LEVEL = "7",
  BLAST_TEMPLATE = "12",
}

export function initLobbyServicesData(id: number, url: string) {
  BRAND_ID = id.toString();
  BASE_URL = url;
}

export async function getDictionaryById<T extends LobbyDictionaryType>(
  type: T,
  itemId: number,
  abort?: AbortSignal,
): Promise<Dictionaries[T]["Data"][0]> {
  const lobbyServiceUrl = new URL(DICTIONARY_BY_ID_URL, BASE_URL);

  lobbyServiceUrl.searchParams.append("Type", type);
  lobbyServiceUrl.searchParams.append("ItemId", String(itemId));
  lobbyServiceUrl.searchParams.append("BrandId", BRAND_ID);

  const responseJson = await fetchData<LobbyServiceResponse>(
    lobbyServiceUrl.href,
    abort,
  );

  return responseJson.DictionariesTypes[type].Data[itemId];
}
