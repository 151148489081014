import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const TableWrapper = styled.div<{ $active?: boolean }>`
  position: relative;
  width: inherit;
  height: 1.2em;
  box-sizing: border-box;

  border-radius: 3.125em;

  font-size: inherit;

  cursor: pointer;

  ${({ $active, theme }) => css`
    background: ${$active
      ? theme.sessionIndicator.table.active
      : theme.sessionIndicator.table.inactive};
    border: 0.0625em solid
      ${$active
        ? theme.sessionIndicator.regular.whiteTableBorder
        : theme.sessionIndicator.regular.greyTableBorder};
    box-shadow: ${$active ? theme.sessionIndicator.regular.glowing : "none"};
  `}
`;

export const ChildrenWrapper = styled.div`
  position: absolute;
  bottom: 0.0625em;
  left: 50%;
  transform: translateX(-50%);
`;
