import Game from "../types/Game";

const initialState: Game = {
  gameInstanceId: "",
  type: "blast",
  bonus: undefined,
  serverId: "",
};

export default initialState;
