import { select } from "redux-saga/effects";
import { sitOutTour } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { getHeroSelector } from "../../selectors";
import Player from "../../types/Player";

export default function* sitOutOfTour(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const hero: Player | undefined = yield select(getHeroSelector);
  connection.send(sitOutTour(hero!.seatId));
}
