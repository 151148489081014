import { GameState } from "../../store";
import getHero from "../hero/getHero";

export default function getHeroTurn(state: GameState) {
  const { turn } = state;
  const heroPlayer = getHero(state);

  return heroPlayer &&
    !heroPlayer.sittingOut &&
    heroPlayer.seatId === turn?.seatId
    ? turn
    : undefined;
}
