import delimiter from "../delimiter";

export function parseFtdAvailabilityBody(body: string) {
  const params = body.split(delimiter.section);

  return params[0] === "1";
}

export function parseFtdSelectionStatusBody(body: string) {
  const params = body.split(delimiter.field);
  return params.reduce<
    { readonly seatId: number; readonly selected: boolean }[]
  >((acc, current) => {
    if (current) {
      const entry = current.split(delimiter.list);
      const isCorrectEntry = entry.length >= 2;
      if (isCorrectEntry) {
        const seatId = Number.parseInt(entry[0]);
        const selected = entry[1] === "1";

        acc.push({ seatId, selected });
      }
    }

    return acc;
  }, []);
}

export function parseFtdNegotiationStatusBody(body: string) {
  const params = body.split(delimiter.section);

  const playersData = params[4].split(delimiter.field);
  const dealPrizes = playersData.reduce<
    { readonly seatId: number; readonly prize: number }[]
  >((acc, current) => {
    if (current) {
      const entry = current.split(delimiter.list);
      const isCorrectEntry = entry.length >= 2;
      if (isCorrectEntry) {
        const seatId = Number.parseInt(entry[0]);
        const prize = Number.parseInt(entry[1]);

        acc.push({ seatId, prize });
      }
    }
    return acc;
  }, []);

  const prizePoolData = params[5].split(delimiter.field);
  const prizePool = prizePoolData.reduce<number[]>((acc, current) => {
    if (current) {
      const entry = current.split(delimiter.list);
      const isCorrectEntry = entry.length >= 2;
      if (isCorrectEntry) {
        acc.push(Number.parseInt(entry[1]));
      }
    }

    return acc;
  }, []);

  const prizes = dealPrizes.map((value, index) => {
    return { ...value, original: prizePool[index] } as const;
  });

  return {
    active: params[0] === "1",
    // seconds
    remaining: Number.parseInt(params[1]),
    // minutes, so we translate it to seconds
    duration: Number.parseInt(params[2]) * 60,
    syncDate: Date.now(),
    firstPlacePrize: Number.parseInt(params[3]),
    prizes,
  } as const;
}

export function parseFtdStatusBody(body: string) {
  const params = body.split(delimiter.field);

  return params.reduce<
    {
      readonly seatId: number;
      readonly decision: number;
      readonly declineReason: number;
    }[]
  >((acc, current) => {
    if (current) {
      const values = current.split(delimiter.list);

      if (values.length >= 3) {
        // filter garbage
        acc.push({
          seatId: Number.parseInt(values[0]),
          /**
           * -1 – didn’t decide yet
           * 0 – don’t agree
           * 1 – agree
           */
          decision: Number.parseInt(values[1]),
          /**
           * 0 - manual
           * 1 - timeout
           */
          declineReason: Number.parseInt(values[2]),
        });
      }
    }
    return acc;
  }, []);
}
