import { call, put, select } from "redux-saga/effects";
import {
  GameServerMessage,
  GetQuickPosErr,
  GetQuickPosOk,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { seatAvailable, seatUnavailable } from "../../actions";
import { getGameSelector, getHeroSelector } from "../../selectors";
import Game from "../../types/Game";
import Player from "../../types/Player";
import getAvailableSeat from "./getAvailableSeat";

export default function* requestAvailableSeat(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const hero: Player | undefined = yield select(getHeroSelector);
  const game: Game = yield select(getGameSelector);
  const isReconnect =
    Boolean(hero && hero.seatId > 0) || game.inializedByOpenSession;

  if (isReconnect) {
    return;
  }

  const [response, error]: [GetQuickPosOk, GetQuickPosErr] = yield call(
    getAvailableSeat,
    connection,
  );

  if (response) {
    yield put(seatAvailable(response));
  } else if (error) {
    yield put(seatUnavailable(error));
  }
}
