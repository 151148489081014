import { sendChatMessage } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { ChatMessageSent } from "../../actions";

export default function chatMessage(
  connection: MessagesCommunication<GameServerMessage>,
  action: ChatMessageSent,
) {
  connection.send(sendChatMessage(action.payload.message));
}
