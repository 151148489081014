export enum TypeID {
  Subscribe = 1,
  Unsubscribe = 0,
  Error = -1,
}

export enum NotificationID {
  PositionInTournament = 1,
  PlayersToTheMoney,
  KoPrizeWon,
  StackStatistics,
  PrizeInfo,
  RemainingPlayers,
}

export interface PushNotification {
  [NotificationID.PositionInTournament]?: PositionInTournament;
  [NotificationID.PrizeInfo]?: PrizeInfo;
}

export interface PositionInTournament {
  heroPosition: number;
  playersLeft: number;
}

export interface PrizeInfo {
  paidPlaces: number;
  totalRegisteredPlayers: number;
  firstPrizeValue: number;
  firstPrizeType: number;
  lastPrizeValue: number;
  lastPrizeType: number;
  totalPrizePool: number;
  totalKOPrizePool: number;
  firstPrizeDescription: string;
  lastPrizeDescription: string;
}
