import { fork, takeEvery } from "redux-saga/effects";
import { POKER_SC_UPDATE_SNAP_POOL_TABLE_ID } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  fold,
  joinPosition,
  ringGameJoined,
  updateSnapPoolTableId,
} from "../../actions";
import pushMessageHandler from "../shared/pushMessageHandler";
import joinSnapPosition from "./joinSnapPosition";
import joinSnapTable from "./joinSnapTable";
import ringGameFlow from "./ringGameFlow";
import sitOutOfCashTableDelayed from "./sitOutOfCashTableDelayed";

export default function* snapGameFlow(
  connection: MessagesCommunication<GameServerMessage>,
) {
  yield takeEvery(joinPosition.type, joinSnapPosition, connection);
  yield takeEvery(fold.type, sitOutOfCashTableDelayed, connection);

  yield takeEvery(ringGameJoined.type, joinSnapTable, connection);

  yield fork(
    pushMessageHandler,
    POKER_SC_UPDATE_SNAP_POOL_TABLE_ID,
    updateSnapPoolTableId,
    connection,
  );

  yield fork(ringGameFlow, connection);
}
