import { MultipilierData } from "./types/MultiplierData";
import { PrizePoolData } from "./types/PrizePoolData";

/**
 * Returns the prize pool data with color index by sorted values
 */
export const getMultiplierData = (
  prizePoolData: PrizePoolData[],
): MultipilierData[] => {
  const orderedValues = prizePoolData.sort((a, b) => a.value - b.value);

  return prizePoolData.map((item) => {
    const index = orderedValues.indexOf(item);

    return {
      id: item.id,
      value: item.value,
      colorIndex: index,
    };
  });
};
