import { performFold } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import { FoldType } from "../../../../network/protocol/gameServer/types/FoldData";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { Fold } from "../../actions";

export default function fold(
  connection: MessagesCommunication<GameServerMessage>,
  action: Fold,
) {
  connection.send(performFold(action.payload || FoldType.REGULAR));
}
