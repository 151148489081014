import { useCallback } from "react";
import getSupportedCurrencySymbol from "../utils/getSupportedCurrencySymbol";
import useAppConfig from "./useAppConfig";

export default function useCurrencyFormat() {
  const { locale, currency } = useAppConfig();

  return useCallback(
    (value: number, currencyCode?: string) => {
      const resolvedCurrencyCode = currencyCode || currency;
      const options: Intl.NumberFormatOptions = {
        style: "currency",
        currency: resolvedCurrencyCode,
      };

      if (isNaN(value)) return "";

      const isInteger = value % 100 === 0;

      if (isInteger) {
        options.minimumFractionDigits = 0;
      }

      const currencySymbol = getSupportedCurrencySymbol(resolvedCurrencyCode);

      if (!currencySymbol) {
        return new Intl.NumberFormat(locale, options).format(value / 100);
      }

      const parts = new Intl.NumberFormat(locale, options).formatToParts(
        value / 100,
      );

      return parts.reduce((prev, part) => {
        return part.type !== "literal" && part.type !== "currency"
          ? prev + part.value
          : prev;
      }, currencySymbol);
    },
    [currency, locale],
  );
}
