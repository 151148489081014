import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { ToastContainer } from "react-toastify";

export const StyledToastContainer = styled(ToastContainer)`
  pointer-events: none;
  transform-origin: 0% 0%;

  overflow: hidden;

  pointer-events: auto;

  font-size: clamp(12px, 0.8vw, 24px);

  --toastify-toast-width: 29em;

  --toastify-font-family: ${({ theme }) => theme.fontFamily.MainFontFamily};
  --toastify-text-color-light: ${({ theme }) =>
    theme.notificationToaster.textColor};
  --toastify-color-progress-light: ${({ theme }) =>
    theme.notificationToaster.progressBarColor};
  --toastify-color-progress-error: ${({ theme }) =>
    theme.notificationToaster.progressBarColor};

  /**  https://fkhadra.github.io/react-toastify/how-to-style/
  # Override existing css classes
  Used to define container behavior: width, position: fixed etc... 
  **/

  &&&.Toastify__toast-container--top-center {
    top: 1em;
    padding: 0 0.5em;
  }

  .Toastify__toast {
    border-radius: 0.5em;
    margin-bottom: 0.5em;

    ${({ theme }) => css`
      border: 0.1em solid ${theme.notificationToaster.borderColor};

      background-color: ${theme.notificationToaster.backgroundColor};
      box-shadow: 0px 0px 0.5em ${theme.notificationToaster.boxShadowColor};
    `};
  }

  .Toastify__toast-icon {
    width: auto;
    margin-inline-end: 0.5em;
  }

  .Toastify__close-button {
    opacity: 1;

    ${({ theme }) => css`
      color: ${theme.notificationToaster.textColor};
    `};
  }

  .Toastify__progress-bar {
    z-index: unset;

    height: 0.3em;
  }
`;

export const Icon = styled.img`
  width: 3em;
`;
