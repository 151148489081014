import { useContext } from "react";
import { RingGameBuyInContext } from "../context";

export default function useRingGameBuyIn() {
  const ringGameBuyInCtx = useContext(RingGameBuyInContext);

  if (!ringGameBuyInCtx) {
    throw new Error(
      "useRingGameBuyIn must be inside a RingGameBuyInContext provider",
    );
  }

  return ringGameBuyInCtx;
}
