import { put, race, select, take } from "redux-saga/effects";
import {
  LOBBY_SC_JOIN_POKER_TOURNAMENT_ERR_BFX,
  LOBBY_SC_JOIN_POKER_TOURNAMENT_OK,
  joinTournament,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  JoinTournamentErrorBfx,
  JoinTournamentOk,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  joinTournamentError,
  tournamentJoined,
} from "../../actions/actionCreators";
import { getGameSelector } from "../../selectors";
import type Game from "../../types/Game";

export default function* joinToTournament(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const gameState: Game = yield select(getGameSelector);

  connection.send(
    joinTournament({
      tournamentId: gameState.gameInstanceId,
    }),
  );

  const [response, error]: [JoinTournamentOk, JoinTournamentErrorBfx] =
    yield race([
      take(connection.messages, LOBBY_SC_JOIN_POKER_TOURNAMENT_OK),
      take(connection.messages, LOBBY_SC_JOIN_POKER_TOURNAMENT_ERR_BFX),
    ]);

  if (response) {
    yield put(tournamentJoined(response));
  } else if (error) {
    yield put(joinTournamentError(error));
  }
}
