export default class WithdrawFundsEvent extends Event {
  static readonly WITHDRAW_FUNDS = "withdrawFunds";

  withdrawnRealAmount: number;
  withdrawnRestrictedAmount: number;

  constructor(realAmount: number, restrictedAmount: number) {
    super(WithdrawFundsEvent.WITHDRAW_FUNDS);

    this.withdrawnRealAmount = realAmount;
    this.withdrawnRestrictedAmount = restrictedAmount;
  }
}
