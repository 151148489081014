export const LOBBY_CS_JOIN_POKER_TOURNAMENT = "46911" as const;
export const LOBBY_SC_JOIN_POKER_TOURNAMENT_OK = "47114" as const;
export const LOBBY_SC_JOIN_POKER_TOURNAMENT_ERR_BFX = "47125" as const;
export const POKER_CS_JOIN = "53502" as const;
export const POKER_SC_JOIN_OK = "53602" as const;
export const POKER_SC_JOIN_TOUR_TABLE = "53701" as const;
export const POKER_CS_JOIN_POS = "53503" as const;
export const POKER_SC_JOIN_POS_OK = "53653" as const;
export const POKER_SC_JOIN_POS_FAILED = "53654" as const;
export const POKER_SC_UP_LEVEL = "53702" as const;
export const POKER_SC_LIST_PLAYERS = "53604" as const;
export const POKER_SC_BREAK_START = "53719" as const;
export const POKER_SC_BREAK_END = "53720" as const;
export const POKER_SC_START_GAME = "53605" as const;
export const POKER_SC_START_ROUND_1 = "53610" as const;
export const POKER_SC_START_ROUND_2 = "53611" as const;
export const POKER_SC_START_ROUND_3 = "53612" as const;
export const POKER_SC_START_ROUND_4 = "53613" as const;
export const POKER_SC_START_SHOW_CARD_ROUND = "53638" as const;
export const POKER_SC_NEW_GAME_RESULT = "53820" as const;
export const POKER_SC_CLEAR_TABLE = "53659" as const;
export const POKER_SC_SMALL_BLIND = "53656" as const;
export const POKER_CS_POST_SMALL_BLIND = "53511" as const;
export const POKER_CS_DECLINE_SMALL_BLIND = "53513" as const;
export const POKER_SC_SMALL_BLIND_POSTED = "53624" as const;
export const POKER_SC_BIG_BLIND = "53655" as const;
export const POKER_CS_POST_BIG_BLIND = "53510" as const;
export const POKER_CS_DECLINE_BIG_BLIND = "53512" as const;
export const POKER_SC_BIG_BLIND_POSTED = "53625" as const;
export const POKER_SC_SET_ANTE_POSTED = "53636" as const;
export const POKER_SC_SIT_IN_LIVE_PENALTY_POSTED = "53627" as const;
export const POKER_SC_CALL = "53618" as const;
export const POKER_SC_RAISE_VAR_POSTED = "53676" as const;
export const POKER_SC_ALL_INS = "53620" as const;
export const POKER_SC_DEAD_PENALTY_BET_POSTED = "53661" as const;
export const POKER_SC_TOUR_ANTE = "53724" as const;
export const POKER_SC_SHOW_CARDS = "53658" as const;
export const POKER_SC_DONT_SHOW_CARDS = "53667" as const;
export const POKER_SC_FOLD = "53615" as const;
export const POKER_SC_CHECK = "53616" as const;
export const POKER_SC_SET_TURN = "53609" as const;
export const POKER_CS_SET_TURN_OK = "53575" as const;
export const POKER_SC_SET_TURN_TB = "53766" as const;
export const POKER_CS_SET_TURN_TB_OK = "53578" as const;
export const POKER_SC_SIT_IN = "53657" as const;
export const POKER_CS_SIT_IN = "53515" as const;
export const POKER_SC_SIT_OUT = "53617" as const;
export const POKER_CS_SIT_OUT = "53508" as const;
export const POKER_CS_SIT_IN_TOUR = "53568" as const;
export const POKER_CS_SIT_OUT_TOUR = "53567" as const;
export const POKER_SC_PLAYER_SIT_IN_TOUR = "53729" as const;
export const POKER_SC_PLAYER_SIT_OUT_TOUR = "53728" as const;
export const POKER_CS_I_AM_ALIVE = "53540" as const;
export const POKER_SC_TABLE_HIST = "53622" as const;
export const POKER_SC_SET_CARDS = "53668" as const;
export const POKER_CS_CHECK = "53505" as const;
export const POKER_CS_CALL = "53506" as const;
export const POKER_CS_CALL_ALL_INS = "53522" as const;
export const POKER_CS_RAISE_VAR_LIM = "53542" as const;
export const POKER_CS_RAISE_ALL_INS = "53523" as const;
export const POKER_CS_FOLD = "53504" as const;
export const POKER_CS_SHOW_CARDS_BEFOR_GAME_RESULT = "53538" as const;
export const POKER_CS_DONT_SHOW_CARDS_BEFOR_GAME_RESULT = "53539" as const;
export const POKER_SC_TOUR_ENDED = "53730" as const;
export const POKER_SC_FINISHED_TOUR = "53703" as const;
export const POKER_SC_ADD_PLAYER = "53603" as const;
export const POKER_SC_REMOVE = "53608" as const;
export const LOBBY_SC_CHAT_EX = "47066" as const;
export const LOBBY_CS_CHAT_EX = "46869" as const;
export const POKER_SC_RETURN_TO_TOUR_LOBBY = "53706" as const;
export const POKER_SC_ALL_IN_EQUITY = "53828" as const;
export const POKER_CS_SHOW_CARDS = "53516" as const;
export const POKER_SC_RABBIT_HUNT = "53810" as const;
export const POKER_CS_THROW = "53581" as const;
export const POKER_SC_THROW = "53781" as const;
export const LOBBY_CS_JOIN_POKER_RING_GAME = "46910" as const;
export const LOBBY_SC_JOIN_POKER_RING_GAME_OK = "47112" as const;
export const LOBBY_SC_JOIN_POKER_RING_GAME_ERR_BFX = "47118" as const;
export const POKER_CS_CHANGE_RSRV_STATUS_EX = "53590" as const;
export const POKER_SC_CHANGE_RSRV_STATUS_EX_OK = "53823" as const;
export const POKER_SC_CHANGE_RSRV_STATUS_EX_ERR = "53824" as const;
export const POKER_SC_CHANGE_RSRV_STATUS = "53775" as const;
export const POKER_CS_GET_QUICK_POS_EX = "53591" as const;
export const POKER_SC_GET_QUICK_POS_EX_OK = "53825" as const;
export const POKER_SC_GET_QUICK_POS_EX_ERR = "53826" as const;
export const POKER_SC_RETURN_TO_VIEWER = "53631" as const;
export const POKER_SC_SIT_IN_LIVE_PENALTY = "53660" as const;
export const POKER_SC_SIT_IN_LIVE_AND_DEAD_PENALTY = "53662" as const;
export const POKER_CS_POST_SIT_IN_PENALTY = "53528" as const;
export const POKER_CS_WAIT_FOR_ENTER_POSITION = "53514" as const;
export const POKER_CS_CANCEL_WAIT_FOR_ENTER_POSITION = "53521" as const;
export const POKER_SC_WAIT_FOR_ENTER_POSITION_STATE = "53903" as const;
export const POKER_SC_RING_SNAP_STATE = "53783" as const;
export const POKER_CS_BUY_IN = "53571" as const;
export const POKER_SC_BUY_IN_ERR_BFX = "53760" as const;
export const POKER_SC_SET_CREDIT_PENDING = "53606" as const;
export const POKER_SC_SET_CREDIT = "53607" as const;
export const POKER_SC_SUCCESSFULL_RE_BUY = "53815" as const;
export const POKER_SC_PLAYER_AM_SESSION_DETAILS = "53816" as const;
export const POKER_SC_ADD_KICK_CANDIDATE = "53821" as const;
export const POKER_SC_REMOVE_KICK_CANDIDATE = "53822" as const;
export const POKER_SC_CLOSE_TABLE = "53794" as const;
export const GH_CS_GET_POKER_SESSIONS_DETAILS = "25576" as const;
export const GH_CS_SET_POKER_SESSIONS_DETAILS = "25676" as const;
export const GH_CS_GET_POKER_TOURNAMENTS_DETAILS = "9000230" as const;
export const GH_CS_SET_POKER_TOURNAMENTS_DETAILS = "9000330" as const;
export const GH_CS_GET_TOUR_GAME_STR = "25570" as const;
export const GH_SC_SET_SCSHL_GAME_STR = "25670" as const;
export const GH_CS_GET_THP_GAME_STR = "25560" as const;
export const GH_SC_SET_THP_GAME_STR = "25660" as const;
export const POKER_SC_CHOOSE_TOUR_DEALER = "53731" as const;
export const POKER_SC_TOUR_BREAK_ACTIVE = "53901" as const;
export const POKER_SC_TOUR_BREAK_INACTIVE = "53902" as const;
export const POKER_SC_FINAL_TABLE = "53776" as const;
export const POKER_SC_FINAL_PLAYERS = "53777" as const;
export const POKER_SC_PLAYER_REGULATION_DATA = "53850" as const;

export const POKER_SC_FTD_ALLOWED = "53796" as const;
export const POKER_CS_PLAYER_FTD_SELECTION = "53584" as const;
export const POKER_SC_PLAYER_FTD_SELECTION_OK = "53797" as const;
export const POKER_SC_PLAYER_FTD_SELECTION_ERR = "53798" as const;
export const POKER_SC_FTD_SELECTION_STATUS = "53799" as const;

export const POKER_SC_FTD_NEGOTIATION_STATUS = "53800" as const;
export const POKER_CS_FT_DEAL_DECISION = "53585" as const;
export const POKER_SC_FT_DEAL_STATUS = "53802" as const;

export const POKER_SC_FTD_RECONNECTION_DETAILS = "53803" as const;

export const POKER_SC_UPDATE_SNAP_POOL_TABLE_ID = "53908" as const;

export const POKER_SC_H4H_ACTIVATE = "53770" as const;
export const POKER_SC_H4H_IN_PROCESS = "53772" as const;
export const POKER_SC_H4H_DEACTIVATE = "53771" as const;

export const POKER_CS_SUBSCRIBE_UNSUBSCRIBE_NOTIFICATION = "53589" as const;
export const POKER_SC_PUSH_NOTIFICATION = "53817" as const;
