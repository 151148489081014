import { AreaType, IMessageBroker, TriggerType } from "@sparkware/uc-sdk-core";
import { v4 as uuid } from "uuid";
import { OpenPokerTournament } from "../../../actions";
import { APP_ID, MB_HEADERS } from "./externalCommunication";

export function publishPokerTournament(
  messageBroker: IMessageBroker,
  { payload: { tourId } }: OpenPokerTournament,
) {
  messageBroker.navigation.topics.performAction.publish(MB_HEADERS, {
    actionID: "openPokerTournament",
    launchInfo: {
      channel: {
        area: AreaType.button,
        source: APP_ID,
      },
      businessCorrelationID: uuid(),
      trigger: TriggerType.userSelection,
      appSpecificParameters: tourId ? { tournamentId: tourId } : {},
      sourceAppID: APP_ID,
      sourceAppVersion: process.env.REACT_APP_VERSION || "",
    },
  });
}
