import delimiter from "../delimiter";

export default function parseUncalledBetSection(source: string) {
  if (!source) {
    return null;
  }
  const [seatId, amount, credit, effectiveStack, uncalledAmount] = source.split(
    delimiter.section,
  );

  if (seatId !== "0") {
    return {
      seatId: Number.parseInt(seatId),
      amount: Number.parseInt(amount),
      credit: Number.parseInt(credit),
      effectiveStack: Number.parseInt(effectiveStack),
      uncalledAmount: Number.parseInt(uncalledAmount),
    } as const;
  }
  return null;
}
