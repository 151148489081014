import TurnProgressBar from "../../TurnProgressBar";
import { TableIndicator } from "../TableIndicator";
import { DealIcon, FtdIndicatorWrapper } from "./styles";
import { FtdIndicatorProps } from "./types";

export default function FtdIndicator({
  active,
  notified,
  onClick,
  disabled,
  ...progressProps
}: FtdIndicatorProps) {
  return (
    <FtdIndicatorWrapper
      $active={active}
      $notified={notified}
      disabled={disabled}
      onClick={onClick}
    >
      <TableIndicator active={active}>
        <DealIcon />
      </TableIndicator>
      <TurnProgressBar {...progressProps} />
    </FtdIndicatorWrapper>
  );
}
