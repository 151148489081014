import { GameContext } from "../store/app/sagas/shared/gamesManager/gameFactory";
import { ActiveSessions } from "../store/app/types/Sessions";
import isGameSession from "./isGameSession";

export const getActiveGameSessions = (sessions: ActiveSessions) => {
  const activeGameSession: GameContext[] = [];

  for (const id in sessions) {
    const session = sessions[id];

    if (isGameSession(session)) activeGameSession.push(session);
  }

  return activeGameSession;
};
