import { blindData } from "./blindData";
import { blindLevel } from "./blindLevel";
import { game } from "./game";
import { gameBreak } from "./gameBreak";
import { hero } from "./hero";
import { history } from "./history";
import { players } from "./players";
import { pots } from "./pots";
import { preActions } from "./preActions";
import { reservation } from "./reservation";
import { seats } from "./seats";
import { statistics } from "./statistics";
import { table } from "./table";
import { tournament } from "./tournament";
import { turn } from "./turn";

export const blastReducers = {
  game,
  table,
  tournament,
  blindLevel,
  players,
  seats,
  gameBreak,
  pots,
  hero,
  turn,
  preActions,
  history,
  statistics,
};

export const ringGameReducers = {
  game,
  table,
  blindLevel: blindData,
  players,
  seats,
  reservation,
  pots,
  hero,
  turn,
  preActions,
  history,
  statistics,
};
