import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import HamburgerMenu, { MenuItem } from "../../components/HamburgerMenu";
import {
  CashierIcon,
  LeaveGameIcon,
  SettingsIcon,
} from "../../components/HamburgerMenu/Icons";
import { PersonalAvatarOwnerContext } from "../../context";
import useAvatarSelection from "../../hooks/useAvatarSelection";
import useModalState from "../../hooks/useModalState";
import useNavBar from "../../hooks/useNavBar";
import useSettings from "../../hooks/useSettings";
import { HamburgerMenuKeys } from "../../i18n/keys";
import { openCashier } from "../../store/app/actions";
import Styleable from "../../utils/types/Styleable";
import getSettingsMenu from "./getSettingsMenu";
import { LobbyHamburgerMenuContainerProps } from "./types";

export function LobbyHamburgerMenuContainer({
  menuPanelRef,
  onBackToLobby,
  className,
}: Styleable<LobbyHamburgerMenuContainerProps>) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSettings();
  const isPersonalAvatarOwner = useContext(PersonalAvatarOwnerContext);
  const hamburgerButtonRef = useNavBar()?.hamburgerButtonRef;
  const { setShowAvatarSelectionPopup } = useAvatarSelection();
  const { opened, setOpened } = useModalState(false, true);

  return (
    <HamburgerMenu
      buttonPlaceholder={hamburgerButtonRef}
      placeholder={menuPanelRef}
      opened={opened}
      onClick={() => setOpened(!opened)}
      className={className}
    >
      <MenuItem
        testId="poker-hamburger-button-settings"
        subMenu={getSettingsMenu(
          settings,
          t,
          isPersonalAvatarOwner
            ? undefined
            : () => setShowAvatarSelectionPopup(true),
        )}
        startIcon={<SettingsIcon />}
      >
        {t(HamburgerMenuKeys.SETTINGS)}
      </MenuItem>
      <MenuItem
        testId="poker-hamburger-button-open-cashier"
        onClick={() => dispatch(openCashier())}
        startIcon={<CashierIcon />}
      >
        {t(HamburgerMenuKeys.CASHIER)}
      </MenuItem>
      {onBackToLobby && (
        <MenuItem
          testId="poker-hamburger-button-back-to-lobby"
          onClick={onBackToLobby}
          startIcon={<LeaveGameIcon />}
        >
          {t(HamburgerMenuKeys.BACK_TO_LOBBY)}
        </MenuItem>
      )}
    </HamburgerMenu>
  );
}
