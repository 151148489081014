import { PropsWithChildren, createContext, useState } from "react";
import { ThrowItemId } from "../containers/Battlefield/types";

export const ThrowItemContext = createContext<
  ReturnType<typeof useState<ThrowItemId>> | undefined
>(undefined);

export default function ThrowItemProvider({ children }: PropsWithChildren) {
  const throwItemState = useState<ThrowItemId>();

  return (
    <ThrowItemContext.Provider value={throwItemState}>
      {children}
    </ThrowItemContext.Provider>
  );
}
