import { RingGameInfo, ServerDataType } from "./types";

export default function getSortedRingGames(data: ServerDataType) {
  const ringGames: RingGameInfo[] = Object.entries(data)
    .map((item) => ({
      ...item[1],
      CreatorId: Number(item[0]),
    }))
    .sort((a, b) => a.UpperLimit - b.UpperLimit);

  return ringGames;
}
