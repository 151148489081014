import { put, select, take } from "redux-saga/effects";
import {
  GH_SC_SET_SCSHL_GAME_STR,
  getTournamentHandHistory,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  SetTourHandHistory,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { HandHistorySelected, handHistoryReceived } from "../../actions";
import { getServerIdSelector } from "../../selectors";

export default function* requestTourHandHistory(
  connection: MessagesCommunication<GameServerMessage>,
  { payload: { id } }: HandHistorySelected,
) {
  const serverId: string = yield select(getServerIdSelector);

  connection.send(getTournamentHandHistory(id, serverId));

  const result: SetTourHandHistory = yield take(
    connection.messages,
    GH_SC_SET_SCSHL_GAME_STR,
  );
  yield put(handHistoryReceived({ id, messages: result.messages }));
}
