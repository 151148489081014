import { INFO_VERSION } from "../../../services";
import User from "../../../store/app/types/User";
import delimiter from "../delimiter";
import { LogoutReason, OutgoingMessage } from "../types";
import {
  CLIENT_INFO_SRV_GET_IDENTICAL_RING_GAME,
  CLIENT_INFO_SRV_GET_QUICK_TABLE_FROM_CREATOR_ID,
  CLIENT_INFO_SRV_POKER_REG_TOUR,
  CLIENT_INFO_SRV_POKER_UNREG_TOUR,
  CLIENT_INFO_SRV_REG_BLAST_POOL,
  CLIENT_INFO_SRV_START_TOUR_OK,
  CLIENT_MAIN_AUTHENTICATE_APP,
  CLIENT_MAIN_GET_ACCOUNT_ATTRIBUTES,
  CLIENT_MAIN_GET_RELEVANT_BONUSES,
  CLIENT_MAIN_JOIN_APP,
  CLIENT_MAIN_RECONNECT_APP,
  CLIENT_PROXY_CONNECT_SERVER,
  CLIENT_PROXY_GENERAL_GAME_MSG,
  CLIENT_PROXY_GENERAL_HISTORY_MSG,
  CLIENT_PROXY_GET_USR_GAME_SESSIONS,
  CLIENT_PROXY_LEAVE_PROXY,
  CLIENT_PROXY_LEAVE_SERVER,
  CLIENT_PROXY_READY_TO_RECEIVE_MTC_MESSAGE,
  CLIENT_PROXY_RECEIVE_MESSAGE_TO_CLIENT,
  PING,
  PONG,
} from "./messageTypes";
import { SessionType } from "./types";
import BlastPoolRegistrationData from "./types/BlastPoolRegistrationData";
import JoinAppData from "./types/JoinAppData";
import { MttRegistrationData } from "./types/MttRegistrationData";

export function ping() {
  return {
    type: PING,
  };
}

export function pong() {
  return {
    type: PONG,
  };
}

export function joinApp({
  clientVersion,
  languageId,
  brandId,
  subBrandTo,
  productPackageTo,
  subBrandFrom,
  productPackageFrom,
  softwareTypeId = 6 /* HTML5 */,
  clientTypeId = 7 /* HTML5 */,
}: JoinAppData) {
  return {
    type: CLIENT_MAIN_JOIN_APP,
    body: JSON.stringify({
      ClientVersion: clientVersion,
      LanguageId: languageId,
      SoftwareType: softwareTypeId,
      ClientType: clientTypeId,
      BrandId: brandId,
      SubBrandTo: subBrandTo,
      ProductPackageTo: productPackageTo,
      SubBrandFrom: subBrandFrom,
      ProductPackageFrom: productPackageFrom,
    }),
  };
}

export function leaveProxy(reason = LogoutReason.ClientLeave) {
  return {
    type: CLIENT_PROXY_LEAVE_PROXY,
    body: `${reason}${delimiter.field}`,
  };
}

export function getRelevantBonuses(
  tournamentTicketTypeId = -1,
  bonusTypeId = 7,
  ticket?: number,
) {
  const params = [bonusTypeId, tournamentTicketTypeId, ticket];

  return {
    type: CLIENT_MAIN_GET_RELEVANT_BONUSES,
    body: `${params.join(delimiter.field)}${delimiter.field}`,
  };
}

export function registerToBlastPool({
  blastPoolId,
  buyInValue,
  cid,
  userName,
  avatarId,
  countryId,
  useTicket,
}: BlastPoolRegistrationData) {
  //similar values to old web client
  const windowId: number = blastPoolId;
  const reservedString = "";
  const regCount = 1; //requested number of registrations: 1 because we have no multitable implementation
  const tabUsage = 1; //Not found

  const useTrT = useTicket ? 1 : 0;

  const params = [
    windowId,
    reservedString,
    blastPoolId,
    buyInValue,
    regCount,
    cid,
    userName,
    INFO_VERSION,
    avatarId,
    countryId,
    tabUsage,
    useTrT,
  ];

  return {
    type: CLIENT_INFO_SRV_REG_BLAST_POOL,
    body: `${params.join(delimiter.section)}`,
  };
}

export function registerToMTT({
  tourId,
  cid,
  userName,
  avatarId,
  countryId,
  useTicket,
  ticketId,
}: MttRegistrationData) {
  //similar values to old web client
  const windowId = tourId;
  const reservedString = "";
  const password = "";
  const supportRematch = 1;
  const tabUsage = 1;
  const teamId = 0;
  const useTrT = useTicket ? 1 : 0;

  const params = [
    windowId,
    reservedString,
    tourId,
    cid,
    userName,
    INFO_VERSION,
    avatarId,
    countryId,
    password,
    supportRematch,
    tabUsage,
    teamId,
    useTrT,
    ticketId || 0,
  ];

  return {
    type: CLIENT_INFO_SRV_POKER_REG_TOUR,
    body: `${params.join(delimiter.section)}`,
  };
}

export function unregisterFromTournament(tourId: number, cid: number) {
  const windowId: number = tourId;
  const reservedString = "";

  const params = [windowId, reservedString, tourId, cid];

  return {
    type: CLIENT_INFO_SRV_POKER_UNREG_TOUR,
    body: `${params.join(delimiter.section)}`,
  };
}

export function getAccountAttributes() {
  return {
    type: CLIENT_MAIN_GET_ACCOUNT_ATTRIBUTES,
  };
}

export function startTornamentAcknowledge(
  tornamentId: string,
  serverId: string,
  tabId: string,
) {
  const params = [serverId, tabId, tornamentId];
  return {
    type: CLIENT_INFO_SRV_START_TOUR_OK,
    body: params.join(delimiter.section),
  };
}

export function connectGameServer(
  gameId: number,
  serverId: string,
  gameType: SessionType,
) {
  return {
    type: CLIENT_PROXY_CONNECT_SERVER,
    body: `${gameId}${delimiter.field}${serverId}${delimiter.field}${gameType}${delimiter.field}`,
  };
}

export function generalGameMessage(
  gameId: number,
  gameMessage: OutgoingMessage,
) {
  return {
    type: CLIENT_PROXY_GENERAL_GAME_MSG,
    body: `${gameId}${delimiter.section}${delimiter.section}${
      gameMessage.type
    }${delimiter.section}${
      gameMessage.body ? gameMessage.body + delimiter.section : ""
    }`,
  };
}

export function generalHistoryMessage(
  gameId: number,
  historyMessage: OutgoingMessage,
) {
  return {
    type: CLIENT_PROXY_GENERAL_HISTORY_MSG,
    body: `${gameId}${delimiter.section}${delimiter.section}${
      historyMessage.type
    }${delimiter.section}${
      historyMessage.body ? historyMessage.body + delimiter.section : ""
    }`,
  };
}

export function leaveGameServer(gameId: number, serverId: string) {
  return {
    type: CLIENT_PROXY_LEAVE_SERVER,
    body: `${gameId}${delimiter.field}${serverId}${delimiter.field}`,
  };
}

export function authenticate(token: string) {
  return {
    type: CLIENT_MAIN_AUTHENTICATE_APP,
    body: JSON.stringify({
      Token: token,
    }),
  };
}

export function reconnectApp({ appSessionId, cid, sessionId, serverId }: User) {
  return {
    type: CLIENT_MAIN_RECONNECT_APP,
    body: JSON.stringify({
      AppSessionId: appSessionId,
      Cid: cid,
      PlayerSessionId: sessionId,
      ServerId: serverId || 0,
    }),
  };
}

export function getIdenticalRingTable({
  cid,
  reservedId,
  tableId,
}: {
  cid: number;
  reservedId: string;
  tableId: string;
}) {
  return {
    type: CLIENT_INFO_SRV_GET_IDENTICAL_RING_GAME,
    body: [0, reservedId, cid, tableId, 0].join(delimiter.section),
  };
}

export function getQuickTableFromCreatorId(cid: number, creatorId: number) {
  return {
    type: CLIENT_INFO_SRV_GET_QUICK_TABLE_FROM_CREATOR_ID,
    body: `${cid}${delimiter.section}${creatorId}${delimiter.section}`,
  };
}

export function getOpenSessions() {
  return {
    type: CLIENT_PROXY_GET_USR_GAME_SESSIONS,
  };
}

export function readyForMTC() {
  return {
    type: CLIENT_PROXY_READY_TO_RECEIVE_MTC_MESSAGE,
  };
}

export function mtcReceived(id: string, reason = 0 /* OK */) {
  return {
    type: CLIENT_PROXY_RECEIVE_MESSAGE_TO_CLIENT,
    body: `${id}${delimiter.field}${reason}${delimiter.field}`,
  };
}
