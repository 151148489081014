import styled from "@emotion/styled/macro";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";

export const MenuWrapper = styled.button`
  font-size: inherit;
  padding: 1em;
  display: flex;

  color: #a3a3a3;

  outline: none;
  background: none;
  border: none;

  cursor: pointer;

  width: 3.5em;

  &:hover {
    color: #d1d1d1;
  }

  &:active {
    color: #767676;
  }
`;

export const Menu = styled(MenuIcon)`
  width: 100%;
`;
