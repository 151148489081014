import { useContext } from "react";
import { SettingsContext } from "../context";

export default function useSettings() {
  const settingsStore = useContext(SettingsContext);

  if (!settingsStore) {
    throw new Error("useSettings must be inside a SettingContext with a value");
  }

  return settingsStore;
}
