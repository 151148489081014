import { Saga, Task } from "redux-saga";
import { cancel, fork, put, take } from "redux-saga/effects";
import Communication from "../../../../network/Communication";
import { TIMEOUT_EXEPTION } from "../../../../network/CommunicationError";
import MainServer from "../../../../network/MainServer";
import { MainServerEventMap } from "../../../../network/events";
import CommunicationLifecycleEvent from "../../../../network/events/CommunicationLifecycleEvent";
import { MainServerMessage } from "../../../../network/protocol/mainServer/types";
import { connect, disconnected } from "../../actions";
import connectToMainServer from "./connectToMainServer";
import createEventChannel from "./createEventChannel";
import MessagesCommunication, {
  createMessagesChannel,
} from "./messagesCommunication";

export default function* connectionLoop(
  server: MainServer,
  applicationFlow: Saga<MessagesCommunication<MainServerMessage>[]>,
  closeAppFlow: Saga<MainServer[]>,
) {
  const communication: Communication<MainServerEventMap> = server;
  const disconnectionChannel = createEventChannel(
    communication,
    CommunicationLifecycleEvent.DISCONNECTED,
  );
  const connection: MessagesCommunication<MainServerMessage> = {
    send: server.send,
    messages: createMessagesChannel(server),
  };

  yield fork(closeAppFlow, server);

  while (true) {
    yield fork(connectToMainServer, server);

    const app: Task = yield fork(applicationFlow, connection);

    const event: CommunicationLifecycleEvent = yield take(disconnectionChannel);

    const isTimeoutException: boolean =
      event.error?.source === TIMEOUT_EXEPTION;
    const error = isTimeoutException ? null : event.error;

    yield cancel(app);
    yield put(disconnected(error));

    if (error) {
      yield take(connect.type);
    }
  }
}
