import { put, take } from "redux-saga/effects";
import {
  POKER_SC_SET_TURN_TB,
  setTurnTimebankSuccess,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  SetTurnTimebank,
} from "../../../../network/protocol/gameServer/types";
import { getMaskActions } from "../../../../utils/getMaskActions";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { setTurnTimebank } from "../../actions/actionCreators";
import { RoundTurn } from "../../types/Turn";

export default function* setTurnTimebankSaga(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const message: SetTurnTimebank = yield take(
      connection.messages,
      POKER_SC_SET_TURN_TB,
    );

    connection.send(setTurnTimebankSuccess());

    const { type, mask, ...turnData } = message;
    const turn: RoundTurn = {
      actions: getMaskActions(message.mask),
      timeBankActive: true,
      ...turnData,
    };

    yield put(setTurnTimebank(turn));
  }
}
