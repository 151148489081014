import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 6px 6px;
  box-sizing: border-box;

  color: black;

  transform-origin: left bottom;

  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`;
