enum CommittedAction {
  ANTE = "ANTE",
  SMALL_BLIND = "SMALL_BLIND",
  BIG_BLIND = "BIG_BLIND",
  FOLD = "FOLD",
  CHECK = "CHECK",
  CALL = "CALL",
  RAISE = "RAISE",
  BET = "BET",
  ALL_IN = "ALL_IN",
  CAP = "CAP",
  PENALTY = "PENALTY",
}

export default CommittedAction;
