import { apply, put, select } from "redux-saga/effects";
import MainServer from "../../../../network/MainServer";
import { connected, connectionError } from "../../actions";
import { getConfigurationSelector } from "../../selectors";
import { FullConfiguration } from "../../types/Configuration";

export default function* connectToMainServer(mainServer: MainServer) {
  const {
    brand: { mainServerUrl },
  }: FullConfiguration = yield select(getConfigurationSelector);

  try {
    yield apply(mainServer, mainServer.connect, [mainServerUrl]);
    yield put(connected());
  } catch (error) {
    yield put(connectionError(error as unknown as Error));
  }
}
