import InferValueTypes from "../../../../utils/types/InferValueTypes";
import * as messageParsers from "../messageParsers";
import * as messageTypes from "../messageTypes";

export enum BlastRegistrationErrorCode {
  BLAST_REG_FAILED_GENERAL_ERROR = 1,
  BLAST_REG_FAILED_SINGLE_REGISTRATION_LIMIT = 2,
  BLAST_REG_FAILED_TOTAL_REGISTRATION_LIMIT = 3,
  BLAST_REG_FAILED_REGISTRATION_SUSPENDED = 4,
  BLAST_REG_FAILED_PLAYER_PENDING_ACTION = 5,
  BLAST_REG_FAILED_FORMATTED_ERROR = 7,
  BLAST_REG_FAILED_BY_NIGHT_LIMIT = 8,
}

export enum TourRegistrationErrorCode {
  REG_PLAYER_FAILED_TOUR_NOT_EXISTS = 1,
  REG_PLAYER_FAILED_DUE_TO_TOUR_STATUS = 2,
  REG_PLAYER_FAILED_REGISTRATION_FULL = 3,
  REG_PLAYER_FAILED_CLIENT_NOT_EXISTS = 4,
  REG_PLAYER_FAILED_LOW_BANKROLL = 5,
  REG_PLAYER_FAILED_ALREADY_REGISTERED = 6,
  REG_PLAYER_FAILED_OTHER_TOUR_BLOCK = 7,
  REG_PLAYER_FAILED_RESTRICTED_TOUR = 8,
  REG_PLAYER_FAILED_SOME_OTHER_ERROR = 9,
  REG_PLAYER_FAILED_VERSION_BLOCK = 10,
  REG_PLAYER_FAILED_REGISTRATION_INPROCESS = 11,
  REG_PLAYER_FAILED_UNREG_INPROCESS = 12,
  REG_PLAYER_FAILED_REGISTRATION_LIMIT = 13,
  LATE_REG_PLAYER_FAILED_ALREADY_PLAYED_TOUR = 15,
  REG_PLAYER_FAILED_INVALID_TEAM_ID = 16,
  REG_PLAYER_FAILED_TEAM_IS_FULL = 17,
  REG_PLAYER_FAILED_ALREADY_IN_TEAM = 18,
  REG_PLAYER_FAILED_BRAND_NOT_EXISTS = 19,
  REG_PLAYER_FAILED_TEAM_TOUR = 20,
  MANUAL_REG_PLAYER_FAILED_INVALID_PARAMS = 21,
  MANUAL_REG_PLAYER_FAILED_INCORRECT_BUYIN_PARAMS = 22,
  MANUAL_REG_PLAYER_FAILED_AM_CONNECTION_ISNOT_AVAILABLE = 23,
  REG_PLAYER_FAILED_ADMIN_IS_NOT_PERMITTED = 24,
  REG_PLAYER_FAILED_TICKET_GENERAL_ERROR = 25,
  REG_PLAYER_FAILED_TICKET_NO_RECORDS_ERROR = 26,
  REG_PLAYER_FAILED_FREEROLL_BY_TEMP_ACCOUNT = 27,
  REG_PLAYER_FAILED_PENDING_ACTION = 28,
  REG_PLAYER_FAILED_FORMATTED_ERROR = 29,
  REG_PLAYER_FAILED_TOUR_RESTRICTED_BY_PASSWORD = 30,
  REG_PLAYER_FAILED_DUE_TO_SEATING_TIME = 31,
  REG_PLAYER_FAILED_FREEROLL_BY_ELIGIBILITY = 32,
  REG_PLAYER_FAILED_BY_NIGHT_LIMIT = 33,
  REG_PLAYER_FAILED_FORMATTED = 41,
  REG_PLAYER_FAILED_SNGS_BLOCKED_BY_IM = 42,
}

export enum RingGameAttribute {
  SUPPORT_SHORT_HANDED = 1,
  ENABLE_OBSERVER_CHAT = 1 << 1,
  TEAM_TABLE = 1 << 2,
  VIDEO_TABLE = 1 << 3,
  BRAND_RESTRICTED_TABLE = 1 << 4,
  PRIVATE_GAME_TABLE = 1 << 5,
  SHOW_ME = 1 << 6,
  RANDOM = 1 << 7,
  SNAP = 1 << 8,
  TEST_GAME = 1 << 9,
  CAPPED_TABLE = 1 << 10,
  PUSH_OR_FOLD = 1 << 12,
  AUTOMATION = 1 << 13,
  JACKPOT = 1 << 14,
  SHOW_IN_BEGINNERS = 1 << 15,
}

export enum TourAttribute {
  UP_LEVEL_BY_GAMES = 1,
  RE_BUY_IN_BY_GAMES = 1 << 1,
  ADD_ON_BY_GAMES = 1 << 2,
  PARTIAL_RE_BUY_IN = 1 << 3,
  PARTIAL_ADD_ON = 1 << 4,
  PRIZES_BY_RATIO = 1 << 5,
  ENABLE_RE_BUY_IN = 1 << 6,
  ENABLE_ADD_ON = 1 << 7,
  SATELITE_TYPE = 1 << 8,
  ENABLE_STOP_TOURNAMENT = 1 << 9,
  RESTRICTED_BY_PASSWORD = 1 << 10,
  REMATCH_ENABLED = 1 << 11,
  NO_PRIZE_POOL_DISTRIBUTION = 1 << 12,
  ALLOW_LATE_REGISTRATION = 1 << 13,
  CHANGE_LAST_TABLE_BACKGROUND = 1 << 14,
  CHANGE_LAST_2_PLAYERS_DISPLAY = 1 << 15,
  ENABLE_OBSERVERS_CHAT = 1 << 16,
  SYNC_BREAK = 1 << 17,
  BRANDED_TOURNAMENT = 1 << 18,
  VIDEO_TOUR = 1 << 19,
  PRIVATE_TOUR = 1 << 20,
  DEEP_STACK = 1 << 21,
  CASH_BUYIN = 1 << 22,
  TICKET_BUYIN = 1 << 23,
  SHOW_ME_TOUR = 1 << 24,
  REGULAR_SPEED = 1 << 25,
  TURBO_SPEED = 1 << 26,
  SUPER_TURBO_SPEED = 1 << 27,
  SNAP = 1 << 28,
  RESTRICT_SATELLITE_REGISTRATION = 1 << 29,
  TEST_GAME = 1 << 30,
}

export enum TourSubType {
  TEAM = 1,
  KNOCK_OUT = 1 << 1,
  HEAD_HUNTER = 1 << 2,
  BOUNTY = 1 << 3,
  FINAL_TABLE_DEAL = 1 << 4,
  BLAST = 1 << 5,
  REMATCH_CLONE = 1 << 6,
  IMMEDIATE_BONUS = 1 << 7,
  PROGRESSIVE_KNOCK_OUT = 1 << 8,
  SECOND_CHANCE = 1 << 9,
  AUTOMATION = 1 << 10,
  MULTI_DAY = 1 << 11,
  MULTI_FLIGHT_DAY1 = 1 << 12,
  MULTI_FLIGHT_DAY2 = 1 << 13,
  BROADCAST = 1 << 14,
  MYSTERY_BOUNTY = 1 << 15,
  TEMPLATE = 1 << 16,
  RE_ENTRY = 1 << 17,
}

export enum SessionType {
  RING_GAME = 101,
  TOURNAMENT = 102,
}

export enum SessionStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  CLOSED = 3,
}

export enum MTCType {
  APPROVE_DEPOSIT = 1,
  SIMPLE_POP_UP,
  CAPTION_POP_UP,
  CAPTION_RUNNING_TEXT_START,
  CAPTION_RUNNING_TEXT_STOP,
  FORMATTED_MESSAGE,
  GM_COMMAND,
  HYPERLINK_TEXT,
}

export type MainServerMessage = ReturnType<
  InferValueTypes<typeof messageParsers>
>;
export type MainServerMessageType = InferValueTypes<typeof messageTypes>;
export type JoinAppSuccess = ReturnType<typeof messageParsers.joinAppSuccess>;
export type JoinAppFault = ReturnType<typeof messageParsers.joinAppFault>;
export type AuthenticationSuccess = ReturnType<
  typeof messageParsers.authenticationSuccess
>;
export type AuthenticationError = ReturnType<
  typeof messageParsers.authenticationFault
>;
export type GetRelevantBonusesResponse = ReturnType<
  typeof messageParsers.getRelevantBonusesResponse
>;
export type RegisterBlastError = ReturnType<
  typeof messageParsers.registerBlastError
>;
export type RegisterTourOk = ReturnType<typeof messageParsers.registerTourOk>;
export type RegisterTourError = ReturnType<
  typeof messageParsers.registerTourError
>;
export type UnregisterTourOk = ReturnType<
  typeof messageParsers.unregisterTourOk
>;
export type UnregisterTourError = ReturnType<
  typeof messageParsers.unregisterTourError
>;
export type GetAccountAttributesOk = ReturnType<
  typeof messageParsers.getAccountAttributesOk
>;
export type GetAccountAttributesError = ReturnType<
  typeof messageParsers.getAccountAttributesError
>;
export type StartTournamentData = ReturnType<
  typeof messageParsers.startTournament
>;
export type ConnectGameServerOk = ReturnType<
  typeof messageParsers.gameServerConnectSuccess
>;
export type ConnectGameServerError = ReturnType<
  typeof messageParsers.gameServerConnectFault
>;
export type GeneralGameMessage = ReturnType<
  typeof messageParsers.generalGameMessage
>;
export type GeneralHistoryMessage = ReturnType<
  typeof messageParsers.generalHistoryMessage
>;
export type QuickTable = ReturnType<
  typeof messageParsers.getQuickTableFromCreatorIdOk
>;
export type QuickTableError = ReturnType<
  typeof messageParsers.getQuickTableFromCreatorIdErr
>;
export type IdenticalRingTableOk = ReturnType<
  typeof messageParsers.getIdenticalRingTableOk
>;
export type IdenticalRingTableError = ReturnType<
  typeof messageParsers.getIdenticalRingTableErr
>;
export type KickToLobbyReason = ReturnType<
  typeof messageParsers.getKickToLobyReason
>;

export type SetOpenSessions = ReturnType<typeof messageParsers.setOpenSessions>;
export type SetOpenSessionsError = ReturnType<
  typeof messageParsers.setOpenSessionsError
>;

export type TourEndOrCancellation = ReturnType<
  typeof messageParsers.tourIsEndOrCancelled
>;

export type PlayerFinishedTour = ReturnType<
  typeof messageParsers.playerFinishedTour
>;

export type MessageToClient = ReturnType<typeof messageParsers.messageToClient>;
