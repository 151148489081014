import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import type { SuitType } from "../../utils/cardsSpecs";
import { CardPaperProps } from "./types";

export const CARD_VIEWBOX = {
  width: 35,
  height: 44,
};

export function getFillColor(suit: SuitType, theme: Theme) {
  switch (suit) {
    case "club":
      return theme.deck.club;
    case "diamond":
      return theme.deck.diamond;
    case "heart":
      return theme.deck.heart;
    case "spade":
      return theme.deck.spade;
  }
}

export const CardPaper = styled.svg<CardPaperProps>`
  min-height: 23px;
  color: ${({ theme }) => theme.card.front.backgroundColor};

  ${({ theme, $suit }) => {
    return css`
      fill: ${getFillColor($suit, theme)};
    `;
  }};
`;
