import { fork, takeEvery } from "redux-saga/effects";
import { MainServerMessage } from "../../../../network/protocol/mainServer/types";
import { authenticate, connected, reconnect } from "../../actions";
import appReconnection from "./appReconnection";
import applicationFlow from "./applicationFlow";
import authenticatePlayer from "./authenticatePlayer";
import joinAppToMainServer from "./joinAppToMainServer";
import MessagesCommunication from "./messagesCommunication";

export default function* authenticationFlow(
  connection: MessagesCommunication<MainServerMessage>,
) {
  yield takeEvery(connected.type, joinAppToMainServer, connection);
  yield takeEvery(authenticate.type, authenticatePlayer, connection);
  yield takeEvery(reconnect.type, appReconnection, connection);

  yield fork(applicationFlow, connection);
}
