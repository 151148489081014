import { performThrowItem } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { ThrowItemSent } from "../../actions";

export default function throwItem(
  connection: MessagesCommunication<GameServerMessage>,
  { payload: { receiverId, throwItemId } }: ThrowItemSent,
) {
  connection.send(performThrowItem(receiverId, throwItemId));
}
