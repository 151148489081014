import { race, select, take } from "redux-saga/effects";
import {
  getQuickPos,
  POKER_SC_GET_QUICK_POS_EX_ERR,
  POKER_SC_GET_QUICK_POS_EX_OK,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  GetQuickPosErr,
  GetQuickPosOk,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { getTableSelector } from "../../selectors";
import Table from "../../types/Table";

export default function* getAvailableSeat(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const table: Table = yield select(getTableSelector);

  connection.send(getQuickPos(table.id));

  const responses: [GetQuickPosOk, GetQuickPosErr] = yield race([
    take(connection.messages, POKER_SC_GET_QUICK_POS_EX_OK),
    take(connection.messages, POKER_SC_GET_QUICK_POS_EX_ERR),
  ]);

  return responses;
}
