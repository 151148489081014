import { Store, UnknownAction } from "@reduxjs/toolkit";
import { AnyGameState } from "../store/game/store";

export const observeStore = <
  A extends Store<AnyGameState, UnknownAction>,
  B extends (s: AnyGameState) => any,
>(
  store: A,
  select: B,
  onChange: (state: ReturnType<B>) => void,
  equalityFn?: (prev: ReturnType<B>, next: ReturnType<B>) => boolean,
) => {
  let prev = select(store.getState());

  const handleChange = () => {
    const nextState = select(store.getState());

    if (!equalityFn?.(prev, nextState)) {
      onChange(nextState);
    }

    prev = nextState;
  };

  return store.subscribe(handleChange);
};
