import {
  IMessageBroker,
  ISharedTriple8Messaging,
} from "@sparkware/uc-sdk-core";
import { fork, select, takeEvery } from "redux-saga/effects";
import { MainServerMessage } from "../../../../../network/protocol/mainServer/types";
import {
  appReconnected,
  disconnected,
  gameCreated,
  gameServerConnected,
  reconnectGame,
  ringGameData,
  setBonus,
  topUpStateChanged,
  tournamentStarted,
} from "../../../actions";
import { getApplicationFeaturesSelector } from "../../../selectors";
import Application from "../../../types/Application";
import MessagesCommunication from "../messagesCommunication";
import closeDuplicates from "./closeDuplicates";
import connectGame from "./connectGame";
import connectToGameServer from "./connectToGameServer";
import { createGame } from "./createGame";
import disconnectGames from "./disconnectGames";
import dispatchBonusShowerToGame from "./dispatchBonusShowerToGame";
import dispatchTopUpToGame from "./dispatchTopUpToGame";
import reconnectGames from "./reconnectGames";
import routeGameMessages from "./routeGameMessages";
import routeHistoryMessages from "./routeHistoryMessages";

export default function* gamesManager(
  connection: MessagesCommunication<MainServerMessage>,
  messageBroker: IMessageBroker,
) {
  const { internalHud }: Application["features"] = yield select(
    getApplicationFeaturesSelector,
  );

  const firebaseInitSuccessPromise = internalHud
    ? new Promise<ISharedTriple8Messaging["subscribe"]>((resolve) => {
        const { unsubscribe } = messageBroker.push.topics.initSuccess.subscribe(
          (data) => {
            if (!("subscribe" in data)) return;

            resolve(data.subscribe);
            unsubscribe();
          },
        );
      })
    : null;

  yield takeEvery(
    [tournamentStarted.type, ringGameData.type],
    createGame,
    connection,
    firebaseInitSuccessPromise,
  );

  yield takeEvery(
    [gameCreated.type, reconnectGame.type],
    connectToGameServer,
    connection,
  );
  yield takeEvery(gameCreated.type, closeDuplicates);
  yield takeEvery(gameServerConnected.type, connectGame);
  yield takeEvery(disconnected.type, disconnectGames);
  yield takeEvery(appReconnected.type, reconnectGames);
  yield takeEvery(setBonus.type, dispatchBonusShowerToGame);
  yield takeEvery(topUpStateChanged.type, dispatchTopUpToGame);

  yield fork(routeGameMessages, connection);
  yield fork(routeHistoryMessages, connection);
}
