import { takeEvery } from "redux-saga/effects";
import {
  blastPoolData,
  blastRegistrationSuccess,
  depositShowed,
  openCashier,
  playAnotherGame,
  ringGameData,
  tourRegistrationSuccess,
  withdrawFunds,
} from "../../../actions";
import sendCmaEvent from "./sendCmaEvent";
import sendDepositClickedEvent from "./sendDepositClickedEvent";
import sendDepositDisplayedEvent from "./sendDepositDisplayedEvent";
import sendOpenGameEvent from "./sendOpenGameEvent";
import sendPlayAnotherEvent from "./sendPlayAnotherEvent";

export default function* clickstream() {
  yield takeEvery(
    [
      blastRegistrationSuccess.type,
      tourRegistrationSuccess.type,
      withdrawFunds.type,
    ],
    sendCmaEvent,
  );
  yield takeEvery([blastPoolData.type, ringGameData.type], sendOpenGameEvent);
  yield takeEvery(openCashier.type, sendDepositClickedEvent);
  yield takeEvery(depositShowed.type, sendDepositDisplayedEvent);
  yield takeEvery(playAnotherGame.type, sendPlayAnotherEvent);
}
