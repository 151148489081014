//For this message the server uses 999 as unlimited
const UNLIMITED = 999;

export function parseReEnryLeft(data: string) {
  const left = Number.parseInt(data);

  if (left === -1) {
    return undefined;
  } else if (left === UNLIMITED) {
    return Number.POSITIVE_INFINITY;
  }

  return left;
}
