const namespace = "handDescription";

export const HIGH_CARD = `${namespace}.highCard`;
export const PAIR = `${namespace}.pair`;
export const TWO_PAIR = `${namespace}.twoPair`;
export const THREE_OF_A_KIND = `${namespace}.threeOfAKind`;
export const STRAIGHT = `${namespace}.straight`;
export const FLUSH = `${namespace}.flush`;
export const FULL_HOUSE = `${namespace}.fullHouse`;
export const FOUR_OF_A_KIND = `${namespace}.fourOfAKind`;
export const STRAIGHT_FLUSH = `${namespace}.straightFlush`;
export const ROYAL_FLUSH = `${namespace}.royalFlush`;
