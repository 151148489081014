import { ReactNode } from "react";
import useGameModalState from "../hooks/useGameModalState";
import { RingGameBuyInContext } from "./RingGameBuyInContext";

export function RingGameBuyInProvider({ children }: { children: ReactNode }) {
  const { visible: showBuyIn, setOpened: setShowBuyIn } =
    useGameModalState(false);

  return (
    <RingGameBuyInContext.Provider value={{ showBuyIn, setShowBuyIn }}>
      {children}
    </RingGameBuyInContext.Provider>
  );
}
