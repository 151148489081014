import { call } from "redux-saga/effects";
import Communication from "../../../../network/Communication";
import { GameServerEventMap } from "../../../../network/events";
import connectionLoop from "../shared/connectionLoop";
import tournamentFlow from "./tournamentFlow";

export default function* blastTournament(
  server: Communication<GameServerEventMap>,
) {
  yield call(connectionLoop, server, tournamentFlow);
}
