import { IRouterChannelTopics } from "@sparkware/uc-sdk-core";
import { Action, History, Listener, Location, parsePath } from "history";
import { Path } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { MB_HEADERS } from "../../store/app/sagas/shared/externalCommunication";

type Events<F> = {
  length: number;
  push: (fn: F) => () => void;
  call: (arg: any) => void;
};

function createEvents<F extends (arg: any) => void>(): Events<F> {
  let handlers: F[] = [];

  return {
    get length() {
      return handlers.length;
    },
    push(fn: F) {
      handlers.push(fn);
      return function () {
        handlers = handlers.filter((handler) => handler !== fn);
      };
    },
    call(arg) {
      handlers.forEach((fn) => fn && fn(arg));
    },
  };
}

const isSameLocation = (location: Location, nextLocation: Location) => {
  const { pathname, search, hash } = location;

  const {
    pathname: nextPathname,
    search: nextSearch,
    hash: nextHash,
  } = nextLocation;

  return (
    pathname === nextPathname && search === nextSearch && hash === nextHash
  );
};

export const createHistory = (
  navigateByCode: IRouterChannelTopics["NavigateByCode"],
  navigateV2: IRouterChannelTopics["NavigateV2"],
  firstHitUrl = "/",
) => {
  const listeners = createEvents<Listener>();

  let location: Location = {
    pathname: "/",
    search: "",
    hash: "",
    ...parsePath(firstHitUrl),
    state: null,
    key: uuid(),
  };

  const history: History = {
    back: () => {},
    forward: () => {},
    go: () => {},
    block: () => () => {},
    createHref: () => "",
    listen: listeners.push,
    action: Action.Replace,
    push: (to: Path, state) => {
      navigateByCode.publish(MB_HEADERS, {
        url: to.pathname,
        state,
      });
    },
    replace: (to: Path, state) => {
      navigateByCode.publish(MB_HEADERS, {
        url: to.pathname,
        state,
        isReplaceState: true,
      });
    },
    get location() {
      return location;
    },
  };

  navigateV2.subscribe(({ url, state }) => {
    const nextLocation: Location = {
      pathname: location.pathname,
      search: "",
      hash: "",
      ...parsePath(url),
      state,
      key: uuid(),
    };

    if (isSameLocation(location, nextLocation)) return;

    location = nextLocation;

    listeners.call({ location, action: Action.Replace });
  });

  return history;
};
