import { useEffect, useRef, useState } from "react";

export const useWakeLock = () => {
  const [locked, setLocked] = useState<boolean>(false);
  const wakeLockSentinel = useRef<WakeLockSentinel | null>(null);

  useEffect(() => {
    if (locked) {
      navigator.wakeLock?.request("screen").then(
        (value) => {
          value.onrelease = () => {
            wakeLockSentinel.current = null;
          };
          wakeLockSentinel.current = value;
        },
        () => {
          wakeLockSentinel.current = null;
        },
      );
    } else {
      wakeLockSentinel.current?.release();
    }
  }, [locked]);

  useEffect(() => {
    async function handleVisibilityChange() {
      const wakeLock = navigator.wakeLock;
      if (
        wakeLock &&
        !wakeLockSentinel.current &&
        document.visibilityState === "visible" &&
        locked
      ) {
        try {
          const sentinel = await wakeLock.request("screen");
          sentinel.onrelease = () => {
            wakeLockSentinel.current = null;
          };
          wakeLockSentinel.current = sentinel;
        } catch (error) {
          wakeLockSentinel.current = null;
        }
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () =>
      document.removeEventListener("visibilityChange", handleVisibilityChange);
  }, [locked]);

  const requestWakeLock = () => setLocked(true);

  const releaseWakeLock = () => setLocked(false);

  return {
    requestWakeLock,
    releaseWakeLock,
  };
};
