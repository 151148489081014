import { put, take } from "redux-saga/effects";
import { MAIN_CLIENT_KICK_TO_LOBBY } from "../../../../network/protocol/mainServer";
import {
  KickToLobbyReason,
  MainServerMessage,
} from "../../../../network/protocol/mainServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { kickToLobby } from "../../actions";

export default function* kickToLobbySaga(
  connection: MessagesCommunication<MainServerMessage>,
) {
  while (true) {
    const result: KickToLobbyReason = yield take(
      connection.messages,
      MAIN_CLIENT_KICK_TO_LOBBY,
    );

    yield put(kickToLobby(result));
  }
}
