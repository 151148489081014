import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const RebuyButton = styled.button`
  padding: 0.5em;
  width: 4em;

  font-size: inherit;

  outline: none;
  background: none;
  border: none;

  &:disabled {
    filter: contrast(1.5) brightness(0.5);

    pointer-events: none;
    cursor: auto;
  }

  @media (hover: hover) {
    &:hover {
      ${({ theme }) => css`
        filter: drop-shadow(0 0 0.1em ${theme.rebuyButton.borderColor.hover});
      `}
    }
  }

  &:active {
    ${({ theme }) => css`
      filter: drop-shadow(0 0 0.1em ${theme.rebuyButton.borderColor.active})
        grayscale(0.5);
    `}
  }

  cursor: pointer;
`;

export const RebuyIcon = styled.img`
  width: 100%;
`;
