import { put, select, take } from "redux-saga/effects";
import { POKER_SC_FTD_SELECTION_STATUS } from "../../../../network/protocol/gameServer";
import {
  FtdSelectionStatus,
  GameServerMessage,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { ftdSelectionStatuses } from "../../actions";
import { getTournamentSelector } from "../../selectors";
import Tournament from "../../types/Tournament";

export default function* ftdSelectionStatusesHandler(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const { type, ...data }: FtdSelectionStatus = yield take(
      connection.messages,
      POKER_SC_FTD_SELECTION_STATUS,
    );
    const tournament: Tournament = yield select(getTournamentSelector);

    /**
     * If we are in the negotiation phase,
     * we should ignore this message,
     * as the server sends incorrect data after the opponent reconnects.
     */
    if (!tournament.ftdNegotiationData) {
      yield put(ftdSelectionStatuses(data));
    }
  }
}
