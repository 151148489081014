import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  allInEquity,
  antePosted,
  betCalled,
  betRaised,
  bigBlindPosted,
  chatMessageReceived,
  checked,
  chooseTourDealer,
  folded,
  ftdReconnectionDetails,
  ftdSatus,
  ftdSelectionStatuses,
  gameResult,
  gameStarted,
  livePenaltyPosted,
  playerAdded,
  playerCardsShowed,
  playerDontShowCards,
  playerSatIn,
  playerSatOut,
  playersListed,
  removePlayer,
  returnToViewer,
  round1Started,
  round2Started,
  round3Started,
  round4Started,
  setCredit,
  setTurn,
  setTurnTimebank,
  showCardsRoundStarted,
  smallBlindPosted,
  stateRestored,
  tableWillClear,
  tournamentEnded,
  uncalledAnte,
} from "../actions";
import CommittedAction from "../types/CommittedAction";
import { DealState } from "../types/Player";
import Players from "../types/Players";

const initialState: Players = {};

function isFinalActionInHand(committedAction?: CommittedAction) {
  return (
    committedAction === CommittedAction.ALL_IN ||
    committedAction === CommittedAction.FOLD
  );
}

function decisionToDealStatus(decision: number): DealState {
  if (decision === -1) {
    return "pending";
  } else {
    return decision === 1 ? "accepted" : "declined";
  }
}

export const players = createReducer<Players>(initialState, (builder) => {
  builder
    .addCase(gameStarted, (state) => {
      for (const seatId in state) {
        const player = state[seatId];
        /**
         * If a player accepts or declines a deal,
         * we should reset it since it not relevant in the a new hand.
         * This case cover reconnections, since in this case we don't
         * always have a tableWillClear action.
         */
        if (player.deal === "accepted" || player.deal === "declined") {
          player.deal = undefined;
        }
      }
    })
    .addCase(playersListed, (state, { payload }) => {
      // restore a deal state
      const newPlayers: Players = { ...payload };

      for (const key in newPlayers) {
        const statePlayer = state[key];

        if (statePlayer?.deal) {
          newPlayers[key] = { ...newPlayers[key], deal: statePlayer.deal };
        }
      }

      return newPlayers;
    })
    .addCase(chooseTourDealer, (state, { payload }) => {
      payload.cards.forEach((playerCard) => {
        const player = state[playerCard.seatId];

        if (player) {
          player.cards = [playerCard.card];
        }
      });
    })
    .addCase(antePosted, (state, { payload }) => {
      const { credit, effectiveStack, seatId } = payload;

      let committedAction: CommittedAction;

      if (credit === 0) {
        committedAction = CommittedAction.ALL_IN;
      } else if (effectiveStack === 0) {
        committedAction = CommittedAction.CAP;
      } else {
        committedAction = CommittedAction.ANTE;
      }

      state[seatId].credit = credit;
      state[seatId].effectiveStack = effectiveStack;
      state[seatId].committedAction = committedAction;
    })
    .addCase(smallBlindPosted, (state, { payload }) => {
      const { credit, effectiveStack, seatId } = payload;

      let committedAction: CommittedAction;

      if (credit === 0) {
        committedAction = CommittedAction.ALL_IN;
      } else if (effectiveStack === 0) {
        committedAction = CommittedAction.CAP;
      } else {
        committedAction = CommittedAction.SMALL_BLIND;
      }

      state[seatId].credit = credit;
      state[seatId].effectiveStack = effectiveStack;
      state[seatId].committedAction = committedAction;
    })
    .addCase(bigBlindPosted, (state, { payload }) => {
      const { credit, effectiveStack, seatId } = payload;

      let committedAction: CommittedAction;

      if (credit === 0) {
        committedAction = CommittedAction.ALL_IN;
      } else if (effectiveStack === 0) {
        committedAction = CommittedAction.CAP;
      } else {
        committedAction = CommittedAction.BIG_BLIND;
      }

      state[seatId].credit = credit;
      state[seatId].effectiveStack = effectiveStack;
      state[seatId].committedAction = committedAction;
    })
    .addCase(livePenaltyPosted, (state, { payload }) => {
      const { credit, effectiveStack, seatId } = payload;

      let committedAction: CommittedAction;

      if (credit === 0) {
        committedAction = CommittedAction.ALL_IN;
      } else if (effectiveStack === 0) {
        committedAction = CommittedAction.CAP;
      } else {
        committedAction = CommittedAction.PENALTY;
      }

      state[seatId].credit = credit;
      state[seatId].effectiveStack = effectiveStack;
      state[seatId].committedAction = committedAction;
    })
    .addCase(betCalled, (state, { payload }) => {
      const { credit, effectiveStack, seatId } = payload;

      let committedAction: CommittedAction;

      if (credit === 0) {
        committedAction = CommittedAction.ALL_IN;
      } else if (effectiveStack === 0) {
        committedAction = CommittedAction.CAP;
      } else {
        committedAction = CommittedAction.CALL;
      }

      state[seatId].credit = credit;
      state[seatId].effectiveStack = effectiveStack;
      state[seatId].committedAction = committedAction;
    })
    .addCase(betRaised, (state, { payload }) => {
      const { credit, effectiveStack, seatId } = payload;

      let committedAction: CommittedAction;

      if (credit === 0) {
        committedAction = CommittedAction.ALL_IN;
      } else if (effectiveStack === 0) {
        committedAction = CommittedAction.CAP;
      } else {
        committedAction = CommittedAction.RAISE;
      }

      state[seatId].credit = credit;
      state[seatId].effectiveStack = effectiveStack;
      state[seatId].committedAction = committedAction;
    })
    .addCase(folded, (state, { payload: { seatId } }) => {
      state[seatId].committedAction = CommittedAction.FOLD;
    })
    .addCase(checked, (state, { payload: { seatId } }) => {
      state[seatId].committedAction = CommittedAction.CHECK;
    })
    .addCase(round1Started, (state, { payload }) => {
      const {
        data: { uncalledBet },
        playersCards,
      } = payload;

      playersCards.forEach(({ seatId, cards }) => {
        const player = state[seatId];

        player.cards = cards;
      });

      if (uncalledBet) {
        state[uncalledBet.seatId].credit = uncalledBet.credit;
        state[uncalledBet.seatId].effectiveStack = uncalledBet.effectiveStack;
      }

      for (const seatId in state) {
        if (!isFinalActionInHand(state[seatId].committedAction)) {
          state[seatId].committedAction = undefined;
        }
      }
    })
    .addCase(uncalledAnte, (state, { payload }) => {
      const { uncalledBet } = payload;
      if (uncalledBet) {
        state[uncalledBet.seatId].credit = uncalledBet.credit;
        state[uncalledBet.seatId].effectiveStack = uncalledBet.effectiveStack;
      }
    })
    .addCase(gameResult, (state, { payload }) => {
      const { winners } = payload;

      winners.forEach((winner) => {
        const player = state[winner.seatId];
        player.credit += winner.payOff;
      });
    })
    .addCase(playerCardsShowed, (state, { payload }) => {
      const { seatId, cards } = payload;
      state[seatId].cards = cards;
    })
    .addCase(playerDontShowCards, (state, { payload }) => {
      const { seatId, cards } = payload;
      state[seatId].cards = state[seatId].cards || cards;
    })
    .addCase(tableWillClear, (state) => {
      for (const seatId in state) {
        const player = state[seatId];
        player.committedAction = undefined;
        player.cards = undefined;
        player.winPercentage = undefined;

        /**
         * If a player accepts or declines a deal,
         * we should reset it since it not relevant in the next hand.
         */
        if (player.deal === "accepted" || player.deal === "declined") {
          player.deal = undefined;
        }
      }
    })
    .addCase(playerSatIn, (state, { payload }) => {
      const player = state[payload.seatId];

      /*
        The server may send this message before the player
        is added to the list.
      */
      if (player) {
        player.sittingOut = false;
      }
    })
    .addCase(playerSatOut, (state, { payload }) => {
      state[payload.seatId].sittingOut = true;
    })
    .addCase(playerAdded, (state, { payload: { player } }) => {
      /*
        Sometimes the Game Server may send this message
        even if the player is at the table,
        in which case it should be ignored. >_<
      */
      const existingPlayer = state[player.seatId];

      if (existingPlayer && existingPlayer.name === player.name) return;

      state[player.seatId] = player;
    })
    .addCase(tournamentEnded, () => initialState)
    .addCase(stateRestored, (_, { payload: { players } }) => players)
    .addCase(chatMessageReceived, (state, { payload }) => {
      const player = Object.values(state).find(
        (value) => value.name === payload.nickname,
      );

      if (player) {
        player.chatData = payload;
      }
    })
    .addCase(allInEquity, (state, { payload }) => {
      Object.values(state).forEach((player) => {
        const equity = payload.equityData;

        if (equity[player.seatId]) {
          player.winPercentage = equity[player.seatId].winPercentage;
        }
      });
    })
    .addCase(setCredit, (state, { payload }) => {
      state[payload.seatId].credit = payload.credit;
      state[payload.seatId].effectiveStack = payload.effectiveChipStack;
    })
    .addCase(ftdSelectionStatuses, (state, { payload }) => {
      const { selections } = payload;

      selections.forEach((status) => {
        const player = state[status.seatId];

        /**
         * If a player accepts or declines a deal,
         * we want to maintain that state until the end of the hand.
         * Even if the server sends false. This is weird and
         * in a normal world should be implemented on the server side.
         */
        if (
          player &&
          player.deal !== "accepted" &&
          player.deal !== "declined"
        ) {
          player.deal = status.selected ? "pending" : undefined;
        }
      });
    })
    .addCase(ftdSatus, (state, { payload }) => {
      const { decisions } = payload;

      decisions.forEach((entry) => {
        const player = state[entry.seatId];
        player.deal = decisionToDealStatus(entry.decision);
      });
    })
    .addCase(ftdReconnectionDetails, (state, { payload }) => {
      if (payload.negotiationStatus.active) {
        payload.decisions.forEach((entry) => {
          const player = state[entry.seatId];
          player.deal = decisionToDealStatus(entry.decision);
        });
      } else {
        payload.selections.forEach((status) => {
          const player = state[status.seatId];

          if (player) {
            player.deal = status.selected ? "pending" : undefined;
          }
        });
      }
    })
    .addMatcher(
      createActionMatcher([
        round2Started,
        round3Started,
        round4Started,
        showCardsRoundStarted,
      ]),
      (state, { payload }) => {
        const { uncalledBet } = payload;

        for (const seatId in state) {
          if (!isFinalActionInHand(state[seatId].committedAction)) {
            state[seatId].committedAction = undefined;
          }
        }

        if (uncalledBet) {
          const player = state[uncalledBet.seatId];

          player.credit = uncalledBet.credit;
          player.effectiveStack = uncalledBet.effectiveStack;
        }
      },
    )
    .addMatcher(
      createActionMatcher([setTurn, setTurnTimebank]),
      (state, { payload }) => {
        const seatId = payload.seatId;
        state[seatId].committedAction = undefined;
        state[seatId].chatData = undefined;
      },
    )
    .addMatcher(
      createActionMatcher([returnToViewer, removePlayer]),
      (state, { payload: { seatId } }) => {
        delete state[seatId];
      },
    );
});
