import Styleable from "../../utils/types/Styleable";
import RoundedButton from "../RoundedButton";
import { CloseIcon } from "./styles";
import { CloseButtonProps } from "./types";

export default function CloseButton({
  onPress,
  className,
  style,
}: Styleable<CloseButtonProps>) {
  return (
    <RoundedButton
      data-testid="poker-close-button"
      className={className}
      style={style}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        onPress(event);
        event.stopPropagation();
      }}
    >
      <CloseIcon />
    </RoundedButton>
  );
}
