import { NotificationBubble, Wrapper } from "./styles";
import { MultiTableIndicatorProps } from "./types";

export const MultiTableIndicator = ({
  onClick,
  sessions,
  notified,
}: MultiTableIndicatorProps) => {
  const bubbleContent = sessions > 9 ? "9+" : `${sessions}`;

  return (
    <Wrapper onClick={onClick} data-content={bubbleContent}>
      <svg fill="currentColor" viewBox="0 0 44 34">
        <path d="M27.036 9.079A9.232 9.232 0 0 0 25.833 9H15.444a9.226 9.226 0 0 0-4.81 1.352 9.117 9.117 0 0 0-3.38 3.653 11.498 11.498 0 0 1 1.874-.157h10.388a11.266 11.266 0 0 1 7.918 3.258 11.078 11.078 0 0 1 3.286 7.852c0 .28-.015.562-.033.843a9.109 9.109 0 0 0 3.703-4.45 9.017 9.017 0 0 0 .512-4.59c-4.15-.377-7.486-3.597-7.866-7.682ZM9.191 34a9.23 9.23 0 0 1-6.5-2.669A9.075 9.075 0 0 1 0 24.887c0-2.417.968-4.735 2.692-6.444a9.23 9.23 0 0 1 6.499-2.67h10.212c2.438 0 4.776.96 6.5 2.67a9.075 9.075 0 0 1 2.691 6.444 9.075 9.075 0 0 1-2.692 6.444A9.231 9.231 0 0 1 19.403 34H9.191Z" />
        <NotificationBubble $notified={notified} r={6.5} cx={35.5} cy={8.5} />
      </svg>
    </Wrapper>
  );
};
