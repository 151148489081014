import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  gameResult,
  gameStarted,
  rabbitHunt,
  ringGameJoined,
  ringSnapState,
  ringTableJoined,
  round2Started,
  round3Started,
  round4Started,
  showCardsRoundStarted,
  stateRestored,
  tableWillClear,
  thrownObject,
  thrownObjectDelivered,
  tournamentEnded,
  tournamentFinalPlayers,
  tournamentFinalTable,
  tourTableJoined,
  updateSnapPoolTableId,
} from "../actions";
import initialState from "../initialStates/table";
import Table from "../types/Table";

export const table = createReducer<Table>(initialState, (builder) => {
  builder
    .addCase(tourTableJoined, (state, { payload }) => {
      state.id = payload.tableId;
      state.minBuyIn = payload.buyIn;
      state.maxBuyIn = payload.buyIn;
      state.maxPlayers = payload.maxPlayers;
      state.limitType = payload.limitType;
      state.isFinalTable = payload.isFinalTable;
      state.isFinalPlayers = payload.isFinalPlayers;
      state.isObserverChatEnable = payload.isObserverChatEnable;
    })
    .addCase(ringGameJoined, (state, { payload }) => {
      state.id = payload.tableId;
      state.handId = payload.handId;
    })
    .addCase(ringTableJoined, (state, { payload }) => {
      state.name = payload.tableName;
      state.maxPlayers = payload.maxPlayers;
      state.pokerType = payload.pokerType;
      state.limitType = payload.limitType;
      state.minBuyIn = payload.minBuyIn;
      state.maxBuyIn = payload.maxBuyIn;
      state.minPlayers = payload.minPlayers;
      state.isObserverChatEnable = payload.isObserverChatEnable;
      state.bigBlind = payload.bigBlind;
      state.smallBlind = payload.smallBlind;
      state.snapRingPoolTableId = payload.snapRingPoolTableId;
    })
    .addCase(updateSnapPoolTableId, (state, { payload }) => {
      state.snapRingPoolTableId = payload.tableId;
    })
    .addCase(ringSnapState, (state, { payload }) => {
      state.isSnapMode = payload.isSnapMode;
    })
    .addCase(gameStarted, (state, { payload: { dealerSeatId, handId } }) => {
      state.dealerSeatId = dealerSeatId;
      state.cards = null;
      state.winners = undefined;
      state.isHandFinished = false;
      state.handId = handId;
      state.rabbitCards = null;
    })
    .addCase(round2Started, (state, { payload }) => {
      state.cards = payload.cards;
    })
    .addCase(gameResult, (state, { payload }) => {
      state.winners = payload.winners;
      state.isHandFinished = true;
    })
    .addCase(rabbitHunt, (state, { payload }) => {
      state.rabbitCards = payload.cards;
    })
    .addCase(thrownObject, (state, { payload }) => {
      state.thrownObjects.push(payload);
    })
    .addCase(
      thrownObjectDelivered,
      (state, { payload: { sender, receiver } }) => {
        state.thrownObjects = state.thrownObjects.filter(
          (value) => value.sender !== sender && value.receiver !== receiver,
        );
      },
    )
    .addCase(stateRestored, (_, { payload }) => {
      return { ...payload.table, thrownObjects: [] };
    })
    .addCase(showCardsRoundStarted, (state) => {
      state.isHandFinished = true;
    })
    .addCase(tournamentFinalTable, (state, { payload }) => {
      state.isFinalTable = payload.isFinalTable;
    })
    .addCase(tournamentFinalPlayers, (state) => {
      state.isFinalPlayers = true;
    })
    .addMatcher(
      createActionMatcher([round3Started, round4Started]),
      (state, { payload }) => {
        state.cards = [...(state.cards ?? []), ...payload.cards];
      },
    )
    .addMatcher(
      createActionMatcher([tournamentEnded, tableWillClear]),
      (state) => {
        state.cards = null;
        state.rabbitCards = null;
        state.dealerSeatId = NaN;
        state.winners = undefined;
        state.isHandFinished = false;
        state.thrownObjects = [];
      },
    );
});
