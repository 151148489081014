import { useContext } from "react";
import { AvatarSelectionContext } from "../context";

export default function useAvatarSelection() {
  const selectionContext = useContext(AvatarSelectionContext);

  if (!selectionContext) {
    throw new Error(
      "useAvatarSelection must be inside a AvatarSelectionContext provider",
    );
  }

  return selectionContext;
}
