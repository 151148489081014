import { CARD_VIEWBOX } from "../styles";
import { ranks } from "./ranks";
import { FrontCardBodyProps } from "./types";

export default function FrontCardBody({ rank, data }: FrontCardBodyProps) {
  return (
    <>
      <rect
        fill="currentColor"
        width={CARD_VIEWBOX.width}
        height={CARD_VIEWBOX.height}
        rx="3"
      />
      <path d={data} transform="translate(2 17)" />
      <path d={ranks[rank]} transform="translate(-1.5 0)" />
    </>
  );
}
