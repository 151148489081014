import { CSSProperties, PropsWithChildren, useMemo, useRef } from "react";
import AvatarSelectionPopup from "../../containers/AvatarSelectionPopup";
import { LobbyHamburgerMenu } from "../../containers/HamburgerMenu";
import { AvatarSelectionProvider, OrientationContext } from "../../context";
import useNavBar from "../../hooks/useNavBar";
import useScaleToFit from "../../hooks/useScaleToFit";
import { HamburgerMenuPlaceholder } from "../HamburgerMenu/styles";
import RebuyChip from "../RebuyChip";
import { GameWrapper, SafeArea, TopSection, Viewport } from "./styles";
import { ScreenProps } from "./types";

export default function Screen({
  children,
  onBackToLobby: onClose,
}: PropsWithChildren<ScreenProps>) {
  const containerRef = useRef(null);
  const hamburgerMenuRef = useRef<HTMLDivElement>(null);
  const rebuyButtonRef = useNavBar()?.rebuyButtonRef;

  const scaleObject = useScaleToFit(containerRef, 2);

  const orientation = useMemo(
    () => ({
      isLandscape: scaleObject.landscape,
      isSmallScreen: scaleObject.smallContainer,
    }),
    [scaleObject.landscape, scaleObject.smallContainer],
  );

  const { offsetX, offsetY, contentScale, sourceWidth, sourceHeight } =
    scaleObject;

  const viewportStyle: CSSProperties = {
    transform: `translate(${offsetX}px, ${offsetY}px) scale(${contentScale})`,
    width: sourceWidth,
    height: sourceHeight,
  };

  return (
    <GameWrapper>
      <AvatarSelectionProvider>
        <SafeArea ref={containerRef}>
          <Viewport style={viewportStyle}>
            <OrientationContext.Provider value={orientation}>
              {children}
            </OrientationContext.Provider>
          </Viewport>
          <TopSection>
            <LobbyHamburgerMenu
              menuPanelRef={hamburgerMenuRef}
              onBackToLobby={onClose}
            />
            <RebuyChip buttonPlaceholder={rebuyButtonRef} disabled />
          </TopSection>
        </SafeArea>
        <HamburgerMenuPlaceholder ref={hamburgerMenuRef} />
        <AvatarSelectionPopup />
      </AvatarSelectionProvider>
    </GameWrapper>
  );
}
