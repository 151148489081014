import styled from "@emotion/styled/macro";

export const ErrorLayout = styled.div`
  pointer-events: auto;

  display: grid;
  grid-gap: 20px;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  width: 262px;

  color: ${({ theme }) => theme.genericError.color};
  font-size: 11px;
  line-height: 17px;
  white-space: pre-line;
  text-align: center;

  opacity: 0.5;
`;

export const ChildrenLayout = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  max-width: 262px;
  min-height: 35px;

  > * {
    grid-column: span 2;
  }

  > :last-child:nth-child(2n - 1) {
    grid-column-end: 4;
  }
`;

export const ErrorIcon = styled.img`
  width: 70px;
`;
