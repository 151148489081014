import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

function isObjectOrArray(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value !== null;
}

export default function useLocalStorage<S>(
  initialValue: S,
  key?: string,
): [S, Dispatch<SetStateAction<S>>] {
  const getValue = useCallback(() => {
    if (!key) {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);

      if (!item) {
        return initialValue;
      }

      const parsedItem = JSON.parse(item);

      // update stored value if initial and stored values are of different type
      if (
        (isObjectOrArray(initialValue) && !isObjectOrArray(parsedItem)) ||
        (!isObjectOrArray(initialValue) && isObjectOrArray(parsedItem)) ||
        (Array.isArray(initialValue) && !Array.isArray(parsedItem)) ||
        (!Array.isArray(initialValue) && Array.isArray(parsedItem))
      ) {
        window.localStorage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      }

      if (isObjectOrArray(initialValue) && isObjectOrArray(parsedItem)) {
        const initialKeys = Object.keys(initialValue);
        const itemKeys = Object.keys(parsedItem);

        // update stored value if initial and stored value have different keys
        if (initialKeys.toString() !== itemKeys.toString()) {
          const mixed: Record<string, unknown> = {};

          initialKeys.forEach((key) => {
            mixed[key] = parsedItem[key] || initialValue[key];
          });

          window.localStorage.setItem(key, JSON.stringify(mixed));
          return mixed;
        }
      }

      return parsedItem;
    } catch {
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState(getValue);

  const dispatch: Dispatch<SetStateAction<S>> = useMemo(
    () => (value) => {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      try {
        if (key) {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch {
        /* do nothing in case of local storage unavailable */
      }
    },
    [key, storedValue],
  );

  useEffect(() => {
    setStoredValue(getValue());
  }, [getValue]);

  return [storedValue, dispatch] as const;
}
