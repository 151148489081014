import { ParsersMap } from "../types";
import {
  authenticationFault,
  authenticationSuccess,
  gameServerConnectFault,
  gameServerConnectSuccess,
  generalGameMessage,
  generalHistoryMessage,
  getAccountAttributesError,
  getAccountAttributesOk,
  getIdenticalRingTableErr,
  getIdenticalRingTableOk,
  getKickToLobyReason,
  getQuickTableFromCreatorIdErr,
  getQuickTableFromCreatorIdOk,
  getRelevantBonusesResponse,
  joinAppFault,
  joinAppSuccess,
  messageToClient,
  playerFinishedTour,
  reconnectAppFault,
  reconnectAppSuccess,
  registerBlastError,
  registerTourError,
  registerTourOk,
  setOpenSessions,
  setOpenSessionsError,
  setReconnectionFlag,
  startTournament,
  tourIsEndOrCancelled,
  unregisterTourError,
  unregisterTourOk,
} from "./messageParsers";
import {
  INFO_SRV_CLIENT_END_OR_CANCEL_TOUR,
  INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_ERR,
  INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_OK,
  INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_ERR,
  INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_OK,
  INFO_SRV_CLIENT_PLAYER_FINISHED_TOUR,
  INFO_SRV_CLIENT_REG_BLAST_POOL_ERR,
  INFO_SRV_CLIENT_REG_TOUR_ERR,
  INFO_SRV_CLIENT_REG_TOUR_OK,
  INFO_SRV_CLIENT_START_TOUR,
  INFO_SRV_CLIENT_UNREG_TOUR_ERR,
  INFO_SRV_CLIENT_UNREG_TOUR_OK,
  MAIN_CLIENT_AUTHENTICATE_APP_ERR,
  MAIN_CLIENT_AUTHENTICATE_APP_OK,
  MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_ERR,
  MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_OK,
  MAIN_CLIENT_GET_RELEVANT_BONUSES_RESPONSE,
  MAIN_CLIENT_JOIN_APP_ERR,
  MAIN_CLIENT_JOIN_APP_OK,
  MAIN_CLIENT_KICK_TO_LOBBY,
  MAIN_CLIENT_RECONNECT_APP_ERR,
  MAIN_CLIENT_RECONNECT_APP_OK,
  PROXY_CLIENT_CONNECT_SERVER_ERR,
  PROXY_CLIENT_CONNECT_SERVER_OK,
  PROXY_CLIENT_GENERAL_GAME_MSG,
  PROXY_CLIENT_GENERAL_HISTORY_MSG,
  PROXY_CLIENT_MESSAGE_TO_CLIENT,
  PROXY_CLIENT_SET_RECONNECTION_FLAG,
  PROXY_CLIENT_SET_USR_GAME_SESSIONS,
  PROXY_CLIENT_SET_USR_GAME_SESSIONS_ERR,
} from "./messageTypes";
import type { MainServerMessage, MainServerMessageType } from "./types";

export * from "./messageCreators";
export * from "./messageTypes";

export const parsers: Partial<
  ParsersMap<MainServerMessageType, MainServerMessage>
> = {
  [MAIN_CLIENT_JOIN_APP_OK]: joinAppSuccess,
  [MAIN_CLIENT_JOIN_APP_ERR]: joinAppFault,
  [MAIN_CLIENT_GET_RELEVANT_BONUSES_RESPONSE]: getRelevantBonusesResponse,
  [INFO_SRV_CLIENT_REG_BLAST_POOL_ERR]: registerBlastError,
  [INFO_SRV_CLIENT_REG_TOUR_OK]: registerTourOk,
  [INFO_SRV_CLIENT_REG_TOUR_ERR]: registerTourError,
  [INFO_SRV_CLIENT_UNREG_TOUR_OK]: unregisterTourOk,
  [INFO_SRV_CLIENT_UNREG_TOUR_ERR]: unregisterTourError,
  [MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_OK]: getAccountAttributesOk,
  [MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_ERR]: getAccountAttributesError,
  [INFO_SRV_CLIENT_START_TOUR]: startTournament,
  [PROXY_CLIENT_CONNECT_SERVER_OK]: gameServerConnectSuccess,
  [PROXY_CLIENT_CONNECT_SERVER_ERR]: gameServerConnectFault,
  [PROXY_CLIENT_GENERAL_GAME_MSG]: generalGameMessage,
  [PROXY_CLIENT_GENERAL_HISTORY_MSG]: generalHistoryMessage,
  [MAIN_CLIENT_AUTHENTICATE_APP_OK]: authenticationSuccess,
  [MAIN_CLIENT_AUTHENTICATE_APP_ERR]: authenticationFault,
  [MAIN_CLIENT_RECONNECT_APP_OK]: reconnectAppSuccess,
  [MAIN_CLIENT_RECONNECT_APP_ERR]: reconnectAppFault,
  [INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_OK]:
    getQuickTableFromCreatorIdOk,
  [INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_ERR]:
    getQuickTableFromCreatorIdErr,
  [INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_OK]: getIdenticalRingTableOk,
  [INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_ERR]: getIdenticalRingTableErr,
  [MAIN_CLIENT_KICK_TO_LOBBY]: getKickToLobyReason,
  [PROXY_CLIENT_SET_RECONNECTION_FLAG]: setReconnectionFlag,
  [PROXY_CLIENT_SET_USR_GAME_SESSIONS]: setOpenSessions,
  [PROXY_CLIENT_SET_USR_GAME_SESSIONS_ERR]: setOpenSessionsError,
  [INFO_SRV_CLIENT_END_OR_CANCEL_TOUR]: tourIsEndOrCancelled,
  [INFO_SRV_CLIENT_PLAYER_FINISHED_TOUR]: playerFinishedTour,
  [PROXY_CLIENT_MESSAGE_TO_CLIENT]: messageToClient,
};
