import { hubConnection, signalR } from "@san4/signalr-no-jquery";
import EventTarget from "@ungap/event-target";
import Communication from "./Communication";
import CommunicationError from "./CommunicationError";
import { MainServerEventMap } from "./events";
import CommunicationLifecycleEvent from "./events/CommunicationLifecycleEvent";
import MessageEvent from "./events/MessageEvent";
import { parseGenericMessage, serializeMessage } from "./protocol";
import { parsers, ping, PING, pong, PONG } from "./protocol/mainServer";
import {
  MainServerMessage,
  MainServerMessageType,
} from "./protocol/mainServer/types";
import type { OutgoingMessage } from "./protocol/types";

interface IncomingMessageEvent {
  MessageData: string;
}

export default class MainServer implements Communication<MainServerEventMap> {
  private _dispatcher: EventTarget;
  private _connection: SignalR.Hub.Connection | null = null;
  private _proxy: SignalR.Hub.Proxy | null = null;
  private _pingTimer = 0;
  private _currentPingRetries = 0;
  private _pingRetries = 4;
  private _pingTimeout = 3000;
  private _lastError: CommunicationError | null = null;

  constructor(pingRetries = 3, pingTimeout = 3000) {
    this._dispatcher = new EventTarget();
    this._pingRetries = pingRetries;
    this._pingTimeout = pingTimeout;

    this.send = this.send.bind(this);
  }

  connect(url: string) {
    this.destroyConnection();

    const connection = hubConnection(url);
    this._connection = connection;
    this._proxy = this._connection.createHubProxy("mainClientHub");
    this._proxy.on("sendMessage", this.onMessageReceived);
    this._connection.disconnected(this.disconnected);
    this._connection.stateChanged(this.onStateChanged);

    return new Promise((resolve, reject) => {
      connection
        .start({
          waitForPageLoad: false,
        })
        .then(resolve, (error: Error) => {
          this._lastError = error;
          reject(error);
        });
    });
  }

  send(message: OutgoingMessage): void {
    if (this._proxy && this.connected) {
      this._proxy.invoke("onMessageReceived", {
        MessageData: serializeMessage(message),
      });
    }
  }

  close(error: Error | null = null): void {
    if (this._connection) {
      if (error) {
        this._lastError = error;
      }
      this._connection.stop();
    }
  }

  get connected(): boolean {
    return this._connection?.state === signalR.connectionState.connected;
  }

  private destroyConnection(): void {
    this._lastError = null;
    this._currentPingRetries = 0;

    if (this._proxy) {
      this._proxy.off("sendMessage", this.onMessageReceived);
      this._proxy = null;
    }

    if (this._connection) {
      this._connection.stop();
      this._connection = null;
    }
  }

  private onMessageReceived = (event: IncomingMessageEvent): void => {
    const incomingMessage = parseGenericMessage(event.MessageData);

    if (incomingMessage.type === PONG) {
      this._currentPingRetries = 0;
      return;
    } else if (incomingMessage.type === PING) {
      this.send(pong());
      return;
    }

    const parser = parsers[incomingMessage.type as MainServerMessageType];

    if (parser) {
      this.dispatchEvent(
        new MessageEvent<MainServerMessage>(parser(incomingMessage)),
      );
    }
  };

  private disconnected = (): void => {
    const event = new CommunicationLifecycleEvent(
      CommunicationLifecycleEvent.DISCONNECTED,
    );
    event.error = this._lastError || this._connection?.lastError || null;

    this._dispatcher.dispatchEvent(event);
  };

  private onStateChanged = (state: SignalR.StateChanged): void => {
    if (state.newState === signalR.connectionState.connected) {
      this.startPingTimer();
    } else {
      this.stopPingTimer();
    }
  };

  private startPingTimer(): void {
    this.stopPingTimer();

    this._pingTimer = window.setInterval(() => {
      if (this._currentPingRetries >= this._pingRetries) {
        this.stopPingTimer();
        this._currentPingRetries = 0;
        // Server not responding
        this.close(new Error("NotResponding"));
      } else {
        this._currentPingRetries++;
        this.send(ping());
      }
    }, this._pingTimeout);
  }

  private stopPingTimer(): void {
    clearInterval(this._pingTimer);
  }
  addEventListener<K extends keyof MainServerEventMap>(
    type: K,
    listener: (event: MainServerEventMap[K]) => void,
    options?: boolean | AddEventListenerOptions,
  ): void {
    this._dispatcher.addEventListener(
      type,
      listener as unknown as EventListener,
      options,
    );
  }

  removeEventListener<K extends keyof MainServerEventMap>(
    type: K,
    callback: (event: MainServerEventMap[K]) => void,
    options?: EventListenerOptions | boolean,
  ): void {
    this._dispatcher.removeEventListener(
      type,
      callback as unknown as EventListener,
      options,
    );
  }

  dispatchEvent(event: Event): boolean {
    return this._dispatcher.dispatchEvent(event);
  }
}
