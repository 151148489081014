import { IMessageBroker } from "@sparkware/uc-sdk-core";
import { SagaIterator, Task } from "redux-saga";
import { cancel, fork, take } from "redux-saga/effects";
import { MainServerMessage } from "../../../network/protocol/mainServer/types";
import { joined, logout } from "../actions";
import gamesManager from "./shared/gamesManager/gamesManager";
import MessagesCommunication from "./shared/messagesCommunication";

export function* gamesManagerRunner(
  connection: MessagesCommunication<MainServerMessage>,
  messageBroker: IMessageBroker,
): SagaIterator {
  while (true) {
    yield take(joined.type);
    const task: Task = yield fork(gamesManager, connection, messageBroker);

    yield take(logout.type);
    yield cancel(task);
  }
}
