import { forwardRef } from "react";
import { IndicatorWrapper } from "../styles";
import { HumanIcon, StyledRegisteredTableIndicator } from "./styles";
import { RegisteredIndicatorProps } from "./types";

export const RegisteredIndicator = forwardRef<
  HTMLButtonElement,
  RegisteredIndicatorProps
>(({ onClick, ...rest }, ref) => {
  return (
    <IndicatorWrapper ref={ref} onClick={onClick}>
      <StyledRegisteredTableIndicator {...rest}>
        <HumanIcon />
      </StyledRegisteredTableIndicator>
    </IndicatorWrapper>
  );
});
