import Popup from "../Popup";
import AvatarSelectionDialog from "./AvatarSelectionDialog";
import { AvatarSelectionPopupProps } from "./types";

export default function AvatarSelectionPopup({
  open,
  onClose,
  onConfirm,
  selectedAvatar,
  testId,
}: AvatarSelectionPopupProps) {
  return (
    <Popup
      open={open}
      onClose={onClose}
      closeOnOverlay={true}
      dataTestId={testId}
    >
      <AvatarSelectionDialog
        selectedAvatar={selectedAvatar}
        onConfirm={onConfirm}
      />
    </Popup>
  );
}
