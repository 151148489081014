import { fork, takeEvery } from "redux-saga/effects";
import {
  POKER_SC_BREAK_END,
  POKER_SC_BREAK_START,
  POKER_SC_CHOOSE_TOUR_DEALER,
  POKER_SC_FINAL_PLAYERS,
  POKER_SC_FINAL_TABLE,
  POKER_SC_FINISHED_TOUR,
  POKER_SC_JOIN_TOUR_TABLE,
  POKER_SC_PLAYER_SIT_IN_TOUR,
  POKER_SC_PLAYER_SIT_OUT_TOUR,
  POKER_SC_RETURN_TO_TOUR_LOBBY,
  POKER_SC_TOUR_ANTE,
  POKER_SC_TOUR_BREAK_ACTIVE,
  POKER_SC_TOUR_BREAK_INACTIVE,
  POKER_SC_TOUR_ENDED,
  POKER_SC_UP_LEVEL,
} from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  blindLevelIncreased,
  breakEnd,
  breakStart,
  chooseTourDealer,
  gameResult,
  handHistorySelected,
  playerSatIn,
  playerSatOut,
  sitIn,
  sitOut,
  tournamentCancelled,
  tournamentEnded,
  tournamentFinalPlayers,
  tournamentFinalTable,
  tournamentFinished,
  tournamentJoined,
  tourTableJoined,
  uncalledAnte,
} from "../../actions";
import genericGameFlow from "../shared/genericGameFlow";
import pushMessageHandler from "../shared/pushMessageHandler";
import requestTourHandHistory from "../shared/requestTourHandHistory";
import getHandsList from "./getHandsList";
import getPrizePool from "./getPrizePool";
import joinToTournament from "./joinToTournament";
import joinTournamentTable from "./joinTournamentTable";
import sitInToTour from "./sitInToTour";
import sitOutOfTour from "./sitOutOfTour";
import sitOutOfTourDelayed from "./sitOutOfTourDelayed";

export default function* tournamentFlow(
  connection: MessagesCommunication<GameServerMessage>,
) {
  yield fork(joinToTournament, connection);
  yield takeEvery(tournamentJoined.type, joinTournamentTable, connection);
  yield fork(
    pushMessageHandler,
    POKER_SC_JOIN_TOUR_TABLE,
    tourTableJoined,
    connection,
  );
  yield takeEvery(tourTableJoined.type, getPrizePool);

  yield fork(
    pushMessageHandler,
    POKER_SC_UP_LEVEL,
    blindLevelIncreased,
    connection,
  );

  yield fork(pushMessageHandler, POKER_SC_BREAK_START, breakStart, connection);
  yield fork(pushMessageHandler, POKER_SC_BREAK_END, breakEnd, connection);
  yield fork(pushMessageHandler, POKER_SC_TOUR_ANTE, uncalledAnte, connection);
  yield fork(
    pushMessageHandler,
    POKER_SC_TOUR_BREAK_ACTIVE,
    breakStart,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_TOUR_BREAK_INACTIVE,
    breakEnd,
    connection,
  );

  yield takeEvery(sitIn.type, sitInToTour, connection);
  yield takeEvery(sitOut.type, sitOutOfTour, connection);
  yield takeEvery(gameResult.type, sitOutOfTourDelayed, connection);

  yield fork(
    pushMessageHandler,
    POKER_SC_PLAYER_SIT_IN_TOUR,
    playerSatIn,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_PLAYER_SIT_OUT_TOUR,
    playerSatOut,
    connection,
  );

  yield fork(
    pushMessageHandler,
    POKER_SC_FINISHED_TOUR,
    tournamentFinished,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_TOUR_ENDED,
    tournamentEnded,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_RETURN_TO_TOUR_LOBBY,
    tournamentCancelled,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_CHOOSE_TOUR_DEALER,
    chooseTourDealer,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_FINAL_TABLE,
    tournamentFinalTable,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_FINAL_PLAYERS,
    tournamentFinalPlayers,
    connection,
  );
  yield takeEvery(gameResult.type, getHandsList, connection);
  yield takeEvery(handHistorySelected.type, requestTourHandHistory, connection);

  yield fork(genericGameFlow, connection);
}
