import { PropsWithChildren } from "react";
import useModalState from "../hooks/useModalState";
import { HudExplanationPopupContext } from "./HudExplanationPopupContext";

export function HudExplanationPopupProvider({ children }: PropsWithChildren) {
  const { opened, setOpened } = useModalState(false, true);

  return (
    <HudExplanationPopupContext.Provider value={{ opened, setOpened }}>
      {children}
    </HudExplanationPopupContext.Provider>
  );
}
