import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  logout,
  playerFinishedTour,
  reconnectError,
  tourEndOrCancelled,
  tourRegistrationRestored,
  tourRegistrationSuccess,
  tourUnregistrationSuccess,
} from "../actions";
import { Tournaments } from "../types/Tournaments";

const initialState: Tournaments = {};

export const tournaments = createReducer(initialState, (builder) => {
  builder
    .addCase(
      playerFinishedTour,
      (state, { payload: { tourId, isReEntry } }) => {
        if (isReEntry) {
          const registration = state[tourId];
          registration.active = false;
        }
      },
    )
    .addCase(tourRegistrationRestored, (state, { payload: { tourId } }) => {
      state[tourId] = { tourId, active: true };
    })
    .addCase(
      tourRegistrationSuccess,
      (state, { payload: { tourId, reEntryLeft } }) => {
        state[tourId] = { tourId, active: true, reEntryLeft };
      },
    )
    .addMatcher(
      createActionMatcher([tourUnregistrationSuccess, tourEndOrCancelled]),
      (state, { payload: { tourId } }) => {
        delete state[tourId];
      },
    )
    .addMatcher(
      createActionMatcher([reconnectError, logout]),
      () => initialState,
    );
});
