import { AnyGameState } from "../../store";
import getHero from "../hero/getHero";

export default function getIsSeatInteractive(state: AnyGameState) {
  const { hero, game } = state;

  const isSnapGame = game.type === "snap";
  const heroExist = Boolean(getHero(state));
  const heroHasReservedSeat = Boolean(hero.reservedSeatId);
  const isTournament = "tournament" in state;

  return isTournament || heroExist || heroHasReservedSeat || isSnapGame;
}
