import { put, race, take } from "redux-saga/effects";
import { PokerType } from "../../../../network/protocol/gameServer/types/PokerType";
import {
  PROXY_CLIENT_SET_USR_GAME_SESSIONS,
  PROXY_CLIENT_SET_USR_GAME_SESSIONS_ERR,
  getOpenSessions,
} from "../../../../network/protocol/mainServer";
import {
  MainServerMessage,
  RingGameAttribute,
  SessionStatus,
  SessionType,
  SetOpenSessions,
  SetOpenSessionsError,
  TourSubType,
} from "../../../../network/protocol/mainServer/types";
import { getTournamentType } from "../../../../utils/getTournamentType";
import makeGameId from "../../../../utils/makeGameId";
import { isRingGamesSupported } from "../../../../utils/supportedRingGames";
import { isTourSupported } from "../../../../utils/supportedTournament";
import {
  blastRegistrationRestored,
  ringGameData,
  tourRegistrationRestored,
  tournamentStarted,
} from "../../actions";
import MessagesCommunication from "./messagesCommunication";

export default function* getOpenSessionsSaga(
  connection: MessagesCommunication<MainServerMessage>,
) {
  connection.send(getOpenSessions());

  const [response]: [SetOpenSessions, SetOpenSessionsError] = yield race([
    take(connection.messages, PROXY_CLIENT_SET_USR_GAME_SESSIONS),
    take(connection.messages, PROXY_CLIENT_SET_USR_GAME_SESSIONS_ERR),
  ]);

  if (response) {
    for (const session of response.sessions) {
      if (session.gameType !== PokerType.TEXASHOLDEM) {
        break;
      }

      if (session.sessionType === SessionType.RING_GAME) {
        if (!isRingGamesSupported(session.attributesMask)) break;

        yield put(
          ringGameData({
            gameInstanceId: session.instanceId,
            gameType:
              session.attributesMask & RingGameAttribute.SNAP ? "snap" : "cash",
            serverId: session.serverId,
            pokerType: session.gameType,
            attributes: session.attributesMask,
            playerInstanceId: session.playerInstanceId,
            isOpenSession: true,
          }),
        );
      } else if (isTourSupported(session.attributesMask, session.subTypeMask)) {
        if (session.sessionStatus === SessionStatus.INACTIVE) {
          if (session.subTypeMask & TourSubType.BLAST) {
            yield put(
              blastRegistrationRestored({
                gameId: makeGameId(),
                tourId: Number(session.instanceId),
              }),
            );
          } else {
            yield put(
              tourRegistrationRestored({ tourId: Number(session.instanceId) }),
            );
          }
        } else if (session.sessionStatus === SessionStatus.ACTIVE) {
          yield put(
            tournamentStarted({
              gameInstanceId: session.instanceId,
              serverId: session.serverId,
              pokerType: session.gameType,
              attributesMask: session.attributesMask,
              tourSubTypeMask: session.subTypeMask,
              isOpenSession: true,
              gameType: getTournamentType(
                session.attributesMask,
                session.subTypeMask,
              ),
            }),
          );

          if (!(session.subTypeMask & TourSubType.BLAST)) {
            yield put(
              tourRegistrationRestored({
                tourId: Number(session.instanceId),
              }),
            );
          }
        }
      }
    }
  }
}
