import { useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Slide, ToastContainerProps, toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import infoIcon from "../../assets/info-icon.png";
import errorIcon from "../../assets/king.png";
import useBFXFormat from "../../hooks/useBFXFormat";
import useServerApi from "../../hooks/useServerApi";
import { NotificationToasterKeys, TourDialogKeys } from "../../i18n/keys";
import {
  INFO_SRV_CLIENT_REG_TOUR_OK,
  PROXY_CLIENT_MESSAGE_TO_CLIENT,
} from "../../network/protocol/mainServer";
import { clearUserNotification } from "../../store/app/actions";
import {
  getRegulationTypeSelector,
  getUserSelector,
} from "../../store/app/selectors";
import { UK_REGULATION_TYPE } from "../../utils/regulationTypes";
import { Icon, StyledToastContainer } from "./styles";

const defaultToastProps: Omit<ToastContainerProps, "theme"> = {
  position: "top-center",
  closeOnClick: true,
  transition: Slide,
  icon: ({ type }) => {
    return <Icon src={type === "error" ? errorIcon : infoIcon} alt="Icon" />;
  },
};

export default function NotificationToasterContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formatBFX = useBFXFormat();

  const regulationType = useSelector(getRegulationTypeSelector);
  const { notification } = useSelector(getUserSelector);
  const { subcribe } = useServerApi();

  useLayoutEffect(injectStyle, []);

  useEffect(() => {
    const unsubscribe = subcribe(PROXY_CLIENT_MESSAGE_TO_CLIENT, (message) => {
      toast(formatBFX(message.rawMessage));
    });

    return unsubscribe;
  }, [formatBFX, subcribe]);

  useEffect(() => {
    const unsubscribe = subcribe(INFO_SRV_CLIENT_REG_TOUR_OK, (message) => {
      if (message.reEntryLeft === undefined) {
        toast(
          t(TourDialogKeys.TOURNAMENT_REGISTRATION, {
            tournamentName: message.tourName,
          }),
        );
      } else if (message.reEntryLeft === 0) {
        toast(t(TourDialogKeys.LAST_RE_ENTRY));
      } else if (message.reEntryLeft === Number.POSITIVE_INFINITY) {
        toast(t(TourDialogKeys.UNLIMITED_RE_ENTRIES));
      } else {
        toast(
          t(TourDialogKeys.LIMITED_RE_ENTRIES, {
            amount: message.reEntryLeft,
          }),
        );
      }
    });

    return unsubscribe;
  }, [subcribe, t]);

  useEffect(() => {
    const cmaId = "CMA";

    if (notification && regulationType === UK_REGULATION_TYPE) {
      const description =
        notification.paymentType === "cash"
          ? t(NotificationToasterKeys.CASH_DESCRIPTION)
          : t(NotificationToasterKeys.BONUS_DESCRIPTION);

      if (toast.isActive(cmaId)) {
        toast.update(cmaId, { render: description });
      } else {
        toast(description, {
          toastId: cmaId,
          onClose: () => dispatch(clearUserNotification()),
          autoClose: false,
        });
      }
    } else {
      toast.dismiss(cmaId);
    }
  }, [dispatch, notification, regulationType, t]);

  return <StyledToastContainer {...defaultToastProps} />;
}
