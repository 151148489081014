import { select } from "redux-saga/effects";
import CommunicationLifecycleEvent from "../../../../../network/events/CommunicationLifecycleEvent";
import isGameSession from "../../../../../utils/isGameSession";
import { GameServerConnected } from "../../../actions";
import { getSessionsSelector } from "../../../selectors";

export default function* connectGame(action: GameServerConnected) {
  const games: ReturnType<typeof getSessionsSelector> =
    yield select(getSessionsSelector);
  const session = games[action.payload.gameId];

  if (session && isGameSession(session)) {
    session.gameServer.dispatchEvent(
      new CommunicationLifecycleEvent(CommunicationLifecycleEvent.CONNECTED),
    );
  }
}
