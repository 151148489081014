import { select } from "redux-saga/effects";
import { getConfigurationSelector, getUserSelector } from "../../../selectors";
import { FullConfiguration } from "../../../types/Configuration";
import User from "../../../types/User";
import type {
  ClickstreamData,
  EventHeaderName,
  EventHeaderTarget,
  EventHeaderType,
} from "./types";

export default function* sendEvent<D = Record<string, unknown>>(
  target: EventHeaderTarget,
  name: EventHeaderName,
  type: EventHeaderType[],
  eventData?: D,
) {
  const {
    cid,
    sessionId: playerSessionID,
    appSessionId,
    initialSessionId: globalSessionID,
  }: User = yield select(getUserSelector);

  const {
    productPackage,
    brand: { id: brandID },
  }: FullConfiguration = yield select(getConfigurationSelector);

  const data: ClickstreamData = {
    eventHeader: {
      target,
      eventType: {
        name,
        type,
      },
      sender: {
        versionNumber: String(process.env.REACT_APP_VERSION),
      },
      productPackageID: String(productPackage),
    },
    sessionData: {
      globalSessionID,
      playerSessionID,
    },
    userData: {
      cid,
      brandID,
    },
    eventData: {
      mode: "hosted",
      appSessionId,
      ...eventData,
    },
  };

  fetch(`${process.env.REACT_APP_CLICKSTREAM_URL}`, {
    method: "POST",
    body: JSON.stringify(data),
    // eslint-disable-next-line no-console
  }).catch(console.error);
}
