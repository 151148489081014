import { useEffect, useState } from "react";
import ProgressTurnData from "../../utils/types/ProgressTurnData";
import Styleable from "../../utils/types/Styleable";
import { ProgressBar, ProgressBarWrapper } from "./styles";

function getProgress(starts: number, ends: number) {
  const currentPos = Math.min(ends - Date.now(), ends - starts);
  return Math.max(currentPos / (ends - starts), 0);
}

export default function TurnProgressBar({
  kind,
  starts,
  ends,
  className,
  style,
}: Styleable<ProgressTurnData>) {
  const [progress, setProgress] = useState(getProgress(starts, ends));

  useEffect(() => {
    const timeout = window.setInterval(() => {
      setProgress(getProgress(starts, ends));
    }, 1000);

    return function cleanup() {
      clearInterval(timeout);
    };
  }, [starts, ends, setProgress]);

  return (
    <ProgressBarWrapper className={className} style={style}>
      <ProgressBar
        key={ends}
        $kind={kind}
        data-testid="poker-turn-progress-bar"
        style={{ transform: `translate(${-(1 - progress) * 100}%, 0)` }}
      />
    </ProgressBarWrapper>
  );
}
