import { select } from "redux-saga/effects";
import { GameCloseEvent } from "../../../../../network/events";
import isGameSession from "../../../../../utils/isGameSession";
import { GameCreated } from "../../../actions";
import getSessionsSelector from "../../../selectors/sessions/getSessions";

export default function* closeDuplicates(action: GameCreated) {
  const sessions: ReturnType<typeof getSessionsSelector> =
    yield select(getSessionsSelector);

  for (const sessionId in sessions) {
    const session = sessions[sessionId];

    if (
      isGameSession(session) &&
      session.gameType === "mtt" &&
      session.gameId !== action.payload.gameId &&
      session.gameInstanceId === action.payload.context.gameInstanceId
    ) {
      session.gameServer.dispatchEvent(new GameCloseEvent());
    }
  }
}
