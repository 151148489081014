import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { HamburgerMenuKeys } from "../../../i18n/keys";
import { CardsDisplay } from "../CardsIndicator/CardsIndicator";
import { IndicatorWrapper } from "../styles";
import { Plate } from "./styles";
import { SitOutIndicatorProps } from "./types";

export const SitOutIndicator = forwardRef<
  HTMLButtonElement,
  SitOutIndicatorProps
>(({ onClick, disabled, ...rest }, ref) => {
  const { t } = useTranslation();

  return (
    <IndicatorWrapper onClick={onClick} ref={ref} disabled={disabled}>
      <CardsDisplay {...rest} />
      <Plate>{t(HamburgerMenuKeys.SIT_OUT)}</Plate>
    </IndicatorWrapper>
  );
});
