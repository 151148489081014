import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  IMessageBroker,
  IPlayerSegmentationData,
} from "@sparkware/uc-sdk-core";
import createSagaMiddleware from "redux-saga";
import MainServer from "../../network/MainServer";
import { MainServerMessage } from "../../network/protocol/mainServer/types";
import * as reducers from "./reducers";
import { initialState } from "./reducers/user";
import { gamesManagerRunner } from "./sagas";
import clickstreamSaga from "./sagas/shared/clickstream/clickstream";
import externalCommunication from "./sagas/shared/externalCommunication";
import MessagesCommunication, {
  createMessagesChannel,
} from "./sagas/shared/messagesCommunication";
import rootSaga from "./sagas/shared/rootSaga";
import { FullConfiguration } from "./types/Configuration";

type StoreType = ReturnType<typeof initApplication>[0];
export type RootState = ReturnType<StoreType["getState"]>;

export default function initApplication(
  configuration: FullConfiguration,
  messageBroker: IMessageBroker,
  segmentation: IPlayerSegmentationData,
) {
  const sagaMiddleware = createSagaMiddleware();

  const rootReducer = combineReducers({
    ...reducers,
    configuration: () => configuration,
  });

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: {
      user: { ...initialState, segmentation },
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false, thunk: false }).concat(
        sagaMiddleware,
      ),
  });

  const mainServer = new MainServer();

  sagaMiddleware.run(rootSaga, mainServer);
  sagaMiddleware.run(clickstreamSaga);
  sagaMiddleware.run(externalCommunication, messageBroker);

  const connection: MessagesCommunication<MainServerMessage> = {
    send: mainServer.send,
    messages: createMessagesChannel(mainServer),
  };

  sagaMiddleware.run(gamesManagerRunner, connection, messageBroker);

  return [store, mainServer] as const;
}
