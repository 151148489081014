import { PropsWithChildren, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Styleable from "../../utils/types/Styleable";
import HamburgerMenuButton from "../HamburgerMenuButton";
import Portal from "../Portal";
import MenuPanel from "./MenuPanel";
import { Backdrop, TRANSITION_DURATION } from "./styles";
import { HamburgerMenuProps } from "./types";

export default function HamburgerMenu(
  props: PropsWithChildren<Styleable<HamburgerMenuProps>>,
) {
  const {
    opened,
    placeholder,
    buttonPlaceholder,
    onClick,
    children,
    className,
    style,
  } = props;
  const backdropRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Portal placeholder={buttonPlaceholder?.current}>
        <HamburgerMenuButton
          className={className}
          style={style}
          onClick={onClick}
        />
      </Portal>
      <TransitionGroup component={null}>
        {opened && (
          <CSSTransition
            nodeRef={backdropRef}
            timeout={TRANSITION_DURATION}
            classNames="menuBackdrop"
          >
            <Portal placeholder={placeholder.current}>
              <Backdrop
                ref={backdropRef}
                onClick={onClick}
                data-testid="poker-hamburger-menu-backdrop"
              />
            </Portal>
          </CSSTransition>
        )}

        {opened && (
          <MenuPanel
            testId="poker-hamburger-menu-panel"
            placeholder={placeholder}
          >
            {children}
          </MenuPanel>
        )}
      </TransitionGroup>
    </>
  );
}
