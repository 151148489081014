import {
  TourAttribute,
  TourSubType,
} from "../network/protocol/mainServer/types";
import { TourGameType } from "../store/game/types/Game";

export const getTournamentType = (
  attributes: TourAttribute,
  subType: TourSubType,
): TourGameType => {
  if (attributes & TourAttribute.SNAP) {
    return "snapTour";
  }

  if (subType & TourSubType.BLAST) {
    return "blast";
  }

  return "mtt";
};
