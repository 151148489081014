import { CSSProperties, PropsWithChildren } from "react";
import Styleable from "../../../utils/types/Styleable";
import { StyledContainer } from "./styles";
import { ContainerProps } from "./types";

export function Container(props: Styleable<PropsWithChildren<ContainerProps>>) {
  const {
    offsetX,
    offsetY,
    contentScale,
    sourceWidth,
    landscape,
    sourceHeight,
    style,
    className,
    children,
  } = props;

  const elementStyle: CSSProperties = {
    transform: `translate(${offsetX}px, ${
      landscape ? offsetY : 0
    }px) scale(${contentScale})`,
    width: sourceWidth,
    height: sourceHeight,
    top: landscape ? 0 : "unset",
    bottom: landscape ? "unset" : 0,
    alignItems: landscape ? "center" : "flex-end",
    transformOrigin: landscape ? "0% 0%" : "0% 100%",
  };

  return (
    <StyledContainer
      $landscape={landscape}
      className={className}
      style={{ ...elementStyle, ...style }}
    >
      {children}
    </StyledContainer>
  );
}
