import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import GenericErrorScreen from "../../components/GenericErrorScreen";
import Screen from "../../components/Screen/Screen";
import Spinner from "../../components/Spinner/Spinner";
import { ReconnectionErrorKeys } from "../../i18n/keys";
import { LOBBY_ROUTE } from "../../router";
import { resetReconnectionError } from "../../store/app/actions";
import {
  getReconnectionErrorSelector,
  isAuthenticatedSelector,
  isReconnectingSelector,
} from "../../store/app/selectors";

export default function PrivateRouteContainer({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const hasReconnectionError = useSelector(getReconnectionErrorSelector);
  const isReconnecting = useSelector(isReconnectingSelector);

  if (hasReconnectionError) {
    return (
      <GenericErrorScreen
        testId="poker-reconnection-error"
        text={t(ReconnectionErrorKeys.DESCRIPTION)}
      >
        <Button
          onClick={() => dispatch(resetReconnectionError())}
          testId="poker-go-to-lobby"
        >
          {t(ReconnectionErrorKeys.CLOSE)}
        </Button>
      </GenericErrorScreen>
    );
  }

  if (!isAuthenticated) {
    return <Navigate replace to={LOBBY_ROUTE} />;
  }

  if (isReconnecting) {
    return (
      <Screen>
        <Spinner />
      </Screen>
    );
  }

  return <>{children}</>;
}
