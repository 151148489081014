import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import color from "color";
import { CardPaper, getFillColor } from "../../FrontCard/styles";

export const CARD_VIEWBOX = {
  width: 21,
  height: 28,
};

export const StyledCardPaper = styled(CardPaper)<{ $active?: boolean }>`
  min-height: 10px;

  height: 100%;

  &:not(:first-child) {
    margin-left: -0.3em;

    box-shadow: ${({ $active }) =>
      `-0.0625em 0 0 ${$active ? "#ccc" : "#666"}`};
    border-radius: 0.1em;
  }

  ${({ $active, theme, $suit }) =>
    !$active &&
    css`
      color: ${theme.sessionIndicator.table.inactiveContent};

      fill: ${color(getFillColor($suit, theme)).darken(0.5).hex()};
    `}
`;
