const MultipliersColor = [
  {
    color: "#206AFF",
    hueRotation: "126deg",
  },
  {
    color: "#FF4E00",
    hueRotation: "-21deg",
  },
  {
    color: "#0DFF3D",
    hueRotation: "78deg",
  },
  {
    color: "#F7FF00",
    hueRotation: "0deg",
  },
  {
    color: "#00E5FF",
    hueRotation: "105deg",
  },
  {
    color: "#FF0000",
    hueRotation: "-45deg",
  },
  {
    color: "#FF04F7",
    hueRotation: "-117deg",
  },
];

export default {
  multipliersColor: MultipliersColor,
  fontSize: {
    xxxsSmall: "6px",
    xxsSmall: "8px",
    xsSmall: "10px",
    small: "11px",
    medium: "12px",
    big: "13px",
    extraBig: "16px",
    large: "29px",
  },
  fontFamily: {
    MainFontFamily: '"Roboto-Bold", Tahoma',
  },
} as const;
