import { keyframes } from "@emotion/react";
import styled from "@emotion/styled/macro";

const PendingSpinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const MoveKeyFrame = keyframes`
 0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(84px);
  }

  100% {
    transform: translateX(112px);
  }
`;

const MoveKeyFrame2 = keyframes`
  0%, 10% {
    transform: translateX(-5.6px);
  }

  50%, 60% {
    transform: translateX(72.8px);
  }

  100% {
    transform: translateX(112px);
  }
`;

const MoveKeyFrame3 = keyframes`
  0%, 15% {
    transform: translateX(-11.2px);
  }

  50%, 65% {
    transform: translateX(61.6px);
  }

  100% {
    transform: translateX(112px);
  }
`;

const MoveKeyFrame4 = keyframes`
  0%, 20% {
    transform: translateX(-16.8px);
  }

  50%, 70% {
    transform: translateX(50.4px);
  }

  100% {
    transform: translateX(112px);
  }
`;

const MoveKeyFrame5 = keyframes`
  0%, 25% {
    transform: translateX(-22.4px);
  }

  50%, 75% {
    transform: translateX(39.2px);
  }

  100% {
    transform: translateX(112px);
  }
`;

const MoveKeyFrame6 = keyframes`
  0%, 30% {
    transform: translateX(-28px);
  }

  50%, 80% {
    transform: translateX(28px);
  }

  100% {
    transform: translateX(112px);
  }
`;

const Dot = styled.div`
  position: absolute;
  top: 50%;
  left: 0;

  width: 6px;
  height: 6px;
  margin: 0 5px;
  border-radius: 50%;

  background-color: white;
  transform: translateY(-50%);

  animation-duration: 1.5s;
  animation-delay: -1s;
  animation-iteration-count: infinite;

  &:nth-child(1) {
    animation-name: ${MoveKeyFrame};
  }

  &:nth-child(2) {
    animation-name: ${MoveKeyFrame2};
  }

  &:nth-child(3) {
    animation-name: ${MoveKeyFrame3};
  }

  &:nth-child(4) {
    animation-name: ${MoveKeyFrame4};
  }

  &:nth-child(5) {
    animation-name: ${MoveKeyFrame5};
  }

  &:nth-child(6) {
    animation-name: ${MoveKeyFrame6};
  }
`;

export { Dot, PendingSpinner };
