const currencySymbols: Record<string, string> = {
  USD: "$",
  EUR: "€",
};

export default function getSupportedCurrencySymbol(
  currency: string,
): string | undefined {
  return currencySymbols[currency];
}
