import { select } from "redux-saga/effects";
import { performShowCards } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { getHeroCardsMaskSelector } from "../../selectors";

export default function* showCards(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const mask: number = yield select(getHeroCardsMaskSelector);
  connection.send(performShowCards(mask));
}
