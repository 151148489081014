import isTournamentState from "../../../../utils/isTournamentState";
import { AnyGameState } from "../../store";
import getHero from "../hero/getHero";
import { getTournamentSelector } from "../tournament";
import { getHeroFocusableTurnSelector } from "../turn";

export default function getFocusEvent(state: AnyGameState) {
  const heroPlayer = getHero(state);
  const turn = getHeroFocusableTurnSelector(state);

  if (turn) {
    return turn;
  } else if (isTournamentState(state)) {
    const { ftdNegotiationData } = getTournamentSelector(state);

    return heroPlayer?.deal === "pending" ? ftdNegotiationData : undefined;
  }

  return undefined;
}
