import { put, take } from "redux-saga/effects";
import {
  INFO_SRV_CLIENT_START_TOUR,
  startTornamentAcknowledge,
} from "../../../../network/protocol/mainServer";
import {
  MainServerMessage,
  StartTournamentData,
} from "../../../../network/protocol/mainServer/types";
import { getTournamentType } from "../../../../utils/getTournamentType";
import { isTourSupported } from "../../../../utils/supportedTournament";
import { tournamentStarted } from "../../actions";
import MessagesCommunication from "../shared/messagesCommunication";

export default function* startTournament(
  connection: MessagesCommunication<MainServerMessage>,
) {
  while (true) {
    const tourData: StartTournamentData = yield take(
      connection.messages,
      INFO_SRV_CLIENT_START_TOUR,
    );
    connection.send(
      startTornamentAcknowledge(
        tourData.tournamentId,
        tourData.serverId,
        tourData.tabId,
      ),
    );

    const attributes = tourData.attributesMask;
    const subType = tourData.tourSubTypeMask;

    if (isTourSupported(attributes, subType)) {
      yield put(
        tournamentStarted({
          ...tourData,
          gameInstanceId: tourData.tournamentId,
          gameType: getTournamentType(attributes, subType),
        }),
      );
    }
  }
}
