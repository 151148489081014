import { BreakType } from "../../store/game/types/Break";

export interface TournamentDataResponse {
  TourID: number;
  LastTimeStamp: number;
  TourStatesRtData: TournamentStateRtData | null;
  TourDefStaticData: TournamentStaticData | null;
  TourRunningStatesRtData?: TourRunningStatesData | null;
  PrizePoolPayingRtData: IPrizePoolPayingRtData[] | null;
  PlayersRtData?: PlayersRtData[];
}

interface TournamentStateRtData {
  ActivePlayersCount: number;
  ActualKOPrizePoolAmount: number;
  CompletedTourRtData: {
    MoneyPrizeTourWinnerData: MoneyPrizeTour | null;
    EqualPrizeTourWinnerData: EqualPrizeTour | null;
  };
  IsTourInLateRegState: boolean;
  MultiflightDay1AddonsCount: number;
  MultiflightDay1ReEntryTotalCount: number;
  MultiflightDay1RebuysCount: number;
  PlayersCount: number;
  QualifiedForDay2PlayersCount: number;
  RebuyAddonRTStatData: RebuyAddonRTStatData | null;
  ServerID: number;
  StartingTime: number;
  TotalActualPrizePoolAmount: number;
  TourStatus: number;
}

interface TourRunningStatesData {
  IsTourInLateRegState: boolean;
  ReEntryTotalCount: number;
  CurrentUpLevelData: CurrentUpLevelData;
  NextUpLevelData: NextUpLevelData;
  RebuyAddonRTStatData: RebuyAddonRTStatData | null;
  BreakRTData: BreakRTData | null;
  BlastRtData: BlastRtData | null;
}

interface CurrentUpLevelData {
  UpLevelValue: number;
  LevelAbsoluteStartTime: number;
  IsLastLevel: boolean;
  SB: number;
  BB: number;
  Ante: number;
}

interface NextUpLevelData {
  UpLevelValue: number;
  LevelAbsoluteStartTime: number;
  IsLastLevel: boolean;
  SB: number;
  BB: number;
  Ante: number;
}

interface RebuyAddonRTStatData {
  RebuysCount: number;
  AddonsCount: number;
}

interface BreakRTData {
  InCurrentBreakInfo: InCurrentBreakInfo | null;
  SyncBreakInfo: SyncBreakInfo | null;
}

interface SyncBreakInfo {
  NextSyncBreakAbsoluteTime: number;
}

interface InCurrentBreakInfo {
  CurrentBreakAbsoluteEndTime: number;
  CurrentBreakAbsoluteStartTime: number;
  CurrentBreakType: number;
}
interface BlastRtData {
  MultiplierID: number;
  MultiplierValue: number;
}
interface PlayerRtData {
  TourPos: number;
  TableID: number;
  Credit: number;
  Status: number;
  RebuysCount: number;
  TotalReEntries: number;
  IsAgreeToFTD: boolean;
  PrizePoolWinningAmount: number;
  PlayerKOData: null;
}

export interface PlayersRtData {
  CID: number;
  PlayerName: string;
  CountryID: number;
  PlayerRtData: PlayerRtData;
  IndexPos: number;
  ReEntryNumber: number;
}

export enum PrizeTypeId {
  MONEY = 1,
  ENTRY = 2,
  TICKET = 4,
}

export interface IPrizePoolPayingRtData {
  From: number;
  To: number;
  PrizeAmount: number;
  PrizePercent: number;
  PrizeTypeID: PrizeTypeId;
  PrizeStructureType: number;
  PrizeDescription: string;
}

export interface TournamentServerResponse {
  LobbyTournaments: TournamentsUpdateData | null;
  LastTimeStamp: number;
  RemovedData: number[] | null;
}

export interface SatelliteData {
  TourID: number;
  LastTimeStamp: number;
  TourSatellites: TourSatellitesData | null;
  RemovedData: number[] | null;
}

export interface SatelliteInfo {
  TourSatellitesStaticData: TourSatellitesStaticData;
  TourSatellitesStatesRtData: TourSatellitesStatesRtData;
  TourPrizePoolPayingRtData: TourPrizePoolPayingRtData;
}

interface TourSatellitesStaticData {
  TourID: number;
  TourName: string;
  TourType: number;
  MinPlayers: number;
  MaxPlayers: number;
  BuyInAmount: number;
  PrizePoolAmount: number;
  RegOpenTime: number;
  SeatingTime: number;
  CloseRegTime: number;
  RestrictedTime: number;
  PlayerRatioPtyID: number;
  TicketTypeID: number;
  KOFee: number;
  TourIconsMask: number;
  TourAttributeMask: number;
  TourSubtypeMask: number;
  SecForSeated: number;
  LateRegDuration: number;
  MaxPlayersPerTable: number;
  GameType: number;
  LimitType: number;
}

interface TourSatellitesStatesRtData {
  TourStatus: number;
  IsInLateRegState: boolean;
  PlayersCount: number;
  ActualTourPrizePoolAmount: number;
  ActivePlayersCount: number;
}

interface TourPrizePoolPayingRtData {
  LUTime: number;
  LULocalMsgDateTime: string;
  PaidPlayersCount: number;
  PrizePoolPayingRanges: PrizePoolPayingRanges[];
}

interface PrizePoolPayingRanges {
  From: number;
  To: number;
  PrizeAmount: number;
  PrizePercent: number;
  PrizeTypeID: number;
  PrizeStructureType: number;
  PrizeDescription: string;
}

export type TournamentsUpdateData = Record<number, TournamentPartialInfo>;
export type TournamentsData = Record<number, TournamentInfo>;
export type TourSatellitesData = Record<number, SatelliteInfo>;

export enum TourStatus {
  DEFINED = 1,
  ANNOUNCED,
  RESTRICTED,
  START_REGISTRATION,
  CLOSE_REGISTRATION,
  SEATING,
  RUNNING,
  COMPLETED,
  ARCHIVE,
  PRE_CANCELLED_VISIBLE,
  PRE_CANCELLED_UNVISIBLE,
  CANCELLED_VISIBLE,
  CANCELLED_INVISIBLE,
  ARCHIVE_CANCELLED,
}

export enum LobbyTourStatus {
  ANNOUNCED = 10,
  REGISTRATION_OPEN = 20,
  REGISTRATION_CLOSE = 30,
  LATE_REGISTRATION = 40,
  RUNNING = 50,
  COMPLETED = 60,
  CANCELLED_VISIBLE = 70,
  CANCELLED_INVISIBLE = 80,
  REMOVED = 100,
}

export enum TourTypes {
  REGULAR,
  SNAP,
  SHORT_HANDED,
  KNOCK_OUT,
  PROGRESSIVE_KNOCK_OUT,
  REBUY,
  ADD_ON,
}

export enum TourTypeIcons {
  TOUR_ICON_DEEP_STACK = 1,
  TOUR_ICON_SHORT_HANDED = 1 << 1,
  TOUR_ICON_REBUY_AND_ADDON = 1 << 2,
  TOUR_ICON_HEADS_UP = 1 << 3,
  TOUR_ICON_GUARANTEED = 1 << 4,
  TOUR_ICON_DOUBLE_OR_NOTHING = 1 << 5,
  TOUR_ICON_ALL_IN = 1 << 6,
  TOUR_ICON_TURBO = 1 << 7,
  TOUR_ICON_PRIVATE_TOUR = 1 << 8,
  TOUR_ICON_TEAMS_TOUR = 1 << 9,
  TOUR_ICON_KNOCK_OUT = 1 << 10,
  TOUR_ICON_BOUNTY = 1 << 11,
  TOUR_ICON_VIDEO = 1 << 12,
  TOUR_ICON_ACCEPTS_TICKETS = 1 << 13,
  TOUR_ICON_SHOW_ME = 1 << 14,
  TOUR_ICON_SUPER_TURBO = 1 << 15,
  TOUR_ICON_SNAP = 1 << 16,
  TOUR_ICON_BLAST = 1 << 17,
  TOUR_ICON_2ND_CHANCE = 1 << 18,
  TOUR_ICON_PROGRESSIVE_KNOCKOUT = 1 << 19,
  TOUR_ICON_MULTIDAY = 1 << 20,
  TOUR_ICON_MULTIFLIGHT = 1 << 21,
  TOUR_ICON_BROADCAST = 1 << 22,
  TOUR_ICON_MYSTERY_BOUNTY = 1 << 23,
}

export interface TournamentPartialInfo {
  TourDefStaticData: TournamentStaticData | null;
  TourStatesRtData: TournamentStateData | null;
}

export interface TournamentInfo {
  TourDefStaticData: TournamentStaticData;
  TourStatesRtData: TournamentStateData;
}

export interface TournamentStaticData {
  LUTime: number;
  TourID: number;
  TourName: string;
  TourType: TourTypes;
  IsSticky: boolean;
  IsBroadcast: boolean;
  GameType: number;
  LimitType: number;
  MinPlayers: number;
  MaxPlayers: number;
  MaxPlayersPerTable: number;
  GuaranteedPrizePoolAmount: number;
  BuyInAmount: number;
  PrizePoolAmount: number;
  BuyinRange: number;
  ChipsInitAmount: number;
  UpLevelTemplateID: number;
  UpLevelTimePeriodInSec: number;
  TourIconsMask: number;
  TourAttributeMask: number;
  TourSubtypeMask: number;
  RebuyData: {
    RebuyDurationInSec: number;
    RebuyInAmount: number;
    RebuyPrizePoolAmount: number;
    RebuyInChipsAmount: number;
    RebuyInsAllowed: number;
    MaxEligibleChipStack: number;
  };
  AnnouncedTime: number;
  RestrictedTime: number;
  RegOpenTime: number;
  SeatingTime: number;
  CloseRegTime: number;
  CloseTourTime?: number;
  ShowCompletedTime: number;
  SecForSeated: number;
  LateRegDuration: number;
  RestrictionFlag: number;
  RowColorInx: number;
  TextFormat: number;
  TicketTypeID: number;
  TimeInSecBeforeStartSyncBreak: number;
  TranslatedNewsData?: string;
  RecessTimeDefInSec: number;
  RecessIntervalDefInSec: number;
  ReEntryAllowedAmount: number;
  TimeBankData: {
    TimeBankInSec: number;
    TimeBankRefreshRate: number;
  };
  PrizePoolData: {
    MoneyPrizePoolData: {
      PlayerPrizePoolID: number;
      PlayerMoneyTypePrizePoolID: number;
      PlayerPrizePoolPercent: number;
    };
    RatioPrizePoolData: RatioPrizePoolData | null;
  };
  KnockOutData?: {
    KOFee: number;
    RebuyKOFee: number;
  };
  TabIDs: [number];
  BlastData?: {
    BuyInID: number;
  };
  PhasesConfigData: PhasesConfigData;
}

interface PhasesConfigData {
  UntilEndOfLateReg: {
    BlindLevelDuration: number;
  };
  UntilInTheMoney: {
    BlindLevelDuration: number;
  };
  UntilFinalTable: {
    BlindLevelDuration: number;
  };
  UntilLastHand: {
    BlindLevelDuration: number;
  };
}

interface MoneyPrizeTour {
  WinnerPlayerName: string;
  FirstPlaceWinningAmount: number;
  FirstPlaceKoWinningAmount: number;
}

interface EqualPrizeTour {
  WinnerPlayersCount: number;
}

export interface BreakData {
  SyncBreakInfo: {
    NextSyncBreakAbsoluteTime: number;
  };
  InCurrentBreakInfo: {
    CurrentBreakType: BreakType;
    CurrentBreakAbsoluteStartTime: number;
    CurrentBreakAbsoluteEndTime: number;
  } | null;
}

export interface TournamentStateData {
  TourStatus: TourStatus;
  IsTourInLateRegState: boolean;
  PlayersCount: number;
  ActivePlayersCount: number;
  ActualKOPrizePoolAmount: number;
  ActualTourPrizePoolAmount: number;
  CompletedTourRtData: {
    MoneyPrizeTourWinnerData: MoneyPrizeTour | null;
    EqualPrizeTourWinnerData: EqualPrizeTour | null;
  };
  LobbyTourStatus: LobbyTourStatus;
  FinalTableStartTime: number;
  BreakData?: BreakData;
}

interface RatioPrizePoolData {
  PlayerRatioPtyID: number;
  PlayerRatioPtyAmount: number;
  RatioPtyName: string;
  SatPrizeTourID: number;
  SatPrizeTourName: string;
  SatPrizeTourGTD: number;
  TicketTypeID: number;
  PromotionID: number;
}
