import { useContext, useLayoutEffect, useRef } from "react";
import { v4 as uuid } from "uuid";
import ModalsContext from "../context/ModalsContext";
import { ModalConfig } from "../context/ModalsContext/types";

type Setter<T> = (value: boolean, data?: T) => void;

export default function useModalState<D = undefined>(
  initial: boolean,
  autoclose = false,
) {
  const modalsContext = useContext(ModalsContext);

  if (!modalsContext) {
    throw new Error(
      "useModalState must be inside an ModalsContext with a value",
    );
  }

  const { modals, add, remove } = modalsContext;
  const idRef = useRef(uuid());

  const visible = modals.length > 0 && modals[0].id === idRef.current;
  const config = modals.find(
    (modal): modal is ModalConfig<D> => modal.id === idRef.current,
  );
  const { current: setOpened } = useRef<Setter<D>>((value, data) => {
    if (value) {
      add({ id: idRef.current, autoclose, data });
    } else {
      remove(idRef.current);
    }
  });

  useLayoutEffect(() => {
    setOpened(initial);

    return () => setOpened(false);
  }, [initial, setOpened]);

  return { visible, opened: Boolean(config), setOpened, data: config?.data };
}
