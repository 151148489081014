import { useCallback } from "react";
import useAppConfig from "./useAppConfig";

export default function useNumberFormat() {
  const { locale } = useAppConfig();
  return useCallback(
    (rawValue: number) => {
      if (isNaN(rawValue)) return "";

      const value = Math.max(0, rawValue) / 100; //Math.max converts negative values to 0 and invalid values to NaN
      const options: Intl.NumberFormatOptions = {
        style: "decimal",
      };

      if (!Number.isInteger(value)) {
        options.maximumFractionDigits = 2;
      }

      return new Intl.NumberFormat(locale, options).format(value);
    },
    [locale],
  );
}
