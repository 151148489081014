import { select } from "redux-saga/effects";
import CommunicationLifecycleEvent from "../../../../../network/events/CommunicationLifecycleEvent";
import isGameSession from "../../../../../utils/isGameSession";
import { getSessionsSelector } from "../../../selectors";

export default function* disconnectGames() {
  const sessions: ReturnType<typeof getSessionsSelector> =
    yield select(getSessionsSelector);

  for (const sessionId in sessions) {
    const session = sessions[sessionId];

    if (isGameSession(session)) {
      session.gameServer.dispatchEvent(
        new CommunicationLifecycleEvent(
          CommunicationLifecycleEvent.DISCONNECTED,
        ),
      );
    }
  }
}
