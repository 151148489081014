import { call, put } from "redux-saga/effects";
import { BlastPoolInfo, getBlastInfo } from "../../../../services";
import { getMultiplierData } from "../../../../utils/getMultiplierData";
import { PrizePoolData } from "../../../../utils/types/PrizePoolData";
import { TourTableJoined, prizePoolReceived } from "../../actions";

export default function* getPrizePool(action: TourTableJoined) {
  try {
    const blastPoolInfo: BlastPoolInfo = yield call(
      getBlastInfo,
      action.payload.blastBuyInId,
    );
    const { BlastMultipliers } = blastPoolInfo;
    const prizePoolData: PrizePoolData[] = [];

    Object.keys(BlastMultipliers).forEach((item: string) => {
      prizePoolData.push({
        id: parseInt(item),
        value:
          BlastMultipliers[parseInt(item)].MultiplierValue *
          action.payload.buyIn,
      });
    });
    yield put(prizePoolReceived(getMultiplierData(prizePoolData)));
  } catch {
    //ignore it
  }
}
