import { takeEvery } from "redux-saga/effects";
import MainServer from "../../../../network/MainServer";
import {
  closeApp as closeAppAction,
  userKickedFromServer,
} from "../../actions";
import closeApp from "./closeApp";

export default function* closeStandaloneAppFlow(mainServer: MainServer) {
  yield takeEvery(
    [closeAppAction.type, userKickedFromServer.type],
    closeApp,
    mainServer,
    false,
  );
}
