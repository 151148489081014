import Hero from "../types/Hero";

const initialState: Hero = {
  seatId: NaN,
  name: "",
  playerInstanceId: 0,
  mask: 0,
  sessionId: NaN,
  sessionLimit: -1,
  initialSessionLimit: -1,
  autoPenaltyPayment: false,
  viewer: true,
};

export default initialState;
