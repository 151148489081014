import { createReducer } from "@reduxjs/toolkit";
import {
  connect,
  connected,
  connectionError,
  disconnected,
  joinError,
  joined,
} from "../actions";
import Connection, { ConnectionStatus } from "../types/Connection";

const initialState: Connection = {
  connectionState: ConnectionStatus.IDLE,
  joinState: ConnectionStatus.IDLE,
};

export const connection = createReducer<Connection>(initialState, (builder) => {
  builder
    .addCase(connect, () => initialState)
    .addCase(connected, (state) => {
      state.connectionState = ConnectionStatus.SUCCESS;
    })
    .addCase(connectionError, (state, { payload }) => {
      state.connectionState = ConnectionStatus.ERROR;
      state.connectionError = payload;
    })
    .addCase(joined, (state) => {
      state.joinState = ConnectionStatus.SUCCESS;
    })
    .addCase(joinError, (state) => {
      state.joinState = ConnectionStatus.ERROR;
    })
    .addCase(disconnected, (state, { payload }) => {
      if (payload instanceof Error) {
        state.connectionState = ConnectionStatus.ERROR;
        state.connectionError = payload;
      } else {
        state.connectionState = ConnectionStatus.IDLE;
      }
    });
});
