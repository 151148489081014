import { select } from "redux-saga/effects";
import { sitInTour } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { getHeroSelector } from "../../selectors";
import Player from "../../types/Player";

export default function* sitInToTour(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const hero: Player | undefined = yield select(getHeroSelector);
  connection.send(sitInTour(hero!.seatId));
}
