import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HamburgerMenu, { MenuItem } from "../../components/HamburgerMenu";
import {
  AddChipsIcon,
  CashierIcon,
  LeaveGameIcon,
  SettingsIcon,
  SitInIcon,
  SitOutIcon,
} from "../../components/HamburgerMenu/Icons";
import { ExitIcon } from "../../components/HamburgerMenu/Icons/styles";
import { PersonalAvatarOwnerContext } from "../../context";
import useAvatarSelection from "../../hooks/useAvatarSelection";
import useCloseConfirmation from "../../hooks/useCloseConfirmation";
import useGameModalState from "../../hooks/useGameModalState";
import useNavBar from "../../hooks/useNavBar";
import useRingGameBuyIn from "../../hooks/useRingGameBuyIn";
import useSettings from "../../hooks/useSettings";
import { HamburgerMenuKeys } from "../../i18n/keys";
import { joinNow, sitIn, sitOut, willSitOut } from "../../store/game/actions";
import {
  getHeroSelector,
  getHeroWillSitOutSelector,
  getIsWaitingForEnterSelector,
  getRebuyPendingSelector,
  getTableSelector,
} from "../../store/game/selectors";
import Styleable from "../../utils/types/Styleable";
import getSettingsMenu from "./getSettingsMenu";
import { GameHamburgerMenuContainerProps } from "./types";

export function RingGameHamburgerMenu({
  menuPanelRef,
  onOpenCashier,
  onBackToLobby,
  className,
  style,
}: Styleable<GameHamburgerMenuContainerProps>) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSettings();
  const isPersonalAvatarOwner = useContext(PersonalAvatarOwnerContext);
  const hamburgerButtonRef = useNavBar()?.hamburgerButtonRef;
  const { setOpened: closeGame } = useCloseConfirmation();
  const hero = useSelector(getHeroSelector);
  const isWaitingForEnter = useSelector(getIsWaitingForEnterSelector);
  const heroWillSitOut = useSelector(getHeroWillSitOutSelector);
  const rebuyPending = useSelector(getRebuyPendingSelector);
  const { bigBlind } = useSelector(getTableSelector);
  const { winners } = useSelector(getTableSelector);
  const { setShowAvatarSelectionPopup } = useAvatarSelection();
  const { opened, setOpened } = useGameModalState(false, true);
  const { setShowBuyIn } = useRingGameBuyIn();

  return (
    <HamburgerMenu
      buttonPlaceholder={hamburgerButtonRef}
      placeholder={menuPanelRef}
      opened={opened}
      onClick={() => setOpened(!opened)}
      className={className}
      style={style}
    >
      {hero &&
        (hero.sittingOut || isWaitingForEnter ? (
          <MenuItem
            testId="poker-hamburger-button-sit-in"
            onClick={() => {
              if (!hero.sittingOut) {
                dispatch(willSitOut());
                return;
              }
              if (isWaitingForEnter) {
                dispatch(joinNow());
              } else {
                Number(hero.credit) < bigBlind
                  ? setShowBuyIn(true)
                  : dispatch(sitIn());
              }
            }}
            startIcon={<SitInIcon />}
          >
            {t(HamburgerMenuKeys.IM_BACK)}
          </MenuItem>
        ) : (
          <MenuItem
            testId="poker-hamburger-button-sit-out"
            active={heroWillSitOut}
            onClick={() => {
              dispatch(hero.cards && !winners ? willSitOut() : sitOut());
            }}
            startIcon={<SitOutIcon $active={heroWillSitOut} />}
          >
            {t(HamburgerMenuKeys.SIT_OUT)}
          </MenuItem>
        ))}
      <MenuItem
        testId="poker-hamburger-button-close-app"
        onClick={() => closeGame(true)}
        startIcon={<ExitIcon />}
      >
        {t(HamburgerMenuKeys.LEAVE_GAME)}
      </MenuItem>
      <MenuItem
        testId="poker-hamburger-button-rebuy"
        onClick={() => setShowBuyIn(true)}
        startIcon={<AddChipsIcon />}
        disabled={rebuyPending}
      >
        {t(HamburgerMenuKeys.ADD_CHIPS)}
      </MenuItem>
      <MenuItem
        testId="poker-hamburger-button-settings"
        startIcon={<SettingsIcon />}
        subMenu={getSettingsMenu(
          settings,
          t,
          isPersonalAvatarOwner
            ? undefined
            : () => setShowAvatarSelectionPopup(true),
        )}
      >
        {t(HamburgerMenuKeys.SETTINGS)}
      </MenuItem>
      <MenuItem
        testId="poker-hamburger-button-open-cashier"
        onClick={onOpenCashier}
        startIcon={<CashierIcon />}
      >
        {t(HamburgerMenuKeys.CASHIER)}
      </MenuItem>
      <MenuItem
        testId="poker-hamburger-button-back-to-lobby"
        onClick={onBackToLobby}
        startIcon={<LeaveGameIcon />}
      >
        {t(HamburgerMenuKeys.BACK_TO_LOBBY)}
      </MenuItem>
    </HamburgerMenu>
  );
}
