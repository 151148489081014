import { useContext } from "react";
import { OrientationContext } from "../context";

export type Orientation = { $isLandscape: boolean };

export default function useScreenOrientation() {
  const contextValue = useContext(OrientationContext);

  if (!contextValue) {
    throw new Error(
      "useScreenOrientation should be executed inside OrientationContext",
    );
  }

  return contextValue;
}
