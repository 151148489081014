export default function parseHandHistoryDate(source: string) {
  const [dateString, timeString] = source.split(" ");
  const [dayString, monthString, yearString] = dateString.split("/");
  const [hoursString, minutesString, secondsString] = timeString.split(":");

  return Date.UTC(
    Number.parseInt(yearString),
    Number.parseInt(monthString) - 1,
    Number.parseInt(dayString),
    Number.parseInt(hoursString),
    Number.parseInt(minutesString),
    Number.parseInt(secondsString),
  );
}
