import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const POPUP_TRANSITION = 100;

export const PopupWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 12;

  pointer-events: none;

  .popup-enter {
    opacity: 0;
  }

  .popup-enter-active {
    opacity: 1;

    transition: opacity ${POPUP_TRANSITION}ms;
  }

  .popup-exit {
    opacity: 1;
  }

  .popup-exit-active {
    opacity: 0;

    transition: opacity ${POPUP_TRANSITION}ms;
  }
`;

export const PopupOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    background-color: ${theme.popup.overlay.backgroundColor}60;
  `}

  pointer-events: auto;
`;

export const PopupContent = styled.div`
  position: relative;
`;

export const ButtonLayout = styled.div`
  position: absolute;
  top: -7px;
  right: -7px;
`;

export const minSizeConstraints = css`
  min-width: 100px;
  min-height: 100px;
`;
