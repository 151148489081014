import styled from "@emotion/styled/macro";

export const SubPanelLayout = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.hamburgerMenu.menuColor};

  > * {
    min-height: 5em;
  }
`;
