import { call, select } from "redux-saga/effects";
import { getUserSelector } from "../../../selectors";
import User from "../../../types/User";
import sendEvent from "./sendEvent";

export default function* sendCmaEvent() {
  const { notification }: User = yield select(getUserSelector);

  if (notification && notification.type === "CMA") {
    const { withdrawnRestrictedAmount, withdrawnRealAmount, paymentType } =
      notification;

    const eventData = { withdrawnRestrictedAmount, withdrawnRealAmount };

    const eventName =
      paymentType === "bonus" ? "Moved to Bonus money" : "Moved to Cash money";

    yield call(sendEvent, "cmamonitoring", eventName, ["Log"], eventData);
  }
}
