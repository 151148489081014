import {
  Circle,
  CircleWrapper,
  Container,
  PokerText,
  SpinnerWrapper,
} from "./styles";

const Spinner = () => (
  <SpinnerWrapper data-testid="poker-spinner-animation">
    <Container>
      <CircleWrapper>
        <Circle />
        <Circle />
        <Circle />
        <Circle />
        <Circle />
        <Circle />
      </CircleWrapper>
      <PokerText>poker</PokerText>
    </Container>
  </SpinnerWrapper>
);

export default Spinner;
