import { QuickPosErrorId } from "./types/QuickPosErrorId";

interface QuickPosMessage {
  TableId: string;
  ErrorId: string;
  Pos: string;
  RatHolingErrorID: string;
  RatHolingMilliSecondsLeft: string;
  RatHolingMoneyUponExiting: string;
}

export default function parseQuickPosMessage(message: string) {
  const data: QuickPosMessage = JSON.parse(message);

  return {
    tableId: data.TableId,
    seatId: Number.parseInt(data.Pos),
    errorCode: Number.parseInt(data.ErrorId) as QuickPosErrorId,
    ratHolingErrorID: Number.parseInt(data.RatHolingErrorID),
    ratHolingMilliSecondsLeft: Number.parseInt(data.RatHolingMilliSecondsLeft),
    ratHolingMoneyUponExiting: Number.parseInt(data.RatHolingMoneyUponExiting),
  } as const;
}
