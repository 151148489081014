import { UnknownAction } from "@reduxjs/toolkit";
import { Dispatch, Reducer, useMemo } from "react";
import useLocalStorage from "./useLocalStorage";

export default function useLocalStorageReducer<S, A extends UnknownAction>(
  reducer: Reducer<S, A>,
  initialValue: S,
  key?: string,
) {
  const [storedValue, setStoredValue] = useLocalStorage<S>(initialValue, key);

  const dispatch: Dispatch<A> = useMemo(
    () => (action: A) => {
      const valueToStore: S = reducer(storedValue, action);

      setStoredValue(valueToStore);
    },
    [reducer, setStoredValue, storedValue],
  );

  return [storedValue, dispatch] as const;
}
