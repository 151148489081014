import { createAction } from "@reduxjs/toolkit";
import { IPlayerSegmentationData } from "@sparkware/uc-sdk-core";
import CommunicationError from "../../../network/CommunicationError";
import {
  ConnectGameServerError,
  JoinAppSuccess,
  RegisterTourError,
  UnregisterTourError,
} from "../../../network/protocol/mainServer/types";
import AuthenticationData from "../../../network/protocol/mainServer/types/AuthenticationData";
import { PlayerAttributesData } from "../../../network/protocol/mainServer/types/PlayerAttributesData";
import ReconnectionData from "../../../network/protocol/mainServer/types/ReconnectionData";
import { BonusData } from "../../game/types/Game";
import { KickToLobby } from "../types/User";
import {
  AccountAttributesError,
  AuthenticateData,
  AuthenticationErrorData,
  BankrollUpdate,
  BlastPoolData,
  BlastRegistrationRestoredData,
  BlastRegistrationSuccessData,
  GameBuyInEventData,
  GameClosed,
  GameCreated,
  GameEventData,
  GameServerConnectionParams,
  GameServerReconnectionParams,
  GameStateChange,
  JoinErrorData,
  PlayerFinishedTourData,
  ReconnectErrorData,
  RingGameData,
  TourEndOrCancellationData,
  TourRegistrationSuccessData,
  TourUnregisterSuccessData,
  TournamentStartedData,
  WithdrawFunds,
} from "./types";

export const connect = createAction("CONNECT");

export const connected = createAction("CONNECTED");

export const connectionError = createAction<Error>("CONNECTION_ERROR");

export const disconnected = createAction<CommunicationError | null>(
  "DISCONNECTED",
);

export const joined = createAction<JoinAppSuccess["configuration"]>("JOINED");

export const joinError = createAction<JoinErrorData>("JOIN_ERROR");

export const authenticate = createAction<AuthenticateData>("AUTHENTICATE");

export const authenticated = createAction<AuthenticationData>("AUTHENTICATED");

export const authenticationError = createAction<AuthenticationErrorData>(
  "AUTHENTICATION_ERROR",
);

export const authenticationRequired = createAction("AUTHENTICATION_REQUIRED");

export const openPokerTournament = createAction<{ tourId?: number }>(
  "OPEN_POKER_TOURNAMENT",
);

export const reconnect = createAction("RECONNECT");

export const reconnectError =
  createAction<ReconnectErrorData>("RECONNECT_ERROR");

export const appReconnected = createAction<ReconnectionData>("APP_RECONNECTED");

export const resetReconnectionError = createAction("RESET_RECONNECTION_ERROR");

export const blastPoolData = createAction<BlastPoolData>("BLAST_POOL_DATA");

export const ringGameData = createAction<RingGameData>("RING_GAME_DATA");

export const blastRegistrationRestored =
  createAction<BlastRegistrationRestoredData>("BLAST_REGISTRATION_RESTORED");

export const blastRegistrationSuccess =
  createAction<BlastRegistrationSuccessData>("BLAST_REGISTRATION_SUCCESS");

export const tourRegistrationRestored = createAction<{ tourId: number }>(
  "TOUR_REGISTRATION_RESTORED",
);

export const tourRegistrationSuccess =
  createAction<TourRegistrationSuccessData>("TOUR_REGISTRATION_SUCCESS");

export const tourRegistrationError = createAction<RegisterTourError>(
  "TOUR_REGISTRATION_ERROR",
);

export const tourUnregistrationSuccess =
  createAction<TourUnregisterSuccessData>("TOUR_UNREGISTRATION_SUCCESS");

export const tourUnregistrationError = createAction<
  Omit<UnregisterTourError, "type">
>("TOUR_UNREGISTRATION_ERROR");

export const resetUnregistrationError = createAction<{ tourId: number }>(
  "RESET_UNREGISTRATION_ERROR",
);

export const pendingActionRequired = createAction("PENDING_ACTION_REQUIRED");

export const getAccountAttributesSuccess = createAction<PlayerAttributesData>(
  "GET_ACCOUNT_ATTRIBUTES_SUCCESS",
);

export const getAccountAttributesError = createAction<AccountAttributesError>(
  "GET_ACCOUNT_ATTRIBUTES_ERROR",
);

export const tournamentStarted =
  createAction<TournamentStartedData>("TOURNAMENT_STARTED");

export const gameServerConnected = createAction<GameServerConnectionParams>(
  "GAME_SERVER_CONNECTED",
);

export const gameServerConnectionError = createAction<ConnectGameServerError>(
  "GAME_SERVER_CONNECTION_ERROR",
);

export const gameCreated = createAction<GameCreated>("GAME_CREATED");

export const reconnectGame =
  createAction<GameServerReconnectionParams>("RECONNECT_GAME");

export const gameClosed = createAction<GameClosed>("GAME_CLOSED");

export const resetTourRegistration = createAction<{ tourId: number }>(
  "RESET_TOUR_REGISTRATION",
);

export const openCashier = createAction("OPEN_CASHIER");

export const closeApp = createAction("CLOSE_APP");

export const logout = createAction("LOGOUT");
export const appClosed = createAction("APP_CLOSED");

export const clearUserNotification = createAction("CLEAR_USER_NOTIFICATION");

export const setBonus = createAction<BonusData>("BONUS_RECEIVED");

export const bonusShowerError = createAction<{ message: string }>(
  "BONUS_SHOWER_ERROR",
);

export const gameStateChanged =
  createAction<GameStateChange>("GAME_STATE_CHANGED");

export const bankrollUpdated = createAction<BankrollUpdate>("BANKROLL_UPDATED");

export const bankrollUpdateError = createAction<{ message: string }>(
  "BANKROLL_UPDATE_ERROR",
);

export const depositShowed = createAction<GameEventData>("DEPOSIT_SHOWED");

export const playAnotherGame =
  createAction<GameBuyInEventData>("PLAY_ANOTHER_GAME");

export const withdrawFunds = createAction<WithdrawFunds>("WITHDRAW_FUNDS");

export const topUpStateChanged = createAction<{ topUp: boolean }>(
  "UPDATE_TOP_UP_STATE",
);

export const segmentationUpdate = createAction<IPlayerSegmentationData>(
  "SEGMENTATION_UPDATE",
);

export const kickToLobby = createAction<KickToLobby>("KICK_TO_LOBBY");

export const userKickedFromServer = createAction("USER_KICKED_FROM_SERVER");

export const resetCurrentSession = createAction("RESET_CURRENT_SESSION");

export const setCurrentSession = createAction<{ gameId: number }>(
  "SET_CURRENT_SESSION",
);

export const tourEndOrCancelled =
  createAction<TourEndOrCancellationData>("TOUR_CANCELLED");

export const playerFinishedTour = createAction<PlayerFinishedTourData>(
  "PLAYER_FINISHED_TOUR",
);
