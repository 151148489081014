import Portal from "../Portal";
import rebuyChip from "./assets/rebuy-chip.png";
import { RebuyButton, RebuyIcon } from "./styles";
import { RebuyChipProps } from "./types";

export default function RebuyChip({
  buttonPlaceholder,
  onClick,
  disabled,
}: RebuyChipProps) {
  const RebuyChipButton = (
    <RebuyButton onClick={onClick} disabled={disabled}>
      <RebuyIcon src={rebuyChip} alt="Rebuy Chip Icon" />
    </RebuyButton>
  );

  return (
    <Portal placeholder={buttonPlaceholder?.current}>{RebuyChipButton}</Portal>
  );
}
