import { createReducer } from "@reduxjs/toolkit";
import {
  handHistoryReceived,
  handHistorySelected,
  handsList,
} from "../actions";
import GameHistory from "../types/GameHistory";

const initialState: GameHistory = {};
export const history = createReducer<GameHistory>(initialState, (builder) => {
  builder
    .addCase(handsList, (state, { payload }) => {
      state.hands = payload;
    })
    .addCase(handHistorySelected, (state) => {
      state.handData = undefined;
    })
    .addCase(handHistoryReceived, (state, { payload }) => {
      state.handData = payload;
    });
});
