import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { IndicatorWrapper } from "../styles";

export const Wrapper = styled(IndicatorWrapper)`
  padding: 0;

  &::before {
    content: attr(data-content);

    width: 1.5em;
    height: 1.5em;

    position: absolute;
    top: 0.3em;
    right: 0.2em;

    font-size: 0.55em;
    font-family: "888-SemiCondensedBold";

    color: #fff;
  }

  color: ${({ theme }) => theme.sessionIndicator.multiTable.background};

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.sessionIndicator.multiTable.hover};
    }
  }

  &:active {
    color: ${({ theme }) => theme.sessionIndicator.multiTable.pressed};
  }
`;

const INITIAL_BUBBLE_COLOR = "hsl(0deg 100% 44%)";

export const NotificationBubble = styled.circle<{ $notified?: boolean }>`
  @keyframes fading {
    0%,
    100% {
      fill: ${INITIAL_BUBBLE_COLOR};
    }
    50% {
      fill: hsl(0deg 100% 24%);
    }
  }

  ${({ $notified }) =>
    $notified
      ? css`
          animation: fading 1.2s linear infinite;
        `
      : css`
          fill: ${INITIAL_BUBBLE_COLOR};
        `}
`;
