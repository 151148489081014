import { take } from "redux-saga/effects";
import {
  PROXY_CLIENT_MESSAGE_TO_CLIENT,
  mtcReceived,
} from "../../../../network/protocol/mainServer";
import type {
  MainServerMessage,
  MessageToClient,
} from "../../../../network/protocol/mainServer/types";
import MessagesCommunication from "./messagesCommunication";

export default function* autoReplyToMTC(
  connection: MessagesCommunication<MainServerMessage>,
) {
  while (true) {
    const mtc: MessageToClient = yield take(
      connection.messages,
      PROXY_CLIENT_MESSAGE_TO_CLIENT,
    );

    connection.send(mtcReceived(mtc.id));
  }
}
