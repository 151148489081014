import styled from "@emotion/styled/macro";
import { Orientation } from "../../hooks/useScreenOrientation";
import { ScrollbarContainer } from "../../styles/scroll";

export const TRANSITION_FADE_IN = 1000;

export const Viewport = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  transform-origin: top left;
`;

export const GameWrapper = styled(ScrollbarContainer)`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  .reflector-enter {
    opacity: 0;
  }

  .reflector-enter-active {
    opacity: 1;
  }

  .reflector-exit {
    opacity: 1;
  }

  .reflector-exit-active {
    opacity: 0;
  }
  .reflector-enter-active,
  .reflector-exit-active {
    transition: opacity ${TRANSITION_FADE_IN}ms;
  }
`;

export const TopSection = styled.div`
  position: relative;
  display: grid;

  grid-template-areas: "topleft topcenter topright" "left center right";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1em auto;

  width: 100%;

  box-sizing: border-box;
  padding: 0em 1em 1em 1em;

  color: black;

  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`;

export const TopCentered = styled.div`
  grid-area: topleft / topleft / right / right;
  justify-self: center;
`;

export const RightButtonsWrapper = styled.div<Orientation>`
  grid-area: right;
  display: flex;
  flex-direction: ${({ $isLandscape }) =>
    $isLandscape ? "row-reverse" : "column"};

  > :first-child {
    margin: ${({ $isLandscape }) =>
      $isLandscape ? "0 0 0 0.7em" : "0 0 0.7em 0"};
  }
`;

export const SafeArea = styled.div`
  position: relative;

  width: calc(100% - env(safe-area-inset-right));
  height: calc(
    100% - env(safe-area-inset-top) - calc(env(safe-area-inset-bottom) + 6px)
  );

  margin: env(safe-area-inset-top) env(safe-area-inset-right)
    calc(env(safe-area-inset-bottom) + 6px) 0;

  pointer-events: none;
`;
