import { PropsWithChildren } from "react";
import ConnectionGuard from "../../containers/ConnectionGuard";
import FullScreenPortal from "../../containers/FullScreenPortal";
import PrivateRoute from "../../containers/PrivateRoute";
import Styleable from "../../utils/types/Styleable";

export const ProtectedFullScreenRoute = ({
  children,
  ...styles
}: Styleable<PropsWithChildren>) => {
  return (
    <FullScreenPortal {...styles}>
      <ConnectionGuard>
        <PrivateRoute>{children}</PrivateRoute>
      </ConnectionGuard>
    </FullScreenPortal>
  );
};
