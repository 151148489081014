import { RankType } from "../../../../utils/cardsSpecs";

export const ranks: Record<RankType, string> = {
  2: "M11.976 14.16H3.992v-1.584L7.76 8.56q.776-.848 1.148-1.48t.372-1.2q0-.776-.392-1.22t-1.12-.444q-.784 0-1.236.54t-.452 1.42H3.76q0-1.064.508-1.944t1.436-1.38 2.104-.5q1.8 0 2.796.864t.996 2.44q0 .864-.448 1.76T9.616 9.504l-2.648 2.792h5.008v1.864z",
  3: "M6.344 7.312h1.232q.88 0 1.304-.44t.424-1.168q0-.704-.42-1.096t-1.156-.392q-.664 0-1.112.364t-.448.948H3.856q0-.912.492-1.636T5.724 2.76t1.948-.408q1.848 0 2.896.884t1.048 2.436q0 .8-.488 1.472t-1.28 1.032q.984.352 1.468 1.056t.484 1.664q0 1.552-1.132 2.488t-2.996.936q-1.744 0-2.852-.92t-1.108-2.432h2.312q0 .656.492 1.072t1.212.416q.824 0 1.292-.436t.468-1.156q0-1.744-1.92-1.744H6.344V7.312z",
  4: "M10.832 9.776h1.32v1.864h-1.32v2.52H8.52v-2.52H3.744l-.104-1.456 4.856-7.672h2.336v7.264zm-4.888 0H8.52V5.664l-.152.264z",
  5: "m4.336 8.432.672-5.92h6.528V4.44H6.904l-.288 2.504q.824-.44 1.752-.44 1.664 0 2.608 1.032t.944 2.888q0 1.128-.476 2.02t-1.364 1.384-2.096.492q-1.056 0-1.96-.428t-1.428-1.204-.556-1.768h2.288q.072.728.508 1.132t1.14.404q.784 0 1.208-.564t.424-1.596q0-.992-.488-1.52t-1.384-.528q-.824 0-1.336.432l-.224.208z",
  6: "M10.264 2.392v1.904h-.224q-1.568.024-2.524.816t-1.148 2.2q.928-.944 2.344-.944 1.52 0 2.416 1.088t.896 2.864q0 1.136-.492 2.056t-1.392 1.432-2.036.512q-1.84 0-2.972-1.28T4 9.624v-.832q0-1.896.716-3.348t2.056-2.248 3.108-.804h.384zM8.008 8.224q-.56 0-1.016.292t-.672.772v.704q0 1.16.456 1.812t1.28.652q.744 0 1.204-.588t.46-1.524q0-.952-.464-1.536t-1.248-.584z",
  7: "M11.912 3.808 7.408 14.16h-2.44L9.48 4.384H3.688V2.512h8.224v1.296z",
  8: "M11.584 5.608q0 .848-.424 1.504T9.992 8.16q.848.408 1.344 1.124t.496 1.684q0 1.552-1.056 2.452t-2.872.9-2.88-.904-1.064-2.448q0-.968.496-1.688t1.336-1.12q-.744-.392-1.164-1.048t-.42-1.504q0-1.488.992-2.372t2.696-.884q1.696 0 2.692.876t.996 2.38zM9.512 10.8q0-.76-.44-1.216t-1.184-.456q-.736 0-1.176.452t-.44 1.22q0 .744.432 1.2t1.2.456q.752 0 1.18-.44t.428-1.216zm-.24-5.08q0-.68-.36-1.092t-1.016-.412q-.648 0-1.008.4t-.36 1.104q0 .696.36 1.12t1.016.424 1.012-.424.356-1.12z",
  9: "M9.4 9.504q-.904.888-2.112.888-1.544 0-2.472-1.06t-.928-2.86q0-1.144.5-2.1t1.396-1.488T7.8 2.352q1.152 0 2.048.576t1.392 1.656.504 2.472v.856q0 2.912-1.448 4.576t-4.104 1.776l-.568.008v-1.928l.512-.008Q9.152 12.2 9.4 9.504zm-1.544-.88q.56 0 .964-.288t.612-.696v-.952q0-1.176-.448-1.824t-1.2-.648q-.696 0-1.144.636t-.448 1.596q0 .952.436 1.564t1.228.612z",
  10: "M7.208 14.16H4.896V5.248l-2.76.856v-1.88L6.96 2.496h.248V14.16zM15.832 9.344q0 2.416-1 3.696t-2.928 1.28q-1.904 0-2.912-1.256t-1.032-3.6V7.32q0-2.44 1.012-3.704t2.916-1.264T14.8 3.604 15.832 7.2v2.144zM13.52 6.992q0-1.448-.396-2.108t-1.236-.66q-.816 0-1.204.628t-.412 1.964v2.832q0 1.424.388 2.116t1.244.692q.848 0 1.224-.664t.392-2.032V6.992z",
  J: "M8.936 2.512h2.4v8.064q0 1.112-.492 1.964t-1.388 1.316-2.024.464q-1.848 0-2.88-.94T3.52 10.72h2.416q0 .856.36 1.264t1.136.408q.688 0 1.096-.472t.408-1.344V2.512z",
  Q: "M13.792 8.6q0 1.632-.528 2.836T11.8 13.368l1.936 1.52-1.528 1.352-2.48-1.992q-.424.072-.88.072-1.448 0-2.584-.696t-1.76-1.988-.632-2.972v-.576q0-1.72.62-3.028t1.752-2.008 2.588-.7 2.588.7 1.752 2.008.62 3.02v.52zm-2.432-.528q0-1.832-.656-2.784t-1.872-.952q-1.208 0-1.864.94t-.664 2.756V8.6q0 1.784.656 2.768t1.888.984q1.208 0 1.856-.948t.656-2.764v-.568z",
  K: "M7.888 9.488 6.64 10.832v3.328h-2.4V2.512h2.4v5.28l1.056-1.448 2.968-3.832h2.952L9.48 7.688l4.256 6.472H10.88z",
  A: "M10.816 11.76H6.608l-.8 2.4H3.256L7.592 2.512h2.224l4.36 11.648h-2.552zm-3.56-1.944h2.912l-1.464-4.36z",
};
