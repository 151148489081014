import { createReducer } from "@reduxjs/toolkit";
import { ringTableJoined, stateRestored } from "../actions";
import initialBlindData from "../initialStates/initialBlindData";
import BlindData from "../types/BlindData";

export const blindData = createReducer<BlindData>(
  initialBlindData,
  (builder) => {
    builder
      .addCase(ringTableJoined, (state, { payload }) => {
        state.currentSmallBlind = payload.smallBlind;
        state.currentLowLimit = payload.bigBlind;
        state.currentHighLimit = payload.bigBlind;
        state.ante = payload.ante;
      })
      .addCase(stateRestored, (_, { payload }) => {
        return payload.blindLevel;
      });
  },
);
