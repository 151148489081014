import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { HumanIcon } from "./RegisteredIndicator/styles";
import { StyledCardPaper } from "./SmallCard/styles";
import { TableWrapper } from "./TableIndicator/styles";

export const IndicatorWrapper = styled.button`
  font-size: inherit;

  display: block;
  border: none;
  background: none;

  width: 2.7em;

  cursor: pointer;
  outline: none;

  position: relative;
  height: fit-content;

  font-family: "Roboto";

  padding: 0.5em 0.2em 0.7em;
  box-sizing: content-box;

  &:disabled {
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      ${StyledCardPaper} {
        color: #fff;

        &:not(:first-child) {
          box-shadow: -0.0625em 0 0 #ccc;
        }
      }

      ${HumanIcon} {
        color: #fff;
      }

      ${TableWrapper} {
        ${({ theme }) => css`
          background: ${theme.sessionIndicator.table.hover};
          border: 0.0625em solid
            ${theme.sessionIndicator.regular.whiteTableBorder};
          box-shadow: ${theme.sessionIndicator.regular.glowing};
        `}
      }
    }
  }
  &:active ${TableWrapper} {
    ${({ theme }) => css`
      background: ${theme.sessionIndicator.table.pressed};
      border: 0.0625em solid ${theme.sessionIndicator.regular.whiteTableBorder};
      box-shadow: ${theme.sessionIndicator.regular.glowing};
    `}
  }
`;
