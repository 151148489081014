import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  breakEnd,
  breakStart,
  stateRestored,
  tournamentJoined,
} from "../actions";
import Break from "../types/Break";

const initialState = null;

export const gameBreak = createReducer<Break | null>(
  initialState,
  (builder) => {
    builder
      .addCase(breakStart, (_, { payload }) => {
        return {
          totalTime: payload.totalTime,
          duration: payload.duration,
          breakType: payload.breakType,
          ends: payload.syncDate + payload.duration * 1000,
        };
      })
      .addCase(stateRestored, (_, { payload }) => {
        if ("gameBreak" in payload) {
          return payload.gameBreak;
        }
      })
      .addMatcher(
        createActionMatcher([tournamentJoined, breakEnd]),
        () => initialState,
      );
  },
);
