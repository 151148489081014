import Tournament from "../types/Tournament";

const initialState: Tournament = {
  tournamentId: "",
  pokerType: NaN,
  tourName: "",
  tournamentType: NaN,
  attributesMask: NaN,
  subTypeMask: NaN,
  tourNames: new Map(),
  blastPoolId: NaN,
  blastMultiplayerId: NaN,
  isStarted: false,
  tourStartTime: NaN,
  lateRegDuration: NaN,
  heroPosition: NaN,
  playersLeft: NaN,
  totalPrizePool: NaN,
};

export default initialState;
