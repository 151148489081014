import CloseReason from "../../store/app/types/CloseReason";

export default class GameCloseEvent extends Event {
  static readonly GAME_CLOSE = "gameClose";

  reason: CloseReason | undefined;

  constructor(reason?: CloseReason) {
    super(GameCloseEvent.GAME_CLOSE);

    this.reason = reason;
  }
}
