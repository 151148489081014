import { ParsersMap } from "../types";
import {
  addKickCandidate,
  addPlayer,
  allInEquityData,
  allInsPosted,
  antePosted,
  betCalled,
  betRaised,
  bigBlind,
  bigBlindPosted,
  breakActive,
  breakEnd,
  breakInactive,
  breakStart,
  buyInErrorBfx,
  check,
  chooseTourDealer,
  clearTable,
  deadPenaltyPosted,
  finishedTour,
  fold,
  ftdAvailability,
  ftdNegotiationStatus,
  ftdReconnectionDetails,
  ftdSelectionErr,
  ftdSelectionOk,
  ftdSelectionStatus,
  ftdStatus,
  gameResult,
  getQuickPosErr,
  getQuickPosOk,
  h4hActivated,
  h4hDeactivated,
  h4hInProcess,
  increaseBlindLevel,
  joinPositionErr,
  joinPositionOk,
  joinRingGameErrorBfx,
  joinRingGameOk,
  joinRingTable,
  joinTournamentErrorBfx,
  joinTournamentOk,
  joinTourTable,
  listPlayers,
  livePenaltyPosted,
  playerDontShowCards,
  playerRegulationData,
  playerSitIn,
  playerSitInTour,
  playerSitOut,
  playerSitOutTour,
  pushNotification,
  rabbitHuntData,
  rebuySuccess,
  receiveChatMessage,
  removeKickCandidate,
  removePlayer,
  resevationStatusChanged,
  resevationStatusChangedErr,
  resevationStatusChangedOk,
  returnToTourLobby,
  returnToViewer,
  ringSnapState,
  sessionDetails,
  setCards,
  setCredit,
  setCreditPending,
  setRingGameHandsList,
  setRingHandHistory,
  setTourHandHistory,
  setTournamentHandsList,
  setTurn,
  setTurnTimebank,
  showCards,
  sitInLiveAndDeadPenalty,
  sitInLivePenalty,
  smallBlind,
  smallBlindPosted,
  startGame,
  startRound1,
  startRound2,
  startRound3,
  startRound4,
  startShowCardsRound,
  tableClosed,
  tableHistory,
  thrownObject,
  tourAnte,
  tourEnded,
  tournamentFinalPlayers,
  tournamentFinalTable,
  updateSnapPoolTableId,
  waitForEnterPositionState,
} from "./messageParsers";
import {
  GH_CS_SET_POKER_SESSIONS_DETAILS,
  GH_CS_SET_POKER_TOURNAMENTS_DETAILS,
  GH_SC_SET_SCSHL_GAME_STR,
  GH_SC_SET_THP_GAME_STR,
  LOBBY_SC_CHAT_EX,
  LOBBY_SC_JOIN_POKER_RING_GAME_ERR_BFX,
  LOBBY_SC_JOIN_POKER_RING_GAME_OK,
  LOBBY_SC_JOIN_POKER_TOURNAMENT_ERR_BFX,
  LOBBY_SC_JOIN_POKER_TOURNAMENT_OK,
  POKER_SC_ADD_KICK_CANDIDATE,
  POKER_SC_ADD_PLAYER,
  POKER_SC_ALL_IN_EQUITY,
  POKER_SC_ALL_INS,
  POKER_SC_BIG_BLIND,
  POKER_SC_BIG_BLIND_POSTED,
  POKER_SC_BREAK_END,
  POKER_SC_BREAK_START,
  POKER_SC_BUY_IN_ERR_BFX,
  POKER_SC_CALL,
  POKER_SC_CHANGE_RSRV_STATUS,
  POKER_SC_CHANGE_RSRV_STATUS_EX_ERR,
  POKER_SC_CHANGE_RSRV_STATUS_EX_OK,
  POKER_SC_CHECK,
  POKER_SC_CHOOSE_TOUR_DEALER,
  POKER_SC_CLEAR_TABLE,
  POKER_SC_CLOSE_TABLE,
  POKER_SC_DEAD_PENALTY_BET_POSTED,
  POKER_SC_DONT_SHOW_CARDS,
  POKER_SC_FINAL_PLAYERS,
  POKER_SC_FINAL_TABLE,
  POKER_SC_FINISHED_TOUR,
  POKER_SC_FOLD,
  POKER_SC_FT_DEAL_STATUS,
  POKER_SC_FTD_ALLOWED,
  POKER_SC_FTD_NEGOTIATION_STATUS,
  POKER_SC_FTD_RECONNECTION_DETAILS,
  POKER_SC_FTD_SELECTION_STATUS,
  POKER_SC_GET_QUICK_POS_EX_ERR,
  POKER_SC_GET_QUICK_POS_EX_OK,
  POKER_SC_H4H_ACTIVATE,
  POKER_SC_H4H_DEACTIVATE,
  POKER_SC_H4H_IN_PROCESS,
  POKER_SC_JOIN_OK,
  POKER_SC_JOIN_POS_FAILED,
  POKER_SC_JOIN_POS_OK,
  POKER_SC_JOIN_TOUR_TABLE,
  POKER_SC_LIST_PLAYERS,
  POKER_SC_NEW_GAME_RESULT,
  POKER_SC_PLAYER_AM_SESSION_DETAILS,
  POKER_SC_PLAYER_FTD_SELECTION_ERR,
  POKER_SC_PLAYER_FTD_SELECTION_OK,
  POKER_SC_PLAYER_REGULATION_DATA,
  POKER_SC_PLAYER_SIT_IN_TOUR,
  POKER_SC_PLAYER_SIT_OUT_TOUR,
  POKER_SC_PUSH_NOTIFICATION,
  POKER_SC_RABBIT_HUNT,
  POKER_SC_RAISE_VAR_POSTED,
  POKER_SC_REMOVE,
  POKER_SC_REMOVE_KICK_CANDIDATE,
  POKER_SC_RETURN_TO_TOUR_LOBBY,
  POKER_SC_RETURN_TO_VIEWER,
  POKER_SC_RING_SNAP_STATE,
  POKER_SC_SET_ANTE_POSTED,
  POKER_SC_SET_CARDS,
  POKER_SC_SET_CREDIT,
  POKER_SC_SET_CREDIT_PENDING,
  POKER_SC_SET_TURN,
  POKER_SC_SET_TURN_TB,
  POKER_SC_SHOW_CARDS,
  POKER_SC_SIT_IN,
  POKER_SC_SIT_IN_LIVE_AND_DEAD_PENALTY,
  POKER_SC_SIT_IN_LIVE_PENALTY,
  POKER_SC_SIT_IN_LIVE_PENALTY_POSTED,
  POKER_SC_SIT_OUT,
  POKER_SC_SMALL_BLIND,
  POKER_SC_SMALL_BLIND_POSTED,
  POKER_SC_START_GAME,
  POKER_SC_START_ROUND_1,
  POKER_SC_START_ROUND_2,
  POKER_SC_START_ROUND_3,
  POKER_SC_START_ROUND_4,
  POKER_SC_START_SHOW_CARD_ROUND,
  POKER_SC_SUCCESSFULL_RE_BUY,
  POKER_SC_TABLE_HIST,
  POKER_SC_THROW,
  POKER_SC_TOUR_ANTE,
  POKER_SC_TOUR_BREAK_ACTIVE,
  POKER_SC_TOUR_BREAK_INACTIVE,
  POKER_SC_TOUR_ENDED,
  POKER_SC_UP_LEVEL,
  POKER_SC_UPDATE_SNAP_POOL_TABLE_ID,
  POKER_SC_WAIT_FOR_ENTER_POSITION_STATE,
} from "./messageTypes";
import { GameServerMessage, GameServerMessageType } from "./types";

export * from "./messageCreators";
export * from "./messageTypes";

export const parsers: Partial<
  ParsersMap<GameServerMessageType, GameServerMessage>
> = {
  [LOBBY_SC_JOIN_POKER_TOURNAMENT_OK]: joinTournamentOk,
  [LOBBY_SC_JOIN_POKER_TOURNAMENT_ERR_BFX]: joinTournamentErrorBfx,
  [POKER_SC_JOIN_TOUR_TABLE]: joinTourTable,
  [POKER_SC_JOIN_POS_OK]: joinPositionOk,
  [POKER_SC_JOIN_POS_FAILED]: joinPositionErr,
  [POKER_SC_UP_LEVEL]: increaseBlindLevel,
  [POKER_SC_LIST_PLAYERS]: listPlayers,
  [POKER_SC_BREAK_START]: breakStart,
  [POKER_SC_BREAK_END]: breakEnd,
  [POKER_SC_START_GAME]: startGame,
  [POKER_SC_START_ROUND_1]: startRound1,
  [POKER_SC_START_ROUND_2]: startRound2,
  [POKER_SC_START_ROUND_3]: startRound3,
  [POKER_SC_START_ROUND_4]: startRound4,
  [POKER_SC_START_SHOW_CARD_ROUND]: startShowCardsRound,
  [POKER_SC_NEW_GAME_RESULT]: gameResult,
  [POKER_SC_CLEAR_TABLE]: clearTable,
  [POKER_SC_SMALL_BLIND_POSTED]: smallBlindPosted,
  [POKER_SC_BIG_BLIND_POSTED]: bigBlindPosted,
  [POKER_SC_SET_ANTE_POSTED]: antePosted,
  [POKER_SC_SIT_IN_LIVE_PENALTY_POSTED]: livePenaltyPosted,
  [POKER_SC_CALL]: betCalled,
  [POKER_SC_RAISE_VAR_POSTED]: betRaised,
  [POKER_SC_ALL_INS]: allInsPosted,
  [POKER_SC_DEAD_PENALTY_BET_POSTED]: deadPenaltyPosted,
  [POKER_SC_TOUR_ANTE]: tourAnte,
  [POKER_SC_SHOW_CARDS]: showCards,
  [POKER_SC_FOLD]: fold,
  [POKER_SC_CHECK]: check,
  [POKER_SC_SET_TURN]: setTurn,
  [POKER_SC_SET_TURN_TB]: setTurnTimebank,
  [POKER_SC_SIT_IN]: playerSitIn,
  [POKER_SC_SIT_OUT]: playerSitOut,
  [POKER_SC_PLAYER_SIT_IN_TOUR]: playerSitInTour,
  [POKER_SC_PLAYER_SIT_OUT_TOUR]: playerSitOutTour,
  [POKER_SC_TABLE_HIST]: tableHistory,
  [POKER_SC_SET_CARDS]: setCards,
  [POKER_SC_DONT_SHOW_CARDS]: playerDontShowCards,
  [POKER_SC_TOUR_ENDED]: tourEnded,
  [POKER_SC_FINISHED_TOUR]: finishedTour,
  [POKER_SC_ADD_PLAYER]: addPlayer,
  [POKER_SC_REMOVE]: removePlayer,
  [LOBBY_SC_CHAT_EX]: receiveChatMessage,
  [POKER_SC_RETURN_TO_TOUR_LOBBY]: returnToTourLobby,
  [POKER_SC_ALL_IN_EQUITY]: allInEquityData,
  [POKER_SC_RABBIT_HUNT]: rabbitHuntData,
  [POKER_SC_THROW]: thrownObject,
  [LOBBY_SC_JOIN_POKER_RING_GAME_OK]: joinRingGameOk,
  [LOBBY_SC_JOIN_POKER_RING_GAME_ERR_BFX]: joinRingGameErrorBfx,
  [POKER_SC_JOIN_OK]: joinRingTable,
  [POKER_SC_CHANGE_RSRV_STATUS]: resevationStatusChanged,
  [POKER_SC_CHANGE_RSRV_STATUS_EX_OK]: resevationStatusChangedOk,
  [POKER_SC_CHANGE_RSRV_STATUS_EX_ERR]: resevationStatusChangedErr,
  [POKER_SC_GET_QUICK_POS_EX_OK]: getQuickPosOk,
  [POKER_SC_GET_QUICK_POS_EX_ERR]: getQuickPosErr,
  [POKER_SC_SMALL_BLIND]: smallBlind,
  [POKER_SC_BIG_BLIND]: bigBlind,
  [POKER_SC_RETURN_TO_VIEWER]: returnToViewer,
  [POKER_SC_SIT_IN_LIVE_PENALTY]: sitInLivePenalty,
  [POKER_SC_SIT_IN_LIVE_AND_DEAD_PENALTY]: sitInLiveAndDeadPenalty,
  [POKER_SC_RING_SNAP_STATE]: ringSnapState,
  [POKER_SC_BUY_IN_ERR_BFX]: buyInErrorBfx,
  [POKER_SC_SET_CREDIT_PENDING]: setCreditPending,
  [POKER_SC_SET_CREDIT]: setCredit,
  [POKER_SC_SUCCESSFULL_RE_BUY]: rebuySuccess,
  [POKER_SC_PLAYER_AM_SESSION_DETAILS]: sessionDetails,
  [POKER_SC_ADD_KICK_CANDIDATE]: addKickCandidate,
  [POKER_SC_REMOVE_KICK_CANDIDATE]: removeKickCandidate,
  [POKER_SC_CLOSE_TABLE]: tableClosed,
  [POKER_SC_WAIT_FOR_ENTER_POSITION_STATE]: waitForEnterPositionState,
  [GH_CS_SET_POKER_SESSIONS_DETAILS]: setRingGameHandsList,
  [GH_CS_SET_POKER_TOURNAMENTS_DETAILS]: setTournamentHandsList,
  [GH_SC_SET_THP_GAME_STR]: setRingHandHistory,
  [GH_SC_SET_SCSHL_GAME_STR]: setTourHandHistory,
  [POKER_SC_CHOOSE_TOUR_DEALER]: chooseTourDealer,
  [POKER_SC_TOUR_BREAK_ACTIVE]: breakActive,
  [POKER_SC_TOUR_BREAK_INACTIVE]: breakInactive,
  [POKER_SC_FINAL_TABLE]: tournamentFinalTable,
  [POKER_SC_FINAL_PLAYERS]: tournamentFinalPlayers,
  [POKER_SC_PLAYER_REGULATION_DATA]: playerRegulationData,
  [POKER_SC_FTD_ALLOWED]: ftdAvailability,
  [POKER_SC_FTD_SELECTION_STATUS]: ftdSelectionStatus,
  [POKER_SC_PLAYER_FTD_SELECTION_OK]: ftdSelectionOk,
  [POKER_SC_PLAYER_FTD_SELECTION_ERR]: ftdSelectionErr,
  [POKER_SC_FTD_NEGOTIATION_STATUS]: ftdNegotiationStatus,
  [POKER_SC_FT_DEAL_STATUS]: ftdStatus,
  [POKER_SC_FTD_RECONNECTION_DETAILS]: ftdReconnectionDetails,
  [POKER_SC_UPDATE_SNAP_POOL_TABLE_ID]: updateSnapPoolTableId,
  [POKER_SC_PUSH_NOTIFICATION]: pushNotification,
  [POKER_SC_H4H_ACTIVATE]: h4hActivated,
  [POKER_SC_H4H_IN_PROCESS]: h4hInProcess,
  [POKER_SC_H4H_DEACTIVATE]: h4hDeactivated,
};
