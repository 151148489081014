import { useLayoutEffect } from "react";
import default888Tour from "../assets/brandedIcons/888poker.png";
import bigShotTour from "../assets/brandedIcons/BigShotTour.png";
import classicTour from "../assets/brandedIcons/ClassicTour.png";
import dashTour from "../assets/brandedIcons/DashTour.png";
import mayhemTour from "../assets/brandedIcons/MayhemTour.png";
import mysteryBountyTour from "../assets/brandedIcons/MysteryBountyTour.png";
import rumbleTour from "../assets/brandedIcons/RumbleTour.png";
import voyageTour from "../assets/brandedIcons/VoyageTour.png";
import popupCloseIcon from "../assets/close-icon.svg";
import badBeat from "../assets/emojis/bad-beat.png";
import goodGame from "../assets/emojis/good-game.png";
import haha from "../assets/emojis/haha.png";
import hurryUp from "../assets/emojis/hurry-up.png";
import omg from "../assets/emojis/omg.png";
import shipIt from "../assets/emojis/ship-it.png";
import sorry from "../assets/emojis/sorry.png";
import thankYou from "../assets/emojis/thank-you.png";
import tilt from "../assets/emojis/tilt.png";
import king from "../assets/king.png";
import tourLogo from "../assets/tour-logo.png";
import allInIcon from "../assets/tournamentTypeIcons/allIn.png";
import broadcastIcon from "../assets/tournamentTypeIcons/broadcast.png";
import headsUpIcon from "../assets/tournamentTypeIcons/headsUp.png";
import knockoutPkoIcon from "../assets/tournamentTypeIcons/knockoutPko.png";
import multiDayIcon from "../assets/tournamentTypeIcons/multiDay.png";
import multiFlightIcon from "../assets/tournamentTypeIcons/multiFlight.png";
import mysteryBountyIcon from "../assets/tournamentTypeIcons/mysteryBounty.png";
import privateIcon from "../assets/tournamentTypeIcons/private.png";
import rebuyAddonIcon from "../assets/tournamentTypeIcons/rebuyAddon.png";
import secondChanceIcon from "../assets/tournamentTypeIcons/secondChance.png";
import shortHandedIcon from "../assets/tournamentTypeIcons/shortHanded.png";
import showMeIcon from "../assets/tournamentTypeIcons/showMe.png";
import snapIcon from "../assets/tournamentTypeIcons/snap.png";
import superTurboIcon from "../assets/tournamentTypeIcons/superTurbo.png";
import turboIcon from "../assets/tournamentTypeIcons/turbo.png";
import betChip from "../components/BetChip/assets/bet-chip.svg";
import sliderThumb from "../components/BetSlider/assets/slider-thumb.png";
import blastDecalsBlastLogo from "../components/BlastDecals/assets/blast-logo.png";
import tableLightningLandscape from "../components/BlastDecals/assets/lightning-landscape.png";
import tableLightningPortrait from "../components/BlastDecals/assets/lightning-portrait.png";
import blastLogo from "../components/BlastLogo/assets/blast-logo.png";
import blastBackground from "../components/BlastStrip/assets/background.png";
import blueTile from "../components/BlastStrip/assets/blue-tile.png";
import cyanTile from "../components/BlastStrip/assets/cyan-tile.png";
import greenTile from "../components/BlastStrip/assets/green-tile.png";
import blastHeaderIcon from "../components/BlastStrip/assets/header-icon.svg";
import blastStripLogo from "../components/BlastStrip/assets/logo.png";
import orangeTile from "../components/BlastStrip/assets/orange-tile.png";
import prizeBlur from "../components/BlastStrip/assets/prize-blur.png";
import purpleTile from "../components/BlastStrip/assets/purple-tile.png";
import redTile from "../components/BlastStrip/assets/red-tile.png";
import yellowTile from "../components/BlastStrip/assets/yellow-tile.png";
import decrement from "../components/BuyInSlider/assets/decrement.png";
import increment from "../components/BuyInSlider/assets/increment.png";
import thumb from "../components/BuyInSlider/assets/thumb.png";
import cashLogo from "../components/CashLogo/assets/cash-logo.png";
import cashBackground from "../components/CashStrip/assets/background.png";
import cashHeaderIcon from "../components/CashStrip/assets/header-icon.svg";
import cashPreview from "../components/CashStrip/assets/preview.png";
import cashTile from "../components/CashStrip/assets/tile.png";
import rabbitCardBackground from "../components/CommunityCards/RabbitHunt/assets/card-background.svg";
import rabbitOnCard from "../components/CommunityCards/RabbitHunt/assets/rabbit-on-card.svg";
import rabbitSprite from "../components/CommunityCards/RabbitHunt/assets/rabbit-sprite.png";
import emptySeat from "../components/EmptySeat/assets/empty-seat.svg";
import hamburgerMenuButton from "../components/HamburgerMenuButton/assets/menu.svg";
import headerArrow from "../components/Header/assets/arrow.svg";
import closeBottonKeyboard from "../components/Keyboard/CloseButton/assets/close-arrow.svg";
import backspaceKeyboarArrow from "../components/Keyboard/assets/backspace-arrow.svg";
import keyboardIcon from "../components/Keyboard/assets/keyboard-icon.svg";
import finalTableLandscape from "../components/MttTable/assets/final-table-landscape.png";
import finalTablePortrait from "../components/MttTable/assets/final-table-portrait.png";
import money from "../components/MttTable/assets/money.png";
import balloon from "../components/PlayersTabContent/assets/balloon.png";
import potChip from "../components/PotChip/assets/pot-chip.svg";
import prizePoolLandscape from "../components/PrizePoolAnimation/assets/prize-pool-landscape.png";
import prizePoolPortrait from "../components/PrizePoolAnimation/assets/prize-pool-portrait.png";
import rebuyChip from "../components/RebuyChip/assets/rebuy-chip.png";
import blastTableLandscape from "../components/ResponsiveBlastGameTable/assets/blast-table-landscape.png";
import blastTablePortrait from "../components/ResponsiveBlastGameTable/assets/blast-table-portrait.png";
import cashTableLandscape from "../components/ResponsiveCashGameTable/assets/cash-table-landscape.png";
import cashTablePortrait from "../components/ResponsiveCashGameTable/assets/cash-table-portrait.png";
import space from "../components/SatellitesTabContent/assets/space.png";
import snapLogo from "../components/SnapLogo/assets/snap-logo.png";
import snapBackground from "../components/SnapStrip/assets/background.png";
import snapHeaderIcon from "../components/SnapStrip/assets/header-icon.svg";
import snapStripLogo from "../components/SnapStrip/assets/logo.png";
import snapPlayers from "../components/SnapStrip/assets/players.svg";
import snapTile from "../components/SnapStrip/assets/tile.png";
import stripItemPlay from "../components/StripItem/assets/play.svg";
import topPrizeBackground from "../components/TopPrize/assets/topPrizeBg.svg";
import tournamentBackground from "../components/TournamentStrip/assets/background.png";
import tournamentHeaderIcon from "../components/TournamentStrip/assets/header-icon.svg";
import tournamentPreview from "../components/TournamentStrip/assets/preview-icon.png";
import tournamentTile from "../components/TournamentStrip/assets/tile.png";
import bannerBackground from "../containers/BannerContainer/assets/bannerBackground.png";
import dealerChip from "../containers/DealerIndication/assets/dealer-chip.svg";
import reflectorLight from "../containers/ReflectorLights/assets/reflector-light.png";
import blastWaitingIcon from "../screens/BlastWaitingLobby/BlastWaitingIcon/assets/icon.svg";
import blastWaitingIconCheckMark from "../screens/BlastWaitingLobby/CheckContainer/assets/checkmark.svg";

const cachesImages = (images: string[]) => {
  for (let index = 0; index < images.length; index++) {
    const img = new Image();
    img.src = images[index];
  }
};

export default function useImagesPreloaded() {
  useLayoutEffect(() => {
    const images = [
      knockoutPkoIcon,
      secondChanceIcon,
      allInIcon,
      broadcastIcon,
      headsUpIcon,
      multiDayIcon,
      multiFlightIcon,
      mysteryBountyIcon,
      privateIcon,
      rebuyAddonIcon,
      shortHandedIcon,
      showMeIcon,
      snapIcon,
      superTurboIcon,
      turboIcon,
      blueTile,
      cyanTile,
      greenTile,
      orangeTile,
      redTile,
      yellowTile,
      purpleTile,
      cashTile,
      snapTile,
      tournamentTile,
      betChip,
      sliderThumb,
      blastDecalsBlastLogo,
      tableLightningPortrait,
      tableLightningLandscape,
      blastLogo,
      cashLogo,
      dealerChip,
      emptySeat,
      king,
      hamburgerMenuButton,
      backspaceKeyboarArrow,
      closeBottonKeyboard,
      keyboardIcon,
      popupCloseIcon,
      potChip,
      prizePoolPortrait,
      prizePoolLandscape,
      blastTableLandscape,
      blastTablePortrait,
      cashTableLandscape,
      cashTablePortrait,
      topPrizeBackground,
      blastWaitingIcon,
      blastWaitingIconCheckMark,
      badBeat,
      haha,
      hurryUp,
      goodGame,
      omg,
      sorry,
      thankYou,
      tilt,
      shipIt,
      rabbitSprite,
      rabbitOnCard,
      rabbitCardBackground,
      increment,
      decrement,
      thumb,
      blastBackground,
      blastStripLogo,
      prizeBlur,
      blastHeaderIcon,
      cashBackground,
      cashHeaderIcon,
      cashPreview,
      headerArrow,
      snapLogo,
      snapBackground,
      snapHeaderIcon,
      snapStripLogo,
      snapPlayers,
      stripItemPlay,
      tournamentBackground,
      default888Tour,
      dashTour,
      bigShotTour,
      classicTour,
      mayhemTour,
      mysteryBountyTour,
      rumbleTour,
      voyageTour,
      tournamentHeaderIcon,
      tournamentPreview,
      bannerBackground,
      rebuyChip,
      finalTablePortrait,
      finalTableLandscape,
      reflectorLight,
      money,
      space,
      balloon,
      tourLogo,
    ];

    cachesImages(images);
  }, []);
}
