import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  abandonResult,
  breakEnd,
  ftdAvailabilityChanged,
  ftdNegotiationStatus,
  ftdReconnectionDetails,
  ftdSelectionError,
  gameStarted,
  h4hDeactivated,
  h4hInProcess,
  positionInTournament,
  prizeInfo,
  prizePoolReceived,
  resetFtdSelectionError,
  stateRestored,
  tournamentCancelled,
  tournamentEnded,
  tournamentFinished,
  tourTableJoined,
} from "../actions";
import initialState from "../initialStates/tournament";
import Tournament from "../types/Tournament";

export const tournament = createReducer<Tournament>(initialState, (builder) => {
  builder
    .addCase(tourTableJoined, (state, { payload }) => {
      state.tournamentId = payload.tournamentId;
      state.pokerType = payload.pokerType;
      state.tourName = payload.tourName;
      state.tournamentType = payload.tournamentType;
      state.attributesMask = payload.attributesMask;
      state.subTypeMask = payload.subTypeMask;
      state.tourNames = payload.tourNames;
      state.blastPoolId = payload.blastBuyInId;
      state.blastMultiplayerId = payload.blastMultiplayerId;
      state.tourStartTime = payload.tourStartTime;
      state.lateRegDuration = payload.lateRegDuration;
    })
    .addCase(prizePoolReceived, (state, { payload }) => {
      state.prizePool = payload;
    })
    .addCase(tournamentCancelled, (state) => {
      state.tournamentCancelled = true;
    })
    .addCase(tournamentEnded, (state) => {
      state.tournamentEnded = true;
    })
    .addCase(tournamentFinished, (state, { payload }) => {
      state.result = {
        position: payload.position,
        players: payload.players,
        addons: payload.addons,
        rebuys: payload.rebuys,
        addonCost: payload.addonCost,
        rebuyCost: payload.rebuyCost,
        prize: payload.prize,
        rematch: payload.rematch,
        availableReEntries: payload.availableReEntries,
        lateRegSecondsLeft: payload.lateRegSecondsLeft,
        abandoned: false,
      };
    })
    .addCase(abandonResult, (state) => {
      if (state.result) {
        state.result.abandoned = true;
      }
    })
    .addCase(ftdAvailabilityChanged, (state, { payload }) => {
      state.ftdAllowed = payload.allowed;
    })
    .addCase(ftdNegotiationStatus, (state, { payload }) => {
      state.ftdNegotiationData = payload.active ? payload : undefined;
    })
    .addCase(ftdReconnectionDetails, (state, { payload }) => {
      state.ftdAllowed = payload.allowed;
      const { negotiationStatus } = payload;

      state.ftdNegotiationData = negotiationStatus.active
        ? negotiationStatus
        : undefined;
    })
    .addCase(ftdSelectionError, (state, { payload }) => {
      state.ftdBlocked = true;
      state.ftdSelectionError = payload;
    })
    .addCase(resetFtdSelectionError, (state) => {
      state.ftdSelectionError = undefined;
    })
    .addCase(gameStarted, (state) => {
      state.ftdBlocked = false;
      state.ftdSelectionError = undefined;
      state.h4hActive = false;
    })
    .addCase(stateRestored, (_, { payload }) => {
      if ("tournament" in payload) {
        return payload.tournament;
      }
    })
    .addCase(positionInTournament, (state, { payload }) => {
      state.heroPosition = payload.heroPosition;
      state.playersLeft = payload.playersLeft;
    })
    .addCase(prizeInfo, (state, { payload }) => {
      state.totalPrizePool = payload.totalPrizePool;
    })
    .addCase(h4hInProcess, (state) => {
      state.h4hActive = true;
    })
    .addCase(h4hDeactivated, (state) => {
      state.h4hActive = false;
    })
    .addMatcher(createActionMatcher([gameStarted, breakEnd]), (state) => {
      state.isStarted = true;
    });
});
