import { IPlayerSegmentationData } from "@sparkware/uc-sdk-core";

export default interface User {
  cid?: number;
  userName?: string;
  appSessionId?: string;
  reconnectionError?: Error;
  autoLogin?: string;
  userInfo?: UserInfo;
  countryId?: number;
  userStatus?: number;
  accountAttributesError?: Error;
  regulationType?: number;
  currentPaymentType: PaymentType;
  notification?: CMANotification;
  bonus?: Bonus;
  sessionId?: number;
  initialSessionId?: number;
  serverId?: number;
  authenticationError?: string;
  segmentation?: IPlayerSegmentationData;
  isReconnecting?: boolean;
  nightLimitReached: boolean;
  nightLimitExpirationDate?: number;
}
interface Bonus {
  text: string;
}

export type PaymentType = "cash" | "bonus";

type CMANotification = {
  type: "CMA";
  paymentType: PaymentType;
  withdrawnRealAmount: number;
  withdrawnRestrictedAmount: number;
};

export interface UserInfo {
  bankroll: number;
  bankrollCurrencyCode: string;
  bankrollInDollars: number;
  exchangeRateToDollar: number;
  exchangeRateDollarToBankrollCurrency: number;
}

export interface Error {
  errorCode: number;
  reason?: number;
  errorMessage?: string;
}

export interface BonusesError {
  errorCode: number;
  errorDescription: string;
}

export enum KickToLobbyReason {
  DAILY_TIME_LIMIT_REACHED = 5,
  WEEKLY_TIME_LIMIT_REACHED = 6,
  MONTHLY_TIME_LIMIT_REACHED = 7,
  NIGHT_LIMIT_REACHED = 8,
}

export interface KickToLobby {
  reasonId: KickToLobbyReason;
  expirationDate?: number;
  correlationId: string;
}
