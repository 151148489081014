import { LimitType } from "../../../network/protocol/gameServer/types/LimitType";
import { PokerType } from "../../../network/protocol/gameServer/types/PokerType";
import Table from "../types/Table";

const initialState: Table = {
  id: "",
  minBuyIn: NaN,
  maxBuyIn: NaN,
  minPlayers: NaN,
  maxPlayers: NaN,
  pokerType: PokerType.UNKNOWN,
  limitType: LimitType.UNKNOWN,
  isFinalTable: false,
  isFinalPlayers: false,
  isObserverChatEnable: false,
  cards: null,
  dealerSeatId: NaN,
  rabbitCards: null,
  thrownObjects: [],
  handId: 0,
  bigBlind: NaN,
  smallBlind: NaN,
  isSnapMode: false,
  isHandFinished: true,
};

export default initialState;
