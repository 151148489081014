import { put, race, select, take } from "redux-saga/effects";
import {
  POKER_SC_BUY_IN_ERR_BFX,
  POKER_SC_SUCCESSFULL_RE_BUY,
  performBuyIn,
} from "../../../../network/protocol/gameServer";
import {
  BuyInErrBfx,
  GameServerMessage,
  ReBuySuccess,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { buyInError, reBuySuccess } from "../../actions";
import {
  getHeroBuyInSelector,
  getHeroSelector,
  getRebuyErrorSelector,
  getRebuyPendingSelector,
  getTableSelector,
} from "../../selectors";
import Player from "../../types/Player";
import Table from "../../types/Table";

export default function* cashBuyIn(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const hero: Player | undefined = yield select(getHeroSelector);
  const heroBuyIn: number | undefined = yield select(getHeroBuyInSelector);
  const { maxBuyIn }: Table = yield select(getTableSelector);
  const rebuyPending: boolean | undefined = yield select(
    getRebuyPendingSelector,
  );
  const rebuyError: BuyInErrBfx | undefined = yield select(
    getRebuyErrorSelector,
  );

  if (!(hero && heroBuyIn) || rebuyPending || rebuyError) return;

  const creditDiff = Math.min(heroBuyIn, maxBuyIn) - hero.credit;

  if (creditDiff > 0) {
    connection.send(performBuyIn(creditDiff));

    const [response, error]: [ReBuySuccess, BuyInErrBfx] = yield race([
      take(connection.messages, POKER_SC_SUCCESSFULL_RE_BUY),
      take(connection.messages, POKER_SC_BUY_IN_ERR_BFX),
    ]);

    if (response) {
      yield put(reBuySuccess(response));
    } else if (error) {
      yield put(buyInError(error));
    }
  }
}
