import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
  UnknownAction,
} from "@reduxjs/toolkit";

export const createActionMatcher = <
  A extends ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload,
>(
  actions: A[],
) => {
  return (action: UnknownAction): action is ReturnType<A> =>
    actions.some((a) => a.type === action.type);
};
