import { put, race, take } from "redux-saga/effects";
import {
  POKER_SC_JOIN_POS_FAILED,
  POKER_SC_JOIN_POS_OK,
  joinToPosition,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  JoinPositionErr,
  JoinPositionOk,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { BuyIn, joinPositionError } from "../../actions";

export default function* joinSnapPosition(
  connection: MessagesCommunication<GameServerMessage>,
  action: BuyIn,
) {
  connection.send(
    joinToPosition(0, action.payload.credit, action.payload.avatarId),
  );

  const [, error]: [JoinPositionOk, JoinPositionErr] = yield race([
    take(connection.messages, POKER_SC_JOIN_POS_OK),
    take(connection.messages, POKER_SC_JOIN_POS_FAILED),
  ]);

  if (error) {
    yield put(joinPositionError({ seatId: error.seatId, error: error.error }));
  }
}
