import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const StyledContainer = styled.div<{ $landscape: boolean }>`
  position: absolute;

  left: 0;

  display: flex;
  justify-content: center;

  pointer-events: none;

  ${({ $landscape }) =>
    $landscape
      ? ""
      : css`
          > * {
            margin-bottom: 81px;
          }
        `}
`;
