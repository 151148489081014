import { fork, takeEvery } from "redux-saga/effects";
import {
  LOBBY_SC_CHAT_EX,
  POKER_SC_ALL_INS,
  POKER_SC_ALL_IN_EQUITY,
  POKER_SC_BIG_BLIND_POSTED,
  POKER_SC_CALL,
  POKER_SC_CHECK,
  POKER_SC_CLOSE_TABLE,
  POKER_SC_DEAD_PENALTY_BET_POSTED,
  POKER_SC_FOLD,
  POKER_SC_JOIN_POS_OK,
  POKER_SC_PLAYER_REGULATION_DATA,
  POKER_SC_RABBIT_HUNT,
  POKER_SC_RAISE_VAR_POSTED,
  POKER_SC_REMOVE,
  POKER_SC_SET_ANTE_POSTED,
  POKER_SC_SET_CARDS,
  POKER_SC_SIT_IN_LIVE_PENALTY_POSTED,
  POKER_SC_SMALL_BLIND_POSTED,
  POKER_SC_START_GAME,
  POKER_SC_START_ROUND_2,
  POKER_SC_START_ROUND_3,
  POKER_SC_START_ROUND_4,
  POKER_SC_START_SHOW_CARD_ROUND,
  POKER_SC_THROW,
} from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  allInEquity,
  allInsPosted,
  antePosted,
  betCalled,
  betRaised,
  bigBlindPosted,
  call,
  callAllIns as callAllInsAction,
  chatMessageReceived,
  chatMessageSent,
  check as checkAction,
  checked,
  deadPenaltyPosted,
  dontShowCards as dontShowCardsAction,
  fold as foldAction,
  folded,
  gameStarted,
  heroCardsReceived,
  heroSatDown,
  livePenaltyPosted,
  playerRegulationData,
  rabbitHunt,
  raise as raiseAction,
  raiseAllIns as raiseAllInsAction,
  removePlayer,
  round2Started,
  round3Started,
  round4Started,
  showCards as showCardsAction,
  showCardsRoundStarted,
  smallBlindPosted,
  tableClosed,
  throwItemSent,
  thrownObject,
} from "../../actions";
import callAllIns from "./callAllIns";
import callBet from "./callBet";
import chatMessage from "./chatMessage";
import check from "./check";
import clearTable from "./clearTable";
import dontShowCards from "./dontShowCards";
import dontShowedCardsHandler from "./dontShowedCardsHandler";
import fold from "./fold";
import gameResultHandler from "./gameResultHandler";
import getPlayersInfo from "./getPlayersInfo";
import pushMessageHandler from "./pushMessageHandler";
import raise from "./raise";
import raiseAllIns from "./raiseAllIns";
import roundOneStarted from "./roundOneStarted";
import setTurn from "./setTurn";
import setTurnTimebank from "./setTurnTimebank";
import showCards from "./showCards";
import showedCardsHandler from "./showedCardsHandler";
import throwItem from "./throwItem";

export default function* genericGameFlow(
  connection: MessagesCommunication<GameServerMessage>,
) {
  yield takeEvery(call.type, callBet, connection);
  yield takeEvery(checkAction.type, check, connection);
  yield takeEvery(callAllInsAction.type, callAllIns, connection);
  yield takeEvery(raiseAction.type, raise, connection);
  yield takeEvery(raiseAllInsAction.type, raiseAllIns, connection);
  yield takeEvery(foldAction.type, fold, connection);
  yield takeEvery(showCardsAction.type, showCards, connection);
  yield takeEvery(dontShowCardsAction.type, dontShowCards, connection);
  yield takeEvery(chatMessageSent.type, chatMessage, connection);
  yield takeEvery(throwItemSent.type, throwItem, connection);

  yield fork(getPlayersInfo, connection);

  yield fork(pushMessageHandler, POKER_SC_START_GAME, gameStarted, connection);

  yield fork(roundOneStarted, connection);
  yield fork(
    pushMessageHandler,
    POKER_SC_START_ROUND_2,
    round2Started,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_START_ROUND_3,
    round3Started,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_START_ROUND_4,
    round4Started,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_START_SHOW_CARD_ROUND,
    showCardsRoundStarted,
    connection,
  );
  yield fork(clearTable, connection);
  yield fork(pushMessageHandler, POKER_SC_ALL_INS, allInsPosted, connection);
  yield fork(
    pushMessageHandler,
    POKER_SC_SET_ANTE_POSTED,
    antePosted,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_SMALL_BLIND_POSTED,
    smallBlindPosted,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_BIG_BLIND_POSTED,
    bigBlindPosted,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_SIT_IN_LIVE_PENALTY_POSTED,
    livePenaltyPosted,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_DEAD_PENALTY_BET_POSTED,
    deadPenaltyPosted,
    connection,
  );

  yield fork(pushMessageHandler, POKER_SC_FOLD, folded, connection);
  yield fork(pushMessageHandler, POKER_SC_CHECK, checked, connection);
  yield fork(pushMessageHandler, POKER_SC_CALL, betCalled, connection);
  yield fork(
    pushMessageHandler,
    POKER_SC_RAISE_VAR_POSTED,
    betRaised,
    connection,
  );

  yield fork(pushMessageHandler, POKER_SC_JOIN_POS_OK, heroSatDown, connection);
  yield fork(showedCardsHandler, connection);
  yield fork(dontShowedCardsHandler, connection);
  yield fork(setTurn, connection);
  yield fork(setTurnTimebank, connection);

  yield fork(
    pushMessageHandler,
    POKER_SC_SET_CARDS,
    heroCardsReceived,
    connection,
  );
  yield fork(pushMessageHandler, POKER_SC_REMOVE, removePlayer, connection);
  yield fork(
    pushMessageHandler,
    LOBBY_SC_CHAT_EX,
    chatMessageReceived,
    connection,
  );
  yield fork(
    pushMessageHandler,
    POKER_SC_ALL_IN_EQUITY,
    allInEquity,
    connection,
  );
  yield fork(gameResultHandler, connection);
  yield fork(pushMessageHandler, POKER_SC_RABBIT_HUNT, rabbitHunt, connection);
  yield fork(pushMessageHandler, POKER_SC_THROW, thrownObject, connection);
  yield fork(pushMessageHandler, POKER_SC_CLOSE_TABLE, tableClosed, connection);
  yield fork(
    pushMessageHandler,
    POKER_SC_PLAYER_REGULATION_DATA,
    playerRegulationData,
    connection,
  );
}
