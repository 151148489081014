import { PropsWithChildren, createContext } from "react";
import { useSelector } from "react-redux";
import { getUserSelector } from "../store/app/selectors";
import { getPersonalAvatarId } from "../utils/avatar";

export const PersonalAvatarOwnerContext = createContext(false);

export default function PersonalAvatarOwnerProvider({
  children,
}: PropsWithChildren) {
  const { cid, userStatus } = useSelector(getUserSelector);
  const isPersonalAvatarOwner =
    cid !== undefined &&
    userStatus !== undefined &&
    userStatus > 0 &&
    getPersonalAvatarId(cid) !== undefined;
  return (
    <PersonalAvatarOwnerContext.Provider value={isPersonalAvatarOwner}>
      {children}
    </PersonalAvatarOwnerContext.Provider>
  );
}
