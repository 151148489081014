import { useSelector } from "react-redux";

import { forwardRef } from "react";
import {
  CardsIndicator,
  SitOutIndicator,
  UserTurnIndicator,
} from "../../components/GameStateIndicator";
import FtdIndicator from "../../components/GameStateIndicator/FtdIndicator";
import {
  getHeroFocusableTurnSelector,
  getHeroSelector,
  getTournamentSelector,
} from "../../store/game/selectors";
import { getTurnProgressData } from "../../utils/getTurnProgressData";
import { GameStateIndicatorContainerProps } from "./types";

export const GameStateIndicatorContainer = forwardRef<
  HTMLButtonElement,
  GameStateIndicatorContainerProps
>((props, ref) => {
  const heroTurn = useSelector(getHeroFocusableTurnSelector);
  const hero = useSelector(getHeroSelector);
  const tournament = useSelector(getTournamentSelector);

  const progressBarProps = getTurnProgressData(heroTurn);

  const cards = hero?.cards;

  if (hero?.sittingOut)
    return <SitOutIndicator ref={ref} cards={cards} {...props} />;

  if (progressBarProps)
    return (
      <UserTurnIndicator
        ref={ref}
        cards={cards}
        {...props}
        {...progressBarProps}
      />
    );

  if (tournament && tournament.ftdNegotiationData) {
    const ftdNegotiationData = tournament.ftdNegotiationData;
    return (
      <FtdIndicator
        notified={hero?.deal === "pending"}
        kind="normal"
        starts={ftdNegotiationData.syncDate}
        ends={ftdNegotiationData.syncDate + ftdNegotiationData.remaining * 1000}
        {...props}
      />
    );
  }

  return <CardsIndicator ref={ref} cards={cards} {...props} />;
});
