import {
  SELECT_AVATAR,
  TOGGLE_AUTO_FOCUS,
  TOGGLE_BIG_BLIND_FORMAT,
  TOGGLE_EMOJIS,
  TOGGLE_EMOJI_QUICK_PANEL,
  TOGGLE_FOUR_COLOR_DECK,
  TOGGLE_SOUNDS,
  TOGGLE_TOP_UP,
} from "./reducer";

export function toggleSounds() {
  return {
    type: TOGGLE_SOUNDS,
  } as const;
}

export function toggleEmojis() {
  return {
    type: TOGGLE_EMOJIS,
  } as const;
}

export function toggleEmojiQuickPanel() {
  return {
    type: TOGGLE_EMOJI_QUICK_PANEL,
  } as const;
}

export function toggleFourColorDeck() {
  return {
    type: TOGGLE_FOUR_COLOR_DECK,
  } as const;
}

export function selectAvatar(id: number) {
  return {
    avatarId: id,
    type: SELECT_AVATAR,
  } as const;
}

export function toggleBigBlindFormat() {
  return {
    type: TOGGLE_BIG_BLIND_FORMAT,
  } as const;
}

export function toggleTopUp() {
  return {
    type: TOGGLE_TOP_UP,
  } as const;
}

export function toggleAutoFocus() {
  return {
    type: TOGGLE_AUTO_FOCUS,
  } as const;
}
