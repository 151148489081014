export interface RingServerResponse {
  CashGroupData: ServerDataType;
  LastTimeStamp: number;
}

export interface ServerDataType {
  [CreatorId: number]: Omit<RingGameInfo, "CreatorId">;
}

export enum RingGameType {
  SNAP = 1,
  CASH = 0,
}

export enum MaxPlayersNumber {
  Holdem = 6,
}

export interface RingGameInfo {
  BigBlind: number;
  GameType: number;
  IsShowInBeginners: number;
  IsSnap: number;
  LimitType: number;
  LowerLimit: number;
  MaxBuyIn: number;
  MaxPlayers: number;
  MinBuyIn: number;
  NumberOfTables: number;
  ServerId: number;
  SmallBlind: number;
  StakesRange: number;
  TableAttributesMask: number;
  TableIconsMask: number;
  TableId: number;
  TotalPlayers: number;
  UnlimitedTableAttributesMask: number[];
  UpperLimit: number;
  CreatorId: number;
}
