import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

const transitionDuration = "300ms";

export const ToggleControl = styled.input`
  width: 4em;
  height: 1.95em;
  padding: 0.1em;
  border: ${({ theme }) => `0.15em solid ${theme.toggle.background.border}`};
  border-radius: 1.5em;

  font-size: inherit;

  background-color: ${({ theme }) => theme.toggle.background.off};

  transition: background-color ${transitionDuration};

  appearance: none;

  &::after {
    display: block;

    width: 1.5em;
    height: 1.45em;
    border-radius: 50%;

    ${({ theme }) => css`
      background: ${theme.toggle.knob.background};
      box-shadow: 0 0.1em 0.2em ${theme.toggle.knob.boxShadow};
    `}

    transition: transform ${transitionDuration};

    content: "";
  }

  &:checked {
    background-color: ${({ theme }) => theme.toggle.background.on};

    &::after {
      transform: translateX(2.1em);
    }
  }

  &:focus {
    outline: 0;
  }
`;
