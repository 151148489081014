import { CSSProperties, PropsWithChildren } from "react";
import Styleable from "../../../utils/types/Styleable";
import { Wrapper } from "./styles";
import { BottomSectionProps } from "./types";

export function BottomSection(
  props: Styleable<PropsWithChildren<BottomSectionProps>>,
) {
  const { offsetX, contentScale, sourceWidth, style, className, children } =
    props;

  const elementStyle: CSSProperties = {
    transform: `translateX(${offsetX}px) scale(${contentScale})`,
    width: sourceWidth,
  };

  return (
    <Wrapper className={className} style={{ ...elementStyle, ...style }}>
      {children}
    </Wrapper>
  );
}
