import CommunicationError from "../CommunicationError";

export default class CommunicationLifecycleEvent extends Event {
  static readonly DISCONNECTED = "disconnected";
  static readonly CONNECTED = "connected";

  error: CommunicationError | null;

  constructor(
    type:
      | typeof CommunicationLifecycleEvent.CONNECTED
      | typeof CommunicationLifecycleEvent.DISCONNECTED,
    error: CommunicationError | null = null,
  ) {
    super(type);

    this.error = error;
  }
}
