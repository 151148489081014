import Styleable from "../../../utils/types/Styleable";
import { cardSuits } from "./cardSuits";
import CardBody from "./components";
import { CARD_VIEWBOX, StyledCardPaper } from "./styles";
import { CardProps } from "./types";

export default function SmallCard({
  suit,
  rank,
  active,
  ...rest
}: Styleable<CardProps>) {
  const data = cardSuits[suit];
  return (
    <StyledCardPaper
      $active={active}
      $suit={suit}
      data-testid={`poker-card_${rank}_${suit}`}
      viewBox={`0 0 ${CARD_VIEWBOX.width} ${CARD_VIEWBOX.height}`}
      {...rest}
    >
      <CardBody rank={rank} data={data} />
    </StyledCardPaper>
  );
}
