import { put } from "redux-saga/effects";
import { APP_WILL_CLOSE } from "../../../../network/CommunicationError";
import MainServer from "../../../../network/MainServer";
import { leaveProxy } from "../../../../network/protocol/mainServer";
import { appClosed, logout } from "../../actions";

export default function* closeApp(
  mainServer: MainServer,
  preventReconnection: boolean,
) {
  yield put(logout());

  if (mainServer.connected) {
    mainServer.send(leaveProxy());
    mainServer.close(preventReconnection ? new Error(APP_WILL_CLOSE) : null);
  }

  yield put(appClosed());
}
