import EventTarget from "@ungap/event-target";
import Communication from "./Communication";
import { GameServerEventMap } from "./events";
import MessageEvent from "./events/MessageEvent";
import { parsers } from "./protocol/gameServer";
import {
  GameServerMessage,
  GameServerMessageType,
} from "./protocol/gameServer/types";
import type { IncomingMessage, OutgoingMessage } from "./protocol/types";

export default class GameServer implements Communication<GameServerEventMap> {
  private _dispatcher: EventTarget;
  private _send: ((message: OutgoingMessage) => void) | undefined;
  constructor(sender?: (message: OutgoingMessage) => void) {
    this._dispatcher = new EventTarget();
    this._send = sender;

    this.send = this.send.bind(this);
    this.dispatchMessage = this.dispatchMessage.bind(this);
  }

  send(message: OutgoingMessage): void {
    if (this._send) this._send(message);
  }

  dispatchMessage(gameMessage: IncomingMessage) {
    const parser = parsers[gameMessage.type as GameServerMessageType];

    if (parser) {
      this.dispatchEvent(
        new MessageEvent<GameServerMessage>(parser(gameMessage)),
      );
    }
  }
  addEventListener<K extends keyof GameServerEventMap>(
    type: K,
    listener: (event: GameServerEventMap[K]) => void,
    options?: boolean | AddEventListenerOptions,
  ): void {
    this._dispatcher.addEventListener(
      type,
      listener as unknown as EventListener,
      options,
    );
  }
  removeEventListener<K extends keyof GameServerEventMap>(
    type: K,
    callback: (event: GameServerEventMap[K]) => void,
    options?: EventListenerOptions | boolean,
  ): void {
    this._dispatcher.removeEventListener(
      type,
      callback as unknown as EventListener,
      options,
    );
  }

  dispatchEvent(event: Event): boolean {
    return this._dispatcher.dispatchEvent(event);
  }
}
