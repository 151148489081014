import { RingGameAttribute } from "../network/protocol/mainServer/types";

export const supportedRingGamesAttributes =
  RingGameAttribute.SNAP |
  RingGameAttribute.SUPPORT_SHORT_HANDED |
  RingGameAttribute.ENABLE_OBSERVER_CHAT |
  RingGameAttribute.SHOW_IN_BEGINNERS;

export function isRingGamesSupported(attributes: number) {
  return (attributes & ~supportedRingGamesAttributes) === 0;
}
