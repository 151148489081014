import PreActions from "../types/PreActions";

const initialState: PreActions = {
  checkOrFold: false,
  check: false,
  amountToCall: NaN,
  isSnapMode: false,
};

export default initialState;
