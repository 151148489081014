export enum JoinRingGameError {
  JOIN_RING_GAME_ERROR_INSUFFICIENT_INFO_VERSION = 1,
  JOIN_RING_GAME_ERROR_NO_SUCH_TABLE = 2,
  JOIN_RING_GAME_ERROR_GENERAL = 3,
  JOIN_RING_GAME_ERROR_MAY_NOT_RECONNECT = 4,
  JOIN_RING_GAME_ERROR_LEAVE_INCOMPLETE = 5,
  JOIN_RING_GAME_ERROR_RESTRICTED_TABLE = 6,
  JOIN_RING_GAME_ERROR_VIEWERS_LIMITATION = 7,
  JOIN_RING_GAME_ERROR_PLAYER_TO_VIEW_ABSENT = 8, //Unable to attach Viewer to Player with given Instance Id. Player is not available.
  JOIN_RING_GAME_ERROR_CANT_VIEW_SELF = 9, //Viewer wants to view his playing instance
  JOIN_RING_GAME_ERROR_INSTANCE_LIMITATION = 10, //Available limit of Instances per Snap Player is reached but player wants to register new instance
  JOIN_RING_GAME_ERROR_HAND_ID_NOT_EXISTS = 11, //Attempt to view hand that is not exists
  JOIN_RING_GAME_ERROR_SELF_EXCLUDED_FROM_SNAP = 12, //Self-defined limitation restricts participation in Snap Ring Games
  JOIN_RING_GAME_ERROR_TIME_LIMIT = 13,
  JOIN_RING_GAME_ERROR_PENDING_ACTION = 14,
  JOIN_TABLE_ERR_NIGHT_LIMIT_REACHED = 15,
}
