import { put, select, take } from "redux-saga/effects";
import { PROXY_CLIENT_SET_RECONNECTION_FLAG } from "../../../../network/protocol/mainServer";
import { MainServerMessage } from "../../../../network/protocol/mainServer/types";
import { userKickedFromServer } from "../../actions";
import { isAuthenticatedSelector } from "../../selectors";
import MessagesCommunication from "./messagesCommunication";

export default function* kickUserHandler(
  connection: MessagesCommunication<MainServerMessage>,
) {
  while (true) {
    yield take(connection.messages, PROXY_CLIENT_SET_RECONNECTION_FLAG);
    const isAuthenticated: boolean = yield select(isAuthenticatedSelector);

    if (isAuthenticated) {
      yield put(userKickedFromServer());
    }
  }
}
