import { put, take } from "redux-saga/effects";
import { POKER_SC_PUSH_NOTIFICATION } from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  PushNotification,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { positionInTournament, prizeInfo } from "../../actions";

export default function* pushNotificationHandler(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const data: PushNotification = yield take(
      connection.messages,
      POKER_SC_PUSH_NOTIFICATION,
    );

    if (data.positionInTournament) {
      yield put(positionInTournament(data.positionInTournament));
    }

    if (data.prizeInfo) {
      yield put(prizeInfo(data.prizeInfo));
    }
  }
}
