import { PropsWithChildren, useMemo, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { OrientationContext } from "../../context";
import useScaleToFit from "../../hooks/useScaleToFit";
import type Styleable from "../../utils/types/Styleable";
import CloseButton from "../CloseButton";
import {
  ButtonLayout,
  POPUP_TRANSITION,
  PopupContent,
  PopupOverlay,
  PopupWrapper,
} from "./styles";
import { PopupProps } from "./types";

export default function Popup({
  open = false,
  closeOnOverlay = true,
  onClose,
  children,
  dataTestId,
  ...styleProps
}: PropsWithChildren<Styleable<PopupProps>>) {
  const containerRef = useRef(null);
  const scaleObject = useScaleToFit(containerRef, 2);
  const orientation = useMemo(
    () => ({
      isLandscape: scaleObject.landscape,
      isSmallScreen: scaleObject.smallContainer,
    }),
    [scaleObject.landscape, scaleObject.smallContainer],
  );

  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <PopupWrapper data-testid={dataTestId} {...styleProps} ref={containerRef}>
      <TransitionGroup component={null}>
        {open && (
          <CSSTransition
            timeout={POPUP_TRANSITION}
            classNames="popup"
            nodeRef={nodeRef}
          >
            <PopupOverlay
              onClick={closeOnOverlay ? onClose : undefined}
              data-testid="poker-popup-overlay"
              ref={nodeRef}
            >
              <div style={{ transform: `scale(${scaleObject.contentScale})` }}>
                <PopupContent
                  onClick={(event) => event.stopPropagation()}
                  data-testid="poker-popup-content"
                >
                  <OrientationContext.Provider value={orientation}>
                    {children}
                  </OrientationContext.Provider>
                </PopupContent>
                {onClose && (
                  <ButtonLayout>
                    <CloseButton onPress={onClose} />
                  </ButtonLayout>
                )}
              </div>
            </PopupOverlay>
          </CSSTransition>
        )}
      </TransitionGroup>
    </PopupWrapper>
  );
}
