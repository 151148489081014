import { PropsWithChildren } from "react";
import useSound from "../../hooks/useSound";
import { MenuItemButton, MenuItemLabel } from "./styles";
import { HamburgerMenuItemProps } from "./types";

export function MenuItem({
  testId,
  onClick,
  startIcon,
  endIcon,
  disabled,
  children,
  active = false,
}: PropsWithChildren<HamburgerMenuItemProps>) {
  const { play } = useSound();
  return (
    <MenuItemButton
      $active={active}
      onClick={() => {
        onClick?.();
        play("click");
      }}
      disabled={disabled}
      data-testid={testId}
    >
      {startIcon}
      {children && (
        <MenuItemLabel data-testid="poker-item-label">{children}</MenuItemLabel>
      )}
      {endIcon}
    </MenuItemButton>
  );
}
