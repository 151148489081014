export enum BreakType {
  SYNC = 0,
  SEATING = 1,
  REBUY = 2,
  ADDON = 3,
  FTDEAL = 4,
  CANCEL_FTDEAL = 5,
  END_FTDEAL = 6,
  SUSPEND = 7,
  SUSPEND_RESUME = 8,
  MULTIDAY = 9,
}

export default interface Break {
  breakType: BreakType;
  totalTime: number;
  duration: number;
  ends: number;
}
