import { put, race, select, take } from "redux-saga/effects";
import {
  LOBBY_SC_JOIN_POKER_RING_GAME_ERR_BFX,
  LOBBY_SC_JOIN_POKER_RING_GAME_OK,
  joinRingGame,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  JoinRingGameErrorBfx,
  JoinRingGameOk,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { joinRingGameError, ringGameJoined } from "../../actions";
import {
  getGameSelector,
  getHeroSelector,
  getPlayerInstanceIdSelector,
  getTableSelector,
} from "../../selectors";
import Game from "../../types/Game";
import Player from "../../types/Player";
import Table from "../../types/Table";

export default function* joinToRingGame(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const gameState: Game = yield select(getGameSelector);
  const playerInstanceId: number = yield select(getPlayerInstanceIdSelector);
  const hero: Player | undefined = yield select(getHeroSelector);
  const table: Table = yield select(getTableSelector);

  connection.send(
    joinRingGame({
      tableId: gameState.gameInstanceId,
      playerInstanceId,
      asPlayer:
        gameState.type === "snap" ||
        Boolean((hero && hero.seatId > 0) || gameState.inializedByOpenSession),
      handId: table.handId,
    }),
  );

  const [response, error]: [JoinRingGameOk, JoinRingGameErrorBfx] = yield race([
    take(connection.messages, LOBBY_SC_JOIN_POKER_RING_GAME_OK),
    take(connection.messages, LOBBY_SC_JOIN_POKER_RING_GAME_ERR_BFX),
  ]);

  if (response) {
    yield put(ringGameJoined(response));
  } else if (error) {
    yield put(joinRingGameError(error));
  }
}
