import Turn from "../store/game/types/Turn";
import isRoundTurn from "./isRoundTurn";
import ProgressTurnData from "./types/ProgressTurnData";

export function getTurnProgressData(
  turn?: Turn | null,
): ProgressTurnData | undefined {
  if (!turn) return;

  const starts = turn.syncDate;
  const ends = turn.syncDate + turn?.timeOut;
  const kind = isRoundTurn(turn) && turn.timeBankActive ? "timebank" : "normal";
  const timeBank = isRoundTurn(turn) ? turn.timeBank : undefined;

  return {
    starts,
    ends,
    kind,
    timeBank,
  };
}
