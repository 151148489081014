import styled from "@emotion/styled/macro";

export const CardsWrapper = styled.div<{
  $notified?: boolean;
}>`
  display: flex;

  height: 1.3em;

  &:last-child::after {
    content: ${({ $notified }) => ($notified ? '"!"' : "none")};

    position: absolute;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;

    background: ${({ theme }) => theme.sessionIndicator.notified.border};

    font-size: 0.45em;
    color: #fff;
    font-family: "Roboto-Bold";
    right: 0;
    transform: translate(40%, -30%);
    padding-top: 0.1em;
    box-sizing: border-box;
  }
`;
