import { forwardRef } from "react";
import TurnProgressBar from "../../TurnProgressBar/TurnProgressBar";
import { CardsDisplay } from "../CardsIndicator/CardsIndicator";
import { UserTurnWrapper } from "./styles";
import { UserTurnIndicatorProps } from "./types";

export const UserTurnIndicator = forwardRef<
  HTMLButtonElement,
  UserTurnIndicatorProps
>(({ active, disabled, cards, onClick, ...progressBar }, ref) => {
  return (
    <UserTurnWrapper
      ref={ref}
      $active={active}
      onClick={onClick}
      disabled={disabled}
    >
      <CardsDisplay active={active} notified cards={cards} />
      <TurnProgressBar {...progressBar} key={progressBar.kind} />
    </UserTurnWrapper>
  );
});
