import { PropsWithChildren, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import TransitionProps from "../../../utils/types/TransitionProps";
import { TRANSITION_DURATION } from "../styles";
import { SubPanelLayout } from "./styles";

export default function SubPanel({
  children,
  ...transitionProps
}: PropsWithChildren<TransitionProps>) {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <CSSTransition
      nodeRef={ref}
      timeout={TRANSITION_DURATION}
      classNames="menuContent"
      {...transitionProps}
    >
      <SubPanelLayout ref={ref}>{children}</SubPanelLayout>
    </CSSTransition>
  );
}
