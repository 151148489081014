import { put, select } from "redux-saga/effects";
import { authenticate, reconnect } from "../../actions";
import {
  getAutoLoginSelector,
  getRegulationTypeSelector,
  isAuthenticatedSelector,
} from "../../selectors";

export default function* prepareForLogin() {
  const isAuthenticated: boolean = yield select(isAuthenticatedSelector);
  const token: string | undefined = yield select(getAutoLoginSelector);
  const regulationType: number = yield select(getRegulationTypeSelector);

  if (isAuthenticated) {
    yield put(reconnect());
  } else if (token) {
    yield put(authenticate({ token, regulationType }));
  }
}
