import styled from "@emotion/styled/macro";

export const FullScreenContent = styled.div`
  font-family: "Roboto-Bold";

  position: absolute;
  height: 100%;
  width: 100%;

  font-size: clamp(12px, 1vw + 1vh, 24px);

  user-select: none;

  img {
    pointer-events: none;
  }
`;
