import { createAction } from "@reduxjs/toolkit";
import {
  AllInEquityData,
  AllIns,
  BreakEnd,
  BreakStart,
  BuyInErrBfx,
  ChatMessage,
  Check,
  ChooseTourDealer,
  DeadPenalty,
  Fold,
  FtdNegotiationStatus,
  FtdReconnectionDetails,
  FtdSatus,
  FtdSelectionErr,
  FtdSelectionStatus,
  GameResult,
  GetQuickPosErr,
  GetQuickPosOk,
  JoinPositionOk,
  JoinRingGameErrorBfx,
  JoinRingGameOk,
  JoinTournamentErrorBfx,
  JoinTournamentOk,
  JoinTournamentTable,
  PlayerAction,
  PlayerRegulationData,
  RabbitHuntData,
  RaisePlayerAction,
  ResevationStatusChangedErr,
  ResevationStatusChangedOk,
  RoundData,
  SetCards,
  SetCredit,
  ShowCardsRound,
  SnapPoolTableId,
  StartGame,
  ThrownObjectData,
  TourAnte,
  TournamentFinalTable,
  TourResult,
} from "../../../network/protocol/gameServer/types";
import { Cards } from "../../../network/protocol/gameServer/types/Card";
import { FoldType } from "../../../network/protocol/gameServer/types/FoldData";
import { HandHistory } from "../../../network/protocol/gameServer/types/HandHistory";
import {
  PositionInTournament,
  PrizeInfo,
} from "../../../network/protocol/gameServer/types/NotificationType";
import { MultipilierData } from "../../../utils/types/MultiplierData";
import { AnyGameState } from "../store";
import BlindLevel from "../types/BlindLevel";
import Players from "../types/Players";
import SitOutData from "../types/SitOutData";
import { RingSnapState, ThrownObject } from "../types/Table";
import { InternalHudData } from "../types/TableMessages";
import { NonRoundTurn, RoundTurn } from "../types/Turn";
import {
  HandHistoryReceivedData,
  JoinPositionData,
  JoinPositionErrorData,
  PlayerAddedData,
  PlayerCardsData,
  PlayerSatOutData,
  PlayerSeatId,
  RebuySuccessData,
  RingTAbleJoinedData,
  RoundOneStartedData,
  SeatReservationData,
  SessionDetailsData,
  TableClosedData,
  ThrowItemSentData,
  WaitingEnterPositionChangedData,
} from "./types";

export const tournamentJoined =
  createAction<Omit<JoinTournamentOk, "type">>("TOURNAMENT_JOINED");

export const joinTournamentError = createAction<
  Omit<JoinTournamentErrorBfx, "type">
>("JOIN_TOURNAMENT_ERROR");

export const tourTableJoined = createAction<Omit<JoinTournamentTable, "type">>(
  "TOURNAMENT_TABLE_JOINED",
);

export const ringGameJoined =
  createAction<Omit<JoinRingGameOk, "type">>("RING_GAME_JOINED");

export const joinRingGameError = createAction<
  Omit<JoinRingGameErrorBfx, "type">
>("JOIN_RING_GAME_ERROR");

export const ringTableJoined =
  createAction<RingTAbleJoinedData>("RING_TABLE_JOINED");

export const call = createAction("CALL");

export const check = createAction("CHECK");

export const callAllIns = createAction("CALL_ALL_INS");

export const raise = createAction<{ amount: number }>("RAISE");

export const raiseAllIns = createAction("RAISE_ALL_INS");

export const fold = createAction<FoldType | undefined>("FOLD");

export const blindLevelIncreased = createAction<BlindLevel>(
  "BLIND_LEVEL_INCREASED",
);

export const heroSatDown = createAction<JoinPositionOk>("HERO_SAT_DOWN");

export const joinPositionError = createAction<JoinPositionErrorData>(
  "JOIN_POSITION_ERROR",
);

export const playersListed = createAction<Players>("PLAYERS_LISTED");

export const breakStart = createAction<Omit<BreakStart, "type">>("BREAK_START");

export const breakEnd = createAction<Omit<BreakEnd, "type">>("BREAK_END");

export const gameStarted =
  createAction<Omit<StartGame, "type">>("GAME_STARTED");

export const round1Started =
  createAction<RoundOneStartedData>("ROUND_1_STARTED");

export const round2Started = createAction<RoundData>("ROUND_2_STARTED");

export const round3Started = createAction<RoundData>("ROUND_3_STARTED");

export const round4Started = createAction<RoundData>("ROUND_4_STARTED");

export const showCardsRoundStarted = createAction<Omit<ShowCardsRound, "type">>(
  "SHOW_CARDS_ROUND_STARTED",
);

export const gameResult = createAction<Omit<GameResult, "type">>("GAME_RESULT");

export const tableWillClear = createAction("TABLE_WILL_CLEAR");

export const allInsPosted =
  createAction<Omit<AllIns, "type">>("ALL_INS_POSTED");

export const antePosted = createAction<PlayerAction>("ANTE_POSTED");

export const smallBlindPosted =
  createAction<PlayerAction>("SMALL_BLIND_POSTED");

export const bigBlindPosted = createAction<PlayerAction>("BIG_BLIND_POSTED");

export const livePenaltyPosted = createAction<PlayerAction>(
  "LIVE_PENALTY_POSTED",
);

export const betCalled = createAction<PlayerAction>("BET_CALLED");

export const betRaised = createAction<RaisePlayerAction>("BET_RAISED");

export const deadPenaltyPosted = createAction<DeadPenalty>(
  "DEAD_PENALTY_POSTED",
);

export const uncalledAnte = createAction<TourAnte>("UNCALLED_ANTE");

export const playerCardsShowed = createAction<PlayerCardsData>(
  "PLAYER_CARDS_SHOWED",
);

export const playerDontShowCards = createAction<PlayerCardsData>(
  "PLAYER_DONT_SHOW_CARDS",
);

export const folded = createAction<Fold>("FOLD_POSTED");

export const checked = createAction<Check>("CHECK_POSTED");

export const setTurn = createAction<RoundTurn>("SET_TURN");

export const setTurnTimebank = createAction<RoundTurn>("SET_TURN_TIMEBANK");

export const playerSatIn = createAction<PlayerSeatId>("PLAYER_SAT_IN");

export const playerSatOut = createAction<PlayerSatOutData>("PLAYER_SAT_OUT");

export const heroCardsReceived = createAction<SetCards>("HERO_CARDS_RECEIVED");

export const sitIn = createAction("PLAYER_SIT_IN");

export const sitOut = createAction("PLAYER_SIT_OUT");

export const willSitOut = createAction("PLAYER_WILL_SIT_OUT");

export const showCards = createAction("SHOW_CARDS");

export const dontShowCards = createAction("DONT_SHOW_CARDS");

export const autoCheckOrFold = createAction("AUTO_CHECK_OR_FOLD");

export const autoCheck = createAction("AUTO_CHECK");

export const autoAmountToCall = createAction<{ amount: number }>(
  "AUTO_AMOUNT_TO_CALL",
);

export const stateRestored = createAction<AnyGameState>("STATE_RESTORED");

export const tournamentEnded = createAction("TOURNAMENT_ENDED");

export const tournamentFinished = createAction<TourResult>(
  "TOURNAMENT_FINISHED",
);

export const abandonResult = createAction("ABANDON_RESULT");

export const playerAdded = createAction<PlayerAddedData>("PLAYER_ADDED");

export const removePlayer = createAction<PlayerSeatId>("REMOVE_PLAYER");

export const gameWillClose = createAction("GAME_WILL_CLOSE");

export const chatMessageReceived = createAction<ChatMessage>(
  "CHAT_MESSAGE_RECEIVED",
);

export const chatMessageSent = createAction<{ message: string }>(
  "CHAT_MESSAGE_SENT",
);

export const tournamentCancelled = createAction("TOURNAMENT_CANCELLED");

export const allInEquity = createAction<AllInEquityData>("ALL_IN_EQUITY");

export const heroShowCards = createAction<Cards>("HERO_SHOW_CARDS");

export const heroDontShowCards = createAction<Cards>("HERO_DONT_SHOW_CARDS");

export const heroCardSelection = createAction<{ cardIndex: number }>(
  "HERO_CARD_SELECTION",
);

export const showCardsOnFold = createAction("SHOW_CARDS_ON_FOLD");

export const rabbitHunt = createAction<RabbitHuntData>("RABBIT_HUNT");

export const throwItemSent = createAction<ThrowItemSentData>("THROW_ITEM_SENT");

export const thrownObject = createAction<ThrownObjectData>("THROWN_OBJECT");

export const thrownObjectDelivered = createAction<ThrownObject>(
  "THROWN_OBJECT_DELIVERED",
);

export const changeSeatReservation =
  createAction<SeatReservationData>("RESERVE_SEAT");

export const heroSeatReservationChanged =
  createAction<ResevationStatusChangedOk>("HERO_SEAT_RESERVATION_CHANGED");

export const heroSeatReservationError =
  createAction<ResevationStatusChangedErr>("HERO_SEAT_RESERVATION_ERROR");

export const seatReservationChanged = createAction<SeatReservationData>(
  "SEAT_RESERVATION_CHANGED",
);

export const seatAvailable = createAction<GetQuickPosOk>("SEAT_AVAILABLE");

export const seatUnavailable = createAction<GetQuickPosErr>("SEAT_UNAVAILABLE");

export const resetJoinPositionError = createAction("RESET_JOIN_POSITION_ERROR");

export const joinPosition = createAction<JoinPositionData>("JOIN_POSITION");

export const smallBlind = createAction<NonRoundTurn>("SMALL_BLIND");

export const bigBlind = createAction<NonRoundTurn>("BIG_BLIND");

export const returnToViewer = createAction<PlayerSeatId>("RETURN_TO_VIEWER");

export const livePenalty = createAction<NonRoundTurn>("LIVE_PENALTY");

export const liveAndDeadPenalty = createAction<NonRoundTurn>(
  "LIVE_AND_DEAD_PENALTY",
);

export const waitForFirstToAct = createAction("WAIT_FOR_FIRST_TO_ACT");

export const postLivePenalty = createAction("POST_LIVE_PENALTY");

export const postLiveAndDeadPenalty = createAction(
  "POST_LIVE_AND_DEAD_PENALTY",
);

export const ringSnapState = createAction<RingSnapState>("RING_SNAP_STATE");

export const buyInError = createAction<BuyInErrBfx>("BUY_IN_ERROR");

export const resetBuyInError = createAction("RESET_BUY_IN_ERROR");

export const creditPending = createAction<{ pending: boolean }>(
  "SET_CREDIT_PENDING",
);

export const setCredit = createAction<SetCredit>("SET_CREDIT");

export const reBuySuccess = createAction<RebuySuccessData>("RE_BUY_SUCCESSFUL");

export const sessionDetails =
  createAction<SessionDetailsData>("AM_SESSION_DETAILS");

export const addKickCandidate = createAction<SitOutData>("ADD_KICK_CANDIDATE");

export const removeKickCandidate = createAction("REMOVE_KICK_CANDIDATE");

export const updateRebuy = createAction<{ credit: number }>("UPDATE_RE_BUY");

export const manualRebuy = createAction("MANUAL_RE_BUY");

export const tableClosed = createAction<TableClosedData>("TABLE_CLOSED");

export const waitingForEnterPositionChanged =
  createAction<WaitingEnterPositionChangedData>(
    "WAITING_FOR_ENTER_POSITION_CHANGED",
  );

export const joinNow = createAction("JOIN_NOW");

export const toggleAutoPenaltyPayment = createAction(
  "TOGGLE_AUTO_PENALTY_PAYMENT",
);

export const handsList = createAction<HandHistory[]>("HANDS_LIST");

export const handHistorySelected = createAction<HandHistory>(
  "HAND_HISTORY_SELECTED",
);

export const handHistoryReceived = createAction<HandHistoryReceivedData>(
  "HAND_HISTORY_RECEIVED",
);

export const prizePoolReceived = createAction<MultipilierData[]>(
  "PRIZE_POOL_RECEIVED",
);

export const chooseTourDealer =
  createAction<ChooseTourDealer>("CHOOSE_TOUR_DEALER");

export const tournamentFinalTable = createAction<
  Omit<TournamentFinalTable, "type">
>("TOURNAMENT_FINAL_TABLE");

export const tournamentFinalPlayers = createAction("TOURNAMENT_FINAL_PLAYERS");

export const playerRegulationData = createAction<PlayerRegulationData>(
  "PLAYER_REGULATION_DATA",
);

export const ftdAvailabilityChanged = createAction<{ allowed: boolean }>(
  "FTD_AVAILABILITY_CHANGED",
);

export const ftdSelection = createAction<{ selected: boolean }>(
  "FTD_SELECTION",
);

export const ftdSelectionError = createAction<FtdSelectionErr["error"]>(
  "FTD_SELECTION_ERROR",
);

export const resetFtdSelectionError = createAction("RESET_FTD_SELECTION_ERROR");

export const ftdSelectionStatuses = createAction<
  Omit<FtdSelectionStatus, "type">
>("FTD_SELECTION_STATUSES");

export const ftdNegotiationStatus = createAction<
  Omit<FtdNegotiationStatus, "type">
>("FTD_NEGOTIATION_STATUS");

export const ftdDecision = createAction<{ accepted: boolean }>("FTD_DECISION");

export const ftdSatus = createAction<Omit<FtdSatus, "type">>("FTD_STATUS");

export const ftdReconnectionDetails = createAction<
  Omit<FtdReconnectionDetails, "type">
>("FTD_RECONNECTION_DETAILS");

export const updateSnapPoolTableId = createAction<
  Omit<SnapPoolTableId, "type">
>("UPDATE_SNAP_POOL_TABLE_ID");

export const setInternalHudData = createAction<InternalHudData[]>(
  "SET_INTERNAL_HUD_DATA",
);

export const resetInternalHudData = createAction("RESET_INTERNAL_HUD_DATA");

export const positionInTournament = createAction<PositionInTournament>(
  "POSITION_IN_TOURNAMENT",
);

export const prizeInfo = createAction<PrizeInfo>("PRIZE_INFO");

export const h4hActivated = createAction("H4H_ACTIVATED");
export const h4hInProcess = createAction("H4H_IN_PROGRESS");
export const h4hDeactivated = createAction("H4H_DEACTIVATED");
