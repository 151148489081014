import CommittedAction from "../../store/game/types/CommittedAction";
import colors from "../colors";

const poker888Theme = {
  actionButton: {
    color: {
      regular: colors.white,
      disabled: colors.charcoal2,
      hover: colors.white,
      active: colors.white,
    },
    textShadowColor: `${colors.black}80`,
    backgroundColor: {
      regular:
        "linear-gradient(0deg, #262626 0%, #292929 18%, #252525 33%, #252525 62%, #3A3A3A 100%)",
      disabled:
        "linear-gradient(0deg, #292929 0%, #292929 18%, #292929 33%, #292929 62%, #2C2C2C 100%)",
      hover:
        "linear-gradient(0deg, #1C1C1C 0%, #1F1F1F 18%, #1C1C1C 33%, #1F1F1F 62%, #2A2A2A 100%)",
      active:
        "linear-gradient(0deg, #131313  0%, #141414 18%, #121212 33%, #121212 62%, #1C1C1C 100%)",
    },

    borderColor: {
      regular: colors.darkGrey,
      disabled: colors.eclipse2,
      hover: colors.grey,
      active: colors.matterhorn,
    },
    iconColor: {
      register: {
        regular: colors.white,
        hover: colors.dodgerBlue,
      },
      unregister: {
        regular: colors.black,
        hover: colors.white,
      },
    },
  },
  dialogPaper: {
    backgroundColor: colors.nero,
    borderColor: colors.paynesGrey,
    defaultBackgroundGradient:
      "#1A1A1A radial-gradient(50% 30% at 50% -5%, #0073E599 0%, #1A1A1A00 100%)",
    snapBackgroundGradient:
      "#1A1A1A radial-gradient(50% 30% at 50% -5%, #1C77A799 0%, #1A1A1A00 100%)",
    tournamentBackgroundGradient:
      "#1A1A1A radial-gradient(50% 30% at 50% -5%, #1C77A760 0%, #1A1A1A00 100%)",
  },
  blastDialog: {
    blastAmount: {
      color: colors.white,
    },
    userAmount: {
      color: colors.suvaGrey,
    },
    tooltip: {
      backdropColor: colors.nero,
    },
  },
  button: {
    border: {
      primary: colors.dodgerBlue,
      secondary: colors.white,
    },
    color: {
      primary: colors.white,
      secondary: colors.black,
    },
    backgroundColor: {
      primary: colors.dodgerBlue,
      secondary: colors.white,
    },
    colorHover: {
      primary: colors.dodgerBlue,
      secondary: colors.white,
    },
    backgroundHoverColor: colors.black,
  },
  card: {
    back: {
      borderColor: colors.white,
      backgroundColor: "#2CCDC2",
      backgroundColorContent: "#259A92",
    },
    front: {
      backgroundColor: "#f7f3ea",
      winner: {
        glowColor: colors.dodgerBlue2,
        shadowColor: colors.black,
      },
      inactive: {
        shadowColor: "#838383",
      },
    },
  },
  checkbox: {
    iconFillColor: colors.white,
    rectangle: {
      fillColor: {
        checked: colors.dodgerBlue,
        unchecked: "transparent",
      },
      strokeColor: {
        checked: colors.dodgerBlue,
        unchecked: "#C1C1C124",
      },
    },
  },
  genericError: {
    color: colors.white,
  },
  hamburgerMenu: {
    buttonOverlayColor: colors.black,
    borderColor: colors.paynesGrey,
    menuColor: colors.nero,
    backdropColor: colors.black,
    separatorColor: colors.paynesGrey,
    iconColor: colors.lightGrey,
    textColor: colors.white,
    activeColor: colors.dodgerBlue,
  },
  hero: {
    borderColor: colors.silver,
    usernameColor: colors.white,
    chipsColor: colors.tangerineYellow,
    plateBorderColor: colors.paynesGrey,
  },
  keyboard: {
    closeButton: {
      color: "#343434",
      hoverColor: colors.black,
    },
    borderColor: `${colors.black}80`,
    backgroundColor: colors.manatee,
    content: {
      color: colors.white,
      backgroundColor:
        "linear-gradient(0deg, #414042 0%, #414042 9%, #58595B 33%, #616160 100%)",
    },
    topSection: {
      borderColor: colors.snow,
    },
    betColor: colors.white,
    keys: {
      borderTopColor: colors.blackRussian,
      borderRightColor: colors.blackRussian,
      borderBottomColor: colors.snow,
      borderLeftColor: colors.snow,
      color: colors.white,
      hoverBackgroundColor: colors.black,
      disabledColor: `${colors.white}30`,
      hoverDisabledBackgroundColor: colors.white,
    },
    button: {
      color: colors.white,
      hoverBackgroundColor: colors.black,
      borderColor: {
        collapsed: colors.darkGrey,
        expanded: colors.manatee,
      },
      backgroundColor: {
        collapsed:
          "linear-gradient(0deg, #262626 0%, #292929 18%, #252525 33%, #252525 62%, #3A3A3A 100%)",
        expanded:
          "linear-gradient(0deg, #414042 0%, #3A3B3F 18%, #525250 50%, #50504D 100%)",
      },
      value: {
        separatorColor: colors.snow,
      },
      icon: {
        separatorColor: colors.blackRussian,
      },
    },
  },
  bettingOption: {
    backgroundColor:
      "linear-gradient(0deg, #414042CC 0%, #414042CC 9%, #58595BCC 33%, #616160CC 100%)",
    borderColor: colors.manatee,
    predefinedBets: {
      fontColor: colors.white,
      activeBgColor: colors.black,
      hoverBgColor: colors.black,
      disabledColor: `${colors.white}50`,
      borderTopColor: `${colors.black}33`,
      borderBottomColor: `${colors.white}33`,
    },
    betSliderTrack: {
      backgroundColor:
        "linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF80 23%, #FFFFFF23 52%, #FFFFFF00 100%)",
      fillBackgroundColor:
        "linear-gradient(-90deg, #C51818 0%, #C72A2A 19%, #EE7214 40%, #FFAF5D 66%, #FEC97F 100%)",
      borderColor: `${colors.black}33`,
    },
    betSliderThumb: {
      borderColor: "#2521233b",
    },
  },
  seat: {
    empty: {
      iconColor: colors.dodgerBlue,
      activeIconColor: colors.white,
      borderColor: colors.paynesGrey,
      hoverBorderColor: colors.white,
      activeBorderColor: colors.lightGrey,
      backgroundColor: colors.nero,
      activeBackground: colors.dodgerBlue,
    },
    reserved: {
      usernameColor: colors.white,
      backgroundColor: colors.dodgerBlue,
    },
    opponent: {
      backgroundColor: colors.dodgerBlue,
      shadowColor: colors.white,
      usernameColor: colors.white,
      lastActions: {
        [CommittedAction.ANTE]: colors.charcoal,
        [CommittedAction.SMALL_BLIND]: colors.charcoal,
        [CommittedAction.BIG_BLIND]: colors.charcoal,
        [CommittedAction.FOLD]: colors.dodgerBlue,
        [CommittedAction.CHECK]: "#66BE2C",
        [CommittedAction.CALL]: "#66BE2C",
        [CommittedAction.RAISE]: colors.mangoTango,
        [CommittedAction.BET]: colors.mangoTango,
        [CommittedAction.ALL_IN]: colors.red,
        [CommittedAction.CAP]: colors.charcoal,
        [CommittedAction.PENALTY]: colors.charcoal,
      },
      lastActionColor: colors.white,
    },
    plate: {
      backgroundColor: colors.nero,
    },
  },
  chips: {
    backgroundColor: {
      visible: "rgb(0, 0, 0, 0.35)",
      notVisible: "rgb(0, 0, 0, 0)",
    },
    color: colors.white,
  },
  prizePool: {
    itemColor: colors.white,
  },
  radioButton: {
    borderColor: colors.black2,
    backgroundColor: "#272727",
    color: colors.suvaGrey,
    markBackgroundColor: "#171717",
    checkedBackgroundColor: colors.dodgerBlue,
    checkedColor: colors.white,
  },
  soundButton: {
    color: colors.white,
  },
  spinner: {
    circle: {
      borderColor: colors.navyBlue,
    },
    text: {
      color: colors.navyBlue,
    },
  },
  topPrize: {
    selectedColor: colors.tangerineYellow,
    unselectedColor: colors.black,
  },
  progressBar: {
    wrapperBackgroundColor: colors.black,
    headerBackgroundColor: colors.paynesGrey,
    backgroundColor: {
      normal: colors.dodgerBlue2,
      timebank: colors.mangoTango,
    },
  },
  tableInfo: {
    textColor: colors.white,
    labelColor: "#8d8d8d",
    prizePoolColor: "#FFC710",
    myPositionColor: "#78B7FF",
    primaryBackgroundColor: colors.nero,
    secondaryBackgroundColor: "#242424",
  },
  participationId: {
    textColor: colors.white,
  },
  totalPot: {
    nameColor: colors.white,
    valueColor: colors.tangerineYellow,
  },
  turnProgress: {
    ringColor: {
      normal: colors.dodgerBlue2,
      timebank: colors.mangoTango,
      counterText: colors.white,
      timebankContainer: colors.black,
    },
    fillColor: {
      ring: `${colors.black}50`,
    },
  },
  blastWaitingLobby: {
    icon: {
      inactive: {
        color: colors.silver,
        fill: colors.paynesGrey,
        stroke: colors.paynesGrey,
      },
      active: {
        fill: colors.dodgerBlue,
        stroke: colors.dodgerBlue,
      },
    },
    checkMark: {
      backgroundColor: colors.dodgerBlue2,
    },
    topPrize: {
      labelColor: colors.silver,
    },
    unregistrationMessage: {
      color: colors.white,
    },
  },
  winningParticles: {
    backgroundColor: colors.white,
  },
  handType: {
    color: colors.white,
    backgroundColor: "linear-gradient(90deg, #00d3ff34 0%, #00d3ff00 100%)",
  },
  roundedButton: {
    borderColor: colors.paynesGrey,
    backgroundColor: colors.nero,
    backgroundColorHover: "#0A0A0A",
    backgroundColorDisabled: colors.nero,
    color: colors.dodgerBlue,
    colorActive: colors.white,
    colorDisabled: "#424246",
  },
  roundedToggleButton: {
    color: colors.white,
    backgroundColor: colors.dodgerBlue,
    backgroundColorHover: "#004A94",
    backgroundColorActive: "#3396F9",
  },
  popup: {
    overlay: {
      backgroundColor: colors.black,
    },
  },
  handStrengthPopup: {
    itemBorderColor: colors.paynesGrey,
    borderColor: colors.paynesGrey,
    backgroundColor: colors.nero,
    textColor: colors.white,
    cards: {
      borderColor: colors.silver,
    },
    currentHandBackgroundColor: colors.dodgerBlue,
  },
  playAnotherGamePopup: {
    message: {
      color: colors.white,
    },
  },
  notificationToaster: {
    backgroundColor: colors.nero,
    borderColor: colors.dodgerBlue,
    snapBorderColor: "#098d97",
    boxShadowColor: "#00d3ff80",
    textColor: colors.suvaGrey,
    progressBarColor: colors.dodgerBlue,
  },
  disclaimer: {
    textColor: colors.suvaGrey,
  },
  emojiPanel: {
    borderColor: colors.paynesGrey,
    backgroundColor: colors.nero,
  },
  callouts: {
    backgroundColor: colors.nero,
    borderColor: colors.dodgerBlue,
    textColor: colors.white,
  },
  bonusShower: {
    textColor: colors.white,
    textShadow: colors.black,
  },
  toggle: {
    background: { on: colors.dodgerBlue, off: "#424246", border: "#707070" },
    knob: { background: "#EBEBEB", boxShadow: "#00000029" },
  },
  equity: {
    textColor: colors.white,
    textShadow: "#00000021",
  },
  eyeCard: {
    inactiveBackground: "#434343",
    activeBackground: colors.dodgerBlue,
    irisColor: colors.white,
  },
  avatarSelectionPopup: {
    text: {
      color: colors.white,
    },
    background: {
      color:
        "#1A1A1A radial-gradient(circle at 50% -5%, #0F68BF96 0%, #1A1A1A00 30%)",
    },
  },
  wheelMenu: {
    defaultFillColor: colors.nero,
    activeFillColor: "#104273",
    defaultStrokeCOlor: colors.paynesGrey,
    activeStrokeColor: "#205eb7",
    iconFillColor: colors.dodgerBlue,
    circleFillColor: colors.black,
  },
  ringBuyIn: {
    titleColor: colors.white,
    labelColor: colors.suvaGrey,
    sliderFillBackground: colors.dodgerBlue,
    sliderDefaultBackground: colors.black2,
  },
  buyInRectangle: {
    borderColor: colors.paynesGrey,
    color: colors.white,
    backgroundColor: colors.black2,
  },
  bankrollInfo: {
    textColor: colors.white,
  },
  inactivityTooltip: {
    backgroundColor: colors.nero,
    borderColor: colors.tangerineYellow,
    timerColor: colors.tangerineYellow,
  },
  handsHistoryPopup: {
    backgroundColor: colors.nero,
    rowColor: {
      regular: colors.nero,
      hover: colors.eclipse,
      active: colors.endeavour,
    },
    borderColor: colors.paynesGrey,
    textColor: colors.white,
    headerColor: colors.black,
  },
  handInfo: {
    color: colors.suvaGrey,
  },
  strip: {
    textColor: colors.white,
    borderColor: colors.white,
    backgroundColor: colors.black,
    scrollButtonBackgroundColor: "#191919",
    defaultIconColor: "#999999",
    activeIconColor: colors.dodgerBlue,
    textShadowColor: colors.dodgerBlue3,
    boxShadowColor: colors.black,
    blast: {
      stripColor: colors.dodgerBlue,
      prizeAmountBackground:
        "-webkit-linear-gradient(#ffc73b 0% 30%, white, #ffc73b 70% 100%)",
      textStrokeColor: "#B35324",
      dropShadowFilter: "drop-shadow(0em 0.14em 0em #391C0E)",
    },
    cash: {
      textShadowColor: colors.tropicalBlue,
    },
    snap: {
      stripColor: colors.blueGreen,
    },
    tournament: {
      stripColor: "#FFB623",
      defaultTextColor: colors.black,
      activeBackgroundColor: colors.black,
      textShadowColor: colors.tropicalBlue,
    },
  },
  homePage: {
    textColor: colors.scienceBlue,
    backgroundColor: colors.black,
  },
  sessionIndicator: {
    table: {
      active:
        "radial-gradient(50% 50.00% at 50% 50.00%, #0081FF 0%, #032C54 100%)",
      inactive:
        "radial-gradient(50% 50.00% at 50% 50.00%, #0E4C89 0%, #0F2439 100%)",
      hover:
        "radial-gradient(72.22% 72.22% at 50.00% 50.00%, #8AC5FF 0%, #0081FF 100%)",
      pressed:
        "radial-gradient(72.22% 72.22% at 50.00% 50.00%, #0081FF 0%, #053D74 100%)",
      inactiveContent: "#898783",
    },
    regular: {
      glowing:
        "0px 0px 0.25em 0px rgba(0, 168, 255, 0.85), 0px 0px 0.25em 0px rgba(0, 168, 255, 0.85), 0px 0px 0.25em 0px rgba(0, 168, 255, 0.85)",
      whiteTableBorder: "#CFCFCF",
      greyTableBorder: "#2D2D2D",
    },
    notified: {
      glowing:
        "0px 0px 0.25em 0px rgba(226, 0, 0, 0.85),0px 0px 0.25em 0px rgba(226, 0, 0, 0.85),0px 0px 0.25em 0px rgba(226, 0, 0, 0.85)",
      border: "#E20000",
    },
    sitOutPlate: {
      background: "#1A1A1A",
      border: "#707070",
    },
    multiTable: {
      background: colors.scienceBlue,
      hover: "#8AC5FF",
      pressed: "#2694FF",
    },
  },
  rebuyButton: {
    borderColor: {
      hover: colors.tangerineYellow,
      active: colors.white,
    },
  },
  navigationBar: {
    boxShadow: colors.paynesGrey,
    background: colors.nero,
    thumbBackground: "#6a6a6d",
    border: "#767676",
  },
  aimButton: {
    stroke: {
      default: "#2376b1",
      hover: "#61b4ee",
    },
  },
  tournamentArena: {
    tourInfo: {
      labelColor: "#A5A5A5",
      textColor: colors.white,
      prizePoolColor: "#FFC710",
      errorColor: "#e20000",
      tourStatus: {
        announced: {
          background: "#002132",
          color: "#00A8FF",
        },
        registering: {
          background: "#00241b",
          color: "#01CD9A",
        },
        lateRegistration: {
          background: "#2e2106",
          color: "#FFB623",
        },
        running: {
          background: "#2d0d05",
          color: "#FF5023",
        },
        completed: {
          background: "#1b1b1b",
          color: "#A5A5A5",
        },
        cancelled: {
          background: "#1b1b1b",
          color: "#A5A5A5",
        },
        sticky: {
          background: "#2a2549",
          color: "#7466CA",
        },
      },
      timerDisplay: {
        defaultRingColor: "#545454",
        thumbColor: colors.black,
        textColor: colors.white,
      },
    },
    extensiveInfo: {
      dividerColor: "#222222",
      closeButtonColor: "#0d0b0b",
      closeButtonHoverColor: "#141412",
      activeColor: "#0161c4",
      tooltipBackground: "#101010",
      textColor: colors.white,
      defaultInfoColor: "#717171",
    },
    tabs: {
      labelColor: colors.white,
      amountColor: "#ffdc67",
      inactiveColor: "#8d8d8d",
    },
    searchBar: {
      border: {
        defaultColor: "#2c2c2c",
        hoverColor: "#717171",
      },
      backgroundColor: "#0a0a0a",
      textColor: "#717171",
      searchIcon: {
        defaultColor: "#717171",
        hoverColor: "#b7b7b7",
      },
      closeIcon: {
        defaultColor: "#b7b7b7",
      },
      dropShadowFilter: "drop-shadow(0 0.2em 0.2em #000000)",
    },
    panelContainer: {
      backgroundColor: "#191919",
      dropShadowFilter: "drop-shadow(0px 0.25em 0.25em #000000)",
    },
    panelOptionGroupButton: {
      borderColor: "#2c2c2c",
      backgroundColor: "#000000",
      contentColor: "#ffffff",
      dropShadow: "drop-shadow(0 0.2em 0.2em black)",
      hoverBorderColor: "#717171",
      iconIdleColor: "#939393",
      activeTextColor: "#007cf7",
    },
    panelOptionButton: {
      borderColor: "#000000",
      backgroundColor: "#262626",
      hoverBorderColor: "#233548",
      hoverBackgroundColor: "#193e63",
      activeBorderColor: "#165492",
      activeBackgroundColor: "#007cf7",
    },
    promotionNews: {
      textColor: colors.white,
      backgroundColor: "#1a1a1a",
      defaultBorderColor: "#1f1f1f",
      hoverBorderColor: "#353535",
      defaultArrowColor: "#ffcf31",
      hoverArowColor: colors.white,
    },
  },
  scrollbar: {
    thumbColor: colors.suvaGrey,
    trackColor: "#313131",
  },
  groupPanel: {
    titleColor: "#ffffff",
    dividerBackgroundColor: "#2C2C2C",
  },
  tourBreakDisplay: {
    color: colors.suvaGrey,
    background: colors.nero,
    borderColor: colors.dodgerBlue,
    timeColor: colors.white,
  },
  tabs: {
    defaultBackgroundColor: "#101010",
    activeBackgroundColor: "#0E4075",
    defaultIconColor: "#8d8d8d",
    activeIconColor: colors.white,
  },
  emptyDataContent: {
    backgroundColor: colors.black2,
    textColor: colors.white,
  },
  table: {
    textColor: colors.white,
    disableTextColor: "#8d8d8d",
    primaryBackgroundColor: colors.nero,
    secondaryBackgroundColor: "#242424",
    selectedBackgroundColor: "#0E4075",
    scrollTrackColor: "#4b4949",
    borderColor: "#303030",
  },
  blindIntervalTooltip: {
    borderColor: colors.dodgerBlue,
    backgroundColor: "#101010",
    textColor: colors.white,
    durationColor: "#717171",
  },
  dealTag: {
    fillPending: "#1292F3",
    fillAccepted: "#30A54F",
    fillDeclined: "#BB0A0A",
  },
  ftdNegotiation: {
    timer: {
      fill: "#0d0d0d",
      stroke: colors.dodgerBlue2,
      fontColor: colors.white,
    },
    table: {
      caption: {
        fontColor: colors.suvaGrey,
      },
      header: {
        fontColor: "#b3b3b5",
        backgroundColor: colors.paynesGrey,
      },
      alternativeRowBackground: "#343434",
      selectedRowBackground: "#007cf7",
    },
    fontColor: colors.white,
    backgroundColor: colors.nero,
    borderColor: colors.paynesGrey,
  },
  internalHud: {
    indicator: {
      idleBorderColor: "#EEA514",
      idleTextColor: "#EEA514",
      idleBackgroundColor: "#1A1A1A",
      activeBorderColor: "#424246",
      activeTextColor: "#1A1A1A",
      activeBackgroundColor: "#EEA514",
    },
    details: {
      borderColor: "#EEA514",
      backgroundColor: "#1A1A1A",
      labelColor: "#8E8E8E",
      dividerBackgroundColor: "#292A2B",
      handsColor: "#78B7FF",
      dataColor: "#FFDC67",
      winningsColor: "#3BFF83",
      idleInfoIconColor: "#424246",
      activeInfoIconColor: "#007CF7",
    },
    explanationPopup: {
      background:
        "#1A1A1A radial-gradient(30% 13% at 50% 0%, rgba(28, 119, 167, 0.2) 30%, rgba(26, 26, 26, 0) 100%)",
      logoColor: "#3f4548",
      borderColor: "#424246",
      descriptionTextColor: "#8E8E8E",
      statisticColor: "#FFDC67",
      handsColor: "#78B7FF",
      winningsColor: "#3BFF83",
      dividerColor: "#292A2B",
    },
  },
  mttFinishedPopup: {
    titleColor: colors.white,
    messageColor: "#8d8d8d",
  },
};

export default poker888Theme;
