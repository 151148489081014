import { createReducer } from "@reduxjs/toolkit";
import { resetInternalHudData, setInternalHudData } from "../actions";
import Statistics from "../types/Statistics";

const initialState: Statistics = {};

export const statistics = createReducer<Statistics>(initialState, (builder) => {
  builder
    .addCase(setInternalHudData, (state, { payload }) => {
      payload.forEach((data) => {
        state[data.Nickname] = data;
      });
    })
    .addCase(resetInternalHudData, () => initialState);
});
