/* eslint-disable no-console */
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import "@formatjs/intl-durationformat/polyfill";
import {
  HostedClientLoadedEvent,
  IInitOptions,
  IPokerClientSettings,
} from "@sparkware/uc-sdk-core";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { createHistory } from "./components/Router";
import CommunicationContext from "./context/CommunicationContext";
import "./i18n";
import i18n from "./i18n";
import { fetchData, initLobbyServicesData } from "./services";
import { authenticate } from "./store/app/actions";
import { MB_HEADERS } from "./store/app/sagas/shared/externalCommunication";
import initApplication from "./store/app/store";
import { Brand } from "./store/app/types/Brand";
import { FullConfiguration } from "./store/app/types/Configuration";
import { Language, getCultureByIso3Lang } from "./utils/cultures";

const getConfiguration = async (settings: IPokerClientSettings) => {
  const {
    brandID,
    productPackageID,
    subBrandID: subBrand,
    deviceTypeID,
    languageCode,
    locale,
    isMobile,
    containers,
    brandRegulationTypeID,
    state,
  } = settings;

  try {
    if (
      isNaN(brandID) ||
      !subBrand ||
      !productPackageID ||
      !containers.fullScreenPopup ||
      !containers.actionIndicator ||
      !containers.modalContainer
    ) {
      throw new Error("Invalid settings");
    }

    const config = await fetchData<Brand>(
      `${process.env.REACT_APP_SOURCE_DOMAIN}/brands/${brandID}.json`,
    ).catch(() => {
      throw new Error("Unknown brand");
    });

    const { defaultLanguage, defaultLocale } = config;

    const currentCulture = getCultureByIso3Lang(
      (languageCode || defaultLanguage) as Language,
    );

    const fullConfig: FullConfiguration = {
      brand: { ...config, subBrand },
      currentCulture,
      currentLocale: locale || defaultLocale,
      productPackage: productPackageID,
      deviceInfo: {
        deviceType: deviceTypeID,
      },
      isMobile,
      containers,
      brandRegulationTypeID,
      state,
    };

    return fullConfig;
  } catch (error) {
    console.error(error);
  }
};

const init = async (data: IInitOptions<IPokerClientSettings>) => {
  try {
    const {
      messageBrokerChannels,
      clientSettings,
      authenticate: authenticationData,
      segmentation,
      navigation,
    } = data;

    const {
      session: {
        topics: { clientInitFailed, clientInitSucceeded },
      },
      router: {
        topics: { NavigateByCode, NavigateV2 },
      },
    } = messageBrokerChannels;

    const fullConfig = await getConfiguration(clientSettings);

    const container = document.getElementById(
      `${process.env.REACT_APP_CLIENT_CONTAINER_ID}`,
    );

    if (!fullConfig || !container) {
      clientInitFailed.publish(MB_HEADERS, {});

      return; // interrupt and nothing to render
    }

    const { id, lobbyServicesUrl } = fullConfig.brand;

    initLobbyServicesData(id, lobbyServicesUrl);

    i18n.changeLanguage(fullConfig.currentCulture.locale);

    const root = createRoot(container);

    const [store, mainServer] = initApplication(
      fullConfig,
      messageBrokerChannels,
      segmentation,
    );

    const navigator = createHistory(NavigateByCode, NavigateV2, navigation);

    const cache = createCache({ key: "pwc" });
    cache.compat = true;

    root.render(
      <CommunicationContext.Provider value={mainServer}>
        <Provider store={store}>
          <CacheProvider value={cache}>
            <App
              routerProps={{
                basename: clientSettings.urlPathPrefix,
                navigator: navigator,
              }}
            />
          </CacheProvider>
        </Provider>
      </CommunicationContext.Provider>,
    );

    clientInitSucceeded.publish(MB_HEADERS, {});

    if (authenticationData) {
      const { Token, RegulationTypeID } = authenticationData;
      store.dispatch(
        authenticate({ token: Token, regulationType: RegulationTypeID }),
      );
    }
  } catch (error) {
    console.error(error);
  }
};

window.dispatchEvent(new HostedClientLoadedEvent(init));
