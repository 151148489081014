const namespace = "hamburgerMenu";

export const SIT_OUT = `${namespace}.sitOut`;
export const JOIN_NOW = `${namespace}.joinNow`;
export const SETTINGS = `${namespace}.settings`;
export const IM_BACK = `${namespace}.imBack`;
export const CASHIER = `${namespace}.cashier`;
export const LEAVE_GAME = `${namespace}.leaveGame`;
export const SOUND = `${namespace}.sound`;
export const EMOJIS = `${namespace}.emojis`;
export const EMOJIQUCKPANEL = `${namespace}.emojiQuickPanel`;
export const FOUR_COLOR_DECK = `${namespace}.fourColorDeck`;
export const AVATAR_SELECTION = `${namespace}.avatarSelection`;
export const BIG_BLIND_FORMAT = `${namespace}.bigBlindFormat`;
export const ADD_CHIPS = `${namespace}.addChips`;
export const BACK_TO_LOBBY = `${namespace}.backToLobby`;
export const AUTO_FOCUS = `${namespace}.autoFocus`;
