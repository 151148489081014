import Player from "../../../store/game/types/Player";
import delimiter from "../delimiter";

export default function parsePlayer(playerString: string): Player {
  const params = playerString.split(delimiter.caret);

  return {
    seatId: Number.parseInt(params[0]),
    name: params[1],
    credit: Number.parseInt(params[2]),
    sittingOut: params[3] === "1",
    vipType: Number.parseInt(params[4]),
    avatarId: Number.parseInt(params[5]),
    countryId: Number.parseInt(params[6]),
    sittingTime: Number.parseInt(params[8]),
    playerType: Number.parseInt(params[9]),
    effectiveStack: Number.parseInt(params[10]),
    koBounty: Number.parseInt(params[11]),
    isTableLeader: params[12] === "1",
    isTourLeader: params[13] === "1",
    isActive: params[14] === "1",
  };
}
