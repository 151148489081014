import {
  INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_ERR,
  INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_OK,
} from ".";
import delimiter from "../delimiter";
import type { IncomingMessage, TourUnregErrorCode } from "../types";
import {
  INFO_SRV_CLIENT_END_OR_CANCEL_TOUR,
  INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_ERR,
  INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_OK,
  INFO_SRV_CLIENT_PLAYER_FINISHED_TOUR,
  INFO_SRV_CLIENT_REG_BLAST_POOL_ERR,
  INFO_SRV_CLIENT_REG_TOUR_ERR,
  INFO_SRV_CLIENT_REG_TOUR_OK,
  INFO_SRV_CLIENT_START_TOUR,
  INFO_SRV_CLIENT_UNREG_TOUR_ERR,
  INFO_SRV_CLIENT_UNREG_TOUR_OK,
  MAIN_CLIENT_AUTHENTICATE_APP_ERR,
  MAIN_CLIENT_AUTHENTICATE_APP_OK,
  MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_ERR,
  MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_OK,
  MAIN_CLIENT_GET_RELEVANT_BONUSES_RESPONSE,
  MAIN_CLIENT_JOIN_APP_ERR,
  MAIN_CLIENT_JOIN_APP_OK,
  MAIN_CLIENT_KICK_TO_LOBBY,
  MAIN_CLIENT_RECONNECT_APP_ERR,
  MAIN_CLIENT_RECONNECT_APP_OK,
  PROXY_CLIENT_CONNECT_SERVER_ERR,
  PROXY_CLIENT_CONNECT_SERVER_OK,
  PROXY_CLIENT_GENERAL_GAME_MSG,
  PROXY_CLIENT_GENERAL_HISTORY_MSG,
  PROXY_CLIENT_MESSAGE_TO_CLIENT,
  PROXY_CLIENT_SET_RECONNECTION_FLAG,
  PROXY_CLIENT_SET_USR_GAME_SESSIONS,
  PROXY_CLIENT_SET_USR_GAME_SESSIONS_ERR,
} from "./messageTypes";
import { parseReEnryLeft } from "./parseReEnryLeft";
import {
  BlastRegistrationErrorCode,
  MTCType,
  SessionType,
  TourRegistrationErrorCode,
} from "./types";
import { AccountAttributes } from "./types/AccountAttributes";
import AuthenticationData from "./types/AuthenticationData";
import { BonusInfoItemsMessage } from "./types/BonusInfoItemsMessage";
import ErrorMessage from "./types/ErrorMessage";
import { JoinAppSuccessData } from "./types/JoinAppSuccessData";
import { PlayerAttributesData } from "./types/PlayerAttributesData";
import ReconnectionData from "./types/ReconnectionData";

export function joinAppSuccess(message: IncomingMessage) {
  const payload: JoinAppSuccessData = JSON.parse(
    message.body.split(delimiter.field)[0],
  );

  return {
    type: MAIN_CLIENT_JOIN_APP_OK,
    regulationType: payload.RegulationType,
    configuration: payload.Configuration,
  } as const;
}

export function joinAppFault(message: IncomingMessage) {
  const payload: ErrorMessage = JSON.parse(
    message.body.split(delimiter.field)[0],
  );

  return {
    type: MAIN_CLIENT_JOIN_APP_ERR,
    errorCode: Number(payload.ErrorCode),
    description: payload.ErrorDescription,
  } as const;
}

export function getRelevantBonusesResponse(message: IncomingMessage) {
  const params = message.body.split(delimiter.field);
  const bonusInfoItemsResponse: BonusInfoItemsMessage[] = [];

  if (params[3]) {
    const bonusItems = params[3].split(delimiter.list).filter(Boolean);

    bonusItems.forEach((bonus) => {
      const bonusMap = bonus.split(delimiter.innerField);

      bonusInfoItemsResponse.push({
        playerId: Number.parseInt(bonusMap[0]),
        systemId: Number.parseInt(bonusMap[1]),
        bonusId: Number.parseInt(bonusMap[2]),
        bonusDescription: bonusMap[3],
        bonusReferenceId: Number.parseInt(bonusMap[4]),
        bonusStatusId: Number.parseInt(bonusMap[5]),
        bonusStatusName: bonusMap[6],
        bonusStatusReason: bonusMap[7],
        bonusTypeId: Number.parseInt(bonusMap[8]),
        bonusTypeName: bonusMap[9],
        budgetBonusCode: Number.parseInt(bonusMap[10]),
        eventTypeId: Number.parseInt(bonusMap[11]),
        eventTypeName: bonusMap[12],
        expirationDate: bonusMap[13],
        grantDate: bonusMap[14],
        isUO: Boolean(bonusMap[15]),
        promotionId: Number.parseInt(bonusMap[16]),
        promotionSystemId: Number.parseInt(bonusMap[17]),
        relatedDepositeTicketId: bonusMap[18],
        sourceEventId: bonusMap[19],
        statusChangeDate: bonusMap[20],
      });
      return null;
    });
  }

  return {
    type: MAIN_CLIENT_GET_RELEVANT_BONUSES_RESPONSE,
    errorCode: Number.parseInt(params[0]),
    errorDescription: params[1],
    ticket: Number.parseInt(params[2]),
    bonusInfoItems: bonusInfoItemsResponse,
  } as const;
}

export function registerBlastError(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  const windowId = Number.parseInt(params[0]);
  const reservedStr = params[1];
  const blastPoolId = Number.parseInt(params[2]);
  const buyInValue = Number.parseInt(params[3]);
  const regCount = Number.parseInt(params[4]);
  const useTrT = Boolean(Number.parseInt(params[5]));
  const resultCode: BlastRegistrationErrorCode = Number.parseInt(params[6]);
  const rawErrorData = params[7];

  let error;

  if (
    resultCode ===
    BlastRegistrationErrorCode.BLAST_REG_FAILED_TOTAL_REGISTRATION_LIMIT
  ) {
    const errorValues = rawErrorData.split(delimiter.caret);
    const errorData = {
      activeRegistrations: Number.parseInt(errorValues[0]),
      limit: Number.parseInt(errorValues[1]),
    };

    error = {
      windowId,
      reservedStr,
      blastPoolId,
      buyInValue,
      regCount,
      useTrT,
      resultCode:
        BlastRegistrationErrorCode.BLAST_REG_FAILED_TOTAL_REGISTRATION_LIMIT as const,
      errorData,
    };
  } else {
    error = {
      windowId,
      reservedStr,
      blastPoolId,
      buyInValue,
      regCount,
      useTrT,
      resultCode,
    };
  }

  return {
    type: INFO_SRV_CLIENT_REG_BLAST_POOL_ERR,
    error,
  } as const;
}

export function registerTourOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  let withdrawnAmounts: string[] = [];
  if (params[7]) {
    withdrawnAmounts = params[7].split(delimiter.innerField);
  }

  return {
    type: INFO_SRV_CLIENT_REG_TOUR_OK,
    windowId: Number.parseInt(params[0]),
    reservedStr: params[1],
    tourId: Number.parseInt(params[2]),
    greetingStringXML: params[3],
    resultCode: Number.parseInt(params[4]),
    //tourId: Number.parseInt(params[5]), //identical to params[2]
    tourName: params[6],
    withdrawnRealAmount: Number.parseInt(withdrawnAmounts[0]),
    withdrawnRestrictedAmount: Number.parseInt(withdrawnAmounts[1]),
    subTypeMask: Number.parseInt(params[11]),
    reEntryLeft: parseReEnryLeft(params[17]),
  } as const;
}

export function registerTourError(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: INFO_SRV_CLIENT_REG_TOUR_ERR,
    error: {
      windowId: Number.parseInt(params[0]),
      reservedStr: params[1],
      tourId: Number.parseInt(params[2]),
      error: params[3],
      errorCode: Number.parseInt(params[4]) as TourRegistrationErrorCode,
      //tourId: Number.parseInt(params[5]), //identical to params[2]
      tourName: params[6],
    },
  } as const;
}

export function unregisterTourOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: INFO_SRV_CLIENT_UNREG_TOUR_OK,
    windowId: Number.parseInt(params[0]),
    tourId: Number.parseInt(params[2]),
    message: params[3],
    regCode: Number.parseInt(params[4]),
    //tourId: Number.parseInt(params[5]), //identical to params[2]
    tourName: params[6],
    buyIn: Number.parseInt(params[10]),
  } as const;
}

export function unregisterTourError(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: INFO_SRV_CLIENT_UNREG_TOUR_ERR,
    error: {
      windowId: Number.parseInt(params[0]),
      dsReserved: params[1],
      tourId: Number.parseInt(params[2]),
      message: params[3],
      resultCode: Number.parseInt(params[4]) as TourUnregErrorCode,
    },
  } as const;
}

export function getAccountAttributesOk(message: IncomingMessage) {
  const accountAttributes = {} as PlayerAttributesData;
  const attributes = message.body.split(delimiter.list);

  for (const attribute of attributes) {
    const [key, value] = attribute.split(delimiter.innerField);
    if (!!key && !!value) {
      switch (Number.parseInt(key)) {
        case AccountAttributes.COUNTRY_ID:
          accountAttributes.countryId = Number.parseInt(value);
          break;
        case AccountAttributes.GENDER:
          accountAttributes.gender = Number.parseInt(value);
          break;
        case AccountAttributes.BLOCK_SWITCH:
          accountAttributes.blockSwitch = value;
          break;
        case AccountAttributes.IS_DEPOSITED:
          accountAttributes.isDeposited = !!value;
          break;
        case AccountAttributes.IS_USER_STATUS:
          accountAttributes.userStatus = Number.parseInt(value);
          break;
        case AccountAttributes.REGULATION_STATUS:
          accountAttributes.regulationStatus = Number.parseInt(value);
          break;
        case AccountAttributes.DAYS_TO_UPLOAD_DOCS:
          accountAttributes.daysToUploadDocs = Number.parseInt(value);
          break;
        case AccountAttributes.REGISTRATION_STATUS:
          accountAttributes.registrationStatus = Number.parseInt(value);
          break;
        case AccountAttributes.TEST_ACCOUNT:
          accountAttributes.testAccount = Number.parseInt(value);
          break;
        case AccountAttributes.REALITY_CHECK_GAP:
          accountAttributes.realityCheckGap = Number.parseInt(value);
          break;
        case AccountAttributes.IS_UPLOAD_DOCS_REQUIRED:
          accountAttributes.isUploadDocumentsRequired =
            Number.parseInt(value) === 1;
          break;
      }
    }
  }

  return {
    type: MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_OK,
    ...accountAttributes,
  } as const;
}

export function getAccountAttributesError(message: IncomingMessage) {
  const errorData = message.body.split(delimiter.section);
  const [code, errorDescription] = errorData[0].split(delimiter.field);
  return {
    type: MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_ERR,
    errorCode: Number.parseInt(code),
    errorDescription: errorDescription,
  } as const;
}

export function startTournament(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  const masks = params[5].split(delimiter.data);

  return {
    type: INFO_SRV_CLIENT_START_TOUR,
    serverId: params[0],
    tabId: params[1],
    tournamentId: params[2],
    name: params[3],
    pokerType: Number.parseInt(params[4]),
    tourSubTypeMask: Number.parseInt(masks[2]),
    attributesMask: Number.parseInt(masks[3]),
  } as const;
}

export function gameServerConnectSuccess(message: IncomingMessage) {
  const params = message.body.split(delimiter.field);
  return {
    type: PROXY_CLIENT_CONNECT_SERVER_OK,
    gameId: Number.parseInt(params[0]),
    serverId: params[1],
  } as const;
}

export function gameServerConnectFault(message: IncomingMessage) {
  const params = message.body.split(delimiter.field);
  return {
    type: PROXY_CLIENT_CONNECT_SERVER_ERR,
    gameId: Number.parseInt(params[0]),
    serverId: params[1],
    error: {
      errorCode: Number.parseInt(params[2]),
      description: params[3],
      reason: Number.parseInt(params[4]),
    },
  } as const;
}

export function generalGameMessage(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const gameId = parseInt(params[0]);
  const gameMessage = {
    type: params[2],
    body: params.slice(3).join(delimiter.section),
  };

  return {
    type: PROXY_CLIENT_GENERAL_GAME_MSG,
    gameId,
    gameMessage,
  } as const;
}

export function generalHistoryMessage(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  const gameId = parseInt(params[0]);
  const historyMessage = {
    type: params[2],
    body: params.slice(3).join(delimiter.section),
  };

  return {
    type: PROXY_CLIENT_GENERAL_HISTORY_MSG,
    gameId,
    historyMessage,
  } as const;
}

export function authenticationSuccess(message: IncomingMessage) {
  const data: AuthenticationData = JSON.parse(
    message.body.split(delimiter.field)[0],
  );

  return {
    type: MAIN_CLIENT_AUTHENTICATE_APP_OK,
    ...data,
  } as const;
}

export function authenticationFault(message: IncomingMessage) {
  const payload: ErrorMessage = JSON.parse(
    message.body.split(delimiter.field)[0],
  );

  return {
    type: MAIN_CLIENT_AUTHENTICATE_APP_ERR,
    errorCode: Number(payload.ErrorCode),
    description: payload.ErrorDescription,
  } as const;
}

export function reconnectAppSuccess(message: IncomingMessage) {
  const data: ReconnectionData = JSON.parse(
    message.body.split(delimiter.field)[0],
  );

  return {
    type: MAIN_CLIENT_RECONNECT_APP_OK,
    ...data,
  } as const;
}

export function reconnectAppFault(message: IncomingMessage) {
  const payload: ErrorMessage = JSON.parse(
    message.body.split(delimiter.field)[0],
  );

  return {
    type: MAIN_CLIENT_RECONNECT_APP_ERR,
    errorCode: Number(payload.ErrorCode),
    description: payload.ErrorDescription,
  } as const;
}

export function getQuickTableFromCreatorIdOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_OK,
    tableId: params[0],
    serverId: params[1],
    attributes: Number.parseInt(params[2]),
    pokerType: Number.parseInt(params[3]),
  } as const;
}

export function getQuickTableFromCreatorIdErr(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_ERR,
    errorCode: Number.parseInt(params[0]),
  } as const;
}

export function getIdenticalRingTableOk(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_OK,
    reservedId: params[1],
    tableId: params[2],
    serverId: params[3],
    pokerType: Number.parseInt(params[4]),
    openTableMode: Number.parseInt(params[5]),
    attributes: Number.parseInt(params[6]),
  } as const;
}

export function getIdenticalRingTableErr(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_ERR,
    reservedId: params[1],
    errorCode: Number.parseInt(params[2]),
  } as const;
}

export function getKickToLobyReason(message: IncomingMessage) {
  const params = message.body.split(delimiter.field);

  return {
    type: MAIN_CLIENT_KICK_TO_LOBBY,
    reasonId: Number.parseInt(params[0]),
    expirationDate: Number.parseInt(params[1]), // in seconds
    correlationId: params[2],
  } as const;
}

export function setReconnectionFlag(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: PROXY_CLIENT_SET_RECONNECTION_FLAG,
    reason: Number.parseInt(params[1]),
    description: params[2],
  };
}

export function setOpenSessions(message: IncomingMessage) {
  const params = message.body
    .split(delimiter.list)
    .filter((item) => item.length > 2);

  const sessions = Array.from(params, (data) => {
    const items = data.split(delimiter.innerField).filter(Boolean);

    const sessionType = Number.parseInt(items[3]);

    let attributesMask = 0;
    let subTypeMask = 0;

    if (items[14]) {
      const extraData = items[14].split(delimiter.data);
      attributesMask = Number.parseInt(extraData[3]) || 0;
      subTypeMask = Number.parseInt(extraData[2]);
    }

    const genericSessionParams = {
      sessionId: Number.parseInt(items[0]),
      serverId: items[1],
      gameType: Number.parseInt(items[2]),
      instanceId: (Number.parseInt(items[4]) & 0xffffffff).toString(),
      playerInstanceId: Math.floor(Number.parseInt(items[4]) / 2 ** 32),
      credit: Number.parseInt(items[5]),
      bonusPoints: Number.parseInt(items[6]),
      sessionStatus: Number.parseInt(items[7]),
    } as const;

    return sessionType === SessionType.RING_GAME
      ? ({
          ...genericSessionParams,
          sessionType: SessionType.RING_GAME,
          attributesMask: subTypeMask,
        } as const)
      : ({
          ...genericSessionParams,
          sessionType: SessionType.TOURNAMENT,
          attributesMask,
          subTypeMask,
        } as const);
  });

  return {
    type: PROXY_CLIENT_SET_USR_GAME_SESSIONS,
    sessions,
  } as const;
}

export function setOpenSessionsError(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);

  return {
    type: PROXY_CLIENT_SET_USR_GAME_SESSIONS_ERR,
    errorCode: Number.parseInt(params[0]),
    description: params[1],
  } as const;
}

export function tourIsEndOrCancelled(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  return {
    type: INFO_SRV_CLIENT_END_OR_CANCEL_TOUR,
    tourId: Number.parseInt(params[0]),
    tourName: params[1],
    regCode: Number.parseInt(params[3]),
    buyIn: Number.parseInt(params[4]),
    cancelled: params[5] === "1",
  } as const;
}

export function playerFinishedTour(message: IncomingMessage) {
  const params = message.body.split(delimiter.section);
  return {
    type: INFO_SRV_CLIENT_PLAYER_FINISHED_TOUR,
    tourId: Number.parseInt(params[0]),
    tourName: params[1],
    regCode: Number.parseInt(params[3]),
    buyIn: Number.parseInt(params[4]),
    isReEntry: params[5] === "1",
  } as const;
}

export function messageToClient(message: IncomingMessage) {
  const params = message.body.split(delimiter.innerField);
  const id = params[0];
  const messageType: MTCType = Number.parseInt(params[1]);
  let rawMessage = params[2];

  if (messageType === MTCType.CAPTION_POP_UP) {
    rawMessage = rawMessage.split(delimiter.data)[2];
  }

  return {
    type: PROXY_CLIENT_MESSAGE_TO_CLIENT,
    id,
    messageType,
    rawMessage,
  } as const;
}
