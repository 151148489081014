import styled from "@emotion/styled/macro";

export const SelectableAvatarImage = styled.img`
  position: relative;
  z-index: 1;

  width: 60px;
`;

export const SelectionBackground = styled.img`
  position: absolute;
  z-index: 0;
`;

export const SelectionHighlight = styled.img`
  position: absolute;
  z-index: 2;
`;

export const SelectableAvatarButton = styled.button`
  position: relative;

  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;

  border: none;

  background-color: transparent;

  &:focus {
    outline: 0;
  }

  cursor: pointer;
`;
