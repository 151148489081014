import {
  Children,
  cloneElement,
  Dispatch,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { MenuItem } from "..";
import Portal from "../../Portal";
import { BackArrowIcon } from "../Icons";
import { TRANSITION_DURATION } from "../styles";
import SubPanel from "../SubPanel";
import { Content, Panel } from "./styles";
import { MenuPanelProps, Path } from "./types";

function getChildren(rootChildren: ReactNode[], path: Path, level: number) {
  let children = rootChildren;

  for (let i = 0; i <= level; i++) {
    const pos = path[i];
    const child = children[pos];

    if (!isValidElement(child)) {
      return null;
    }

    children = child.props.subMenu;
  }

  return children;
}

function getChildrenWithCallback(
  children: ReactNode[] | null,
  setPath: Dispatch<SetStateAction<Path>>,
) {
  return Children.map(children, (child, index) => {
    if (!isValidElement(child)) return child;

    if (!child.props.subMenu) {
      return child;
    }

    const childProps = {
      onClick: () => {
        setPath((prev) => {
          if (prev[prev.length - 1] !== index) {
            return [...prev, index];
          } else {
            return prev;
          }
        });
      },
    };

    return cloneElement(child, childProps);
  });
}

const MenuPanel = ({
  testId,
  placeholder,
  children,
  ...transitionProps
}: PropsWithChildren<MenuPanelProps>) => {
  const [path, setPath] = useState<Path>([]);

  const panelRef = useRef<HTMLDivElement>(null);

  const childrenArray = useMemo(() => Children.toArray(children), [children]);
  const rootChildren = useMemo(
    () => getChildrenWithCallback(childrenArray, setPath),
    [childrenArray],
  );

  return (
    <CSSTransition
      nodeRef={panelRef}
      timeout={TRANSITION_DURATION}
      classNames="menuContent"
      {...transitionProps}
    >
      <Portal placeholder={placeholder.current}>
        <Panel ref={panelRef}>
          <Content data-testid={testId}>
            {rootChildren}
            <TransitionGroup component={null}>
              {path.map((_value, index) => {
                const backPath = path.slice(0, index - path.length);
                return (
                  <SubPanel key={`level-${index}`}>
                    <MenuItem
                      testId="poker-hamburger-button-go-back"
                      onClick={() => {
                        setPath(backPath);
                      }}
                      startIcon={<BackArrowIcon />}
                    />
                    {getChildrenWithCallback(
                      getChildren(childrenArray, path, index),
                      setPath,
                    )}
                  </SubPanel>
                );
              })}
            </TransitionGroup>
          </Content>
        </Panel>
      </Portal>
    </CSSTransition>
  );
};

export default MenuPanel;
