import { Culture, RecordMap } from "./types";

export const Cultures: RecordMap<LanguageCode, Culture, "locale"> = {
  "en-US": {
    languageId: 0,
    iso2: "en",
    iso3: "eng",
    locale: "en-US",
    languageName: "English",
  },
  "es-ES": {
    languageId: 8,
    iso2: "es",
    iso3: "spa",
    locale: "es-ES",
    languageName: "Español",
  },
  "pt-BR": {
    languageId: 9,
    iso2: "pt",
    iso3: "por",
    locale: "pt-BR",
    languageName: "Português",
  },
  "uk-UA": {
    languageId: 90,
    iso2: "uk",
    iso3: "ukr",
    locale: "uk-UA",
    languageName: "Українська",
  },
  "it-IT": {
    languageId: 6,
    iso2: "it",
    iso3: "ita",
    locale: "it-IT",
    languageName: "Italiano",
  },
};

export type Language = "eng" | "spa" | "por" | "ukr" | "ita";

export type LanguageCode = "en-US" | "es-ES" | "pt-BR" | "uk-UA" | "it-IT";

export const languagesMap: Record<Language, LanguageCode> = {
  eng: "en-US",
  spa: "es-ES",
  por: "pt-BR",
  ukr: "uk-UA",
  ita: "it-IT",
};

export const getCultureByIso3Lang = (iso3Lang: Language) => {
  const langCode = languagesMap[iso3Lang] || "en-US";

  return Cultures[langCode];
};
