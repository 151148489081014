import styled from "@emotion/styled/macro";

export const Panel = styled.div`
  position: absolute;

  height: 100%;

  overflow-x: hidden;

  background-color: ${({ theme }) => theme.hamburgerMenu.menuColor};

  pointer-events: all;
`;

export const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  width: 25em;

  transform-origin: top left;
`;
