import React from "react";
import useSound from "../../hooks/useSound";
import { Menu, MenuWrapper } from "./styles";

export default function HamburgerMenuButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
) {
  const { play } = useSound();

  return (
    <MenuWrapper
      {...props}
      onClick={(e) => {
        props.onClick?.(e);
        play("click");
      }}
      data-testid="poker-hamburger-menu-button"
    >
      <Menu />
    </MenuWrapper>
  );
}
