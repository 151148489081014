import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const RoundedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  width: 20px;
  height: 20px;

  border-radius: 50%;

  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.roundedButton.color};
    border: 1px solid ${theme.roundedButton.borderColor};
    background-color: ${theme.roundedButton.backgroundColor};
  `}

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;

    color: ${({ theme }) => theme.roundedButton.colorActive};
  }

  &:disabled {
    ${({ theme }) => css`
      color: ${theme.roundedButton.colorDisabled};
      background-color: ${theme.roundedButton.backgroundColorDisabled};
    `}

    cursor: auto;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover:not(:active, :disabled) {
      ${({ theme }) => css`
        background-color: ${theme.roundedButton.backgroundColorHover};
      `}
    }
  }
`;
