import { useContext } from "react";
import { ApplicationConfigContext } from "../context";

export default function useAppConfig() {
  const appCfgContext = useContext(ApplicationConfigContext);

  if (!appCfgContext) {
    throw new Error(
      "useAppConfig must be inside a ApplicationConfigContext provider",
    );
  }

  return appCfgContext;
}
