import InferValueTypes from "../types/InferValueTypes";
import Settings from "../types/Settings";
import * as actions from "./actions";

export const TOGGLE_SOUNDS = "TOGGLE_SOUNDS" as const;
export const TOGGLE_EMOJIS = "TOGGLE_EMOJIS" as const;
export const TOGGLE_EMOJI_QUICK_PANEL = "TOGGLE_EMOJI_QUICK_PANEL" as const;
export const TOGGLE_FOUR_COLOR_DECK = "TOGGLE_FOUR_COLOR_DECK" as const;
export const SELECT_AVATAR = "SELECT_AVATAR" as const;
export const TOGGLE_BIG_BLIND_FORMAT = "TOGGLE_BIG_BLIND_FORMAT" as const;
export const TOGGLE_TOP_UP = "TOGGLE_TOP_UP" as const;
export const TOGGLE_AUTO_FOCUS = "TOGGLE_AUTO_FOCUS" as const;

export type SettingsAction = ReturnType<InferValueTypes<typeof actions>>;

export default function settings(
  state: Settings,
  action: SettingsAction,
): Settings {
  switch (action.type) {
    case TOGGLE_SOUNDS:
      return {
        ...state,
        sounds: !state.sounds,
      };
    case TOGGLE_EMOJIS: {
      return {
        ...state,
        emojis: !state.emojis,
      };
    }
    case TOGGLE_EMOJI_QUICK_PANEL: {
      return {
        ...state,
        emojiQuickPanel: !state.emojiQuickPanel,
      };
    }
    case TOGGLE_FOUR_COLOR_DECK: {
      return {
        ...state,
        fourColorDeck: !state.fourColorDeck,
      };
    }
    case SELECT_AVATAR: {
      return state.avatarId === action.avatarId
        ? state
        : {
            ...state,
            avatarId: action.avatarId,
          };
    }
    case TOGGLE_BIG_BLIND_FORMAT: {
      return {
        ...state,
        bigBlindFormat: !state.bigBlindFormat,
      };
    }
    case TOGGLE_TOP_UP: {
      return {
        ...state,
        topUp: !state.topUp,
      };
    }
    case TOGGLE_AUTO_FOCUS: {
      return {
        ...state,
        autoFocus: !state.autoFocus,
      };
    }
  }
}
