import { put, take } from "redux-saga/effects";
import { POKER_SC_LIST_PLAYERS } from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  ListPlayers,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { playersListed } from "../../actions/actionCreators";

export default function* getPlayersInfo(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const players: ListPlayers = yield take(
      connection.messages,
      POKER_SC_LIST_PLAYERS,
    );

    yield put(playersListed(players.data));
  }
}
