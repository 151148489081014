interface SeatReservationErrorMessage {
  Pos: string;
  CID: string;
  Reserve: string;
  ErrorId: string;
  SecsToUnban: string;
  RatHolingErrorID: string;
  RatHolingMilliSecondsLeft: string;
  RatHolingMoneyUponExiting: string;
}

export default function parseSeatReservationError(message: string) {
  const data: SeatReservationErrorMessage = JSON.parse(message);

  return {
    seatId: Number.parseInt(data.Pos),
    reserved: data.Reserve === "1",
    errorCode: Number.parseInt(data.ErrorId),
    secsToUnban: Number.parseInt(data.SecsToUnban),
    ratHolingErrorID: Number.parseInt(data.RatHolingErrorID),
    ratHolingMilliSecondsLeft: Number.parseInt(data.RatHolingMilliSecondsLeft),
    ratHolingMoneyUponExiting: Number.parseFloat(
      data.RatHolingMoneyUponExiting,
    ),
  } as const;
}
