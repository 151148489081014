import { put, select, take } from "redux-saga/effects";
import {
  POKER_SC_NEW_GAME_RESULT,
  sendShowCards,
} from "../../../../network/protocol/gameServer";
import {
  GameResult,
  GameServerMessage,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { gameResult, showCardsOnFold } from "../../actions";
import { getHeroCardsMaskSelector, getHeroSelector } from "../../selectors";
import CommittedAction from "../../types/CommittedAction";
import Hero from "../../types/Hero";
import Player from "../../types/Player";

export default function* gameResultHandler(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const message: GameResult = yield take(
      connection.messages,
      POKER_SC_NEW_GAME_RESULT,
    );

    const hero: Player | undefined = yield select(getHeroSelector);

    if (hero?.committedAction === CommittedAction.FOLD) {
      yield put(showCardsOnFold());

      const mask: Hero["mask"] = yield select(getHeroCardsMaskSelector);
      connection.send(sendShowCards(mask));
    }

    yield put(gameResult(message));
  }
}
