import delimiter from "./delimiter";

export default function parseGenericMessage(input: string) {
  const params = input.split(delimiter.section);

  if (params[params.length - 1] === delimiter.endOfMessage) {
    params.pop();
  }

  const type = params[0];
  const body = params.slice(1).join(delimiter.section);

  return {
    type,
    body,
  };
}
