const namespace = "tableInfo";

export const BLIND_LEVEL = `${namespace}.blindLevel`;
export const NEXT_LEVEL = `${namespace}.nextLevel`;
export const NEXT_HAND = `${namespace}.nextHand`;
export const IN = `${namespace}.in`;
export const CURRENT_LEVEL = `${namespace}.currentLevel`;
export const PRIZE_POOL = `${namespace}.prizePool`;
export const LATE_REGISTRATION = `${namespace}.lateRegistration`;
export const MY_POSITION = `${namespace}.myPosition`;
export const PARTCIPATION_ID = `${namespace}.participationId`;
