import createSagaMiddleware from "redux-saga";
import { put, select, take } from "redux-saga/effects";
import GameServer from "../../../../network/GameServer";
import { POKER_SC_TABLE_HIST } from "../../../../network/protocol/gameServer";
import type {
  GameServerMessage,
  TableHistory,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication, {
  createMessagesChannel,
} from "../../../app/sagas/shared/messagesCommunication";
import { stateRestored } from "../../actions";
import createGameStore, { GameState } from "../../store";
import { GameType } from "../../types/Game";
import cashGameFlow from "../ringGames/cashGameFlow";
import snapGameFlow from "../ringGames/snapGameFlow";
import multiTableTournamentFlow from "../tournaments/multiTableTournamentFlow";
import tournamentFlow from "../tournaments/tournamentFlow";

export function getGameFlow(
  type: GameType,
): (connection: MessagesCommunication<GameServerMessage>) => Generator {
  switch (type) {
    case "blast":
      return tournamentFlow;
    case "snapTour":
    case "mtt":
      return multiTableTournamentFlow;
    case "cash":
      return cashGameFlow;
    case "snap":
      return snapGameFlow;
  }
}

export default function* getTableHistory(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const { messages }: TableHistory = yield take(
      connection.messages,
      POKER_SC_TABLE_HIST,
    );

    const historyGameServer = new GameServer();
    const historyConnection: MessagesCommunication<GameServerMessage> = {
      send: historyGameServer.send,
      messages: createMessagesChannel(historyGameServer),
    };
    const currentState: GameState = yield select();
    const sagaMiddleware = createSagaMiddleware();
    const newGameStore = createGameStore(currentState, [sagaMiddleware]);
    const task = sagaMiddleware.run(
      getGameFlow(currentState.game.type),
      historyConnection,
    );

    for (const message of messages) {
      historyGameServer.dispatchMessage(message);
    }

    task.cancel();

    yield put(stateRestored(newGameStore.getState()));
  }
}
