import styled from "@emotion/styled/macro";
import { Kind } from "../../utils/types/ProgressTurnData";

export const ProgressShape = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
`;

export const ProgressBarWrapper = styled(ProgressShape)`
  overflow: hidden;

  background-color: ${({ theme }) => theme.progressBar.wrapperBackgroundColor};
`;

export const ProgressBar = styled(ProgressShape)<{ $kind: Kind }>`
  background-color: ${({ $kind, theme }) =>
    theme.progressBar.backgroundColor[$kind]};

  transition: transform 1s linear;
`;
