import { put, take } from "redux-saga/effects";
import { POKER_SC_CLEAR_TABLE } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { tableWillClear } from "../../actions";

export default function* clearTable(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    yield take(connection.messages, POKER_SC_CLEAR_TABLE);
    yield put(tableWillClear());
  }
}
