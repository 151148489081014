import { GameContext } from "../store/app/sagas/shared/gamesManager/gameFactory";
import { getFocusEventSelector } from "../store/game/selectors";
import isRoundTurn from "./isRoundTurn";
import isTurn from "./isTurn";

function calculateEventTime(
  event: NonNullable<ReturnType<typeof getFocusEventSelector>>,
) {
  const { syncDate } = event;
  const eventTime = syncDate;

  if (isTurn(event)) {
    const turnTime = eventTime + event.timeOut;
    return isRoundTurn(event) ? turnTime + (event.timeBank || 0) : turnTime;
  }

  return eventTime + event.remaining * 1000;
}

export const getActiveGameId = (sessions: GameContext[]) => {
  const activeSession = sessions.reduce<GameContext | null>(
    (prevSession, nextSession) => {
      const nextEvent = getFocusEventSelector(nextSession.store.getState());

      if (!nextEvent) return prevSession;
      if (!prevSession) return nextSession;

      const prevEvent = getFocusEventSelector(prevSession.store.getState());

      if (!prevEvent) return nextSession;

      const prevTime = calculateEventTime(prevEvent);
      const nextTime = calculateEventTime(nextEvent);

      return prevTime < nextTime ? prevSession : nextSession;
    },
    null,
  );

  return activeSession?.gameId;
};
