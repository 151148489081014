import { put, take } from "redux-saga/effects";
import {
  joinTable,
  POKER_SC_JOIN_OK,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  JoinRingTable,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { ringTableJoined } from "../../actions";

export default function* joinCashTable(
  connection: MessagesCommunication<GameServerMessage>,
) {
  connection.send(joinTable());

  // snapRingPoolTableId not relavant for cash games but server sends it anyway
  // to not break the subscription for HUD we should exctact it from the response
  const { type, snapRingPoolTableId, ...response }: JoinRingTable = yield take(
    connection.messages,
    POKER_SC_JOIN_OK,
  );

  yield put(ringTableJoined(response));
}
