import { forwardRef } from "react";
import CARDS_SPECS from "../../../utils/cardsSpecs";
import SmallCard from "../SmallCard";
import { TableIndicator } from "../TableIndicator";
import { IndicatorWrapper } from "../styles";
import { CardsWrapper } from "./styles";
import { CardsIndicatorProps } from "./types";

export const CardsDisplay = ({
  active,
  cards,
  notified,
}: Omit<CardsIndicatorProps, "onClick">) => {
  return (
    <TableIndicator active={active}>
      {cards && (
        <CardsWrapper $notified={notified}>
          {cards.map(
            (card) =>
              card && (
                <SmallCard
                  key={`${CARDS_SPECS.suit[card.suit]}${
                    CARDS_SPECS.rank[card.rank]
                  }`}
                  suit={CARDS_SPECS.suit[card.suit]}
                  rank={CARDS_SPECS.rank[card.rank]}
                  active={active}
                />
              ),
          )}
        </CardsWrapper>
      )}
    </TableIndicator>
  );
};

export const CardsIndicator = forwardRef<
  HTMLButtonElement,
  CardsIndicatorProps
>(({ onClick, disabled, ...rest }, ref) => {
  return (
    <IndicatorWrapper ref={ref} onClick={onClick} disabled={disabled}>
      <CardsDisplay {...rest} />
    </IndicatorWrapper>
  );
});
