import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  allInsPosted,
  antePosted,
  betCalled,
  betRaised,
  bigBlindPosted,
  deadPenaltyPosted,
  gameResult,
  gameStarted,
  livePenaltyPosted,
  round1Started,
  round2Started,
  round3Started,
  round4Started,
  showCardsRoundStarted,
  smallBlindPosted,
  stateRestored,
  tableWillClear,
  tournamentEnded,
  uncalledAnte,
} from "../actions";
import { PlayersBets, PotState } from "../types/Pots";

function reducePlayerBets(initialValue: number, bets?: PlayersBets) {
  return bets
    ? Object.values(bets).reduce((total, bet) => total + bet, initialValue)
    : initialValue;
}

const initialState: PotState = {
  bets: 0,
  antes: 0,
};

export const pots = createReducer<PotState>(initialState, (builder) => {
  builder
    .addCase(deadPenaltyPosted, (state, { payload: { penalty } }) => {
      const { penalties = [] } = state;
      state.penalties = [...penalties, penalty];
    })
    .addCase(antePosted, (state, { payload: { roundBet, seatId } }) => {
      const { roundAntes: antes = {} } = state;
      state.roundAntes = {
        ...antes,
        [seatId]: roundBet,
      };
    })
    .addCase(round1Started, (state, { payload }) => {
      const { data } = payload;

      state.roundAntes = undefined;
      state.antes = reducePlayerBets(state.antes, state.roundAntes);

      if (data.uncalledBet) {
        state.uncalledBet = {
          seatId: data.uncalledBet.seatId,
          amount: data.uncalledBet.amount,
        };
      }
    })
    .addCase(gameResult, (state, { payload }) => {
      const { rakes = [], roundBets, bets } = state;
      const { rakeSize } = payload;

      state.rakes = [...rakes, rakeSize];
      state.bets = reducePlayerBets(bets, roundBets);
      state.roundBets = undefined;
    })
    .addCase(uncalledAnte, (state, { payload: { uncalledBet } }) => {
      if (uncalledBet) {
        state.uncalledBet = {
          seatId: uncalledBet.seatId,
          amount: uncalledBet.amount,
        };
      }
    })
    .addCase(allInsPosted, (state, { payload }) => {
      const lastPotSize = state.pots
        ? state.pots[state.pots.length - 1].total
        : 0;
      const { pots = [], rakes = [] } = state;
      const pot = {
        size: payload.potSize - lastPotSize,
        total: payload.potSize,
      };

      state.rakes = [...rakes, payload.rakeSize];
      state.pots = [...pots, pot];
    })
    .addCase(stateRestored, (_, { payload }) => {
      return payload.pots;
    })
    .addMatcher(
      createActionMatcher([
        smallBlindPosted,
        bigBlindPosted,
        livePenaltyPosted,
        betCalled,
        betRaised,
      ]),
      (state, { payload }) => {
        const { roundBets = {}, roundAntes, antes } = state;

        state.roundBets = {
          ...roundBets,
          [payload.seatId]: payload.roundBet,
        };
        state.antes = reducePlayerBets(antes, roundAntes);
        state.roundAntes = undefined;
      },
    )
    .addMatcher(
      createActionMatcher([
        round2Started,
        round3Started,
        round4Started,
        showCardsRoundStarted,
      ]),
      (state, { payload }) => {
        const { rakes = [], roundBets, bets } = state;

        state.rakes = [...rakes, payload.rakeSize];
        state.bets = reducePlayerBets(bets, roundBets);
        state.roundBets = undefined;

        if (payload.uncalledBet) {
          state.uncalledBet = {
            seatId: payload.uncalledBet.seatId,
            amount: payload.uncalledBet.amount,
          };
        }
      },
    )
    .addMatcher(
      createActionMatcher([gameStarted, tournamentEnded, tableWillClear]),
      () => initialState,
    );
});
