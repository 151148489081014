export default function fillToLength<T>(
  source: T[] | null,
  length = 2,
): (T | null)[] {
  const start = source?.length || 0;
  const result: (T | null)[] = source ? [...source] : new Array(length);

  result.length = length;

  return result.fill(null, start);
}
