import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  ringGameJoined,
  seatReservationChanged,
  stateRestored,
  tournamentJoined,
} from "../actions";
import Reservation from "../types/Reservation";

const initialState = {};

export const reservation = createReducer<Reservation>(
  initialState,
  (builder) => {
    builder
      .addCase(seatReservationChanged, (state, { payload }) => {
        state[payload.seatId] = payload.reserved;
      })
      .addCase(stateRestored, (_, { payload }) => {
        if ("reservation" in payload) {
          return payload.reservation;
        }
      })
      .addMatcher(
        createActionMatcher([tournamentJoined, ringGameJoined]),
        () => initialState,
      );
  },
);
