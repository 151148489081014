import { PropsWithChildren } from "react";
import Styleable from "../../../utils/types/Styleable";
import { ChildrenWrapper, TableWrapper } from "./styles";
import { TableIndicatorProps } from "./types";

export const TableIndicator = ({
  active,
  children,
  ...rest
}: Styleable<PropsWithChildren<TableIndicatorProps>>) => {
  return (
    <TableWrapper $active={active} {...rest}>
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </TableWrapper>
  );
};
