import styled from "@emotion/styled/macro";
import Bold from "../assets/fonts/888-Bold.woff";
import SemiCondensedBold from "../assets/fonts/888-SemiCondensedBold.woff";
import SemiCondensedDemiBold from "../assets/fonts/888-SemiCondensedDemiBold.woff";
import RobotoBold from "../assets/fonts/Roboto-Bold.woff";
import Roboto from "../assets/fonts/Roboto.woff";

export const ApplicationWrapper = styled.div`
  font-size: clamp(12px, 0.8vw, 24px);

  @font-face {
    font-family: "Roboto";
    src: url(${Roboto}) format("opentype");
    font-display: swap;
  }

  @font-face {
    font-weight: bold;
    font-family: "Roboto-Bold";
    src: url(${RobotoBold}) format("opentype");
    font-display: block;
  }

  @font-face {
    font-family: "888-Bold";
    src: url(${Bold}) format("opentype");
    font-display: swap;
  }

  @font-face {
    font-weight: "bold";
    font-family: "888-SemiCondensedDemiBold";
    src: url(${SemiCondensedDemiBold}) format("opentype");
    font-display: swap;
  }

  @font-face {
    font-weight: "bold";
    font-family: "888-SemiCondensedBold";
    src: url(${SemiCondensedBold}) format("opentype");
    font-display: swap;
  }

  position: relative;
  height: 100%;

  font-family: "Roboto-Bold";

  user-select: none;

  img {
    pointer-events: none;
  }
`;
