export async function fetchData<T = Record<string, unknown>>(
  address: string,
  abort?: AbortSignal,
): Promise<T> {
  const response = await fetch(address, { signal: abort });

  if (!response.ok) {
    throw new Error("HTTP error, status = " + response.status);
  }

  return response.json();
}
