type Turn = NonRoundTurn | RoundTurn;

export interface AbstractTurn {
  seatId: number;
  timeOut: number;
  syncDate: number;
}

export interface NonRoundTurn extends AbstractTurn {
  actions: NonRoundAction[];
  bet?: number;
  penalty?: number;
}

export interface RoundTurn extends AbstractTurn {
  actions: TurnAction[];
  bringIn: number;
  upperLimit: number;
  callAmount: number;
  minRaiseAmount: number;
  maxRaiseAmount: number;
  timeBank?: number;
  timeBankNotification?: number;
  timeBankActive?: boolean;
}

export enum NonRoundAction {
  SMALL_BLIND = "SMALL_BLIND",
  DECLINE_SMALL_BLIND = "DECLINE_SMALL_BLIND",
  BIG_BLIND = "BIG_BLIND",
  DECLINE_BIG_BLIND = "DECLINE_BIG_BLIND",
  LIVE_PENALTY = "LIVE_PENALTY",
  DEAD_PENALTY = "DEAD_PENALTY",
  WAIT_FOR_FIRST_TO_ACT = "WAIT_FOR_FIRST_TO_ACT",
}

export enum TurnAction {
  FOLD = "FOLD",
  CHECK = "CHECK",
  CALL = "CALL",
  CALL_ALL_INS = "CALL_ALL_INS",
  RAISE = "RAISE",
  RAISE_ALL_INS = "RAISE_ALL_INS",
  RAISE_2 = "RAISE_2",
  RAISE_2_ALL_INS = "RAISE_2_ALL_INS",
  BRING_IN = "BRING_IN",
  SHOW_CARDS = "SHOW_CARDS",
  DONT_SHOW_CARDS = "DONT_SHOW_CARDS",
}

export default Turn;
