import BlindData from "../types/BlindData";

const initialBlindData: BlindData = {
  currentSmallBlind: NaN,
  currentLowLimit: NaN,
  currentHighLimit: NaN,
  ante: NaN,
};

export default initialBlindData;
