import { call } from "redux-saga/effects";
import {
  BlastPoolDataSuccess,
  RingGameData,
  blastPoolData,
} from "../../../actions";
import sendEvent from "./sendEvent";

export default function* sendOpenGameEvent(
  action: BlastPoolDataSuccess | RingGameData,
) {
  const eventData = blastPoolData.match(action)
    ? {
        gameType: "blast",
        buyInAmount: action.payload.blastInfo.BuyInAmount,
      }
    : { gameType: action.payload.gameType };

  yield call(sendEvent, "poker-web", "openGame", ["Log"], eventData);
}
