export enum TableMessageType {
  CardStrikeWinning = 1,
  InternalHud,
}

export interface InternalHudData {
  Nickname: string;
  Vpip: number;
  Pfr: number;
  ThreeBet: number;
  TotalWinnings?: number;
  TotalNumberOfHands: number;
}

export interface TableMessageSnapshot {
  data: {
    MessageId: TableMessageType;
    TableId: number;
    TournamentId?: number;
    SnapRingPoolTableId?: number;
    CorrelationId: string;
    LastUpdateUtcTimeEpochSec: number;
    ExpiredAtUtcTime: {
      seconds: number;
      nanoseconds: number;
    };
    CreateUtcTimeEpochSec: number;
    ExpiredAtUtcTimeEpochSec: number;
    MessageName: keyof typeof TableMessageType;
    CreateUtcTime: {
      seconds: number;
      nanoseconds: number;
    };
    LastUpdateUtcTime: {
      seconds: number;
      nanoseconds: number;
    };
    MessageData: string;
  };
  type: "added" | "removed" | "modified";
  id: string;
}
