import { Action } from "@reduxjs/toolkit";
import { ISharedTriple8Messaging } from "@sparkware/uc-sdk-core";
import { call, put, race, select, take, takeEvery } from "redux-saga/effects";
import {
  GameCloseEvent,
  GameStateEvent,
  WithdrawFundsEvent,
} from "../../../../../network/events";
import {
  GH_CS_GET_POKER_SESSIONS_DETAILS,
  GH_CS_GET_POKER_TOURNAMENTS_DETAILS,
  GH_CS_GET_THP_GAME_STR,
  GH_CS_GET_TOUR_GAME_STR,
} from "../../../../../network/protocol/gameServer";
import {
  generalGameMessage,
  generalHistoryMessage,
  leaveGameServer,
} from "../../../../../network/protocol/mainServer";
import { MainServerMessage } from "../../../../../network/protocol/mainServer/types";
import { OutgoingMessage } from "../../../../../network/protocol/types";
import findTourRegistration from "../../../../../utils/findTourRegistration";
import makeGameId from "../../../../../utils/makeGameId";
import {
  RingGameData,
  TournamentStarted,
  gameClosed,
  gameCreated,
  gameStateChanged,
  logout,
  reconnectError as reconnectErrorAction,
  withdrawFunds,
} from "../../../actions";
import {
  getCashTableSessionLimitSelector,
  getSessionsSelector,
  getUserSelector,
} from "../../../selectors";
import User from "../../../types/User";
import createEventChannel from "../createEventChannel";
import MessagesCommunication from "../messagesCommunication";
import gameFactory from "./gameFactory";

function* getGameId(
  gameData: TournamentStarted["payload"] | RingGameData["payload"],
) {
  if (gameData.gameType === "blast") {
    const sessions: ReturnType<typeof getSessionsSelector> =
      yield select(getSessionsSelector);
    const registration = findTourRegistration(
      sessions,
      Number(gameData.gameInstanceId),
    );

    if (registration) {
      return registration.gameId;
    }
  }

  return makeGameId();
}

export function* createGame(
  connection: MessagesCommunication<MainServerMessage>,
  triple8DataPromise: Promise<ISharedTriple8Messaging["subscribe"]> | null,
  { payload: gameData }: TournamentStarted | RingGameData,
) {
  const user: User = yield select(getUserSelector);
  const sessionLimit: number = yield select(getCashTableSessionLimitSelector);

  if (!user.userName) {
    throw new Error("Unatorized access");
  }

  const gameId: number = yield call(getGameId, gameData);

  const sender = (message: OutgoingMessage) => {
    connection.send(
      message.type === GH_CS_GET_POKER_SESSIONS_DETAILS ||
        message.type === GH_CS_GET_POKER_TOURNAMENTS_DETAILS ||
        message.type === GH_CS_GET_TOUR_GAME_STR ||
        message.type === GH_CS_GET_THP_GAME_STR
        ? generalHistoryMessage(gameId, message)
        : generalGameMessage(gameId, message),
    );
  };

  const gameContext = gameFactory(
    gameId,
    gameData,
    sender,
    user.userName,
    sessionLimit,
    triple8DataPromise,
  );
  yield put(
    gameCreated({ gameId, serverId: gameData.serverId, context: gameContext }),
  );

  const closeGameChannel = createEventChannel(
    gameContext.gameServer,
    GameCloseEvent.GAME_CLOSE,
  );

  const gameStateChannel = createEventChannel(
    gameContext.gameServer,
    GameStateEvent.GAME_STATE_CHANGED,
  );

  const withdrawFundsChannel = createEventChannel(
    gameContext.gameServer,
    WithdrawFundsEvent.WITHDRAW_FUNDS,
  );

  yield takeEvery(gameStateChannel, function* ({ state }: GameStateEvent) {
    yield put(gameStateChanged({ gameId, state }));
    connection.send(leaveGameServer(gameId, gameData.serverId));
  });

  yield takeEvery(
    withdrawFundsChannel,
    function* ({
      withdrawnRealAmount,
      withdrawnRestrictedAmount,
    }: WithdrawFundsEvent) {
      yield put(
        withdrawFunds({ withdrawnRealAmount, withdrawnRestrictedAmount }),
      );
    },
  );

  const [closeEvent, reconnectError]: [
    GameCloseEvent | undefined,
    Action | undefined,
    Action | undefined,
  ] = yield race([
    take(closeGameChannel),
    take(reconnectErrorAction.type),
    take(logout.type),
  ]);

  closeGameChannel.close();
  gameStateChannel.close();
  gameContext.close();
  withdrawFundsChannel.close();

  if (!reconnectError) {
    connection.send(leaveGameServer(gameId, gameData.serverId));
  }

  yield put(gameClosed({ gameId, reason: closeEvent?.reason }));
}
