import styled from "@emotion/styled/macro";

export const SessionsList = styled.nav`
  display: flex;
  flex-direction: row;
`;

export const ActionIndicatorWrapper = styled.div`
  font-size: 1.75em;

  margin: 0 0.5em;
`;
