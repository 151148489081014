import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  autoAmountToCall,
  autoCheck,
  autoCheckOrFold,
  betRaised,
  gameStarted,
  ringSnapState,
  round1Started,
  round2Started,
  round3Started,
  round4Started,
  showCardsRoundStarted,
  stateRestored,
  tableWillClear,
} from "../actions";
import initialState from "../initialStates/preActions";
import PreActions from "../types/PreActions";

export const preActions = createReducer<PreActions>(initialState, (builder) => {
  builder
    .addCase(ringSnapState, (state, { payload: { isSnapMode } }) => {
      state.isSnapMode = isSnapMode;
    })
    .addCase(autoCheckOrFold, (state) => {
      state.checkOrFold = !state.checkOrFold;
      state.check = false;
      state.amountToCall = NaN;
    })
    .addCase(autoCheck, (state) => {
      state.checkOrFold = false;
      state.check = !state.check;
      state.amountToCall = NaN;
    })
    .addCase(autoAmountToCall, (state, { payload: { amount } }) => {
      state.checkOrFold = false;
      state.check = false;
      state.amountToCall = amount;
    })
    .addCase(betRaised, (state) => {
      state.check = false;
      if (state.isSnapMode) {
        state.checkOrFold = false;
      }
    })
    .addCase(stateRestored, (_, { payload: { preActions } }) => preActions)
    .addMatcher(
      createActionMatcher([
        gameStarted,
        tableWillClear,
        round1Started,
        round2Started,
        round3Started,
        round4Started,
        showCardsRoundStarted,
      ]),
      (state) => {
        return { ...initialState, isSnapMode: state.isSnapMode };
      },
    );
});
