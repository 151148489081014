import { put, race, take } from "redux-saga/effects";
import {
  MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_ERR,
  MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_OK,
  getAccountAttributes,
} from "../../../../network/protocol/mainServer";
import {
  GetAccountAttributesError,
  GetAccountAttributesOk,
  MainServerMessage,
} from "../../../../network/protocol/mainServer/types";
import {
  getAccountAttributesError,
  getAccountAttributesSuccess,
} from "../../actions";
import MessagesCommunication from "./messagesCommunication";

export default function* getAccountAttributesSaga(
  connection: MessagesCommunication<MainServerMessage>,
) {
  connection.send(getAccountAttributes());

  const [response, error]: [GetAccountAttributesOk, GetAccountAttributesError] =
    yield race([
      take(connection.messages, MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_OK),
      take(connection.messages, MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_ERR),
    ]);

  if (response) {
    const { type, ...data } = response;
    yield put(getAccountAttributesSuccess(data));
  } else if (error) {
    yield put(
      getAccountAttributesError({
        errorMessage: error.errorDescription,
        errorId: error.errorCode,
      }),
    );
  }
}
