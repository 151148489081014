import { IHostedClientMessageData } from "@sparkware/uc-sdk-core/lib/message-broker/channels/communications/communications.channel.interfaces";
import { put } from "redux-saga/effects";
import { bonusShowerError, setBonus } from "../../../actions";

export enum BonusShower {
  Toaster = 0,
  Showers = 1,
}

export enum AnimationType {
  Boxes = 1,
}

interface MetaData {
  tourID: string;
  tableID: string;
}

export default function* dispatchBonusShower(
  payload: IHostedClientMessageData,
) {
  const { Title, SubTitle, AnimationType, MetaDataParams, DisplayMode } =
    payload.MessageData;

  try {
    if (DisplayMode !== BonusShower.Showers) {
      throw new Error(
        `Bonus shower process - unknown DisplayMode: ${DisplayMode}`,
      );
    }

    const metaData: MetaData = JSON.parse(MetaDataParams);

    yield put(
      setBonus({
        title: Title,
        subTitle: SubTitle,
        animationType: AnimationType,
        tournamentId: metaData.tourID,
        tableId: metaData.tableID,
      }),
    );
  } catch (error) {
    yield put(bonusShowerError({ message: String(error) }));
  }
}
