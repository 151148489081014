import { Unsubscribe } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  MultiTableIndicator,
  RegisteredIndicator,
} from "../../components/GameStateIndicator";
import GameStateIndicator from "../../containers/GameStateIndicatorContainer";
import { setCurrentSession } from "../../store/app/actions";
import { getSessionsSelector } from "../../store/app/selectors";
import { getFocusEventSelector } from "../../store/game/selectors";
import { observeStore } from "../../utils";
import { getActiveGameId } from "../../utils/getActiveGameId";
import { getActiveGameSessions } from "../../utils/getActiveGameSessions";
import isGameSession from "../../utils/isGameSession";
import isTurn from "../../utils/isTurn";
import { ActionIndicatorWrapper } from "./styles";

export default function ActionIndicator() {
  const dispatch = useDispatch();
  const sessions = useSelector(getSessionsSelector);

  const [activeSessionId, setActiveSessionId] = useState<number | undefined>();

  useEffect(() => {
    const listeners = new Set<Unsubscribe>();

    const activeSessions = getActiveGameSessions(sessions);

    activeSessions.forEach((session) => {
      const unsubscribe = observeStore(
        session.store,
        getFocusEventSelector,
        () => setActiveSessionId(getActiveGameId(activeSessions)),
        (prev, next) =>
          isTurn(prev) && isTurn(next)
            ? prev.seatId === next.seatId
            : prev === next,
      );

      listeners.add(unsubscribe);
    });

    setActiveSessionId(getActiveGameId(activeSessions));

    return () => listeners.forEach((fn) => fn());
  }, [sessions]);

  const activeSessions = Object.values(sessions);
  const [firstSession] = activeSessions;

  if (!firstSession) return;

  const sessionIndicator =
    activeSessions.length > 1 ? (
      <MultiTableIndicator
        sessions={activeSessions.length}
        notified={Boolean(activeSessionId)}
        onClick={() =>
          dispatch(
            setCurrentSession({
              gameId: activeSessionId || firstSession.gameId,
            }),
          )
        }
      />
    ) : isGameSession(firstSession) ? (
      <Provider store={firstSession.store}>
        <GameStateIndicator
          active
          onClick={() =>
            dispatch(setCurrentSession({ gameId: firstSession.gameId }))
          }
        />
      </Provider>
    ) : (
      <RegisteredIndicator
        active
        onClick={() =>
          dispatch(setCurrentSession({ gameId: firstSession.gameId }))
        }
      />
    );

  return <ActionIndicatorWrapper>{sessionIndicator}</ActionIndicatorWrapper>;
}
