import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import { setBonus } from "../../app/actions";
import {
  gameResult,
  heroSatDown,
  round2Started,
  round3Started,
  round4Started,
  tableWillClear,
} from "../actions";
import initialState from "../initialStates/game";
import Game from "../types/Game";

export const game = createReducer<Game>(initialState, (builder) => {
  builder
    .addCase(heroSatDown, (state) => {
      state.inializedByOpenSession = false;
    })
    .addCase(setBonus, (state, { payload }) => {
      state.bonus = payload;
    })
    .addMatcher(
      createActionMatcher([
        round2Started,
        round3Started,
        round4Started,
        gameResult,
        tableWillClear,
      ]),
      (state) => {
        state.bonus = undefined;
      },
    );
});
