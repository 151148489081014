import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    returnNull: false,
    load: "currentOnly",
    lng: "en-US",
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === "ordinal") {
          const rules = new Intl.PluralRules(lng, { type: "ordinal" });
          return rules.select(value);
        }
        return value;
      },
    },
    joinArrays: "\n",
    backend: {
      crossDomain: true,
      loadPath: `${
        process.env.REACT_APP_SOURCE_DOMAIN || ""
      }/locales/{{lng}}/{{ns}}.json`,
    },
    react: {
      useSuspense: false,
    },
    postProcess: ["pseudo"],
  });

export default i18n;
