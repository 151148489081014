export const PING = "998" as const;
export const PONG = "999" as const;
export const CLIENT_MAIN_JOIN_APP = "1000001" as const;
export const MAIN_CLIENT_JOIN_APP_OK = "1000101" as const;
export const MAIN_CLIENT_JOIN_APP_ERR = "1000102" as const;
export const CLIENT_PROXY_LEAVE_PROXY = "200003" as const;
export const CLIENT_MAIN_GET_RELEVANT_BONUSES = "200190" as const;
export const MAIN_CLIENT_GET_RELEVANT_BONUSES_RESPONSE = "200358" as const;
export const CLIENT_MAIN_GET_ACCOUNT_ATTRIBUTES = "200086" as const;
export const MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_OK = "200284" as const;
export const MAIN_CLIENT_GET_ACCOUNT_ATTRIBUTES_ERR = "200285" as const;
export const CLIENT_INFO_SRV_REG_BLAST_POOL = "102033" as const;
export const INFO_SRV_CLIENT_REG_BLAST_POOL_ERR = "103066" as const;
export const CLIENT_INFO_SRV_POKER_REG_TOUR = "102009" as const;
export const INFO_SRV_CLIENT_REG_TOUR_OK = "103015" as const;
export const INFO_SRV_CLIENT_REG_TOUR_ERR = "103017" as const;
export const CLIENT_INFO_SRV_POKER_UNREG_TOUR = "102010" as const;
export const INFO_SRV_CLIENT_UNREG_TOUR_OK = "103016" as const;
export const INFO_SRV_CLIENT_UNREG_TOUR_ERR = "103018" as const;
export const INFO_SRV_CLIENT_START_TOUR = "103009" as const;
export const CLIENT_INFO_SRV_START_TOUR_OK = "102008" as const;
export const CLIENT_PROXY_CONNECT_SERVER = "200069" as const;
export const PROXY_CLIENT_CONNECT_SERVER_OK = "200251" as const;
export const PROXY_CLIENT_CONNECT_SERVER_ERR = "200252" as const;
export const CLIENT_PROXY_GENERAL_GAME_MSG = "200071" as const;
export const PROXY_CLIENT_GENERAL_GAME_MSG = "200253" as const;
export const CLIENT_PROXY_LEAVE_SERVER = "200070" as const;
export const CLIENT_MAIN_AUTHENTICATE_APP = "1000002" as const;
export const MAIN_CLIENT_AUTHENTICATE_APP_OK = "1000103" as const;
export const MAIN_CLIENT_AUTHENTICATE_APP_ERR = "1000104" as const;
export const CLIENT_MAIN_RECONNECT_APP = "1000003" as const;
export const MAIN_CLIENT_RECONNECT_APP_OK = "1000105" as const;
export const MAIN_CLIENT_RECONNECT_APP_ERR = "1000106" as const;
export const CLIENT_INFO_SRV_GET_IDENTICAL_RING_GAME = "102022" as const;
export const INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_OK = "103033" as const;
export const INFO_SRV_CLIENT_GET_IDENTICAL_RING_GAME_ERR = "103034" as const;
export const CLIENT_INFO_SRV_GET_QUICK_TABLE_FROM_CREATOR_ID =
  "102034" as const;
export const INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_OK =
  "103068" as const;
export const INFO_SRV_CLIENT_GET_QUICK_TABLE_FROM_CREATOR_ID_ERR =
  "103069" as const;
export const CLIENT_PROXY_GENERAL_HISTORY_MSG = "200111" as const;
export const PROXY_CLIENT_GENERAL_HISTORY_MSG = "200299" as const;
export const MAIN_CLIENT_KICK_TO_LOBBY = "200370" as const;
export const PROXY_CLIENT_SET_RECONNECTION_FLAG = "200331" as const;
export const CLIENT_PROXY_GET_USR_GAME_SESSIONS = "200068" as const;
export const PROXY_CLIENT_SET_USR_GAME_SESSIONS = "200249" as const;
export const PROXY_CLIENT_SET_USR_GAME_SESSIONS_ERR = "200250" as const;
export const INFO_SRV_CLIENT_END_OR_CANCEL_TOUR = "103072" as const;
export const INFO_SRV_CLIENT_PLAYER_FINISHED_TOUR = "103073" as const;
export const CLIENT_PROXY_RECEIVE_MESSAGE_TO_CLIENT = "200074" as const;
export const CLIENT_PROXY_READY_TO_RECEIVE_MTC_MESSAGE = "200077" as const;
export const PROXY_CLIENT_MESSAGE_TO_CLIENT = "200259" as const;
