import { put, race, take } from "redux-saga/effects";
import {
  ftdSelection,
  POKER_SC_PLAYER_FTD_SELECTION_ERR,
  POKER_SC_PLAYER_FTD_SELECTION_OK,
} from "../../../../network/protocol/gameServer";
import {
  FtdSelectionErr,
  FtdSelectionOk,
  GameServerMessage,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  ftdSelection as ftdSelectionAction,
  ftdSelectionError,
} from "../../actions";

export default function* selectFTD(
  connection: MessagesCommunication<GameServerMessage>,
  action: ReturnType<typeof ftdSelectionAction>,
) {
  connection.send(ftdSelection(action.payload.selected));

  const [, error]: [FtdSelectionOk, FtdSelectionErr] = yield race([
    take(connection.messages, POKER_SC_PLAYER_FTD_SELECTION_OK),
    take(connection.messages, POKER_SC_PLAYER_FTD_SELECTION_ERR),
  ]);

  if (error) {
    yield put(ftdSelectionError(error.error));
  }
}
