import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import {
  appReconnected,
  authenticate,
  authenticated,
  authenticationError,
  bankrollUpdated,
  blastRegistrationSuccess,
  clearUserNotification,
  getAccountAttributesError,
  getAccountAttributesSuccess,
  kickToLobby,
  logout,
  reconnect,
  reconnectError,
  resetReconnectionError,
  segmentationUpdate,
  tourRegistrationSuccess,
  withdrawFunds,
} from "../actions";
import User, { KickToLobbyReason } from "../types/User";

export const initialState: User = {
  currentPaymentType: "cash",
  nightLimitReached: false,
};

export const user = createReducer<User>(initialState, (builder) => {
  builder
    .addCase(authenticate, (state, { payload: { token, regulationType } }) => {
      state.autoLogin = token;
      state.regulationType = regulationType;
    })
    .addCase(authenticated, (state, { payload }) => {
      state.sessionId = payload.PlayerSessionId;
      state.initialSessionId = payload.InitialSessionId;
      state.appSessionId = payload.AppSessionId;
      state.cid = payload.Cid;
      state.userName = payload.Nickname;
      state.userInfo = {
        bankroll: payload.AvailableBankrollInCurrency,
        bankrollInDollars: payload.AvailableBankrollInDollars,
        bankrollCurrencyCode: payload.BankrollCurrencyCode,
        exchangeRateToDollar: payload.ExchangeRateToDollar,
        exchangeRateDollarToBankrollCurrency:
          payload.ExchangeRateDollarToBankrollCurrency,
      };
      state.serverId = payload.ServerId;
      state.autoLogin = undefined;
      state.authenticationError = undefined;
      state.reconnectionError = undefined;
      state.isReconnecting = undefined;
      state.nightLimitReached = payload.NightLimitReached;
      state.nightLimitExpirationDate = payload.NightLimitExpirationDate
        ? payload.NightLimitExpirationDate * 1000
        : undefined;
    })
    .addCase(
      authenticationError,
      (state, { payload: { errorDescription } }) => {
        state.autoLogin = undefined;
        state.authenticationError = errorDescription;
      },
    )
    .addCase(reconnect, (state) => {
      state.isReconnecting = true;
    })
    .addCase(appReconnected, (state, { payload }) => {
      state.userInfo = {
        bankroll: payload.AvailableBankrollInCurrency,
        bankrollInDollars: payload.AvailableBankrollInDollars,
        bankrollCurrencyCode: payload.BankrollCurrencyCode,
        exchangeRateToDollar: payload.ExchangeRateToDollar,
        exchangeRateDollarToBankrollCurrency:
          payload.ExchangeRateDollarToBankrollCurrency,
      };
      state.serverId = payload.ServerId;
      state.isReconnecting = false;
    })
    .addCase(reconnectError, (state, { payload }) => {
      return {
        ...initialState,
        reconnectionError: payload,
        isReconnecting: false,
      };
    })
    .addCase(getAccountAttributesSuccess, (state, { payload }) => {
      state.countryId = payload.countryId;
      state.userStatus = payload.userStatus;
    })
    .addCase(
      getAccountAttributesError,
      (state, { payload: { errorId: errorCode, errorMessage } }) => {
        state.accountAttributesError = { errorCode, errorMessage };
      },
    )
    .addCase(clearUserNotification, (state) => {
      state.notification = undefined;
    })
    .addCase(bankrollUpdated, (state, { payload }) => {
      if (!state.userInfo) return;

      state.userInfo.bankroll = payload.real + payload.bonus;
      state.userInfo.bankrollInDollars =
        (payload.real + payload.bonus) * state.userInfo.exchangeRateToDollar;
    })
    .addCase(segmentationUpdate, (state, { payload }) => {
      state.segmentation = payload;
    })
    .addCase(kickToLobby, (state, { payload }) => {
      const { reasonId, expirationDate } = payload;

      if (reasonId === KickToLobbyReason.NIGHT_LIMIT_REACHED) {
        state.nightLimitReached = true;
        state.nightLimitExpirationDate = expirationDate
          ? expirationDate * 1000
          : undefined;
      }
    })
    .addMatcher(
      createActionMatcher([
        withdrawFunds,
        blastRegistrationSuccess,
        tourRegistrationSuccess,
      ]),
      (state, { payload }) => {
        const { withdrawnRealAmount, withdrawnRestrictedAmount } = payload;

        let newPaymentType = state.currentPaymentType;

        if (withdrawnRestrictedAmount > 0) newPaymentType = "bonus";

        if (withdrawnRealAmount > 0) newPaymentType = "cash";

        if (state.currentPaymentType !== newPaymentType) {
          state.currentPaymentType = newPaymentType;
          state.notification = {
            type: "CMA",
            paymentType: newPaymentType,
            withdrawnRealAmount,
            withdrawnRestrictedAmount,
          };
        }
      },
    )
    .addMatcher(
      createActionMatcher([resetReconnectionError, logout]),
      () => initialState,
    );
});
