import { useCallback } from "react";
import useAppConfig from "./useAppConfig";

const options: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
};

export default function useTimeFormat() {
  const { locale } = useAppConfig();

  return useCallback(
    (rawValue: number) => {
      return new Date(rawValue).toLocaleTimeString(locale, options);
    },
    [locale],
  );
}
