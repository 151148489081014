import styled from "@emotion/styled/macro";
import { TableIndicator } from "../TableIndicator/TableIndicator";
import { ReactComponent as Human } from "../assets/human.svg";

export const HumanIcon = styled(Human)`
  width: 1.25em;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledRegisteredTableIndicator = styled(TableIndicator)`
  ${HumanIcon} {
    color: ${({ active, theme }) =>
      active ? "#fff" : theme.sessionIndicator.table.inactiveContent};
  }
`;
