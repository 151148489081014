import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Orientation } from "../../hooks/useScreenOrientation";
import { minSizeConstraints } from "../Popup/styles";

export const ContentWrapper = styled.div<Orientation>`
  position: relative;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${minSizeConstraints}

  max-width: ${({ $isLandscape }) => ($isLandscape ? 600 : 330)}px;
  max-height: ${({ $isLandscape }) => ($isLandscape ? 254 : 457)}px;

  padding: ${({ $isLandscape }) =>
    $isLandscape ? "10px 3px" : "16px 1px 16px 0px"};

  border-radius: 10px;

  ${({ theme }) => css`
    border: 1px solid ${theme.dialogPaper.borderColor};

    color: ${theme.avatarSelectionPopup.text.color};
    font-size: ${theme.fontSize.small};

    background: ${theme.avatarSelectionPopup.background.color} 0 0 no-repeat
      padding-box;
  `}
`;

export const HeaderWrapper = styled.div<Orientation>`
  display: flex;
  align-items: center;
  width: 100%;

  flex-direction: ${({ $isLandscape }) => ($isLandscape ? "row" : "column")};
`;

export const PlayerIcon = styled.img<Orientation>`
  width: ${({ $isLandscape }) => ($isLandscape ? 24 : 37)}px;
  height: ${({ $isLandscape }) => ($isLandscape ? 24 : 37)}px;

  margin: ${({ $isLandscape }) =>
    $isLandscape ? "0px 10px 0px 14px" : "0px 0px 0px 14px"};
`;

export const AvatarGrid = styled.div<Orientation>`
  display: grid;
  grid-column-gap: 13px;

  overflow-x: hidden;
  overflow-y: scroll;

  ${({ $isLandscape }) =>
    $isLandscape
      ? css`
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          margin: 6px 0px 7px 0px;
          padding: 0 48px;
        `
      : css`
          grid-template-columns: 1fr 1fr 1fr 1fr;
          margin-bottom: 4px;
          padding: 0 4px;
        `}
`;
