import type { NotUndefined } from "@redux-saga/types";
import { ITopic } from "@sparkware/uc-sdk-core";
import { eventChannel } from "redux-saga";
import { MB_HEADERS } from "./externalCommunication";

export default function createExternalMessageCommunication<
  P extends NotUndefined,
>(topic: ITopic<P>) {
  return eventChannel<P>((emitter) => {
    const { unsubscribe } = topic.subscribe((payload, envelope) => {
      if (envelope.headers.publisher !== MB_HEADERS.publisher) {
        emitter(payload);
      }
    });

    return unsubscribe;
  });
}
