import { TFunction } from "i18next";
import { MenuItem } from "../../components/HamburgerMenu";
import { ForwardArrowIcon } from "../../components/HamburgerMenu/Icons";
import Toggle from "../../components/Toggle";
import { SettingsContextProps } from "../../context";
import { HamburgerMenuKeys } from "../../i18n/keys";
import {
  toggleAutoFocus,
  toggleBigBlindFormat,
  toggleEmojiQuickPanel,
  toggleEmojis,
  toggleFourColorDeck,
  toggleSounds,
} from "../../utils/settingsReducer/actions";

export default function getSettingsMenu(
  settings: SettingsContextProps,
  t: TFunction,
  onOpenAvatarsPopup?: () => void,
) {
  const {
    state: {
      sounds,
      emojis,
      emojiQuickPanel,
      fourColorDeck,
      bigBlindFormat,
      autoFocus,
    },
    dispatch,
  } = settings;

  return [
    <MenuItem
      testId="poker-settings-menu-toggle-auto-focus"
      onClick={() => dispatch(toggleAutoFocus())}
      key="SettingsMenu-ToggleAutoFocus"
      endIcon={<Toggle checked={autoFocus} />}
    >
      <>{t(HamburgerMenuKeys.AUTO_FOCUS)}</>
    </MenuItem>,
    <MenuItem
      testId="poker-settings-menu-toggle-sound"
      onClick={() => dispatch(toggleSounds())}
      key="SettingsMenu-ToggleSound"
      endIcon={<Toggle checked={sounds} />}
    >
      <>{t(HamburgerMenuKeys.SOUND)}</>
    </MenuItem>,
    <MenuItem
      testId="poker-settings-menu-toggle-emojis"
      onClick={() => dispatch(toggleEmojis())}
      key="SettingsMenu-ToggleEmojis"
      endIcon={<Toggle checked={emojis} />}
    >
      <>{t(HamburgerMenuKeys.EMOJIS)}</>
    </MenuItem>,
    <MenuItem
      testId="poker-settings-menu-toggle-emoji-quick-panel"
      onClick={() => dispatch(toggleEmojiQuickPanel())}
      key="SettingsMenu-ToggleEmojiQuickPanel"
      disabled={!emojis}
      endIcon={<Toggle checked={emojiQuickPanel} />}
    >
      <>{t(HamburgerMenuKeys.EMOJIQUCKPANEL)}</>
    </MenuItem>,
    <MenuItem
      testId="poker-settings-menu-toggle-4-color-deck"
      onClick={() => dispatch(toggleFourColorDeck())}
      key="SettingsMenu-Toggle4ColorDeck"
      endIcon={<Toggle checked={fourColorDeck} />}
    >
      <>{t(HamburgerMenuKeys.FOUR_COLOR_DECK)}</>
    </MenuItem>,
    <MenuItem
      testId="poker-settings-menu-big-blind-format"
      onClick={() => dispatch(toggleBigBlindFormat())}
      key="SettingsMenu-BigBlindFormat"
      endIcon={<Toggle checked={bigBlindFormat} />}
    >
      <>{t(HamburgerMenuKeys.BIG_BLIND_FORMAT)}</>
    </MenuItem>,
    onOpenAvatarsPopup && (
      <MenuItem
        testId="poker-settings-menu-avatar-selection"
        onClick={() => onOpenAvatarsPopup()}
        key="SettingsMenu-AvatarSelection"
        endIcon={<ForwardArrowIcon />}
      >
        <>{t(HamburgerMenuKeys.AVATAR_SELECTION)}</>
      </MenuItem>
    ),
  ];
}
