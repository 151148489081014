import { put, race, take } from "redux-saga/effects";
import {
  PROXY_CLIENT_CONNECT_SERVER_ERR,
  PROXY_CLIENT_CONNECT_SERVER_OK,
  connectGameServer,
} from "../../../../../network/protocol/mainServer";
import {
  SessionType,
  type ConnectGameServerError,
  type ConnectGameServerOk,
  type MainServerMessage,
} from "../../../../../network/protocol/mainServer/types";
import isRingGameType from "../../../../../utils/isRingGameType";
import {
  GameCreated,
  ReconnectGame,
  gameServerConnected,
  gameServerConnectionError,
} from "../../../actions";
import MessagesCommunication from "../messagesCommunication";

export default function* connectToGameServer(
  connection: MessagesCommunication<MainServerMessage>,
  action: GameCreated | ReconnectGame,
) {
  const { gameId, serverId, context } = action.payload;

  connection.send(
    connectGameServer(
      gameId,
      serverId,
      isRingGameType(context.gameType)
        ? SessionType.RING_GAME
        : SessionType.TOURNAMENT,
    ),
  );

  let response: ConnectGameServerOk | undefined;
  let error: ConnectGameServerError | undefined;

  while (!response && !error) {
    const [responseMessage, errorMessage]:
      | [ConnectGameServerOk, undefined]
      | [undefined, ConnectGameServerError] = yield race([
      take(connection.messages, PROXY_CLIENT_CONNECT_SERVER_OK),
      take(connection.messages, PROXY_CLIENT_CONNECT_SERVER_ERR),
    ]);
    if (responseMessage?.gameId === gameId) response = responseMessage;
    if (errorMessage?.gameId === gameId) error = errorMessage;
  }

  if (response) {
    yield put(
      gameServerConnected({
        gameId: response.gameId,
        serverId: response.serverId,
      }),
    );
  } else if (error) {
    yield put(gameServerConnectionError(error));
  }
}
