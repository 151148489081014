import styled from "@emotion/styled/macro";
import { ProtectedFullScreenRoute } from "../ProtectedFullScreenRoute";

export const StyledProtectedFullScreen = styled(ProtectedFullScreenRoute)`
  z-index: 1;

  background: linear-gradient(
      180deg,
      #03070c 0,
      #07101a 10%,
      #0a1927 22%,
      #0a131e 37%,
      #070e16 56%,
      #070a0f 74%,
      #09131f 89%,
      #06090c 100%
    )
    0 0 no-repeat;

  pointer-events: auto;
`;
