import { INFO_VERSION } from "../../../services";
import Hero from "../../../store/game/types/Hero";
import delimiter from "../delimiter";
import {
  GH_CS_GET_POKER_SESSIONS_DETAILS,
  GH_CS_GET_POKER_TOURNAMENTS_DETAILS,
  GH_CS_GET_THP_GAME_STR,
  GH_CS_GET_TOUR_GAME_STR,
  LOBBY_CS_CHAT_EX,
  LOBBY_CS_JOIN_POKER_RING_GAME,
  LOBBY_CS_JOIN_POKER_TOURNAMENT,
  POKER_CS_BUY_IN,
  POKER_CS_CALL,
  POKER_CS_CALL_ALL_INS,
  POKER_CS_CANCEL_WAIT_FOR_ENTER_POSITION,
  POKER_CS_CHANGE_RSRV_STATUS_EX,
  POKER_CS_CHECK,
  POKER_CS_DECLINE_BIG_BLIND,
  POKER_CS_DECLINE_SMALL_BLIND,
  POKER_CS_DONT_SHOW_CARDS_BEFOR_GAME_RESULT,
  POKER_CS_FOLD,
  POKER_CS_FT_DEAL_DECISION,
  POKER_CS_GET_QUICK_POS_EX,
  POKER_CS_I_AM_ALIVE,
  POKER_CS_JOIN,
  POKER_CS_JOIN_POS,
  POKER_CS_PLAYER_FTD_SELECTION,
  POKER_CS_POST_BIG_BLIND,
  POKER_CS_POST_SIT_IN_PENALTY,
  POKER_CS_POST_SMALL_BLIND,
  POKER_CS_RAISE_ALL_INS,
  POKER_CS_RAISE_VAR_LIM,
  POKER_CS_SET_TURN_OK,
  POKER_CS_SET_TURN_TB_OK,
  POKER_CS_SHOW_CARDS,
  POKER_CS_SHOW_CARDS_BEFOR_GAME_RESULT,
  POKER_CS_SIT_IN,
  POKER_CS_SIT_IN_TOUR,
  POKER_CS_SIT_OUT,
  POKER_CS_SIT_OUT_TOUR,
  POKER_CS_SUBSCRIBE_UNSUBSCRIBE_NOTIFICATION,
  POKER_CS_THROW,
  POKER_CS_WAIT_FOR_ENTER_POSITION,
} from "./messageTypes";
import { FoldType } from "./types/FoldData";
import JoinRingTableData from "./types/JoinRingTableData";
import JoinTournamentData from "./types/JoinTournamentData";
import { TypeID } from "./types/NotificationType";
import { PokerType } from "./types/PokerType";
import TimeBankMode from "./types/TimeBankMode";

const ADDED_FEATURES_SUPPORT = 9;

export function joinTournament({
  tournamentId,
  featuresSupport = ADDED_FEATURES_SUPPORT,
  sendTableHistoryOnViewerMode = true,
}: JoinTournamentData) {
  const params = [
    tournamentId,
    INFO_VERSION,
    sendTableHistoryOnViewerMode ? 1 : 0,
    featuresSupport,
  ];

  return {
    type: LOBBY_CS_JOIN_POKER_TOURNAMENT,
    body: params.join(delimiter.section),
  } as const;
}

export function joinTable() {
  return {
    type: POKER_CS_JOIN,
  } as const;
}

export function setTurnSuccess(timeBankMode: TimeBankMode = 0) {
  const param = [timeBankMode];

  return {
    type: POKER_CS_SET_TURN_OK,
    body: param.join(delimiter.section),
  } as const;
}

export function setTurnTimebankSuccess() {
  return {
    type: POKER_CS_SET_TURN_TB_OK,
  } as const;
}

export function sitIn() {
  return {
    type: POKER_CS_SIT_IN,
  } as const;
}

export function sitOut() {
  return {
    type: POKER_CS_SIT_OUT,
  } as const;
}

export function sitInTour(pos: number) {
  const param = [pos];
  return {
    type: POKER_CS_SIT_IN_TOUR,
    body: param.join(delimiter.section),
  } as const;
}

export function sitOutTour(pos: number) {
  const param = [pos];
  return {
    type: POKER_CS_SIT_OUT_TOUR,
    body: param.join(delimiter.section),
  } as const;
}

export function keepAlive() {
  return {
    type: POKER_CS_I_AM_ALIVE,
  } as const;
}

export function performCheck() {
  return {
    type: POKER_CS_CHECK,
  } as const;
}

export function performCall() {
  return {
    type: POKER_CS_CALL,
  } as const;
}

export function performCallAllIns() {
  return {
    type: POKER_CS_CALL_ALL_INS,
  } as const;
}

export function performRaise(value: number) {
  return {
    type: POKER_CS_RAISE_VAR_LIM,
    body: `${value}${delimiter.section}`,
  } as const;
}

export function performRaiseAllIns() {
  return {
    type: POKER_CS_RAISE_ALL_INS,
  } as const;
}

export function performFold(foldType: FoldType) {
  return {
    type: POKER_CS_FOLD,
    body: `${foldType}${delimiter.section}`,
  } as const;
}

export function performShowCards(mask: number) {
  return {
    type: POKER_CS_SHOW_CARDS_BEFOR_GAME_RESULT,
    body: `${mask}`,
  } as const;
}

export function performDontShowCards() {
  return {
    type: POKER_CS_DONT_SHOW_CARDS_BEFOR_GAME_RESULT,
  } as const;
}

export function sendChatMessage(message: string, style = 0, color = "#000000") {
  return {
    type: LOBBY_CS_CHAT_EX,
    body: `${message}${delimiter.field}${style}${delimiter.field}${color}${delimiter.field}`,
  } as const;
}

export function sendShowCards(mask: Hero["mask"]) {
  return {
    type: POKER_CS_SHOW_CARDS,
    body: `${mask}`,
  } as const;
}

export function performThrowItem(receiverId: number, throwItemId: number) {
  return {
    type: POKER_CS_THROW,
    body: `${receiverId}${delimiter.section}${throwItemId}${delimiter.section}`,
  } as const;
}

export function joinRingGame({
  tableId,
  handId,
  asPlayer,
  playerInstanceId,
  featuresSupport = ADDED_FEATURES_SUPPORT,
  privateGamePassword = "",
  sourceTabId = 1,
}: JoinRingTableData) {
  const params = [
    tableId,
    INFO_VERSION,
    asPlayer ? 1 : 0,
    featuresSupport,
    sourceTabId,
    privateGamePassword,
    playerInstanceId,
    handId,
  ];

  return {
    type: LOBBY_CS_JOIN_POKER_RING_GAME,
    body: `${params.join(delimiter.section)}${delimiter.section}`,
  } as const;
}

export function changeSeatReservation(seatId: number, reserve: boolean) {
  return {
    type: POKER_CS_CHANGE_RSRV_STATUS_EX,
    body: `${seatId}${delimiter.section}${0}${delimiter.section}${
      reserve ? 1 : 0
    }${delimiter.section}`,
  } as const;
}

export function getQuickPos(tableId: string) {
  return {
    type: POKER_CS_GET_QUICK_POS_EX,
    body: `${tableId}${delimiter.section}`,
  } as const;
}

export function joinToPosition(
  seatId: number,
  credit: number,
  avatarId: number,
) {
  const params = [
    seatId,
    credit,
    avatarId,
    "1", // Not used. Allways equals 1
  ];

  return {
    type: POKER_CS_JOIN_POS,
    body: `${params.join(delimiter.section)}${delimiter.section}`,
  } as const;
}

export function postSmallBlind() {
  return {
    type: POKER_CS_POST_SMALL_BLIND,
    body: delimiter.section,
  } as const;
}

export function declineSmallBlind() {
  return {
    type: POKER_CS_DECLINE_SMALL_BLIND,
  } as const;
}

export function postBigBlind() {
  return {
    type: POKER_CS_POST_BIG_BLIND,
    body: delimiter.section,
  } as const;
}

export function declineBigBlind() {
  return {
    type: POKER_CS_DECLINE_BIG_BLIND,
  } as const;
}

export function waitForFirstToAct() {
  return {
    type: POKER_CS_WAIT_FOR_ENTER_POSITION,
  } as const;
}

export function cancelWaitForFirstToAct() {
  return {
    type: POKER_CS_CANCEL_WAIT_FOR_ENTER_POSITION,
  } as const;
}

export function postSitInPenalty() {
  return {
    type: POKER_CS_POST_SIT_IN_PENALTY,
    body: `${0}${delimiter.section}`,
  } as const;
}

export function performBuyIn(amount: number) {
  return {
    type: POKER_CS_BUY_IN,
    body: `${amount}${delimiter.section}`,
  } as const;
}

export function getRingGameHands(sessionId: number, pokerType: PokerType) {
  return {
    type: GH_CS_GET_POKER_SESSIONS_DETAILS,
    body: `${sessionId}${delimiter.star}${pokerType}${delimiter.star}${delimiter.star}`,
  } as const;
}

export function getTournamentHands(tournamentId: string) {
  return {
    type: GH_CS_GET_POKER_TOURNAMENTS_DETAILS,
    body: `${delimiter.star}${3 /* TOURNAMENT_GAMES_SCREEN */}${
      delimiter.star
    }${delimiter.star}3${delimiter.star}${tournamentId}${delimiter.star}0${
      delimiter.star
    }`,
  } as const;
}

export function getTournamentHandHistory(handId: number, serverId: string) {
  return {
    type: GH_CS_GET_TOUR_GAME_STR,
    body: `${serverId}${delimiter.section}${handId}${delimiter.section}0${delimiter.section}`,
  } as const;
}

export function getRingHandHistory(handId: number, serverId: string) {
  return {
    type: GH_CS_GET_THP_GAME_STR,
    body: `${serverId}${delimiter.star}0${delimiter.star}${delimiter.star}0${delimiter.star}${handId}${delimiter.star}`,
  } as const;
}

export function ftdSelection(selected: boolean) {
  return {
    type: POKER_CS_PLAYER_FTD_SELECTION,
    body: `${selected ? 1 : 0}`,
  } as const;
}

export function ftdDecision(accepted: boolean) {
  return {
    type: POKER_CS_FT_DEAL_DECISION,
    body: `${accepted ? 1 : 0}${delimiter.section}`,
  } as const;
}

export function subscribeNotification(notificationId: number[]) {
  return {
    type: POKER_CS_SUBSCRIBE_UNSUBSCRIBE_NOTIFICATION,
    body: `${TypeID.Subscribe}${delimiter.section}${notificationId.join(delimiter.field)}`,
  } as const;
}
