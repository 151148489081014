import { eventChannel, Subscribe } from "redux-saga";
import Communication from "../../../../network/Communication";

export default function createEventChannel<
  C extends Communication<Record<K, any>>,
  K extends string,
>(source: C, type: K) {
  const subscribe: Subscribe<
    C extends Communication<infer M>
      ? M extends Record<K, infer E>
        ? E extends Event
          ? E
          : never
        : never
      : never
  > = (emit) => {
    source.addEventListener(type, emit);

    return () => {
      source.removeEventListener(type, emit);
    };
  };

  return eventChannel(subscribe);
}
