import Card, { CardId, Rank, Suit } from "./types/Card";

const RANKS_AMOUNT = 13;

function isValidCardId(id: number): id is CardId {
  return id > 0 && id < 53;
}

function getSuit(id: CardId): Suit {
  return (Math.ceil(id / RANKS_AMOUNT) - 1) as Suit;
}

function getRank(id: CardId): Rank {
  return ((id - 1) % RANKS_AMOUNT) as Rank;
}

export default function getCardData(id: number): Card | null {
  if (isValidCardId(id)) {
    return {
      id,
      rank: getRank(id),
      suit: getSuit(id),
    } as const;
  }
  return null;
}
