import { Howl } from "howler";
import soundsInfo from "../assets/sounds/allSounds.json";
import allSounds from "../assets/sounds/allSounds.mp3";
import { SoundNames, SoundsSpriteMap } from "./types";

const sounds = new Howl({
  src: allSounds,
  sprite: soundsInfo.sprite as SoundsSpriteMap,
});

const play = (spriteOrId?: SoundNames | number) => {
  return sounds.play(spriteOrId);
};

const stop = (id?: number) => {
  sounds.stop(id);
};

const mute = (muted: boolean, id?: number) => {
  sounds.mute(muted, id);
};

export default function useSoundControls() {
  return {
    play,
    stop,
    mute,
  };
}
