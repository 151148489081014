import { createReducer } from "@reduxjs/toolkit";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import { ringGameJoined, stateRestored, tournamentJoined } from "../actions";
import Seats from "../types/Seats";

const initialState: Seats = [];

export const seats = createReducer<Seats>(initialState, (builder) => {
  builder
    .addCase(stateRestored, (_, { payload }) => payload.seats)
    .addMatcher(
      createActionMatcher([tournamentJoined, ringGameJoined]),
      (_, { payload }) => payload.positions,
    );
});
