import colors from "../colors";
import poker888 from "./888Poker";
import base from "./base";

interface DeckColors {
  spade: string;
  club: string;
  diamond: string;
  heart: string;
}

const twoColorDeck: DeckColors = {
  spade: colors.black,
  club: colors.black,
  diamond: colors.red,
  heart: colors.red,
};

const fourColorDeck: DeckColors = {
  spade: colors.black,
  club: colors.sanFelix,
  diamond: colors.navyBlue2,
  heart: colors.red,
};

const defaultTheme = Object.assign({}, base, poker888, { deck: twoColorDeck });

export const fourColorDeckTheme = {
  ...defaultTheme,
  deck: fourColorDeck,
};

export default defaultTheme;
