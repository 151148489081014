import { call, select } from "redux-saga/effects";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { getTopUpSelector } from "../../selectors";
import cashBuyIn from "./cashBuyIn";

export default function* autoRebuy(
  connection: MessagesCommunication<GameServerMessage>,
) {
  const topUp: boolean | undefined = yield select(getTopUpSelector);
  if (topUp) yield call(cashBuyIn, connection);
}
