import { ActionCreator, UnknownAction } from "@reduxjs/toolkit";
import { put, take } from "redux-saga/effects";
import {
  GameServerMessage,
  GameServerMessageType,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";

export default function* pushMessageHandler(
  messageType: GameServerMessageType,
  actionCreator: ActionCreator<UnknownAction>,
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const { type, ...message }: GameServerMessage = yield take(
      connection.messages,
      messageType,
    );
    yield put(actionCreator(message));
  }
}
