import { PropsWithChildren } from "react";
import defaultIcon from "../../assets/king.png";
import Styleable from "../../utils/types/Styleable";
import { ChildrenLayout, ErrorIcon, ErrorLayout, ErrorMessage } from "./styles";
import type GenericErrorProps from "./types";

export default function GenericError({
  text,
  testId,
  children,
  iconSource,
  className,
}: PropsWithChildren<Styleable<GenericErrorProps>>) {
  return (
    <ErrorLayout className={className} data-testid={testId}>
      <ErrorIcon src={iconSource || defaultIcon} alt="Error Icon" />
      <ErrorMessage>{text}</ErrorMessage>
      {children && <ChildrenLayout>{children}</ChildrenLayout>}
    </ErrorLayout>
  );
}
