import { ISharedTriple8Messaging } from "@sparkware/uc-sdk-core";
import type { Task } from "redux-saga";
import { fork, put, take, takeEvery } from "redux-saga/effects";
import Communication from "../../../../network/Communication";
import {
  CommunicationLifecycleEvent,
  GameServerEventMap,
} from "../../../../network/events";
import createEventChannel from "../../../app/sagas/shared/createEventChannel";
import {
  resetInternalHudData,
  ringTableJoined,
  tourTableJoined,
  updateSnapPoolTableId,
} from "../../actions";
import internalHudHandler from "./internalHudHandler";

export default function* internalHudFlow(
  server: Communication<GameServerEventMap>,
  triple8DataPromise: Promise<ISharedTriple8Messaging["subscribe"]>,
) {
  const disconnectionChannel = createEventChannel(
    server,
    CommunicationLifecycleEvent.DISCONNECTED,
  );

  let internalHudTask: Task | undefined;

  yield takeEvery(
    [tourTableJoined.type, ringTableJoined.type, updateSnapPoolTableId.type],
    function* () {
      if (internalHudTask) {
        internalHudTask.cancel();

        yield put(resetInternalHudData());
      }

      internalHudTask = yield fork(internalHudHandler, triple8DataPromise);
    },
  );

  yield take(disconnectionChannel);

  internalHudTask?.cancel();
}
