export enum JoinPositionErrorReason {
  INVALID_POS = 0,
  ANOTHER_PLAYER_ALREADY_SEATED,
  NOT_ALLOWED,
  BELOW_MIN_BUY_IN,
  TABLE_FULL,
  IS_ALREADY_SEATED,
  OVER_MAX_BUY_IN,
  IP_BLOCK,
  GENERAL,
  INSUFFICIENT_FUNDS,
  ADMIN_IS_NOT_PERMITTED,
  LOSS_LIMIT_REACHED,
  WAGER_LIMIT_REACHED,
  PENDING_ACTION,
  BUY_IN_LIMIT_REACHED,
  NON_PERIODIC_LOSS_LIMIT_REACHED,
  RAT_HOLING_POLICY,
  OVER_SESSION_LIMIT,
}

export default interface JoinPositionError {
  reason: JoinPositionErrorReason;
}
