export type SuitType = "spade" | "club" | "diamond" | "heart";
export type RankType =
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "J"
  | "Q"
  | "K"
  | "A";

const CARDS_SPECS: { suit: SuitType[]; rank: RankType[] } = {
  suit: ["spade", "club", "diamond", "heart"],
  rank: ["2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"],
};

export default CARDS_SPECS;
