export enum ConnectionStatus {
  IDLE = "IDLE",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export default interface Connection {
  connectionState: ConnectionStatus;
  connectionError?: Error;
  joinState: ConnectionStatus;
  joinError?: Error;
}
