import { AreaType, IMessageBroker, TriggerType } from "@sparkware/uc-sdk-core";
import { v4 as uuid } from "uuid";
import { APP_ID, MB_HEADERS } from "../externalCommunication";

export function publishOpenCashier(messageBroker: IMessageBroker) {
  messageBroker.navigation.topics.performAction.publish(MB_HEADERS, {
    actionID: "deposit",
    launchInfo: {
      businessCorrelationID: uuid(),
      sourceAppID: APP_ID,
      sourceAppVersion: process.env.REACT_APP_VERSION || "",
      trigger: TriggerType.userSelection,
      channel: {
        area: AreaType.button,
        source: APP_ID,
      },
    },
  });
}
