import { ISharedTriple8Messaging } from "@sparkware/uc-sdk-core";
import { EventChannel, eventChannel, SagaIterator } from "redux-saga";
import { call, cancelled, put, select, take } from "redux-saga/effects";
import { setInternalHudData } from "../../actions";
import { getTableSelector, getTournamentSelector } from "../../selectors";
import Table from "../../types/Table";
import {
  InternalHudData,
  TableMessageSnapshot,
  TableMessageType,
} from "../../types/TableMessages";
import Tournament from "../../types/Tournament";

function createSubscribeChannel(
  subscribe: ISharedTriple8Messaging["subscribe"],
  params: {
    tableId: string;
    tournamentId?: string;
    snapRingPoolTableId?: number;
  },
): EventChannel<TableMessageSnapshot> {
  const { tableId, tournamentId, snapRingPoolTableId } = params;

  return eventChannel((emitter) => {
    const { unsubscribe } = subscribe<TableMessageSnapshot[]>(
      "pokerTableMessages",
      (data) => {
        const hud = data.find(
          (item) =>
            item.data.ExpiredAtUtcTimeEpochSec > Math.floor(Date.now() / 1000),
        );

        if (!hud) return;

        emitter(hud);
      },
      {
        query: [
          {
            fieldPath: "TableId",
            opStr: "==",
            value: Number(tableId),
          },
          {
            fieldPath: "MessageId",
            opStr: "==",
            value: TableMessageType.InternalHud,
          },
          ...(tournamentId
            ? [
                {
                  fieldPath: "TournamentId",
                  opStr: "==",
                  value: Number(tournamentId),
                },
              ]
            : []),
          ...(snapRingPoolTableId
            ? [
                {
                  fieldPath: "SnapRingPoolTableId",
                  opStr: "==",
                  value: Number(snapRingPoolTableId),
                },
              ]
            : []),
        ],
      },
    );

    return unsubscribe;
  });
}

export default function* internalHudHandler(
  triple8DataPromise: Promise<ISharedTriple8Messaging["subscribe"]>,
): SagaIterator {
  let channel: EventChannel<TableMessageSnapshot[]> | undefined;

  try {
    const subscribe: ISharedTriple8Messaging["subscribe"] = yield call(
      () => triple8DataPromise,
    );

    const { id: tableId, snapRingPoolTableId }: Table =
      yield select(getTableSelector);

    const tournament: Tournament | undefined = yield select(
      getTournamentSelector,
    );

    channel = yield call(createSubscribeChannel, subscribe, {
      tableId,
      snapRingPoolTableId,
      tournamentId: tournament?.tournamentId,
    });

    while (true) {
      if (!channel) break;

      const snapshot: TableMessageSnapshot = yield take(channel);

      const data: InternalHudData[] = JSON.parse(snapshot.data.MessageData);

      yield put(setInternalHudData(data));
    }
  } catch {
    // just ignore it
  } finally {
    if (yield cancelled()) {
      channel?.close();
    }
  }
}
