import { TurnAction } from "../store/game/types/Turn";

export const actionMaskType = {
  FOLD: 1,
  CHECK: 1 << 1,
  CALL: 1 << 2,
  RAISE: 1 << 3,
  SIT_OUT: 1 << 4,
  CALL_ALL_INS: 1 << 5,
  RAISE_ALL_INS: 1 << 6,
  RAISE_2: 1 << 7,
  RAISE_2_ALL_INS: 1 << 8,
  BRING_IN: 1 << 9,
  SHOW_HAND: 1 << 10,
  SNAP_FOLD: 1 << 11,
};

export const getMaskActions = (mask: number) => {
  const actions = [];

  if (mask & actionMaskType.FOLD) {
    actions.push(TurnAction.FOLD);
  }

  if (mask & actionMaskType.CHECK) {
    actions.push(TurnAction.CHECK);
  }

  if (mask & actionMaskType.CALL) {
    actions.push(TurnAction.CALL);
  }

  if (mask & actionMaskType.RAISE) {
    actions.push(TurnAction.RAISE);
  }

  if (mask & actionMaskType.CALL_ALL_INS) {
    actions.push(TurnAction.CALL_ALL_INS);
  }

  if (mask & actionMaskType.RAISE_ALL_INS) {
    actions.push(TurnAction.RAISE_ALL_INS);
  }

  if (mask & actionMaskType.SHOW_HAND) {
    actions.push(TurnAction.SHOW_CARDS, TurnAction.DONT_SHOW_CARDS);
  }

  return actions;
};
