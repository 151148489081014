import { subscribeNotification } from "../../../../network/protocol/gameServer";
import { GameServerMessage } from "../../../../network/protocol/gameServer/types";
import { NotificationID } from "../../../../network/protocol/gameServer/types/NotificationType";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";

export default function subscribePushNotification(
  connection: MessagesCommunication<GameServerMessage>,
) {
  connection.send(
    subscribeNotification([
      NotificationID.PositionInTournament,
      NotificationID.PrizeInfo,
    ]),
  );
}
