import { PokerType } from "../network/protocol/gameServer/types/PokerType";

export const MAX_CARDS_NUMBER = 4;
const HOLDEM_CARDS_NUMBER = 2;

export default function getPlayerCardsLength(pokerType: PokerType) {
  return pokerType === PokerType.OMAHAHI || pokerType === PokerType.OMAHAHILO
    ? MAX_CARDS_NUMBER
    : HOLDEM_CARDS_NUMBER;
}
