import { createReducer } from "@reduxjs/toolkit";
import {
  GetQuickPosOk,
  ResevationStatusChangedErr,
} from "../../../network/protocol/gameServer/types";
import { RatHolingErrorId } from "../../../network/protocol/gameServer/types/RatHolingErrorId";
import { createActionMatcher } from "../../../utils/createActionMatcher";
import { topUpStateChanged } from "../../app/actions";
import {
  addKickCandidate,
  antePosted,
  betCalled,
  betRaised,
  bigBlindPosted,
  buyInError,
  checked,
  creditPending,
  deadPenaltyPosted,
  folded,
  gameStarted,
  heroCardSelection,
  heroCardsReceived,
  heroDontShowCards,
  heroSatDown,
  heroSeatReservationChanged,
  heroSeatReservationError,
  heroShowCards,
  joinNow,
  joinPosition,
  joinPositionError,
  livePenaltyPosted,
  playerRegulationData,
  reBuySuccess,
  removeKickCandidate,
  removePlayer,
  resetBuyInError,
  resetJoinPositionError,
  returnToViewer,
  ringGameJoined,
  round1Started,
  seatAvailable,
  seatReservationChanged,
  seatUnavailable,
  sessionDetails,
  showCards,
  showCardsOnFold,
  smallBlindPosted,
  stateRestored,
  tableWillClear,
  thrownObject,
  toggleAutoPenaltyPayment,
  tournamentFinished,
  updateRebuy,
  waitingForEnterPositionChanged,
  willSitOut,
} from "../actions";
import initialState from "../initialStates/hero";
import Hero from "../types/Hero";

function getRatHolingError(
  payload: GetQuickPosOk | ResevationStatusChangedErr,
) {
  return payload.ratHolingErrorID !== RatHolingErrorId.NONE
    ? {
        id: payload.ratHolingErrorID as RatHolingErrorId,
        amount: payload.ratHolingMoneyUponExiting,
        expires: payload.syncDate + payload.ratHolingMilliSecondsLeft,
      }
    : undefined;
}

export const hero = createReducer<Hero>(initialState, (builder) => {
  builder
    .addCase(ringGameJoined, (state) => {
      state.reservedSeatId = undefined;
      state.sitOutData = undefined;
      state.ratHolingError = undefined;
      state.rebuyPending = undefined;
      state.waitingForEnter = undefined;
    })
    .addCase(heroSatDown, (state, { payload }) => {
      state.playerInstanceId = payload.playerInstanceId;
      state.seatId = payload.seatId;
      state.ratHolingError = undefined;
      state.sessionId = payload.sessionId;
      state.viewer = false;
    })
    .addCase(heroCardsReceived, (state, { payload }) => {
      if (payload.seatId === state.seatId) {
        state.restoredCards = payload.cards;
      }
    })
    .addCase(round1Started, (state) => {
      state.restoredCards = undefined;
    })
    .addCase(heroCardSelection, (state, { payload: { cardIndex } }) => {
      state.mask = state.mask ^ (1 << cardIndex);
    })
    .addCase(thrownObject, (state, { payload }) => {
      if (payload.sender === state.seatId) {
        state.willCooldownAt = payload.willCooldownAt;
      }
    })
    .addCase(heroSeatReservationChanged, (state, { payload }) => {
      state.reservedSeatId = payload.reserved ? payload.seatId : undefined;
    })
    .addCase(heroSeatReservationError, (state, { payload }) => {
      state.reservedSeatId = payload.reserved ? payload.seatId : undefined;
      state.ratHolingError = getRatHolingError(payload);
    })
    .addCase(seatAvailable, (state, { payload }) => {
      state.reservedSeatId = payload.seatId;
      state.ratHolingError = getRatHolingError(payload);
    })
    .addCase(seatUnavailable, (state, { payload: { errorCode } }) => {
      state.seatUnavailableError = { errorCode };
    })
    .addCase(seatReservationChanged, (state, { payload }) => {
      if (state.reservedSeatId === payload.seatId && !payload.reserved) {
        state.reservedSeatId = undefined;
      }
    })
    .addCase(joinPositionError, (state, { payload }) => {
      state.reservedSeatId = undefined;
      state.joinPositionError = payload.error;
    })
    .addCase(resetJoinPositionError, (state) => {
      state.joinPositionError = undefined;
      state.seatUnavailableError = undefined;
    })
    .addCase(tournamentFinished, (state) => {
      return { ...initialState, name: state.name, viewer: true };
    })
    .addCase(stateRestored, (_, { payload: { hero } }) => hero)
    .addCase(updateRebuy, (state, { payload: { credit } }) => {
      state.buyIn = credit;
    })
    .addCase(joinPosition, (state, { payload }) => {
      state.buyIn = payload.credit;
    })
    .addCase(addKickCandidate, (state, { payload }) => {
      state.sitOutData = payload;
    })
    .addCase(removeKickCandidate, (state) => {
      state.sitOutData = undefined;
    })
    .addCase(topUpStateChanged, (state, { payload: { topUp } }) => {
      state.topUp = topUp;
    })
    .addCase(creditPending, (state, { payload }) => {
      state.rebuyPending = payload.pending;
    })
    .addCase(reBuySuccess, (state) => {
      state.rebuyPending = undefined;
    })
    .addCase(buyInError, (state, { payload }) => {
      state.rebuyPending = undefined;
      state.rebuyError = payload;
    })
    .addCase(resetBuyInError, (state) => {
      state.rebuyError = undefined;
    })
    .addCase(waitingForEnterPositionChanged, (state, { payload }) => {
      state.waitingForEnter = payload.waiting;
    })
    .addCase(joinNow, (state) => {
      state.autoPenaltyPayment = true;
      state.firstPaymentMade = true;
    })
    .addCase(toggleAutoPenaltyPayment, (state) => {
      state.autoPenaltyPayment = !state.autoPenaltyPayment;
    })
    .addCase(willSitOut, (state) => {
      state.willSitOut = !state.willSitOut;
    })
    .addCase(playerRegulationData, (state, { payload }) => {
      state.participationId = payload.participationId;
    })
    .addCase(sessionDetails, (state, { payload }) => {
      state.sessionLimit = payload.playerLimit;
    })
    .addCase(returnToViewer, (state, { payload }) => {
      if (payload.seatId === state.seatId) {
        return {
          ...initialState,
          name: state.name,
          viewer: true,
          initialSessionLimit: state.initialSessionLimit,
          sessionLimit: state.initialSessionLimit,
        };
      }
    })
    /**
     * At some point we receive a sequence of messages
     * POKER_SC_JOIN_POS_OK -> POKER_SC_REMOVE -> POKER_SC_ADD_PLAYER
     * from the server, so we cannot mark the player as a viewer.
     */
    .addCase(removePlayer, (state, { payload }) => {
      if (payload.seatId === state.seatId) {
        return {
          ...initialState,
          name: state.name,
          seatId: state.seatId,
          viewer: state.viewer,
          initialSessionLimit: state.initialSessionLimit,
          sessionLimit: state.initialSessionLimit,
        };
      }
    })
    .addMatcher(createActionMatcher([showCards, showCardsOnFold]), (state) => {
      state.manuallyShowedCards = true;
    })
    .addMatcher(
      createActionMatcher([heroShowCards, heroDontShowCards]),
      (state, { payload }) => {
        state.mask = 0;
        state.showedCards = payload;
      },
    )
    .addMatcher(createActionMatcher([tableWillClear, gameStarted]), (state) => {
      state.manuallyShowedCards = undefined;
      state.showedCards = undefined;
      state.mask = 0;
      state.willSitOut = false;
    })
    .addMatcher(
      createActionMatcher([
        antePosted,
        smallBlindPosted,
        bigBlindPosted,
        livePenaltyPosted,
        deadPenaltyPosted,
        betCalled,
        betRaised,
        folded,
        checked,
      ]),
      (state, { payload }) => {
        if (state.seatId === payload.seatId) {
          state.autoPenaltyPayment = undefined;
          state.firstPaymentMade = true;
        }
      },
    );
});
