import { put, select, take } from "redux-saga/effects";
import {
  POKER_SC_BIG_BLIND,
  postBigBlind,
} from "../../../../network/protocol/gameServer";
import {
  BigBlind,
  GameServerMessage,
} from "../../../../network/protocol/gameServer/types";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { bigBlind } from "../../actions";
import { getHeroSelector } from "../../selectors";
import Player from "../../types/Player";
import { NonRoundAction } from "../../types/Turn";

export default function* bigBlindHandler(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const blind: BigBlind = yield take(connection.messages, POKER_SC_BIG_BLIND);
    const hero: Player | undefined = yield select(getHeroSelector);

    if (blind.seatId === hero?.seatId) {
      connection.send(postBigBlind());
    } else {
      yield put(
        bigBlind({
          seatId: blind.seatId,
          actions: [NonRoundAction.BIG_BLIND, NonRoundAction.DECLINE_BIG_BLIND],
          timeOut: blind.timeOut,
          syncDate: blind.syncDate,
        }),
      );
    }
  }
}
