import { put, select, take } from "redux-saga/effects";
import { POKER_SC_DONT_SHOW_CARDS } from "../../../../network/protocol/gameServer";
import {
  DontShowCards,
  GameServerMessage,
} from "../../../../network/protocol/gameServer/types";
import { PokerType } from "../../../../network/protocol/gameServer/types/PokerType";
import fillToLength from "../../../../utils/fillToLength";
import getPlayerCardsLength from "../../../../utils/getPlayerCardsLength";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import { heroDontShowCards, playerDontShowCards } from "../../actions";
import { getHeroSelector, getPokerTypeSelector } from "../../selectors";
import Player from "../../types/Player";

export default function* dontShowedCardsHandler(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const data: DontShowCards = yield take(
      connection.messages,
      POKER_SC_DONT_SHOW_CARDS,
    );

    const pokerType: PokerType = yield select(getPokerTypeSelector);
    const hero: Player = yield select(getHeroSelector);

    const playerCards = fillToLength(
      data.cards,
      getPlayerCardsLength(pokerType),
    );

    if (data.seatId === hero?.seatId) {
      yield put(heroDontShowCards(playerCards));
    } else {
      yield put(
        playerDontShowCards({ seatId: data.seatId, cards: playerCards }),
      );
    }
  }
}
