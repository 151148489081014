import i18next from "i18next";
import { PokerType } from "../../network/protocol/gameServer/types/PokerType";
import { Cultures, LanguageCode } from "../../utils/cultures";
import { BASE_URL, BRAND_ID, INFO_VERSION } from "../lobbyServices";
import { fetchData } from "../utils";
import {
  TourStatus,
  TournamentDataResponse,
  TournamentInfo,
  TournamentServerResponse,
  TournamentsData,
} from "./types";
const TOUR_DETAILS_API_URL = "api/mobile/v1/tournament/";
const TOURNAMENTS_API_URL = "/api/desktop/v1/tournament/tours";

export async function getTourDetails(tourId: number, abort?: AbortSignal) {
  const language = i18next.language as LanguageCode;
  const languageId = Cultures[language].languageId.toString();

  const serviceEndpoint = new URL(`${TOUR_DETAILS_API_URL}${tourId}`, BASE_URL);

  serviceEndpoint.searchParams.append("BrandID", BRAND_ID);
  serviceEndpoint.searchParams.append("LangID", languageId);

  const responseJson = await fetchData<TournamentDataResponse>(
    serviceEndpoint.href,
    abort,
  );

  return responseJson;
}

let lastTimeStamp = 0;
let currentRequest: Promise<TournamentsData> | undefined;
let tournamentsCache: TournamentsData = {};
let languageId = Cultures["en-US"].languageId;

export function getTournaments(langId: number) {
  if (languageId !== langId) {
    languageId = langId;
    tournamentsCache = {};
    lastTimeStamp = 0;
  }

  currentRequest ||= new Promise((resolve, reject) => {
    const lobbyServiceUrl = new URL(TOURNAMENTS_API_URL, BASE_URL);

    lobbyServiceUrl.searchParams.append(
      "GameType",
      String(PokerType.TEXASHOLDEM),
    );
    lobbyServiceUrl.searchParams.append("LangID", `${langId}`);
    lobbyServiceUrl.searchParams.append("BrandID", BRAND_ID);
    lobbyServiceUrl.searchParams.append("InfoVer", INFO_VERSION);
    lobbyServiceUrl.searchParams.append("LastTimeStamp", String(lastTimeStamp));

    fetchData<TournamentServerResponse>(lobbyServiceUrl.href)
      .then((responseJson) => {
        lastTimeStamp = responseJson.LastTimeStamp;

        if (responseJson.LobbyTournaments) {
          for (const tournamentId in responseJson.LobbyTournaments) {
            const cachedElement = tournamentsCache[tournamentId];
            const element = responseJson.LobbyTournaments[tournamentId];

            if (cachedElement) {
              if (element.TourDefStaticData) {
                cachedElement.TourDefStaticData = element.TourDefStaticData;
              }

              /**
               * Sometimes server sends garbage inside TourStatesRtData,
               * we should ignore this data until server fixes.
               */
              if (
                element.TourStatesRtData &&
                element.TourStatesRtData.TourStatus >= TourStatus.DEFINED
              ) {
                cachedElement.TourStatesRtData = element.TourStatesRtData;
              }
            } else if (element.TourDefStaticData && element.TourStatesRtData) {
              tournamentsCache[tournamentId] = element as TournamentInfo;
            }
          }
        }

        if (responseJson.RemovedData) {
          responseJson.RemovedData.forEach((tourId) => {
            delete tournamentsCache[tourId];
          });
        }

        resolve(tournamentsCache);
      })
      .catch((reason) => {
        reject(reason);
      });
  });

  currentRequest
    .catch(() => {})
    .finally(() => {
      currentRequest = undefined;
    });

  return currentRequest;
}
