import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { PortalProps } from "./types";

export default function Portal({
  placeholder,
  children,
}: PropsWithChildren<PortalProps>) {
  return placeholder && createPortal(children, placeholder);
}
