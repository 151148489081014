import { Dot, PendingSpinner } from "./styles";

export default function Button() {
  return (
    <PendingSpinner data-testid="poker-pending-spinner">
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
    </PendingSpinner>
  );
}
