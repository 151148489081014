import delimiter from "../delimiter";

export default function parsePlayerAction(source: string) {
  const params = source.split(delimiter.section);
  const credit = Number.parseInt(params[3]);
  const effectiveStack = Number.parseInt(params[4]);

  return {
    seatId: Number.parseInt(params[0]),
    bet: Number.parseInt(params[1]),
    roundBet: Number.parseInt(params[2]),
    credit,
    effectiveStack: Number.isNaN(effectiveStack) ? credit : effectiveStack,
  } as const;
}
