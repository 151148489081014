import { PropsWithChildren } from "react";
import Styleable from "../../utils/types/Styleable";
import PendingSpinner from "../PendingSpinner";
import { ButtonStyle } from "./styles";
import ButtonProps from "./types";

export default function Button({
  onClick,
  kind = "primary",
  disabled = false,
  testId,
  pending,
  children,
  className,
}: PropsWithChildren<Styleable<ButtonProps>>) {
  return (
    <ButtonStyle
      kind={kind}
      onClick={onClick}
      data-testid={testId}
      disabled={disabled || pending}
      className={className}
    >
      {pending ? (
        <PendingSpinner data-testid="poker-pending-spinner" />
      ) : (
        children
      )}
    </ButtonStyle>
  );
}
