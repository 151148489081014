import { put, race, select, take } from "redux-saga/effects";
import {
  MAIN_CLIENT_AUTHENTICATE_APP_ERR,
  MAIN_CLIENT_AUTHENTICATE_APP_OK,
  authenticate,
} from "../../../../network/protocol/mainServer";
import type {
  AuthenticationError,
  AuthenticationSuccess,
  MainServerMessage,
} from "../../../../network/protocol/mainServer/types";
import {
  Authenticate,
  authenticated,
  authenticationError,
} from "../../actions";
import { getConnectionSelector } from "../../selectors";
import Connection, { ConnectionStatus } from "../../types/Connection";
import MessagesCommunication from "./messagesCommunication";

export default function* authenticatePlayer(
  connection: MessagesCommunication<MainServerMessage>,
  action: Authenticate,
) {
  const { joinState }: Connection = yield select(getConnectionSelector);

  if (joinState !== ConnectionStatus.SUCCESS) return;

  connection.send(authenticate(action.payload.token));

  const [response, error]: [AuthenticationSuccess, AuthenticationError] =
    yield race([
      take(connection.messages, MAIN_CLIENT_AUTHENTICATE_APP_OK),
      take(connection.messages, MAIN_CLIENT_AUTHENTICATE_APP_ERR),
    ]);

  if (response) {
    yield put(authenticated(response));
  } else if (error) {
    yield put(
      authenticationError({
        errorCode: error.errorCode,
        errorDescription: error.description,
      }),
    );
  }
}
