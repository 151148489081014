interface SeatReservationStatusMessage {
  Pos: string;
  CID: string;
  Reserve: string;
}

export default function parseSeatReservationStatus(message: string) {
  const data: SeatReservationStatusMessage = JSON.parse(message);

  return {
    seatId: Number.parseInt(data.Pos),
    reserved: data.Reserve === "1",
  } as const;
}
