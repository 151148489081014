import { IAuthenticateTopicData } from "@sparkware/uc-sdk-core";
import { put } from "redux-saga/effects";
import { authenticate } from "../../../../actions";

export function* dispatchAuthenticate({
  Token,
  RegulationTypeID,
}: IAuthenticateTopicData) {
  yield put(authenticate({ token: Token, regulationType: RegulationTypeID }));
}
