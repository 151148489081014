import { useCallback } from "react";
import useAppConfig from "./useAppConfig";

const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

export default function useDateFormat() {
  const { locale } = useAppConfig();

  return useCallback(
    (rawValue: number) => {
      return new Date(rawValue).toLocaleDateString(locale, options);
    },
    [locale],
  );
}
