const namespace = "internalHud";

const details = `${namespace}.details`;
const explanationPopup = `${namespace}.explanationPopup`;

export const HANDS_LABEL = `${details}.hands`;
export const VPIP_LABEL = `${details}.vpip`;
export const PFR_LABEL = `${details}.pfr`;
export const THREE_BET_LABEL = `${details}.threeBet`;
export const NO_DATA = `${details}.noData`;
export const MTT_WINNINGS = `${details}.mttWinnings`;
export const BLAST_WINNINGS = `${details}.blastWinnings`;

export const POPUP_HEADER_TEXT = `${explanationPopup}.headerText`;
export const POPUP_VPIP_LABEL = `${explanationPopup}.vpipLabel`;
export const POPUP_VPIP_DESCRIPTION = `${explanationPopup}.vpipDescription`;
export const POPUP_PFR_LABEL = `${explanationPopup}.pfrLabel`;
export const POPUP_PFR_DESCRIPTION = `${explanationPopup}.pfrDescription`;
export const POPUP_THREE_BET_LABEL = `${explanationPopup}.threeBetLabel`;
export const POPUP_THREE_BET_DESCRIPTION = `${explanationPopup}.threeBetDescription`;
export const POPUP_HANDS_LABEL = `${explanationPopup}.handsLabel`;
export const POPUP_HANDS_DESCRIPTION = `${explanationPopup}.handsDescription`;
export const POPUP_TOURNAMENTS_WINNINGS_LABEL = `${explanationPopup}.tournamentsWinningsLabel`;
export const POPUP_TOURNAMENTS_WINNINGS_DESCRIPTION = `${explanationPopup}.tournamentsWinningsDescription`;
export const POPUP_BLAST_WINNINGS_LABEL = `${explanationPopup}.blastWinningsLabel`;
export const POPUP_BLAST_WINNINGS_DESCRIPTION = `${explanationPopup}.blastWinningsDescription`;
