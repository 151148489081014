export enum AccountAttributes {
  CID = 1, // int
  COUNTRY_ID = 2, // int
  SERIAL = 3, // string
  AGE = 4, // int
  GENDER = 5, // int
  REGISTRATION_DATE = 6, // DateTime
  STATUS_ID = 7, // int
  EMAIL = 8, // string
  USERNAME = 9, // string
  DEMO_ID = 10, // int
  IS_NTS_USER = 11, // bit
  ORIG_CID = 12, // int
  BLOCK_SWITCH = 34,
  IS_DEPOSITED = 45,
  IS_USER_STATUS = 46,
  PLAYER_TYPE = 48, // int
  REGULATION_STATUS = 49, // int (0 - approved temp account, 1 - not approved account)
  DAYS_TO_UPLOAD_DOCS = 50, // int
  REGISTRATION_STATUS = 53,
  TEST_ACCOUNT = 54,
  REALITY_CHECK_GAP = 58, // in minutes
  FIRST_NAME = 61,
  PLAYER_STATUS = 62,
  ADDITIONAL_DATA = 63,
  LAST_NAME = 64,
  IS_UPLOAD_DOCS_REQUIRED = 65,
  FIRST_NAME_UTF8_BASE64 = 66,
  LAST_NAME_UTF8_BASE64 = 67,
}
