import { ReactNode } from "react";
import useGameModalState from "../hooks/useGameModalState";
import { HandStrengthPopupContext } from "./HandStrengthPopupContext";

export function HandStrengthPopupProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { opened, setOpened } = useGameModalState(false, true);

  return (
    <HandStrengthPopupContext.Provider
      value={{
        handStrengthPopupIsOpen: opened,
        setHandStrengthPopupIsOpen: setOpened,
      }}
    >
      {children}
    </HandStrengthPopupContext.Provider>
  );
}
