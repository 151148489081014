import { call, put, race, select, take } from "redux-saga/effects";
import {
  POKER_SC_SET_TURN,
  keepAlive,
  setTurnSuccess,
} from "../../../../network/protocol/gameServer";
import {
  GameServerMessage,
  SetTurn,
} from "../../../../network/protocol/gameServer/types";
import {
  actionMaskType,
  getMaskActions,
} from "../../../../utils/getMaskActions";
import MessagesCommunication from "../../../app/sagas/shared/messagesCommunication";
import {
  betCalled,
  betRaised,
  call as callAction,
  check,
  checked,
  fold,
  folded,
  heroDontShowCards,
  heroShowCards,
  setTurn,
} from "../../actions/actionCreators";
import { getHeroSelector, getPreActionsSelector } from "../../selectors";
import type Player from "../../types/Player";
import PreActions from "../../types/PreActions";
import { RoundTurn } from "../../types/Turn";
import delayShowCards from "./delayShowCards";

const KEEP_ALIVE_INTERVAL = 6000;

export default function* setTurnSaga(
  connection: MessagesCommunication<GameServerMessage>,
) {
  while (true) {
    const message: SetTurn = yield take(connection.messages, POKER_SC_SET_TURN);
    const hero: Player | undefined = yield select(getHeroSelector);

    const { type, mask, ...turnData } = message;
    const turn: RoundTurn = {
      actions: getMaskActions(message.mask),
      ...turnData,
    };

    const isShowhand = Boolean(message.mask & actionMaskType.SHOW_HAND);

    if (hero?.seatId === message.seatId) {
      connection.send(setTurnSuccess());

      const preActions: PreActions = yield select(getPreActionsSelector);

      if (preActions.checkOrFold) {
        if (message.callAmount > 0) {
          yield put(fold());
        } else {
          yield put(check());
        }
      } else if (preActions.check && message.callAmount === 0) {
        yield put(check());
      } else if (preActions.amountToCall === message.callAmount) {
        yield put(callAction());
      } else {
        yield put(setTurn(turn));
      }

      const keepAliveInterval = window.setInterval(() => {
        connection.send(keepAlive());
      }, KEEP_ALIVE_INTERVAL);

      try {
        yield race([
          ...(isShowhand ? [call(delayShowCards, message.timeOut * 0.9)] : []),
          take(betCalled.type),
          take(betRaised.type),
          take(folded.type),
          take(checked.type),
          take(heroShowCards.type),
          take(heroDontShowCards.type),
        ]);
      } finally {
        clearInterval(keepAliveInterval);
      }
    } else {
      yield put(setTurn(turn));
    }
  }
}
